<template>
  <div>
    {{type.name}}
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["typeID"],
  computed: {
    type() {
      return this.$store.getters["matchFormats/typeById"](this.typeID);
    },
  },
  components: {},
  mounted() {

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>