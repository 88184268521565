<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <!--New User-->
    <modal :show.sync="showNewUserForm" size="lg" modal-classes="modal-scrollable">
      <form class="edit-user--form" @submit.prevent="makeNewUser">
        <base-input label="Email" v-model="newUser.email" />
        <base-input label="Display Name" v-model="newUser.name" />
        <base-input label="Slack ID" v-model="newUser.slack" />
        <base-input label="Company">
          <el-select input-classes="form-control" v-model="newUser.company" filterable allow-create
            placeholder="Select here">
            <el-option v-for="prod in this.config.companies" :key="prod" :label="prod" :value="prod">
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Department Default">
          <el-select input-classes="form-control" v-model="newUser.department" clearable
            placeholder="Default department on filters">
            <el-option v-for="prod in this.config.departments" :key="prod" :label="prod" :value="prod">
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Department Restrictions">
          <el-select input-classes="form-control" v-model="newUser.departmentAccess" clearable multiple filterable
            placeholder="Limit access to these departments">
            <el-option v-for="prod in this.config.departments" :key="prod" :label="prod" :value="prod">
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Brand Restrictions">
          <el-select input-classes="form-control" v-model="newUser.brandAccess" clearable filterable multiple
            placeholder="Limit access to these brands">
            <el-option v-for="prod in this.config.brand" :key="prod" :label="prod" :value="prod">
            </el-option>
          </el-select>
        </base-input>
        <div class="form-control-label mb-2">Module Access</div>
        <div class="container">
          <div class="row">
            <div class="col-6">
              <base-checkbox type="info" v-model="newUser.roles.creative_catalogue_access">
                Creative Catalogue: Access
              </base-checkbox>
              <base-checkbox type="success" v-model="newUser.roles.production_access">
                Production: Access
              </base-checkbox>

              <base-checkbox type="success" v-model="newUser.roles.inspirations_access">
                Inspirations: Access
              </base-checkbox>

            </div>
            <div class="col-6">
              <base-checkbox type="danger" v-model="newUser.roles.archive_access">
                Match Archive: Access
              </base-checkbox>
              <base-checkbox type="danger" v-model="newUser.roles.archive_search">
                Match Archive: Recordings Clipping
              </base-checkbox>
              <base-checkbox type="yellow" v-model="newUser.roles.fcc_access">
                FCC Video: Access
              </base-checkbox>

              <base-checkbox type="primary" v-model="newUser.roles.livestream_access">
                Livestreaming: Access
              </base-checkbox>
            </div>
          </div>
        </div>
        <div class="form-control-label mb-2 mt-4">Priviledges</div>
        <div class="container mb-4">
          <div class="row">
            <div class="col-6">
              <el-tooltip placement="left" content="See drafts of deliverables">
                <base-checkbox type="success" v-model="newUser.roles.production_drafts">
                  Production: Draft Deliverables
                </base-checkbox>
              </el-tooltip>
              <el-tooltip placement="left" content="Add feedback to drafts, create empty deliverables and draft briefs">

                <base-checkbox type="success" v-model="newUser.roles.production_feedback">
                  Production: Feedbacker
                </base-checkbox>
              </el-tooltip>
              <el-tooltip placement="left" content="See and edit the work planner">
                <base-checkbox type="success" v-model="newUser.roles.production_shifts">
                  Production: Scheduler
                </base-checkbox>
              </el-tooltip>
              <el-tooltip placement="left" content="Submit briefs">
                <base-checkbox type="success" v-model="newUser.roles.production_admin">
                  Production: Admin
                </base-checkbox>
              </el-tooltip>
            </div>
            <div class="col-6">
              <el-tooltip placement="left" content="Create auto-signed-off deliverables by uploading files to briefs">
                <base-checkbox type="success" v-model="newUser.roles.production_new_deliberables">
                  Production: Upload Signed Off Deliverables
                </base-checkbox>
              </el-tooltip>
              <base-checkbox type="danger" v-model="newUser.roles.archive_uploader">
                Match Archive: Admin
              </base-checkbox>
              <base-checkbox type="default" v-model="newUser.roles.user_admin">
                Users: Admin
              </base-checkbox>
            </div>
          </div>
        </div>
        <div class="row" v-if="newUser.roles.creative_catalogue_access || newUser.roles.production_access">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <h4>Creative Catalogue Restrictions</h4>
              <small>Users can access all content unless restrictions are specified - if so, then only the selected areas
                can be accessed</small>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <base-input label="Departments">
                    <el-select input-classes="form-control" v-model="newUser.departmentAccess" clearable multiple filterable
                      placeholder="Access Everything">
                      <el-option v-for="prod in this.config.departments" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Brands">
                    <el-select input-classes="form-control" v-model="newUser.brandAccess" clearable filterable multiple
                      placeholder="Access Everything">
                      <el-option v-for="prod in config.brand" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Partners">
                    <el-select input-classes="form-control" v-model="newUser.parnterAccess" clearable filterable multiple
                      placeholder="Access Everything">
                      <el-option v-for="prod in config.partners" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="row" v-if="newUser.roles.archive_access">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <h4>Match Video Catalogue Access</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Internationals</label>
                    <div class="col">
                      <base-switch v-model="newUser.accessInt" type="warning" on-text="Yes" off-text="No"
                       ></base-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group permission-form" v-if="newUser.accessInt">

                <div class="row mb-3">
                  <label class="col-auto form-control-label">Access games from the current year</label>
                  <div class="col">
                    <base-switch v-model="newUser.accessIntCurrent" type="warning" on-text="Yes" off-text="No"
                      ></base-switch>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <base-input label="Restrict user to:">
                      <el-select v-model="newUser.accessIntFields" filterable multiple placeholder="Add Access Controls"
                        >
                        <el-option v-for="option in internationalFilterOptionsArray" :key="option.field"
                          :label="option.label" :value="option.field">
                        </el-option>
                      </el-select>
                    </base-input>

                    <div class="row align-items-center">
                      <div class="col-4" v-for="filter in newUser.accessIntFields" :key="filter">
                        <filter-selector v-if="newUser.accessIntPermissions" :selection="internationalFilterOptions[filter]"
                         
                         >
                        </filter-selector>
                        <filter-selector v-else :selection="internationalFilterOptions[filter]"
                   >
                        </filter-selector>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Domestic</label>
                    <div class="col">
                      <base-switch v-model="newUser.accessDom" type="warning" on-text="Yes" off-text="No"
                  ></base-switch>
                    </div>
                  </div>
                  <div class="form-group permission-form" v-if="newUser.accessDom">

                    <div class="row mb-3">
                      <label class="col-auto form-control-label">Access games from the current year</label>
                      <div class="col">
                        <base-switch v-model="newUser.accessDomCurrent" type="warning" on-text="Yes" off-text="No"
                      ></base-switch>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <base-input label="Restrict user to:">
                          <el-select v-model="newUser.accessDomFields" filterable multiple placeholder="Add Access Controls"
                           >
                            <el-option v-for="option in domesticFilterOptionsArray" :key="option.field"
                              :label="option.label" :value="option.field">
                            </el-option>
                          </el-select>
                        </base-input>

                        <div class="row align-items-center">
                          <div class="col-4" v-for="filter in newUser.accessDomFields" :key="filter">
                            <filter-selector v-if="newUser.accessDomPermissions" :selection="domesticFilterOptions[filter]"
             
                             >
                            </filter-selector>
                            <filter-selector v-else :selection="domesticFilterOptions[filter]"
                             >
                            </filter-selector>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
      <div class="row">
        <div class="col-3">
          <base-button block type="default" @click="showNewUserForm = false">Close</base-button>
        </div>
        <div class="col-3" v-if="checkNewUserFormValid">
          <base-button block type="warning" @click="makeNewUser">Create</base-button>
        </div>
      </div>
    </modal>
    <!--     Bulk  Edit User-->
    <modal :show.sync="showBulkEditModal" size="lg" modal-classes="modal-scrollable">
      <batch-edit :selection="multipleSelection"></batch-edit>
      <div class="row">
        <div class="col-3">
          <base-button block type="default" @click="showBulkEditModal = false">Close</base-button>
        </div>
      </div>
    </modal>
    <!--    Pending Table -->
    <div class="container-fluid mt--9" v-if="loading === false">
      <div class="row" v-if="user.roles.user_admin && pending.length > 0">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <h3 class="mb-0">Pending Approval</h3>
            </div>
            <ul class="list-group list-group-flush list">
              <li class="list-group-item" v-for="user in pending" :key="user.id">
                <div class="row">
                  <div class="col">
                    <user-entry :user="user"> </user-entry>
                  </div>
                </div>
              </li>
            </ul>


          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="row">
                <div class="col-auto h3">Users</div>
                <div class="col"></div>
                <div class="col-auto">
                  <base-button type="secondary" size="sm" @click="newUserForm()">Add User</base-button>
                </div>
                <div class="col-auto mb-0" v-show="multipleSelection.length > 0">
                  <base-button type="secondary" size="sm" @click.prevent="clearSelection">Clear Selection</base-button>
                  <base-button type="primary" size="sm" @click.prevent="showBulkEditModal = true">Edit
                    Selected</base-button>
                </div>
              </div>
              <div class="row align-items-center mt-2 mb--4">
                <div class="col">
                  <form @submit.prevent="runQuery">
                    <base-input v-model="search" placeholder="Search Name and Email" prepend-icon="fa fa-search" />
                  </form>
                </div>


                <div class="col-2 text-right">
                  <base-input>
                    <el-select v-model="companyFilter" placeholder="Company" clearable filterable
                      @change="runQuery(true)">
                      <el-option v-for="option in config.companies" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-2 text-right">
                  <base-input>
                    <el-select v-model="roleFilter" placeholder="Permission" clearable @change="runQuery(true)">
                      <el-option v-for="option in roles" :key="option.role" :label="option.label" :value="option.role">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <!-- <div class="col-2">
                  <base-checkbox class="mb-4" v-model="deactivated" @input="runQuery(true)">
                    Include Disabled
                  </base-checkbox>
                </div> -->

              </div>
            </div>

            <el-table style="width: 100%" max-height="800" class="align-items-center" header-row-class-name="thead-light"
              lazy :data="users" @selection-change="handleSelectionChange" @sort-change="handleSortChange"
              ref="usersTable" :default-sort="{ prop: 'last_log_in._seconds', order: 'descending' }">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="Login" prop="email" min-width="220px" sortable="custom">
                <div slot-scope="{ row }" class="table-actions">
                  <a :href="`#/user/${row.id}`">{{ row.email }}</a>
                </div>
              </el-table-column>
              <el-table-column label="Display Name" min-width="150px" prop="name" sortable="custom" />
              <el-table-column label="Last Login" min-width="150px" prop="last_log_in._seconds" sortable="custom"
                :formatter="dateFormat" />
              <!-- <el-table-column label="Slack ID" min-width="90px" prop="slack">
                <template slot-scope="scope">
                  <i v-if="scope.row.slack" class="fa fa-check"></i>
                </template>
              </el-table-column> -->
              <el-table-column label="Company" prop="company" min-width="130px" sortable="custom" />

              <!-- <el-table-column label="Enabled" prop="active" min-width="50px" header-align="center" align="center">
                <template slot-scope="scope">
                  <i v-if="scope.row.active" class="fa fa-check"></i>
                </template>
              </el-table-column> -->
              <!-- <el-table-column  label="Permissions" prop="roles">
                <template slot-scope="scope">
                  <roles-length :roles="scope.row.roles"> </roles-length>
                </template>
             
              </el-table-column> -->

              <el-table-column label="Rights" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.archive_access" class="fa fa-check mr-1"></i>
                  <i v-if="scope.row.roles.archive_uploader" class="fa fa-tools mr-1"></i>
                </template>
              </el-table-column>
              <el-table-column label="International" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.accessInt" class="fa fa-check mr-1"></i>
                  <i v-if="scope.row.accessInt && scope.row.accessIntCurrent" class="fa fa-calendar mr-2"></i>
                  <badge v-for="item in scope.row.accessIntFields" type="default">{{ item }}</badge>

                </template>
              </el-table-column>
              <el-table-column label="Domestic" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.accessDom" class="fa fa-check mr-1"></i> <i
                    v-if="scope.row.accessDom && scope.row.accessDomCurrent" class="fa fa-calendar mr-2"></i>
                  <badge v-for="item in scope.row.accessDomFields" type="default">{{ item }}</badge>
                </template>
              </el-table-column>
              <el-table-column label="Production" prop="roles" min-width="130">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.production_access" class="fa fa-check"></i>
                  <i v-if="scope.row.roles.production_drafts" class="fa fa-drafting-compass ml-1"></i>
                  <i v-if="scope.row.roles.production_feedback" class="fa fa-comments ml-1"></i>
                  <i v-if="scope.row.roles.production_shifts" class="fa fa-clock ml-1"></i>
                  <i v-if="scope.row.roles.production_new_deliberables" class="fa fa-upload ml-1"></i>
                  <i v-if="scope.row.roles.production_admin" class="fa fa-tools ml-1"></i>
                </template>
              </el-table-column>
              <el-table-column label="Restrictions" min-width="110">
                <template slot-scope="scope">
                  <badge v-for="item in scope.row.departmentAccess" type="danger">{{ item }}</badge>
                  <badge v-for="item in scope.row.brandAccess" type="success">{{ item }}</badge>
                  <badge v-for="item in scope.row.partnerAccess" type="primary">{{ item }}</badge>
                </template>
              </el-table-column>
              <el-table-column label="Catalogue" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.creative_catalogue_access" class="fa fa-check"></i>
                  <i v-if="scope.row.roles.creative_catalogue_upload" class="fa fa-tools ml-1"></i>
                </template>
              </el-table-column>
              <el-table-column label="FCC" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.fcc_access" class="fa fa-video"></i>
                  <i v-if="scope.row.roles.livestream_access" class="ni ni-world-2 ml-1"></i>
                </template>
              </el-table-column>
              <el-table-column label="Inspirations" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.inspirations_access" class="fa fa-check"></i>
                </template>
              </el-table-column>
              <el-table-column label="User Admin" prop="roles">
                <template slot-scope="scope">
                  <i v-if="scope.row.roles.user_admin" class="fa fa-check"></i>
                </template>
              </el-table-column>

            </el-table>
            <div slot="footer" class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
                px-4 py-3
              ">
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.from + 1 }} to {{ pagination.to }} of {{ searchHits }} users
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="currentPage" :per-page="pagination.chunkSize"
                :total="searchHits" @input="changePage($event)"></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";
import RolesLength from "./Components/RolesLength.vue";
import FilterSelector from "../Archive/Components/FilterSelector";
import UserEntry from "./Components/UserEntry";
import BatchEdit from './Components/BatchEdit.vue'
import moment from 'moment'
import { Firebase } from "@/firebase";
import { TimeSelect } from "element-ui";

export default {
  name: "users",
  components: {

    "roles-length": RolesLength,
    "user-entry": UserEntry,
    "batch-edit": BatchEdit,
    "filter-selector": FilterSelector,
  },
  data() {
    return {
      batchUsers: {},
      showNewUserForm: false,
      multipleSelection: [],
      newUser: {
        name: "",
        company: "",
        email: "",
        brandAccess: "",
        active: true,
        feedbackEmail: true,
        departmentAccess: "",
        defaultDepartment: "",
        slack: "",
        roles: {
          creative_catalogue_access: false,
          archive_access: false,
          archive_search: false,
          archive_upload: false,
          creative_catalogue_upload: false,
          fcc_access: false,
          production_access: false,
          production_drafts: false,
          production_feedback: false,
          production_new_deliberables: false,
          production_admin: false,
          inspirations_access: false,
          livestream_access: false,
          user_admin: false,
        },
      },
      roles: [
        {
          role: "archive_access",
          label: "Archive: Access",
        },
        {
          role: "archive_search",
          label: "Archive: Clipping",
        },
        {
          role: "archive_upload",
          label: "Archive: Uploader",
        },
        {
          role: "creative_catalogue_access",
          label: "Creative Catalogue: Access",
        },
        // {
        //   role: "creative_catalogue_upload",
        //   label: "Creative Catalogue: Uploader",
        // },
        {
          role: "fcc_access",
          label: "FCC Video: Access",
        },
        {
          role: "production_access",
          label: "Production: Access",
        },
        {
          role: "production_drafts",
          label: "Production: Draft Deliverables",
        },
        {
          role: "production_feedback",
          label: "Production: Add Feedback",
        },
        {
          role: "production_new_deliberables",
          label: "Production: Create New Deliverables",
        },
        {
          role: "production_admin",
          label: "Production: Admin",
        },
        {
          role: "inspirations_access",
          label: "Inspirations: Access",
        },
        {
          role: "livestream_access",
          label: "Livestreaming: Access",
        },
        {
          role: "user_admin",
          label: "User Admin",
        },
      ],
      internationalFilterOptions: {
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "multi-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "multi-select",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home or Away Teams",
          type: "multi-select",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "multi-select",
        },
      },
      domesticFilterOptions: {
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "multi-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "multi-select",
        },
        teamId1: {
          field: "teamId1",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home Team Only",
          type: "multi-select",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home or Away Teams",
          type: "multi-select",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "multi-select",
        },
      },
      showEditModal: false,
      showBulkEditModal: false,
      showAddModal: false,
      departmentFilter: "",
      companyFilter: "",
      roleFilter: "",
      // deactivated: false,
      search: "",
      users: [],
      searchHits: 0,
      currentPage: 1,
      searchPages: 0,
      searchReturn: 0,
      pagination: {
        default: 1,
        chunkSize: 30,
        from: 0,
        to: 0,
      },
      sortField: 'last_log_in._seconds',
      sortOrder: 'desc',
      pending: []
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    departments() {
      return this.config.departments;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    checkNewUserFormValid() {
      if (
        this.newUser.company != "" &&
        this.newUser.email != "" &&
        this.newUser.name != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    internationalFilterOptionsArray() {
      return Object.values(this.internationalFilterOptions);
    },
    domesticFilterOptionsArray() {
      return Object.values(this.domesticFilterOptions);
    },
  },
  beforeMount() {
    this.roles.forEach((role) => {
      this.model.roles[role.role] = false;
    });
  },
  mounted() {
    // this.$store.dispatch("preApprovedUsers/openDBChannel");
    // let where = [['active', '==', 'false']]
    // this.$store.dispatch("users/openDBChannel", {clauses: { where, limit: 100 }});
    this.runQuery()
    this.getPending()
    this.$store.dispatch("teams/fetchAndAdd");  // can remove this after development
    this.$store.dispatch("venues/fetchAndAdd"); // can remove this after development
  },
  methods: {
    dateFormat(row, col, value, index) {
      if (!value) {
        return null
      } else {
        let v
        if (value) {
          v = new Date(value * 1000)
        } else if (value) {
          v = new Date(value * 1000)
        }
        else {
          v = value
        }
        return moment(v).calendar(null, {
          sameDay: '[Today] hh:mm a',
          nextDay: '[Tomorrow] hh:mm a',
          nextWeek: 'dddd hh:mm a',
          lastDay: '[Yesterday] hh:mm a',
          lastWeek: '[Last] dddd hh:mm a',
          sameElse: 'DD/MM/YYYY hh:mm a'
        });
      }
    },
    newUserForm() {
      if (this.newUser.id) {
        delete this.newUser.id
      }
      this.showNewUserForm = true;
    },
    makeNewUser() {
      this.newUser.email = this.newUser.email.toLowerCase();
      this.$store.dispatch("users/insert", this.newUser);
      this.showNewUserForm = false;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changePage(value) {
      console.log(value)
      this.pagination.from = (value - 1) * this.pagination.chunkSize;
      this.runQuery(false)
    },
    handleSortChange(event) {
      console.log(event.prop)
      console.log(event.order)
      if (event.prop === 'last_log_in._seconds') {
        this.sortField = event.prop
      } else {
        this.sortField = event.prop + '.keyword'  // add keyword onto strings for elasticsearch to work, as it can't sort text without this
      }
      if (event.order === 'ascending') {
        this.sortOrder = 'asc'
      } else if (event.order === 'descending') {
        this.sortOrder = 'desc'
      } else {
        this.sortOrder = 'null'
      }
      this.runQuery(true)
    },
    async getPending() {

      let payload = {
        response: null,
        request: {
          index: "users",
          body: {
            sort: [

            ],
            from: 0,
            size: 100,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [{ match: { active: false } }],
              },
            },
          },
        },
      };

      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
      console.log(result.id);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            console.log(d);
            this.pending = []
            d.response.hits.hits.forEach(res => {

              //flatten the time object
              let obj = res._source
              this.pending.push(obj)
            })

          }
        });
    },
    async runQuery(reset) {
      let starter = reset ? 0 : this.pagination.from;
      this.pagination.from = starter;

      let payload = {
        response: null,
        request: {
          index: "users",
          body: {
            sort: [

            ],
            from: starter,
            size: this.pagination.chunkSize,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
              },
            },
          },
        },
      };
      //sorting
      if (this.sortOrder != 'null') {
        let sort = {}
        sort[this.sortField] = { order: this.sortOrder }
        payload.request.body.sort.push(sort)
      }
      //searching
      if (this.search) {
        payload.request.body.query.bool.should.push({
          match: { name: { query: this.search.toLowerCase(), fuzziness: "AUTO" } },
        })
        payload.request.body.query.bool.should.push({
          match: { email: { query: this.search.toLowerCase(), fuzziness: "AUTO" } },
        })
        payload.request.body.query.bool.minimum_should_match = 1
      }

      if (this.companyFilter) {
        payload.request.body.query.bool.filter.push({
          match: { company: { query: this.companyFilter, operator: "and" } },
        })
      }

      if (this.roleFilter) {
        let temp = { match: {} }
        let text = 'roles.' + this.roleFilter
        temp.match[text] = true

        payload.request.body.query.bool.filter.push(temp)
      }

      // if(!this.deactivated) {
      //   payload.request.body.query.bool.filter.push({
      //     match: { active: { query: true } },
      //   })
      // }
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
      console.log(payload.request.body.query.bool);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things

            this.users = []
            d.response.hits.hits.forEach(res => {

              //flatten the time object
              let obj = res._source
              this.users.push(obj)
            })
            this.searchHits = d.response.hits.total.value;
            this.searchReturn = d.response.hits.hits.length;
            this.pagination.to = this.pagination.from + this.searchReturn;
            this.searchPages = Math.ceil(
              this.searchHits / this.pagination.chunkSize
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.table-flush {
  border-radius: 10px;
}
</style>
