<template>
  <div>
    <base-header
      class="pb-6 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Main</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <base-input
                    label="Display Name"
                    v-model="resource.displayName"
                    @change="changeField($event.target.value, 'displayName')"
                  />
                  <base-input label="Company">
                    <el-select
                      v-model="resource.company"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'company')"
                    >
                      <el-option
                        v-for="option in config.producer"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Available Billings">
                    <el-select
                      v-model="resource.availableBillings"
                      filterable
                      multiple
                      placeholder="Select"
                      @change="changeField($event, 'availableBillings')"
                    >
                      <el-option
                        v-for="option in billings"
                        :key="option.id"
                        :label="option.label"
                        :value="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Default Billing">
                    <el-select
                      v-model="resource.defaultBilling"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'defaultBilling')"
                    >
                      <el-option
                        v-for="option in selectedBillings"
                        :key="option.id"
                        :label="option.label"
                        :value="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>

                  <base-input
                    label="Group"
                    v-model="resource.group"
                    @change="changeField($event.target.value, 'group')"
                  />
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Active</label>
                    <div class="col">
                      <base-switch
                        v-model="resource.active"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'active')"
                      ></base-switch>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">User</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Is a User</label>
                    <div class="col">
                      <base-switch
                        v-model="resource.isPerson"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'isPerson')"
                      ></base-switch>
                    </div>
                  </div>
                  <base-input label="User" v-if="resource.isPerson">
                    <el-select
                      v-model="resource.user"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'user')"
                    >
                      <el-option
                        v-for="option in users"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Shifts</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col">Total Days: {{ shiftsTotal }}</div>
                </div>
                <!-- <div class="row">
                  <div class="col">
                    <base-input label="Transfer">
                      <el-select
                        v-model="transfer"
                        filterable
                        placeholder="Select transfer"
                      >
                        <el-option
                          v-for="option in resources"
                          :key="option.id"
                          :label="option.displayName + ' - ' + option.group"
                          :value="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-auto">
                    <base-button @click="transferAction">Transfer</base-button>
                  </div>
                </div> -->
                <div class="row" v-if="shiftsTotal === 0">
                  <div class="col">
                    <base-button @click="deleteResource">Delete</base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "resource",
  data: function () {
    return {
      transfer: "",
    };
  },
  beforeMount() {
    this.$store.dispatch("resources/fetchAndAdd");

    this.$store.dispatch("shifts/fetchAndAdd", {
      where: [["resourceId", "==", this.$route.params.id]],
      limit: 0,
    });
  },
  computed: {
    resourceId() {
      return this.$route.params.id;
    },
    users() {
      return this.$store.getters["users/users"];
    },
    resource() {
      return this.$store.getters["resources/byId"](this.$route.params.id);
    },
    resources() {
      return this.$store.getters["resources/resources"];
    },
    billings() {
      return this.$store.getters["billings/active"]
    },
    selectedBillings() {
      let output = [];
      if (this.resource.availableBillings) {
        let bills = this.resource.availableBillings;
        bills.forEach((b) => {
          output.push(this.$store.getters["billings/byId"](b));
        });

        return output;
      } else {
        return this.billings;
      }
    },
    shifts() {
      return this.$store.getters["shifts/byResourceId"](this.$route.params.id);
    },
    shiftsTotal() {
      let total = 0;
      this.shifts.forEach((a) => {
        total = total + a.days;
      });
      return total;
    },
    groups() {
      const resources = this.resources;
      let groups = [];
      for (const element of resources) {
        groups.push(element.group);
      }
      const groupsInter = [...new Set(groups)];
      let groupsTwo = [];
      for (const element of groupsInter) {
        groupsTwo.push({
          text: element,
          value: element,
        });
      }
      return groupsTwo;
    },
    config() {
      return this.$store.getters["config/config"];
    },
  },
  methods: {
    changeField(value, field) {
      var payload = {};
      payload.id = this.resourceId;
      payload[field] = value;
      this.$store.dispatch("resources/patch", payload);
    },
    transferAction() {
      this.shifts.forEach((shift) => {
        this.$store.dispatch("shifts/patch", {
          id: shift.id,
          resourceId: this.transfer,
        });
      });
    },
    deleteResource() {
      this.$store.dispatch("resources/delete", this.resourceId);
    },
  },
};
</script>
