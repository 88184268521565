const shoots = {
  firestorePath: 'shoots',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'shoots',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      date: '%convertTimestamp%',
    },
  },


  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    byId: (state) => (id) => {
      return state.data[id]
    },
    loaded: (state) => {
      return Object.values(state.data)
    },
    byBriefId: (state) => (id) => {
      const shoots = Object.values(state.data)
      return shoots.filter(b => b.productionId === id)
    },
    // calendar: (state) => {
    //   var rotas = []

    //   var stateList = Object.values(state.data)
    //   stateList.forEach(function (entry) {
    //     entry.shiftId = entry.id
    //     entry.title = entry.title + ' - ' + entry.shootTitle
      
    //       entry.constraint = {
    //         resourceIds: ["shoots"]
    //     }

    //     if ( entry.shootStatus === 'pencilled') {
    //       entry.className = 'pencilled'
    //     }
    //     if ( entry.shootStatus === 'confirmed') {
    //       entry.className = 'confirmed'
    //     }
    //     if ( entry.shootStatus === 'cancelled') {
    //       entry.className = 'cancelled'
    //     }
    //     rotas.push(entry)
    //   })
    //   return rotas
    // }
  },
  mutations: {},
  actions: {},
}

export default shoots