import { Firebase } from '../../firebase.js'
// import firebase from 'firebase'
import user from './user.js'
import { eachDay } from 'date-fns'

const users = {
  firestorePath: 'users',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'users',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    users: state => {
    let all = Object.values(state.data)
    return all.filter(user => user.active)
  },
  byId: (state) => (id) => {return state.data[id]},
  feedbackers: state => {
    let all = Object.values(state.data)
    let admins = []
    all.forEach(usr => {
      if (usr.roles) {
        if (usr.roles.production_feedback) {
          console.log(usr)
          admins.push(usr)
        }
      }
    })
    return admins
  },
  pendingApproval: state => {
    let all = Object.values(state.data)
    return all.filter(user => !user.active)
  },
  roles: state => (id) => {
    return state.data[id].roles
  },
  companies: state => {
    let companies = []
    let all = Object.values(state.data)
    for (const element of all) {
      companies.push(element.company)
    }
   let uniq = [...new Set(companies)];
   uniq.sort()
    return uniq
  },
  },
  mutations: {},
  actions: {
  },
}

export default users