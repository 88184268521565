<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="mt--7">
      <div class="container-fluid mt--7">
<new-inspiration></new-inspiration>
</div>
    </div>
  </div>
</template>
<script>
import NewInspirationComponent from "./Components/NewInspirationComponent";

export default {
  name: "new-inspiration-page",
  data() {
    return {

    };
  },
  components: {
    "new-inspiration": NewInspirationComponent,
  },
  computed: {
   
  },
  methods: {
  
  },
  beforeMount() {},
  watch: {},
};
</script>
<style>
</style>
