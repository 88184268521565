
<template>
  <div>
    <card>
      <file-preview
        :file="file"
        slot="image"
        class="card-img-top"
        v-if="inspiration.what === 'file'"
      ></file-preview>

      <img
        v-else-if="inspiration.capturedExtFiles"
        slot="image"
        class="card-img-top"
        :src="chosenImage"
      />
      <!--        <div class="card-body">-->
      <h4 class="mb-1">
        <i :class="icon" class="mr-2" v-if="icon"></i>{{ inspiration.author }}
      </h4>
      <badge class="mb-1">{{ inspiration.type }}</badge>
      <div class="mb-2" v-if="inspiration.tags">
        <badge type="warning" class="mr-1" v-for="tag in inspiration.tags">{{ tag }}</badge>
      </div>
      <div>
        <small>{{ inspiration.title }} </small>
      </div>
      <!-- <p class="card-text">{{ link.description }}</p> -->

      <div class="small mt-3" v-if="inspiration.created_at">{{ inspiration.created_at | dateTime }}</div>
      <div class="row mt-3">
        <div class="col-auto">
          <i class="fa fa-heart text-danger mr-3" v-if="isFavourite"></i>
          <a v-bind:href="`/#/inspiration/${inspiration.id}`">
            <i class="fa fa-comments mr-1" v-if="inspiration.commentsNumber"></i
            ><span v-if="inspiration.commentsNumber" class="mr-1">{{
              inspiration.commentsNumber
            }}</span
            ><span v-else>Edit</span></a
          >
        </div>
        <div class="col text-right" v-if="inspiration.url">
          <a v-bind:href="inspiration.url">Visit</a>
        </div>
      </div>

      <!--        </div>-->
    </card>
  </div>
</template>
<script>
import router from "@/routes/router";

export default {
  name: "inpsiration",
  data: function () {
    return {
      link: {},
    };
  },
  props: ["inspiration"],
  async beforeMount() {
    // if (!this.inspiration.scraped && !this.inspiration.file) {
    //   console.log(this.inspiration);
    //   this.$store.dispatch("inspirations/loadInspiration", this.inspiration);
    // }
    if (this.inspiration.file && !this.file) {
      await this.$store.dispatch("files/fetchById", this.inspiration.file);
    }
    this.$store.dispatch("files/fetchAndAdd", {
      where: [["inspiration", "==", this.inspiration.id]],
    });
  },
  components: {},
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    // poster() {

    //     if (this.inspiration.created_by) {
    //       let user = this.$store.getters["users/byId"](this.inspiration.created_by)
    //       if (user) {
    //         return user;
    //       } else {
    //         this.$store.dispatch("users/fetchById", this.inspiration.created_by);
    //       }
    
    //   } else {
    //     return false;
    //   }
    // },
    icon() {
      if (this.inspiration.platform) {
        switch (this.inspiration.platform.toLowerCase()) {
          case "instagram":
            return "fab fa-instagram";
            break;
          case "twitter":
            return "fab fa-twitter";
            break;
          case "youtube":
            return "fab fa-youtube";
            break;
          case "facebook":
            return "fab fa-facebook";
            break;
            case "tiktok":
            return "fab fa-tiktok";
            break;
          case "linkedin":
            return "fab fa-linkedin";
            break;
        }
      } else {
        return false;
      }
    },
    isFavourite() {
      if (this.inspiration.favourites) {
        if (this.inspiration.favourites[this.user.id]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    chosenImage() {
      let obj = this.$store.getters["files/fileById"](
        this.inspiration.chosenImage
      );
      if (obj) {
        return obj.sourceDownloadURL
      }
    },
    file() {
      if (this.inspiration.file) {
        let f = this.$store.getters["files/byId"](this.inspiration.file);
        return f;
      } else {
        return false;
      }
    },
  },
  methods: {
    // changeField(value, field) {
    //   var payload = {};
    //   payload.id = this.$route.params.id;
    //   payload.value = value.target.value;
    //   payload.field = field;
    //   console.log(payload);
    //   this.$store.dispatch("changeInspirationField", payload);
    // },
  },
};
</script>
<style>
</style>
