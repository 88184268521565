const rtmpOutputs = {
    firestorePath: 'config/default/rtmpOutputs',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'rtmpOutputs',
    statePropName: 'data',
    namespaced: true, // automatically added
  
    // this object is your store module (will be added as '/myModule')
    // you can also add state/getters/mutations/actions
    state: {
    },
    getters: {
      array: state => {
        let arr = Object.values(state.data)
        return arr.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          if (!a.name) {
            return -1
          }
          return 0
        })
      },
      obj: state => state.data,
      byId: (state) => (id) => {
        return state.data[id]
      },
      byKey: (state) => (key) => {
        let array = Object.values(state.data)
        return array.filter(r => r.key === key)[0]
      },
    },
    mutations: {},
    actions: {},
  }
  
  export default rtmpOutputs