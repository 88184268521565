import moment from 'moment'

const deliverables = {
  firestorePath: 'deliverables',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'deliverables',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    dels: (state) => Object.values(state.data),
    signed: (state) => {
      const dels = Object.values(state.data)
      return dels.filter(del => del.state === 'signed')
    },
    byCollection: (state) => (colID) => {
      const dels = Object.values(state.data)
      let de1 = dels.filter(del => del.collections.includes(colID))
      return de1.filter(del => del.state === 'signed')
    },
    delsByProdID: (state) => (id) => {
      const dels = Object.values(state.data)
      return dels.filter(del => del.production === id)
    },
    delById: (state) => (id) => {
      const dels = Object.values(state.data)
      return dels.filter(del => del.id === id)[0]
    },
    delsMerged(state, getters, rootState, rootGetters, dispatch) {
      // this is to add production info to all the deliverables

      let dels = Object.values(state.data)
      // now do the "join":
      if (dels.length > 0) {
        dels.forEach(function (del, index, object) {

          //combi title is for searching through both deliverable title and production title
          del.combiTitle = del.productionTitle + ' ' + del.title
          if (del.updated_at) {
            // del.sortCreated = new Date(del.updated_at.seconds * 1000)
            del.sortCreated = del.updated_at
          } else if (del.created_at) {
            //del.sortCreated = new Date(del.created_at.seconds * 1000)
            del.sortCreated = del.created_at
          } else {
            console.log('error timestamping: ' + del.id + ' ' + del.title)
            del.sortCreated = new Date()
          }
          var deadline = moment(del.deadline, 'YYYY-MM-DD').endOf("day")
          del.overdue = deadline.isBefore()
          del.due = deadline.calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'DD/MM/YYYY'
          })
          if (!del.deadline) {
            del.due = false
            del.overdue = false
          }

        });
      }
      return dels
    }
  },
  mutations: {},
  actions: {
  },
}

export default deliverables