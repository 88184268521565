<template>
  <div class="mb-4">
    <div class="row">
      <div class="col">
        {{ comment.text }}
      </div>
      <div class="col-auto">
        <div class="btn btn-sm" @click="editModal = true">Edit</div>
      </div>
    </div>
    <div class="row">
      <small class="col">{{ poster.name }}</small>
    </div>
    <modal :show.sync="editModal">
      <template slot="header">
        <h5 class="modal-title">Edit Note</h5>
      </template>
      <div>
        <base-input>
          <textarea
            @change="changeComment"
            class="form-control"
            v-model="comment.text"
            box
            placeholder="Leave a comment here..."
            type="text"
            rows="7"
          />
        </base-input>
      </div>
      <template slot="footer">
        <base-button type="danger" @click="deleteComment()"
          >Delete Note</base-button
        >
        <base-button type="primary" @click="editModal = false"
          >Done</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
export default {
  name: "inspiration-comment",
  data() {
    return {
      editModal: false,
    };
  },
  components: {},
  props: ["inspiration", "comment"],
  computed: {
    poster() {
      if (this.comment) {
        if (this.comment.created_by) {
          let user = this.$store.getters["users/byId"](this.comment.created_by);
          if (user) {
            return user;
          } else {
            this.$store.dispatch("users/fetchById", this.comment.created_by);
          }
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    deleteComment() {
      this.$store.dispatch("inspirationComments/delete", this.comment.id);
      this.editModal = false;
    },
    changeComment() {
      var payload = {};
      payload.id = this.comment.id;
      payload.text = this.comment.text;
      this.$store.dispatch("inspirationComments/patch", payload);
    },
  },
};
</script>

<style>
.noteTextEdit {
  cursor: pointer;
}
.textBit:first-line {
  /* line-height: 0; */
  white-space: pre-line;
}
.noteTextEdit:hover {
  color: #5e72e4;
}
.noteDone {
  text-decoration: line-through;
}
</style>
