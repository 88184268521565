<template>
  <div class="mb-4">
    <div class="row">
      <!-- tick button -->
      <div v-if="fileStatus === 'edit'" class="col-auto pr-0">
        <base-button
          v-if="!note.done"
          size="sm"
          outline
          type="default"
          @click="toggleDone"
          ><i class="ni ni-check-bold"></i
        ></base-button>
        <base-button v-else size="sm" type="default" @click="toggleDone"
          ><i class="ni ni-check-bold"></i
        ></base-button>
      </div>
      <!-- timecode button -->
      <div v-if="note.tc" class="col-auto pr-0">
        <base-button
          size="sm"
          outline
          type="default"
          @click="this.timeButton"
          >{{ this.time }}</base-button
        >
      </div>
            <!-- PDF button -->
      <div v-if="note.pdf">
        <base-button
          size="sm"
          outline
          type="primary"
          @click="this.pdfPage"
          >{{ note.pdfPage }}</base-button
        >
      </div>
      <div class="col">
        <div
          class="row"
          :class="{ noteDone: note.done, noteTextEdit: editable }"
        >
          <div class="col textBit" @click="edit">
            {{ note.text }}
          </div>
        </div>
        <div class="row">
          <small class="col mt-1">{{ note.author }}</small>
          <div
            class="col-auto btn-sm btn mr-2"
            @click="responseModal = true"
            v-if="fileStatus === 'edit'"
          >
            <i class="fa fa-comments"></i>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div v-for="res in responses" class="mt-2">
              <response :note="res" :delID="delID"></response>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="editModal">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Note</h5>
      </template>
      <div>
        <base-input>
          <textarea
            @change="changeNote"
            class="form-control"
            v-model="note.text"
            box
            placeholder="Note text here..."
            type="text"
            rows="10"
          />
        </base-input>
      </div>
      <div class="row" v-if="assetType === 'video'">
        <div class="col">
          <base-checkbox v-model="note.tc" @input="changeNote"
          > Timecoded note </base-checkbox>
        </div>
        <div class="col" v-if="note.tc">
          <base-input
            label="Time in seconds"
            @input="changeNote"
            v-model="note.seconds"
            type="number"
          />
        </div>
      </div>
      <template slot="footer">
        <base-button type="danger" @click="deleteNote()"
          >Delete Note</base-button
        >
        <base-button type="primary" @click="editModal = false"
          >Done</base-button
        >
      </template>
    </modal>
    <modal :show.sync="responseModal">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Leave Response</h5>
      </template>
      <div>
        <response-form
          @submit="responseModal = false"
          :noteId="note.id"
          :fileId="note.file"
          :delID="delID"
        >
        </response-form>
      </div>
    </modal>
  </div>
</template>

<script>
import Response from "./Response";
import ResponseForm from "./ResponseForm";

export default {
  data() {
    return {
      editModal: false,
      responseModal: false,
    };
  },
  components: {
    response: Response,
    "response-form": ResponseForm,
  },
  props: ["note", "delID", "feedbacker", "fileStatus", "assetType"],
  computed: {
    time() {
      var date = new Date(null);
      date.setSeconds(this.note.seconds); // specify value for SECONDS here
      var result = date.toISOString().substr(14, 5);
      return result;
    },
    responses() {
      return this.$store.getters["notes/responsesByNoteId"](this.note.id);
    },
    editable() {
      if (this.feedbacker && this.fileStatus === "review") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    timeButton() {
      this.$emit("jumpTo", this.note.seconds);
    },
    pdfPage() {
      this.$emit("pdfPage", this.note.pdfPage);
    },
    deleteNote() {
      this.editModal = false;
      this.$emit("deleteNote", this.note.id);
    },
    edit() {
      if (this.editable) {
        this.editModal = true;
      }
    },
    toggleDone() {
      var payload = {};
      payload.id = this.note.id;
      // payload.delID = this.delID
      //payload.value = this.note.text
      payload.done = !this.note.done;
      //console.log(payload)
      this.$store.dispatch("notes/patch", payload);
    },
    changeNote() {
      var payload = {};
      payload.id = this.note.id;
      //payload.delID = this.delID
      payload.text = this.note.text;
      payload.tc = this.note.tc
      payload.seconds = this.note.seconds
      payload.done = false;
      //console.log(payload)
      this.$store.dispatch("notes/patch", payload);
    },
  },
};
</script>

<style>
.noteTextEdit {
  cursor: pointer;
}
.textBit:first-line {
  /* line-height: 0; */
  white-space: pre-line;
}
.noteTextEdit:hover {
  color: #5e72e4;
}
.noteDone {
  text-decoration: line-through;
}
</style>
