<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col text-right">
                  <base-input>
                    <el-select v-model="departmentFilter" placeholder="Department Filter" clearable
                      @change="setUserField($event, 'department')">
                      <el-option v-for="option in departments" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select v-model="producerFilter" placeholder="Producer Filter" clearable
                      @change="setUserField($event, 'productionCompany')">
                      <el-option v-for="option in config.producer" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row" v-if="isLoaded">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <h3 class="mb-0">The Hundred</h3>
                  <h5 class="mb-0" v-if="hundredQueue">
                    {{ hundredQueue.length }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <draggable v-bind="dragOptions" v-model="hundredQueue" @start="drag = true" @end="drag = false">
                  <!-- <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  > -->
                  <li v-for="(brief, index) in hundredQueue" class="list-group-item px-0" :key="brief">
                    <brief :briefID="brief" :index="index"></brief>
                  </li>
                  <!-- </transition-group> -->
                </draggable>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <h3 class="mb-0">ECB Digital</h3>
                  <h5 class="mb-0" v-if="ECBQueue">
                    {{ ECBQueue.length }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <draggable v-bind="dragOptions" v-model="ECBQueue" @start="drag = true" @end="drag = false">
                  <li v-for="(prodID, index) in ECBQueue" class="list-group-item px-0" :key="prodID">
                    <brief :briefID="prodID" :index="index"></brief>
                  </li>
                </draggable>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <h3 class="mb-0">Commercial and Other</h3>
                  <h5 class="mb-0" v-if="otherQueue">
                    {{ otherQueue.length }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <draggable v-bind="dragOptions" v-model="otherQueue" @start="drag = true" @end="drag = false">
                  <li v-for="(prodID, index) in otherQueue" class="list-group-item px-0" :key="prodID">
                    <brief :briefID="prodID" :index="index"></brief>
                  </li>
                </draggable>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <h3 class="mb-0">Unqueued</h3>
                  <h5 class="mb-0" v-if="unQueued">{{ unQueued.length }}</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <draggable v-bind="dragOptions" @start="drag = true" @end="drag = false" v-model="unQueued">
                  <!-- <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  > -->
                  <li v-for="(brief, index) in unQueued" class="list-group-item px-0" :key="brief">
                    <brief :briefID="brief" :index="index"></brief>
                  </li>
                  <!-- </transition-group> -->
                </draggable>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner v-else></loading-spinner>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";

import ResourceName from "./Briefs/Components/ResourceName";
import PriorityBriefCard from "./Components/PriorityBriefCard";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    brief: PriorityBriefCard,
    "resource-name": ResourceName,
  },
  data() {
    return {
      graphicsOrder: [],
      producerFilter: "",
      departmentFilter: "",
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    hundredQueue: {
      get() {
        let prods = this.productions;
        prods = prods.filter((prod) => {
          if (prod.queue) {
            return prod.queue === "Hundred";
          }
        });
        let sorted = prods.sort((a, b) => {
          if (a.queueOrder < b.queueOrder) {
            return -1;
          }
          if (a.queueOrder > b.queueOrder) {
            return 1;
          }
          if (!a.queueOrder) {
            return -1;
          }
          return 0;
        });
        let arr = [];
        sorted.forEach((prod) => {
          arr.push(prod.id);
        });
        return arr;
      },
      set(value) {
        var i = 0;
        for (i = 0; i < value.length; i++) {
          this.$store.dispatch("briefs/patch", {
            id: value[i],
            queue: "Hundred",
            queueOrder: i,
          });
        }
      },
    },
    ECBQueue: {
      get() {
        let prods = this.productions;
        prods = prods.filter((prod) => {
          if (prod.queue) {
            return prod.queue === "ECB";
          }
        });
        let sorted = prods.sort((a, b) => {
          if (a.queueOrder < b.queueOrder) {
            return -1;
          }
          if (a.queueOrder > b.queueOrder) {
            return 1;
          }
          if (!a.queueOrder) {
            return -1;
          }
          return 0;
        });
        let arr = [];
        sorted.forEach((prod) => {
          arr.push(prod.id);
        });
        return arr;
      },
      set(value) {
        var i = 0;
        for (i = 0; i < value.length; i++) {
          this.$store.dispatch("briefs/patch", {
            id: value[i],
            queue: "ECB",
            queueOrder: i,
          });
        }
      },
    },
    otherQueue: {
      get() {
        let prods = this.productions;
        prods = prods.filter((prod) => {
          if (prod.queue) {
            return prod.queue === "other";
          }
        });
        let sorted = prods.sort((a, b) => {
          if (a.queueOrder < b.queueOrder) {
            return -1;
          }
          if (a.queueOrder > b.queueOrder) {
            return 1;
          }
          if (!a.queueOrder) {
            return -1;
          }
          return 0;
        });
        let arr = [];
        sorted.forEach((prod) => {
          arr.push(prod.id);
        });
        return arr;
      },
      set(value) {
        var i = 0;
        for (i = 0; i < value.length; i++) {
          this.$store.dispatch("briefs/patch", {
            id: value[i],
            queue: "other",
            queueOrder: i,
          });
        }
      },
    },
    unQueued: {
      get() {
        let prods = this.productions;
        prods = prods.filter((prod) => !prod.queue);
        let arr = [];
        prods.forEach((prod) => {
          arr.push(prod.id);
        });
        return arr;
      },
      set(value) {
        var i = 0;
        for (i = 0; i < value.length; i++) {
          this.$store.dispatch("briefs/patch", {
            id: value[i],
            queue: "",
            queueOrder: i,
          });
        }
      },
    },
    productions() {
      if (this.isLoaded) {
        let deps = this.$store.getters["briefs/productions"];
        deps = deps.filter((p) => !p.completed);
        if (this.departmentFilter !== "") {
          deps = deps.filter((p) => p.department === this.departmentFilter);
        }
        // if (this.producerFilter !== "") {
        //   deps = deps.filter((p) => p.producer === this.producerFilter);
        // }
        return deps;
      }
    },
    departments() {
      if (this.isLoaded) {
        let pA = this.config.departments;
        let arr = [];
        pA.forEach(function (entry) {
          arr.push({
            text: entry,
            value: entry,
          });
        });
        return this.config.departments;
      }
    },
  },
  mounted() {
    // if department, set it
    this.departmentFilter = this.user.department ? this.user.department : "";
    // if producer, set it
    // this.producerFilter = this.user.productionCompany
    //   ? this.user.productionCompany
    //   : "";

    // if the user as restrictions
    // if(this.restrictions) {
    //     this.$store.dispatch("briefs/fetchAndAdd", { limit: 0, where: this.restrictions });
    // } else {

    var start = new Date();
    start.setMonth(start.getMonth() - 12);
    this.$store.dispatch("briefs/openDBChannel", {
      // where: [["created_at", ">=", start]],
      limit: 0
    });

    // }
  },
  methods: {
    queueCalc(queue) {
      console.log(queue);
    },
  },
  beforeMount() {
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("resources/fetchAndAdd");
  },
};
</script>
<style>
.smaller-text {
  font-size: 0.9em;
}

.overdue {
  color: tomato;
}

.worklistIcon {
  font-size: 1.2em;
}

.m-xy-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.progress-percentage {
  display: none;
}

.progress-label {
  margin-bottom: 5px;
  margin-top: -5px;
}
</style>
