<template>
  <div class="row">
    <div class="col">
      <div class="card-wrapper">
        <!-- Form controls -->
        <div class="card">
          <!-- Card header -->
          <!-- <div class="card-header">
                <h3 class="mb-0"></h3>
              </div> -->
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <base-input label="Paste link" v-model="link" />
                <div class="row justify-content-md-center" v-if="formIsValid">
                  <div class="col-auto">
                    <base-button
                      @click="createLink()"
                      block
                      class="px-3"
                      type="default"
                    >
                      <i class="ni ni-curved-next"></i>
                      Create
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-control-label mb-2">Or upload file</div>
                <inspiration-uploader
                  boxHeight="100px"
                  @success="uploadSuccess"
                ></inspiration-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InspirationUploadWrapper from "./InspirationUploadWrapper";

export default {
  name: "new-inspiration",
  props: ["brief"],
  data() {
    return {
      link: "",
    };
  },
  components: {
    "inspiration-uploader": InspirationUploadWrapper,
  },
  computed: {
    formIsValid() {
      return this.link !== "";
    },
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
  },
  methods: {
    uploadSuccess($event) {
      console.log($event);
      this.create({ what: "file", file: $event });
    },
    createLink() {
      let payload = { url: this.link, what: "url" };
      this.create(payload);
    },
    create(payload) {
      console.log(payload);
      if (this.brief) {
        payload.briefs = {};
        payload.briefs[this.brief] = true;
      }
      console.log(payload)
      this.$store.dispatch("inspirations/insert", payload).then((data) => {
        console.log(data);
        this.$router.push("/inspiration/" + data);
      });
    },
  },
  beforeMount() {},
  watch: {},
};
</script>
<style>
</style>
