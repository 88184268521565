<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    <div class="row">
              <div class="col-4">
          <stats-card title="Current Balance"
                      type="gradient-red"
                      :sub-title="formatter(currentBalance)"
                      icon="fas fa-money-bill-wave">

            <!-- <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-4">
          <stats-card title="Total In"
                      type="gradient-orange"
                      :sub-title="formatter(paymentsTotal)"
                      icon="fas fa-sign-in-alt">
<!--
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-4">
          <stats-card title="Total Out"
                      type="gradient-yellow"
                      :sub-title='formatter(costsTotal + shiftsTotalCost)'
                      icon="fas fa-sign-out-alt">
<!--
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
            </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <!-- <div class="card-header">
                <h3 class="mb-0">Main</h3>
              </div> -->
              <!-- Card body -->
              <div class="card-body">

                <form>
                  <base-input
                    label="Name"
                    v-model="budget.name"
                    @change="changeField($event.target.value, 'name')"
                  />
                  <base-input label="Department">
                    <el-select
                      v-model="budget.department"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'department')"
                    >
                      <el-option
                        v-for="option in config.departments"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Company">
                    <el-select
                      v-model="budget.company"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'company')"
                    >
                      <el-option
                        v-for="option in config.producer"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Active</label>
                    <div class="col">
                      <base-switch
                        v-model="budget.active"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'active')"
                      ></base-switch>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col"><h3 class="mb-0">Payments In</h3></div>
                  <div
                    class="col-auto text-right"
                    v-if="user.roles.production_admin"
                  >
                    <el-tooltip content="New" placement="top">
                      <base-button
                        type="warning"
                        icon
                        size="sm"
                        @click="addPayment"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fas fa-plus-square"></i
                        ></span>
                        <span class="btn-inner--text">Add New Payment</span>
                      </base-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <div class="col">
                    <li class="list-group list-group-flush">
                      <ul
                        class="list-group-item"
                        v-for="(payment, index) in payments"
                        :key="index"
                      >
                        <payment :payment="payment"></payment>
                      </ul>
                    </li>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Work</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col"></div>
                  <div class="col h3">Total Days: {{ shiftsTotal }}</div>
                  <div class="col h3">Total Cost: £{{ shiftsTotalCost }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                  <div class="row" v-for="(brief, index) in timeByBrief">
                    <div class="col-4">
                      {{ briefName(index) }}
                    </div>
                    <div class="col-4">
                      Days: {{ brief.days }}
                    </div>
                    <div class="col-4">
                      Cost: {{ formatter(brief.cost) }}
                    </div>

                    </div></div>

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Expenditure</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col h3">Total Spend: {{ formatter(costsTotal) }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col-12" v-for="(brief, index) in costsByBrief">
                    {{ briefName(index) }} - {{ formatter(brief) }}
                    </div>

                  </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import payment from "./Components/Payment";
export default {
  name: "budget",
  data: function () {
    return {
      transfer: "",
    };
  },
  components: {"payment": payment},
  beforeMount() {
    this.$store.dispatch("budgets/fetchAndAdd");

    this.$store.dispatch("shifts/fetchAndAdd", {
      where: [["budget", "==", this.$route.params.id]],
      limit: 0,
    });
    this.$store.dispatch("payments/fetchAndAdd", {
      where: [["budgetId", "==", this.$route.params.id]],
      limit: 0,
    });
    this.$store.dispatch("costs/fetchAndAdd", {
      where: [["budget", "==", this.$route.params.id]],
      limit: 0,
    });
  },
  computed: {
    budgetId() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    budget() {
      return this.$store.getters["budgets/byId"](this.budgetId);
    },
    payments() {
      return this.$store.getters["payments/byBudget"](this.budgetId);
    },
    paymentsTotal() {
      let total = 0;
      this.payments.forEach((a) => {
        total = total + Number(a.total);
      });
      return total;
    },
    shifts() {
      return this.$store.getters["shifts/byBudget"](this.budgetId);
    },
    shiftsTotal() {
      let total = 0;
      this.shifts.forEach((a) => {
        total = total + a.days;
      });
      return total;
    },
    shiftsTotalCost() {
      let total = 0;
      this.shifts.forEach((a) => {
        total = total + Number(a.cost);
      });
      return total;
    },
    costs() {
      return this.$store.getters["costs/byBudget"](this.budgetId);
    },
    costsTotal() {
      let total = 0;
      this.costs.forEach((a) => {
        total = total + Number(a.amount);
      });
      return total;
    },
    // groups() {
    //   const resources = this.resources;
    //   let groups = [];
    //   for (const element of resources) {
    //     groups.push(element.group);
    //   }
    //   const groupsInter = [...new Set(groups)];
    //   let groupsTwo = [];
    //   for (const element of groupsInter) {
    //     groupsTwo.push({
    //       text: element,
    //       value: element,
    //     });
    //   }
    //   return groupsTwo;
    // },
    config() {
      return this.$store.getters["config/config"];
    },
    currentBalance(){
 let sum = this.paymentsTotal - this.costsTotal - this.shiftsTotalCost
    //  sum = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return sum
    },
    costsByBrief(){
      const result = {};
      const arr = this.costs
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const productionId = obj.productionId;
    const cost = parseFloat(obj.amount);
    if (!result.hasOwnProperty(productionId)) {
      this.$store.dispatch("briefs/fetchById", productionId);
      result[productionId] = 0
    }
    result[productionId] += cost;
  }
  return result;
    },
    timeByBrief(){
      const result = {};
      const arr = this.shifts
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const productionId = obj.productionId;
    const cost = parseFloat(obj.cost);
    const days = parseFloat(obj.days);
    console.log(cost)
    if (!result.hasOwnProperty(productionId)) {
      this.$store.dispatch("briefs/fetchById", productionId);
      result[productionId] = {days: 0, cost: 0}
    }
    result[productionId]['days'] += days;
    result[productionId]['cost'] += cost;
  }
  return result;
    }
  },
  methods: {
    formatter(value) {
      value = Math.round((value + Number.EPSILON) * 100) / 100

      return '£' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    briefName(id){
      return this.$store.getters["briefs/byId"](id)['title'];
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.budgetId;
      payload[field] = value;
      this.$store.dispatch("budgets/patch", payload);
    },
    transferAction() {
      this.shifts.forEach((shift) => {
        this.$store.dispatch("shifts/patch", {
          id: shift.id,
          resourceId: this.transfer,
        });
      });
    },
    addPayment() {
      this.$store.dispatch("payments/insert", {
        total: 0,
        budgetId: this.budgetId
      });
    }
    // deleteResource() {
    //   this.$store.dispatch("resources/delete", this.resourceId);
    // },
  },
};
</script>
