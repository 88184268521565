<template>
  <div>
    <div v-if="fileGetter">
      <div v-if="fileGetter.assetType === 'video'">
        <video
          :id="fileGetter.id"
          controls
 
          :style="dimensions"
          preload="metadata"
          loading="lazy"
        >
          <source :src="previewOrSourceSwitch" type="video/mp4" />
          Your browser does not support the video element.
        </video>
      </div>
      <div v-else-if="fileGetter.sourceExtention === '.pdf'">
                    <pdf
                      :src="this.previewOrSourceSwitch"
                      @num-pages="pageCount = $event"
                      @page-loaded="currentPage = $event"
                      :page="currentPage"
                    ></pdf>
      
      </div>
      <div v-else>
        <img
          loading="lazy"
          draggable="false"
          :id="fileGetter.id"
          :src="previewOrSourceSwitch"
          class="prevSizer"
          :style="dimensions"
        />
      </div>
    </div>
    <loading-spinner v-else></loading-spinner>
  </div>
</template>

<script>
import { db } from "@/firebase";
import pdf from "vue-pdf";

export default {
  props: ["file", "fileID", "sizeObject", "in", "out", "useRaw"],
  name: "file-preview",
  data() {
    return {
      // file: {}
    };
  },
  // beforeMount() {
  //   this.$store.dispatch("files/fetchByID", this.fileID);
  // },
  computed: {
    dimensions() {
      if (this.sizeObject) {
        return this.sizeObject;
      } else {
        return {
          width: "100%",
          height: "100%",
        };
      }
    },
    fileGetter() {
      if (this.fileID) {
        let file = this.$store.getters["files/fileById"](this.fileID);
        // console.log(file)
        // if (file === undefined) {
        //   await this.$store.dispatch("files/fetchById", this.fileID); 
        //   file = this.$store.getters["files/fileById"](this.fileID);
        // }
        return file
      } else {
        return this.file;
      }
    },
    previewOrSourceSwitch() {
      let fragment = this.in && this.out ? `#t=${this.in},${this.out}` : '#t=0.001'
      if (this.fileGetter.hasPreview && !this.useRaw) {
        return this.fileGetter.previewDownloadURL + fragment;
      } else {
        return this.fileGetter.sourceDownloadURL + fragment;
      }
    },
  },

  components: { pdf },
};
</script>

<style scoped>
.prevSizer {
  object-fit: cover;
}
</style>
