<template>
  <div>
    <div class="form-group row">
      <label class="col-3 col-form-label form-control-label">Title</label>
      <div class="col-9">
        <base-input v-model="title" placeholder="e.g. Main Feature" />
      </div>
      <label
        class="col-3 col-form-label form-control-label"
   
        >Folder</label
      >
      <div class="col-9">
        <base-input>
          <el-select v-model="folderName" placeholder="Folder" clearable filterable allow-create>
            <el-option
              v-for="option in folders"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Type</label>
      <div class="col-9">
        <base-input>
          <el-select v-model="type" placeholder="Select" clearable>
            <el-option
              v-for="option in config.delTypes"
              :key="option.display"
              :label="option.display"
              :value="option.display"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Dimensions</label>
      <div class="col-9">
        <base-input>
          <el-select v-model="format" placeholder="Select" clearable>
            <el-option
              v-for="option in config.formats"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label
        class="col-3 col-form-label form-control-label"
        v-if="type != 'Image'"
        >Expected Duration</label
      >
      <div class="col-9" v-if="type != 'Image'">
        <base-input v-model="desiredDuration" placeholder="e.g. 2 minutes" />
      </div>
      <label class="col-3 col-form-label form-control-label">Brand</label>
      <div class="col-9">
        <base-input>
          <el-select v-model="brand" placeholder="Select" filterable clearable>
            <el-option
              v-for="option in config.brand"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

                  <label class="col-3 col-form-label form-control-label pt-0">Burnt-in Subtitles</label>
      <div class="col-9 pb-3">

                      <base-switch
                        v-model="subs"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                      ></base-switch>

        </div>
        <label class="col-3 col-form-label form-control-label pt-0"
        >WebVTT Subtitle Track</label
      >
      <div class="col-9 pb-3">
        <base-switch
          v-model="subsTrack"
          type="warning"
          on-text="Yes"
          off-text="No"
        ></base-switch>
      </div>


      <label class="col-3 col-form-label form-control-label">Feedbackers</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="owners"
            multiple
            filterable
            placeholder="Who should be notified for feedback on this"
            class="wider-select"
          >
            <el-option
              class="select-warning"
              v-for="option in admins"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label"
        >Deadline</label
      >
      <div class="col-9">
        <base-input>
          <flat-picker
            slot-scope="{ focus, blur }"
            @on-open="focus"
            @on-close="blur"
            :config="{ allowInput: true }"
            class="form-control datepicker"
            v-model="deadline"
            placeholder="When do we need this by"
          >
          </flat-picker>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Description</label>
      <div class="col-9">
        <base-input>
          <textarea
            placeholder="Description with CTA"
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="2"
            v-model="description"
          ></textarea>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="form-group row" v-if="checkNewDelFormValid">
          <label
            for="example-number-input"
            class="col-6 col-form-label form-control-label"
            >How many to make:</label
          >
          <div class="col-4">
            <base-input
              type="number"
              v-model="versions"
              min="1"
              placeholder="1"
              id="example-number-input"
            />
          </div>
        </div>
      </div>

      <div class="col-3" v-if="checkNewDelFormValid">
        <base-button block type="success" @click="addDeliverable"
          >Create {{ versions }} copies</base-button
        >
      </div>
      <div class="col-3" v-else-if="!title">
        <base-button block type="warning">Title Needed</base-button>
      </div>
      <div class="col-3" v-else-if="!type">
        <base-button block type="warning">Type Needed</base-button>
      </div>
      <div class="col-3" v-else>
        <base-button block type="warning">Deadline Needed</base-button>
      </div>
      <!-- <div class="col-3">
        <base-button block type="secondary" @click="showAddDelModal = false"
          >Cancel</base-button
        > -->
    </div>
  </div>
</template>

<script>
import { Select, Option, Tooltip } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// import firebase from 'firebase'

export default {
  props: ["prod", "folders"],
  name: "DeliverableForm",
  data: function () {
    return {
      title: "",
      type: "",
      folderName: "",
      format: "",
      desiredDuration: "",
      brand: "",
      description: "",
      status: "",
      owners: "",
      deadline: "",
      subs: false,
      subsTrack: false,
      versions: 1,
    };
  },
  computed: {
    checkNewDelFormValid() {
      if (this.title != "" && this.deadline != "" && this.type != "") {
        return true;
      } else {
        return false;
      }
    },
    users() {
      return this.$store.getters["users/users"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    admins() {
      const result = this.users.filter((usr) => usr.roles.production_feedback);
      return result;
    },
    folderList() {
      let test = this.folders
      test.push(this.folderName)
      return test
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    flatPicker,
  },
  mounted() {
    this.owners = this.prod.owners;
    // if (this.prod.brand) {
    // this.brand = this.prod.brand;
    // }
  },
  methods: {
    addDeliverable() {
      // need to check to make sure no duplicates
      var i;
      var docs = [];
      for (i = 0; i < this.versions; i++) {
        var payload = {};
        payload.title = this.title;
        if (this.type) {
          payload.type = this.type;
        }
        if (this.format) {
          payload.format = this.format;
        }
        payload.folderName = this.folderName;

        if (this.desiredDuration) {
          payload.desiredDuration = this.desiredDuration;
        }
        payload.state = "production";
        payload.active = true;
        payload.currentVersion = 0;
        payload.owners = this.owners;
        payload.deadline = this.deadline;
        if (this.description) {
          payload.description = this.description;
        }
        if (this.brand) {
          payload.brand = this.brand;
        }
        payload.production = this.prod.id;
        payload.productionTitle = this.prod.title;
        payload.subs = this.subs
        payload.subsTrack = this.subsTrack
        if (this.prod.department) {
          payload.department = this.prod.department;
        }
        if (this.prod.collections) {
          payload.collections = this.prod.collections;
        }
        if (this.prod.partner) {
          payload.partner = this.prod.partner;
        }
        payload.iteration = i;
        docs.push(payload);
      }
      console.log(docs);
      this.$store.dispatch("deliverables/insertBatch", docs);
      this.$emit("submitted");
      //   this.showAddDelModal = false;
      this.title = "";
      this.type = "";
      this.format = "";
      this.desiredDuration = "";
      this.description = "";
      this.subs = false;
      this.subsTrack = false;
      this.owners = this.prod.owners;
      this.versions = 1;
    },
  },
};
</script>