<template>
  <div class="uploadWidget" :class="{ uploadWidgetSlot: isASlot}" :style="cssProps">
    <div
      id="drop_zone"
      @click="onPickFile"
      @drop="drop_handler($event)"
      @dragover="dragover_handler($event)"
      @dragend="dragend_handler($event)"
    >
      <div class="dropText">{{ message }}</div>
    </div>
    <input
      style="display: none"
      type="file"
      @change="onInputSelection($event)"
      ref="fileInput"
      multiple
      :accept="accepted"
      id="fileselect"
    />
    <Uploader
      class="uploadComp"
      v-for="(file, index) in files"
      :file="file"
      :key="index"
      :multiple="multiple"
      @successfulUpload="success"
      :basePath="basePath"
      @cancel="cancel(index)"
    ></Uploader>
  </div>
</template>

<script>
import { Firebase } from "@/firebase";
import Uploader from "@/echoComponents/file/Uploader";

export default {
  name: "NewFile",
  props: [
    "boxHeight",
    "message",
    "multiple",
    "deliverable",
    "production",
    "accepted",
    "fileName",
    "basePath",
    "droppedFile",
    "isASlot",
    "fileNameSuffix",
    "randomSuffix",
    "nameRequired",
  ],
  components: {
    Uploader,
  },
  watch: {
    droppedFile() {
      //console.log(this.droppedFile)
      this.validCheck(this.droppedFile)
    }
  },
  data: function () {
    return {
      files: [],
    };
  },
  computed: {
    cssProps() {
      return {
        "--box-height": this.boxHeight,
      };
    },
  },
  methods: {
    onInputSelection() {
      for (var i = 0; i < event.target.files.length; i++) {
        const f = event.target.files[i];
        this.validCheck(f);
      }
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    drop_handler(ev) {
      //console.log("Drop");
      ev.preventDefault();
      // If dropped items aren't files, reject them
      var dt = ev.dataTransfer;
      // don't do anything if this is in a jimstaslot, as we will use the drop handling in the parent component
      if (!this.isASlot) {
   
      if (dt.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var j = 0; j < dt.items.length; j++) {
          if (dt.items[j].kind === "file") {
            var f = dt.items[j].getAsFile();
            //console.log("... file[" + j + "].name = " + f.name);
            this.validCheck(f);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var g = 0; g < dt.files.length; g++) {
          //console.log("... file[" + g + "].name = " + dt.files[g].name);
          var l = dt.files[g];
          this.validCheck(l);
        }
      }
      }
    },
    dragover_handler(ev) {
      //console.log("dragOver");
      // Prevent default select and drag behavior
      ev.preventDefault();
    },
    dragend_handler(ev) {
      //console.log("dragEnd");
      // Remove all of the drag data
      var dt = ev.dataTransfer;
      if (dt.items) {
        // Use DataTransferItemList interface to remove the drag data
        for (var l = 0; l < dt.items.length; l++) {
          dt.items.remove(l);
        }
      } else {
        // Use DataTransfer interface to remove the drag data
        ev.dataTransfer.clearData();
      }
    },
    fileNameSwitch(f) {
      let tempName = ''
      if (this.fileName) {
        tempName = this.fileName;
      } else {
        tempName = f.name.slice(0, f.name.lastIndexOf("."));
      }
      if (this.fileNameSuffix){
        tempName = tempName + this.fileNameSuffix
      }
      if (this.randomSuffix){
        tempName = tempName + '_' + Math.random().toString(36).substr(2, 9)
      }
      return tempName
    },
    validCheck(f) {
      console.log(f)
      if(this.nameRequired){
        if(f.name !== this.nameRequired) {
          return alert("Filename does not match");
        }
      }
      if (f.name.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file");
      } else {
        // validate file type
        let test = "";
        //check if the accepted file type
        if (this.accepted) {
          test = this.accepted.includes(f.type);
        } else {
          test = true;
        }
        if (!test) {
          alert(
            "This is a: " +
              f.type +
              ", but this uploader only accepts: " +
              this.accepted
          );
        } else {
          // check if multiple allowed
          if (this.multiple) {
            let payload = {};
            payload.file = f;
            payload.uploadName = this.fileNameSwitch(f);
            this.files.push(payload);
          } else {
            if (this.files.length >= 1) {
              console.log("Max Files reached");
              // todo - alert user to max files
            } else {
              let payload = {};
              payload.file = f;
              payload.uploadName = this.fileNameSwitch(f);
              this.files.push(payload);
            }
          }
        }
      }
    },
    success(payload) {
      this.$emit("success", payload);
    },
    cancel(index) {
      //console.log("cancel");
      //console.log(index);
      this.files.splice(index, 1);
    },
  },
};
</script>

<style scoped>
#drop_zone {
  height: 100%;
  width: 100%;
  cursor: pointer;
  min-height: var(--box-height);
}
.uploadWidget:hover {
  border-color: #969696;
}
.dropText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--box-height);
  color: rgb(146, 146, 146);
}
.uploaderBG {
  background: none;
}

.uploadWidgetSlot {
  height: 100%
}

.uploadWidget {
  border: 1px #e9e9e9 dashed;
  border-radius: 6px;
  min-height: var(--box-height);
  width: 100%;
  position: relative;
  box-shadow: inset 0px 2px 5px 0px #e9e9e9;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 249, 252, 1) 100%
  ); */
}
</style>
