<template>
  <div>
    <base-header class="pb-9 pt-3" type="danger">
      <div class="card">
        <div class="card-body mb--4">
          <div class="row align-items-center">
            <div class="col">
              <div class="form-group row">
                <label class="col-auto form-control-label">User Clips:</label>
                <div class="col">
                  <base-switch type="default" v-model="showUser"> </base-switch>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-auto form-control-label">Deliveries:</label>
                <div class="col">
                  <base-switch type="default" v-model="showDeliveries">
                  </base-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col" v-if="showUser">
              <base-input>
                <el-select
                  v-model="userFilter"
                  filterable
                  clearable
                  multiple
                  placeholder="Clipped By"
                >
                  <el-option
                    v-for="option in clippedBy"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col">
              <base-input>
                <el-select
                  v-model="bookmarkFilter"
                  filterable
                  clearable
                  multiple
                  placeholder="Tag Filter"
                >
                  <el-option
                    v-for="option in bookmarksAvailable"
                    :key="option"
                    :label="option"
                    :value="option"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col" v-if="showDeliveries">
              <base-input>
                <el-select
                  v-model="optaFilter"
                  clearable
                  placeholder="Delivery Filters"
                >
                  <el-option
                    v-for="option in optaFilters"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div></div
    ></base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="viewing-card">
                <div class="viewer" v-if="file">
                  <video id="player" controls>
                    <source :src="file.previewDownloadURL" type="video/mp4" />
                    Your browser does not support the video element.
                  </video>
                  <div class="container">
                    <div class="row justify-content-md-center mt-2 mb-3">
                      <div class="col-auto">
                        <base-button
                          type="secondary"
                          size="sm"
                          @click="jump(-30)"
                          ><i class="fas fa-backward"></i>30s</base-button
                        >
                        <base-button
                          type="secondary"
                          size="sm"
                          @click="jump(-5)"
                          ><i class="fas fa-backward"></i>5s</base-button
                        ><base-button
                          type="secondary"
                          size="sm"
                          @click="jump(-1)"
                          ><i class="fas fa-backward"></i>1s</base-button
                        >
                        <base-button type="secondary" size="sm" @click="jump(0.04)"
                          ><i class="fas fa-backward"></i>1f</base-button
                        >
                        <base-button
                          type="warning"
                          size="sm"
                          @click="pauseVideo"
                          ><i class="fas fa-pause"></i
                        ></base-button>
                        <base-button
                          type="primary"
                          size="sm"
                          @click="playVideo(1)"
                          ><i class="fas fa-play"></i
                        ></base-button>     <base-button
                          type="primary"
                          size="sm"
                          @click="playVideo(2)"
                          ><i class="fas fa-play">x2</i
                        ></base-button>
                        <base-button
                          type="primary"
                          size="sm"
                          @click="playVideo(5)"
                          ><i class="fas fa-play">x5</i
                        ></base-button>
                        <base-button type="secondary" size="sm" @click="jump(0.04)"
                          ><i class="fas fa-forward"></i>1f</base-button
                        >
                        <base-button type="secondary" size="sm" @click="jump(1)"
                          ><i class="fas fa-forward"></i>1s</base-button
                        >
                        <base-button type="secondary" size="sm" @click="jump(5)"
                          ><i class="fas fa-forward"></i>5s</base-button
                        >
                        <base-button
                          type="secondary"
                          size="sm"
                          @click="jump(30)"
                          ><i class="fas fa-forward"></i>30s</base-button
                        >
                      </div>
                    </div>
                    <div class="row justify-content-md-center mb-5">
                      <div class="col-3">
                        <base-button
                          type="secondary"
                          block
                          @click="setIn()"
                          v-if="!editEvent"
                          >Set In</base-button
                        >
                        <base-button
                          block
                          type="danger"
                          @click="updateIn()"
                          v-else
                          >Update In</base-button
                        >
                      </div>
                      <div class="col-3">
                        <base-button
                          type="secondary"
                          block
                          @click="setOut()"
                          v-if="!editEvent"
                          >Set Out</base-button
                        >
                        <base-button
                          type="danger"
                          block
                          @click="updateOut()"
                          v-else
                          >Update Out</base-button
                        >
                      </div>
                    </div>
                    <!-- new event -->
                    <div class="row" v-if="!editEvent">
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col-4" v-if="tcIn">
                            <base-button
                              v-if="tcIn"
                              block
                              type="secondary"
                              @click="goToVideoTime(tcIn)"
                              >In: {{ timeIn }}</base-button
                            >
                          </div>
                          <div class="col-4" v-if="tcOut">
                            <base-button
                              v-if="tcOut"
                              block
                              type="secondary"
                              @click="goToVideoTime(tcOut)"
                              >Out: {{ timeOut }}</base-button
                            >
                          </div>

                          <!-- <div class="col">
                          <base-checkbox
                            type="default"
                            class="
                              form-control form-control-label
                              bookmarkOption
                            "
                            v-model="addTags"
                            >Add Tags</base-checkbox
                          >
                        </div> -->

                          <div class="col-4" v-if="tcIn && tcOut">
                            <base-button block type="success" @click="markIt"
                              >Mark It</base-button
                            >
                          </div>
                        </div>
                        <div class="row" v-if="isSet">
                          <div class="col">
                            <base-input label="Tag Name">
                              <el-select
                                v-model="bookmark"
                                filterable
                                allow-create
                                placeholder="Pick or write new"
                              >
                                <el-option
                                  v-for="option in bookmarkOptions"
                                  :key="option"
                                  :label="option"
                                  :value="option"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col">
                            <filter-selector
                              :selection="playerSearchObj"
                              @valueChange="bookmarkPlayer = $event"
                            >
                            </filter-selector>
                          </div>
                          <div class="col">
                            <base-input label="Tag Team">
                              <el-select
                                v-model="bookmarkTeam"
                                filterable
                                clearable
                                placeholder="Tag Team"
                              >
                                <el-option
                                  v-for="option in teams"
                                  :key="option.id"
                                  :label="option.teamName"
                                  :value="option.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                                              <div class="row" v-if="isSet">
                          <div class="col">
                            <base-input label="Clip Note" v-model="note"
                           >
                            </base-input>
                          </div>
                             </div>
                      </div>
                    </div>
                    <!-- edit event -->
                    <div class="row mb-4" v-else>
                      <div class="col">
                        <div class="row mb-3">
                          <div class="col-4">
                            <base-button
                              block
                              type="secondary"
                              @click="goToVideoTime(tcIn)"
                              >In: {{ timeIn }}</base-button
                            >
                          </div>
                          <div class="col-4">
                            <base-button
                              type="secondary"
                              block
                              @click="goToVideoTime(tcOut)"
                              >Out: {{ timeOut }}</base-button
                            >
                          </div>

                          <div class="col-4">
                            <div @click="stopEdit()" class="btn btn-block">
                              <i class="fas fa-window-close mr-1"></i>Stop
                              Editing {{ editEvent.innings }}.{{
                                editEvent.over
                              }}.{{ editEvent.ballNum }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <base-input label="Tag Name">
                              <el-select
                                v-model="editEvent.bookmark"
                                filterable
                                placeholder="Pick or write new"
                                @change="changeEvent($event, 'bookmark')"
                              >
                                <el-option
                                  v-for="option in bookmarkOptions"
                                  :key="option"
                                  :label="option"
                                  :value="option"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col">
                            <filter-selector
                              :selection="playerSearchObj"
                              @valueChange="
                                changeEvent($event, 'bookmarkPlayer')
                              "
                              :preValue="editEvent.bookmarkPlayer"
                            >
                            </filter-selector>
                          </div>
                          <div class="col">
                            <base-input label="Tag Team">
                              <el-select
                                v-model="editEvent.bookmarkTeam"
                                filterable
                                clearable
                                @change="changeEvent($event, 'bookmarkTeam')"
                                placeholder="Tag Team"
                              >
                                <el-option
                                  v-for="option in teams"
                                  :key="option.id"
                                  :label="option.teamName"
                                  :value="option.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </div>
                        </div>
                             <div class="row">
                          <div class="col">
                            <base-input label="Clip Note" v-model="editEvent.note"
                             @change="changeEvent($event.target.value, 'note')">
                            </base-input>
                          </div>
                             </div>
                        <div class="row align-items-center">
                          <div
                            class="col-auto"
                            v-if="editEventLive.clipStatus === 'clipping'"
                          >
                            Clipping In Progress
                          </div>

                          <div class="col-auto" v-if="editEventLive.clipped">
                            <a
                              class="btn"
                              :href="editEventFile.sourceDownloadURL"
                              ><i class="fas fa-file-download mr-2"></i>Download
                            </a>
                          </div>

                          <div
                            class="col-auto"
                            v-else-if="editEventLive.clipStatus != 'clipping'"
                          >
                            <div @click="clipIt()" class="btn btn-primary">
                              Clip It
                            </div>
                          </div>
                          <div
                            class="col-auto"
                            v-if="
                              editEvent.source === 'user' &&
                              editEventLive.clipStatus != 'clipping'
                            "
                          >
                            <base-button default @click="deleteEvent"
                              >Delete Clip</base-button
                            >
                          </div>
                          <div class="col" v-if="editEvent.source === 'user'">
                            Marked by: {{ editEventLive.username }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="events">
                  <div class="col event-controls">
                    <base-input>
                      <el-select
                        v-model="displayFields"
                        @change="displayFieldsSet($event)"
                        filterable
                        multiple
                        placeholder="Timeline Options"
                      >
                        <el-option
                          v-for="option in displayOptions"
                          :key="option.field"
                          :label="option.label"
                          :value="option.field"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <ul class="list-group list-group-flush list event-list">
                    <li
                      v-for="event in events"
                      class="list-group-item px-0"
                      :key="event.in"
                    >
                      <event
                        :event="event"
                        :currentEditEvent="editEvent.id"
                        @jumpTo="goToVideoTime(event.tcIn)"
                        @select="edit(event)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
           <div class="col" v-if="user.roles.archive_uploader">
          <div class="card">
            <div class="card-header">
              <h3 class="mb-0">File Info</h3>
            </div>
            <div class="card-body">
              <div>
              Timecode Start: {{ file.metadata.timecodeStart }}
              </div>
              <base-button @click="reassociate()"
                >Associate file with new match</base-button
              >
            </div>
          </div>
        </div>


        <!-- <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="mb-0">Recordings of this match</h3>
            </div>
            <div class="card-body">
              <ul>
                <li v-for="recording in recordings">
                  <a class="btn btn-sm" :href="'/#/archive/recording/' + recording.id">Day: {{recording.day}}, Part: {{ recording.part }}</a>
                </li>
              </ul>

            </div>
          </div>
        </div> -->

        </div>
        <div class="col" v-if="selectedClipLength">
          <div class="card shadow">
            <div class="card-header">
              <h3 class="mb-0">Export</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  {{ selectedClipLength }} clips for a duration of
                  {{ selectedClipDuration }} seconds
                </div>
                <div class="col">
                  <a href="#/archive/collections" class="btn btn-default"
                    >Add To Collection</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoEventDisplay from "./Components/VideoEventDisplay";
import FilterSelector from "./Components/FilterSelector";

import moment from "moment";
export default {
  components: {
    event: VideoEventDisplay,
    "filter-selector": FilterSelector,
  },
  name: "archive-video-viewer",
  data() {
    return {
      playerSearchObj: {
        index: "players",
        searchField: "fullName",
        label: "Tag Player",
        type: "single-search",
      },
      collectionFinder: false,
      tcIn: "",
      tcOut: "",
      editEvent: "",
      showUser: true,
      showDeliveries: true,
      bookmarkFilter: [],
      userFilter: [],
      optaFilter: "",
      optaFilters: [
        { label: "Wickets", value: "wicket" },
        { label: "Runs", value: "runs" },
        { label: "Extras", value: "extras" },
      ],
      bookmark: "",
      bookmarkPlayer: "",
      bookmarkTeam: "",
      note: '',
    };
  },
  computed: {
    recordings() {
      var arr = this.$store.getters["files/recordingsByMatch"](this.match.id);
      return arr.sort(function (a, b) {
        var keyA1 = parseInt(a.day),
          keyA2 = parseInt(a.part),
          keyB1 = parseInt(b.day),
          keyB2 = parseInt(b.part);

        // Compare the 2 keys
        if (keyA1 < keyB1) return -1;
        if (keyA1 > keyB1) return 1;
        if (keyA2 < keyB2) return -1;
        if (keyA2 > keyB2) return 1;
        return 0;
      });
    },
    bookmarkOptions() {
      return this.$store.getters["archiveTemp/bookmarkOptions"];
    },
    fileId() {
      return this.$route.params.id;
    },
    isSet() {
      if (this.tcIn && this.tcOut) {
        return true;
      }
    },
    bookmarksAvailable() {
      var all = this.$store.getters["archiveEvents/array"];
      var newArr = [];
      all.forEach((event) => {
        if (event.bookmark) {
          if (!newArr.includes(event.bookmark)) newArr.push(event.bookmark);
        }
      });
      return newArr;
    },
    clippedBy() {
      var all = this.$store.getters["archiveEvents/array"];
      var newArr = [];
      all.forEach((event) => {
        if (event.source === "user") {
          if (!newArr.includes(event.username)) newArr.push(event.username);
        }
      });
      return newArr;
    },
    eventsObj() {
      return this.$store.getters["archiveEvents/obj"];
    },
    events() {
      var all = this.$store.getters["archiveEvents/array"];
      var newArr = [];
      all.forEach((event) => {
        if (this.showUser && event.source === "user") {
          if (this.userFilter.length > 0) {
            if (this.userFilter.includes(event.username)) {
              newArr.push(event);
            }
          } else {
            newArr.push(event);
          }
        }
        if (this.showDeliveries && event.source !== "user") {
          if (this.optaFilter) {
            if (event[this.optaFilter]) {
              newArr.push(event);
            }
          } else {
            newArr.push(event);
          }
        }
      });
      if (this.bookmarkFilter.length > 0) {
        //if tag filter
        newArr = newArr.filter((event) =>
          this.bookmarkFilter.includes(event.bookmark)
        );
      }
      return newArr.sort((a, b) => {
        if (a.tcIn < b.tcIn) {
          return -1;
        }
        if (a.tcIn > b.tcIn) {
          return 1;
        }
        if (!a.tcIn) {
          return -1;
        }
        return 0;
      });
    },
    teams() {
      return this.$store.getters["teams/array"];
    },
    players() {
      return this.$store.getters["players/array"];
    },
    file() {
      return this.$store.getters["files/fileById"](this.fileId);
    },
    selectedClips() {
      var selects = this.$store.getters["archiveTemp/eventSelection"];
      var clips = [];
      selects.forEach((select) => {
        clips.push(this.eventsObj[select]);
      });
      return clips;
    },
    selectedClipLength() {
      return this.selectedClips.length;
    },
    selectedClipDuration() {
      var clips = this.selectedClips;
      var dur = 0;
      clips.forEach((select) => {
        dur = dur + (select.tcOut - select.tcIn);
      });
      return Math.floor(dur);
    },
    editEventLive() {
      return this.$store.getters["archiveEvents/byId"](this.editEvent.id);
    },
    editEventFile() {
      return this.$store.getters["files/fileById"](this.editEventLive.clipFile);
    },
    match() {
      var id = this.$store.getters["matches/byId"](this.file.match);
      if (id) {
        return id;
      } else {
        this.$store.dispatch("matches/fetchById", this.file.match);
      }
    },
    user() {
      return this.$store.getters["user/user"];
    },
    timeIn() {
      var date = new Date(null);
      date.setSeconds(this.tcIn); // specify value for SECONDS here
      var result = date.toISOString().substr(11, 8);
      return result;
    },
    timeOut() {
      var date = new Date(null);
      date.setSeconds(this.tcOut); // specify value for SECONDS here
      var result = date.toISOString().substr(11, 8);
      return result;
    },
    displayFields() {
      return this.$store.getters["archiveTemp/displayFields"];
    },
    displayOptions() {
      return this.$store.getters["archiveTemp/displayOptions"];
    },
  },

  watch: {
    events: function (newOne) {
      let players = {};
      let teams = {};
      newOne.forEach((clip) => {
        if (clip.batter) players[clip.batter] = true;
        if (clip.bowler) players[clip.bowler] = true;
        if (clip.bookmarkPlayer) players[clip.bookmarkPlayer] = true;
        if (clip.bookmarkTeam) teams[clip.bookmarkTeam] = true;
      });
      // load the players
      players = Object.keys(players);
      players.forEach((player) => {
        let res = this.$store.getters["players/byId"](player);
        if (res === undefined) {
          this.$store.dispatch("players/fetchById", player);
        }
      });
      // load the teams
      teams = Object.keys(teams);
      teams.forEach((team) => {
        let res = this.$store.getters["teams/byId"](team);
        if (res === undefined) {
          this.$store.dispatch("teams/fetchById", team);
        }
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("files/fetchById", this.$route.params.id);
    // this.$store.dispatch("files/fetchAndAdd", {
    //   where: [
    //     ["what", "==", "recording"],
    //     ["match", "==", this.match.id],
    //   ],
    // });
    this.$store.dispatch("files/openDBChannel", {
      clauses: { where: [["originalFile", "==", this.$route.params.id]] },
    });
    // this.$store.dispatch("matches/fetchById", "67138");
    this.$store.commit("archiveEvents/RESET_VUEX_EASY_FIRESTORE_STATE");
    this.$store.commit("archiveTemp/clearSelection");
    this.$store
      .dispatch("archiveEvents/fetchAndAdd", {
        clauses: {
          limit: 1000,
          where: [["file", "==", this.$route.params.id]],
        },
      })
      .then(() => {
        // if a direct link to a clip is found in the url
        if (this.$route.params.clip) {
          var event = this.eventsObj[this.$route.params.clip];
          this.edit(event);
          this.goToVideoTime(event.tcIn);
        }
      });
  },
  methods: {
    displayFieldsSet(value) {
      this.$store.commit("archiveTemp/displayFields", value);
    },
    goToVideoTime(time) {
      let myvideo = document.getElementById("player");
      myvideo.currentTime = time;
    },
    jump(diff) {
      let myvideo = document.getElementById("player");
      myvideo.currentTime = myvideo.currentTime + diff;
    },
    pauseVideo() {
      let myvideo = document.getElementById("player");
      myvideo.pause();
    },
    playVideo(speed) {
      let myvideo = document.getElementById("player");
      myvideo.playbackRate = speed
      myvideo.play();
    },
    setIn() {
      let myvideo = document.getElementById("player");
      this.tcIn = myvideo.currentTime;
    },
    setOut() {
      let myvideo = document.getElementById("player");
      this.tcOut = myvideo.currentTime;
    },
    updateIn() {
      let myvideo = document.getElementById("player");
      this.tcIn = myvideo.currentTime;
      if (this.editEventLive.clipped) {
        this.deleteClip();
      }
      this.$store.dispatch("archiveEvents/patch", {
        id: this.editEvent.id,
        tcIn: this.tcIn,
        manualSet: true,
      });
    },
    updateOut() {
      let myvideo = document.getElementById("player");
      this.tcOut = myvideo.currentTime;
      if (this.editEventLive.clipped) {
        this.deleteClip();
      }
      this.$store.dispatch("archiveEvents/patch", {
        id: this.editEvent.id,
        tcOut: this.tcOut,
        manualSet: true,
      });
    },
    edit(event) {
      this.editEvent = event;
      this.tcIn = event.tcIn;
      this.tcOut = event.tcOut;
      this.$store.dispatch("archiveEvents/openDBChannel", {
        where: [["id", "==", event.id]],
      });
    },
    stopEdit() {
      this.editEvent = "";
      this.tcIn = "";
      this.tcOut = "";
    },
    clipIt() {
      const f1 = `${this.editEventLive.bookmark} `
      const f2 = `${this.editEvent.innings}.${this.editEvent.over}.${this.editEvent.ballNum} - `
      const f3 = `${this.match.team1Name} vs ${this.match.team2Name} - ${this.match.competition} ${this.match.year} - ${this.editEvent.id}`
      let f = ''
      if (this.editEventLive.bookmark) {
        f = f1
      }
      if (this.editEvent.innings) {
        f = f + f2
      }
      f = f + f3

      this.$store.dispatch("clippingRequests/insert", {
        file: this.editEvent.file,
        event: this.editEvent,
        filename: f,
        status: "requested",
      });
      this.$store.dispatch("archiveEvents/patch", {
        id: this.editEvent.id,
        clipStatus: "clipping",
      });

    },
    changeEvent(event, type) {
      var update = { id: this.editEvent.id };
      update[type] = event;
      console.log(update);
      this.$store.dispatch("archiveEvents/patch", update);
    },
    deleteEvent() {
      this.$store.dispatch("files/delete", this.editEvent.clipFile);
      this.$store.dispatch("archiveEvents/delete", this.editEvent.id);
      this.stopEdit();
    },
    deleteClip() {
      this.$store.dispatch("files/delete", this.editEvent.clipFile);
      this.$store.dispatch("archiveEvents/patch", {
        id: this.editEvent.id,
        clipStatus: "not clipped",
        clipped: false,
        clipSource: "user",
        clipFile: "n/a",
      });
    },
    markIt() {
      let days = this.file.day ? this.file.day : 1; // use a match day if one set
      var newDate = moment(this.match.startDate).clone().add(days, "days");
      var newDateStr = newDate.format("DD/MM/YYYY");
      let payload = {
        tcIn: this.tcIn,
        tcOut: this.tcOut,
        file: this.fileId,
        match: this.file.match,
        year: this.match.year,
        month: newDate.month() + 1,
        date: newDateStr,
        cricketFormat: this.match.cricketFormat,
        cricketType: this.match.cricketType,
        ground: this.match.ground,
        video: true,
        source: "user",
        username: this.user.name,
      };
      if (this.bookmark) {
        payload.bookmark = this.bookmark;
      }
      if (this.bookmarkPlayer) {
        payload.bookmarkPlayer = this.bookmarkPlayer;
      }
      if (this.bookmarkTeam) {
        payload.bookmarkTeam = this.bookmarkTeam;
      }
          if (this.note) {
        payload.note = this.note;
      }

      this.$store.dispatch("archiveEvents/insert", payload).then((res) => {
        // make this the edited clip
        let obj = this.eventsObj[res];
        this.editEvent = obj;
      });
    },
    reassociate() {
      this.$store.dispatch("files/patch", {
        id: this.$route.params.id,
        what: "archive-awaiting",
      });
    },
    // navigate(id) {
    //   console.log(id)
    //   // window.location.href = "http://echo.ecb.co.uk/#/archive/recording/" + id
    //   // // this.$router.push({ path: '/#/archive/recording/' + id })
    // }
  },
};
</script>

<style scoped>
.viewing-card {
  display: flex;
  /* overflow: hidden; */
  
}
.viewer {
  flex: 1 1 auto;
}
.events {
  padding-left: 15px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.event-controls{
flex: 0 0 auto;
}
.event-list {
flex: 1 1 0px;
overflow-y: auto;

}

.bookmarkOption {
  border: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
</style>
