<template>
  <div>
    <form class="new-event--form" @submit.prevent="saveEvent">
      <base-input label="Production" v-if="!productionId">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.production"
          filterable
          remote
          :remote-method="search"
          :loading="loading"
          placeholder="Select here"
        >
          <el-option
            v-for="prod in searchResults"
            :key="prod._source.id"
            :label="prod._source.department + ': ' + prod._source.title"
            :value="prod._source.id"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-checkbox
        class="mt--3 mb-4"
        v-model="showCompletedProductions"
        v-if="!productionId"
      >
        Include Completed Productions
      </base-checkbox>
      <base-input type="text" label="Shoot Title" v-model="model.shootTitle" />

      <base-input label="Status">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.shootStatus"
          placeholder="Select here"
        >
          <el-option label="Pencilled" value="pencilled"> </el-option>
          <el-option label="Confirmed" value="confirmed"> </el-option>
          <el-option label="Cancelled" value="cancelled"> </el-option>
        </el-select>
      </base-input>
      <div class="row">
        <div class="col">
          <label class="col-form-label form-control-label">Date</label>
        </div>
        <div class="col">
          <label class="col-form-label form-control-label">Days</label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-input>
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="{ allowInput: true }"
              class="form-control datepicker"
              v-model="model.date"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="col">
          <base-input type="number" v-model="model.days"> </base-input>
        </div>
      </div>

      <base-input label="Timings">
        <textarea
          class="form-control"
          rows="2"
          v-model="model.timings"
        ></textarea>
      </base-input>
      <base-input label="Shooter">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.shooter"
          placeholder="Select here"
        >
          <el-option label="Brandon" value="Brandon"> </el-option>
          <el-option label="Danny" value="Danny"> </el-option>
          <el-option label="Other" value="Other"> </el-option>
        </el-select>
      </base-input>
      <base-input label="Crew">
        <textarea class="form-control" rows="3" v-model="model.crew"></textarea>
      </base-input>
      <base-input label="Location Details">
        <textarea
          class="form-control"
          rows="3"
          v-model="model.location"
        ></textarea>
      </base-input>
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <button v-if="(this.productionId || this.model.production) && this.model.date"
        type="submit"
        class="btn btn-primary new-event--add"
        @click="saveEvent()"
      >
        Add Shoot
      </button>
      <!-- <button type="warning" class="btn btn-warning new-event--add" @click="saveEvent('unavailable')">Mark as Unavailable</button> -->

      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Firebase } from "@/firebase";

export default {
  name: "shoot-add-form",
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
      model: {
        production: "",
        days: 1,
        timings: "",
        location: "",
        crew: "",
        shooter: "",
        date: "",
        shootTitle: "",
        shootStatus: "pencilled",
      },
    };
  },
  props: ["start", "productionId"],
  components: { flatPicker },
  computed: {
    productions() {
      var newArray = this.$store.getters["briefs/productions"];
      var filteredOnce = newArray;
      if (!this.showCompletedProductions) {
        filteredOnce = filteredOnce.filter(function (el) {
          return !el.completed;
        });
      }
      return filteredOnce;
    },
    production() {
      if (this.productionId) {
        return this.$store.getters["briefs/byId"](this.productionId)
      } else {
        return this.searchResults.filter(a => a._id === this.model.production)[0]['_source']
      }
    },
  },
  watch: {
    start(newRes, oldRes) {
      this.model.date = newRes;
    },
  },
  methods: {
    closeModals() {
      this.model = {
        production: "",
        days: 1,
        date: "",
        timings: "",
        location: "",
        shooter: "",
        crew: "",
        shootTitle: "",
        shootStatus: "pencilled",
      };
      this.$emit("closeModals");
    },
    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },
    saveEvent() {
      var start = moment(this.model.date);
      var end = moment(this.model.date);
      end.add(this.model.days, "days");

        let payload = {
          start: this.strDate(start),
          end: this.strDate(end),
          date: new Date(this.model.date),
          resourceId: "shoots",
          type: "shoot",
          days: this.model.days,
          title: this.production.title,
          prodTitle: this.production.title,
          shootTitle: this.model.shootTitle,
          crew: this.model.crew,
          shooter: this.model.shooter,
          timings: this.model.timings,
          location: this.model.location,
          shootStatus: this.model.shootStatus,
        };
        if (this.productionId) {
          //if on the brief form, then the productionId is inherited, otherwise it's added by the user
          payload.productionId = this.productionId
        } else {
          payload.productionId = this.model.production
        }

             // which month-year this covers
      // e.g. {mar2024: true}
      // for each day in the span

      let monthYear = {}
      for (let i = 0; i < payload.days; i++) {
        if (i > 0) {
           start = start.add(1, "d") // add a day to the date
        }
        const formattedDate = start.format('MM-YYYY')
        monthYear[formattedDate] = true
      }
      payload.monthYear = monthYear

        console.log(payload);
        this.$store.dispatch("shoots/insert", payload);

        var newBrief = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("newShootSlack");

      newBrief({
        briefTitle: this.production.title,
        url: `/brief/${this.productionId}`,
        date: this.model.date,
        shootTitle: this.model.shootTitle,
      });


      this.closeModals();
    },
    async search(query) {
    let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [
                ],
                must_not: [{match: { status: 'Draft' }}]
              },
            },
          },
        },
      };

      if (!this.showCompletedProductions) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (query) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: query, fuzziness: "AUTO" } },
        });
      }

      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
};
</script>
