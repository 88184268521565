<template>
  <div>
    <div v-if="exists">
      <div v-for="(owner, index) in ownerStatus" :key="index">
        <div class="row mb-2">
          <div class="col-4 mt-2">{{ owner.name }}</div>
          <!-- display the current users' box -->
          <div class="col" v-if="user.id === index">
            <el-select
              v-model="del.reviewStatus[index]"
              placeholder="Not Seen"
              @input="reviewStatusChange($event, index)"
            >
              <el-option
                v-for="option in feedbackStatuses"
                :key="option"
                :label="option"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>
          <!-- display everyone elses' box -->
          <div class="col" v-else>
            <el-select
              v-model="del.reviewStatus[index]"
              disabled
              placeholder="Not Seen"
            >
              <el-option
                v-for="option in feedbackStatuses"
                :key="option"
                :label="option"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <!-- if this field doesn't exist (legacy support - can delete this div in the future, maybe Q2 2021) -->
    <div v-else> 
      <div v-for="(owner, index) in ownerStatus" :key="index">
        <div class="row mb-2">
          <div class="col-4 mt-2">{{ owner.name }}</div>
          <!-- display the current users' box -->
          <div class="col" v-if="user.id === index">
            <el-select
              v-model="dummy[index]"
              placeholder="Not Seen"
              @input="reviewStatusChange($event, index)"
            >
              <el-option
                v-for="option in feedbackStatuses"
                :key="option"
                :label="option"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>
          <!-- display everyone elses' box -->
          <div class="col" v-else>
            <el-select
              v-model="dummy[index]"
              disabled
              placeholder="Not Seen"
            >
              <el-option
                v-for="option in feedbackStatuses"
                :key="option"
                :label="option"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dummy:{}, // legacy support, safe to delete in maybe Q2 2021
      feedbackStatuses: [
        "Approve",
        "Reject: added my notes",
        "Reject: no notes",
        "No opinion",
      ],
    };
  },
  props: ["del"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    exists() {
      if (this.del.hasOwnProperty("reviewStatus")) {
        return true;
      } else {
        return false;
      }
    },
    ownerStatus() {
      const oList = {};
      this.del.owners.forEach((d) => {
        var t = this.users.filter((u) => u.id === d);
        if (t[0]) {
          oList[d] = t[0];
        } else {
          oList[d] = { name: "Deleted User" };
        }
      });
      return oList;
    },
  },
  methods: {
    reviewStatusChange(value, owner) {
      console.log("value: " + value);
      console.log("owner: " + owner);
      let rev = {};
      rev[owner] = value;
      this.$store.dispatch("deliverables/patch", {
        id: this.del.id,
        reviewStatus: rev,
      });
    },
  },
};
</script>

<style>
</style>
