const matches = {
  firestorePath: 'matches',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'matches',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      startDate: '%convertTimestamp%',
    },
  },
  state: {
  },
  getters: {
    array: state => Object.values(state.data),
    selectQuery: state => {
      let data = Object.values(state.data)
      data.forEach(d => {

        d.label = ``
        d.label = d.competitionName ? d.label + d.competitionName + ' | ' : ''
        d.label = d.competition ? d.label + d.competition + ' | ' : ''
        d.label = d.label + d.team1Name + ' vs ' + d.team2Name

        d.selectedLabel = ``
        d.selectedLabel = d.competition ? d.selectedLabel + d.competition + ' | ' : ''
        d.selectedLabel = d.selectedLabel + d.team1Name + ' vs ' + d.team2Name

      })
      return data.sort((a, b) => {
        if (a.startDate > b.startDate) {
          return -1
        }
        if (a.startDate < b.startDate) {
          return 1
        }
        if (!a.startDate) {
          return -1
        }
        return 0
      })


    },
    obj: state => state.data,
    byId: (state) => (id) => {
      return state.data[id]
    },
  },
  mutations: {
  },
  actions: {
  },
}

export default matches