<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <base-alert type="default" v-if="prod.status == 'Draft'">
        <div class="row">
          <div class="col mt-2">
            <span class="alert-inner--icon"
              ><i class="ni ni-air-baloon"></i
            ></span>
            <span class="alert-inner--text"
              ><strong> Draft!</strong> This is brief is not on the worklist as
              in draft mode.</span
            >
          </div>
          <div class="col-2 ml-auto" v-if="user.roles.production_admin">
            <base-button
              type="secondary"
              block
              aria-label="Submit"
              @click="submit('Submitted', 'status')"
            >
              Submit
            </base-button>
          </div>
        </div>
      </base-alert>
      <div class="row mb-4">
        <div class="col">
          <div class="tabBox">
            <div
              class="tab btn btn-secondary mr-0"
              :class="{ 'btn-default': tab === 'deliverables' }"
              @click="tab = 'deliverables'"
            >
              Deliverables
              <badge
                class="ml-1"
                v-if="deliverables.length > 0"
                type="success"
                >{{ deliverables.length }}</badge
              >
            </div>

            <div
              class="tab btn btn-secondary mr-0"
              :class="{ 'btn-default': tab === 'brief' }"
              @click="tab = 'brief'"
            >
              Brief Form
            </div>

            <div class="tab btn btn-secondary mr-0"
            :class="{ 'btn-default': tab === 'inspirations' }"
              @click="tab = 'inspirations'">
             Inspirations
             <badge
                class="ml-1"
                v-if="inspirations.length > 0"
                type="success"
                >{{ inspirations.length }}</badge
              ></div>

            <div
              class="tab btn btn-secondary mr-0"
              :class="{ 'btn-default': tab === 'shoots' }"
              @click="tab = 'shoots'"
              v-if="prod.status != 'Draft'"
            >
              Shoots
              <badge
                class="ml-1"
                v-if="shoots.length > 0"
                type="success"
                >{{ shoots.length }}</badge
              >
            </div>

            <div
              class="tab btn btn-secondary mr-0"
              :class="{ 'btn-default': tab === 'effort' }"
              @click="tab = 'effort'"
              v-if="prod.status != 'Draft'"
            >
              Effort
              <badge
                class="ml-1"
                v-if="prod.totalDays > 0"
                type="success"

                >{{ prod.totalDays }}</badge
              >
            </div>

            <div class="tab btn btn-secondary mr-0" v-if="prod.status != 'Draft'"
            :class="{ 'btn-default': tab === 'costs' }"
              @click="tab = 'costs'"
              >Costs
                       <badge
                class="ml-1"
                v-if="costs.length > 0"
                type="success"
                >{{ costs.length }}</badge
              >
              </div>


          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <DeliverablesWidget
        v-if="tab === 'deliverables'"
        :prod="prod"
      ></DeliverablesWidget>
      <brief-form v-if="tab === 'brief'"></brief-form>
      <inspirations v-if="tab === 'inspirations'"></inspirations>
      <shoots v-if="tab === 'shoots'"></shoots>
      <shifts v-if="tab === 'effort'"></shifts>
      <costs v-if="tab === 'costs'"></costs>
    </div>
  </div>
</template>
<script>

import { Firebase } from "@/firebase.js";

import DeliverablesWidget from "./Components/DeliverablesWidget";

import BriefForm from "./Brief-Form.vue";
import Inspirations from "./InspirationsPerBrief.vue";
import Shoots from "./ShootsPerBrief.vue";
import Shifts from "./ShiftsPerBrief.vue";
import Costs from "./CostsPerBrief.vue";

export default {
  name: "brief",
  data: function () {
    return {
      tab: "deliverables",
    };
  },
  components: {
    DeliverablesWidget,
    Inspirations,
    BriefForm,
    Shoots,
    Shifts,
    Costs,
  },
  computed: {
    prodID() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.getters["user/user"];
    },

    deliverables() {
      return this.$store.getters["deliverables/delsByProdID"](
        this.$route.params.id
      );
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    prod() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id);
    },
    shifts() {
      return this.$store.getters["shifts/byBriefId"](this.$route.params.id);
    },
    costs() {
      return this.$store.getters["costs/byBrief"](this.$route.params.id);
    },
    shoots() {
      return this.$store.getters["shoots/byBriefId"](this.$route.params.id);
    },
    inspirations() {
      return this.$store.getters["inspirations/byBrief"](this.$route.params.id)
    },
  },
  beforeMount() {
    this.$store.dispatch("briefs/fetchById", this.$route.params.id);
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("collections/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("shifts/fetchAndAdd", {
      where: [["productionId", "==", this.prodID]],
      limit: 0,
    });
    this.$store.dispatch("shoots/fetchAndAdd", {
      where: [["productionId", "==", this.prodID]],
      limit: 0,
    });
    this.$store.dispatch("costs/fetchAndAdd", {
      where: [["productionId", "==", this.prodID]],
      limit: 0,
    });
    this.$store.dispatch("files/openDBChannel", {
      where: [["prodID", "==", this.$route.params.id]],
    });
    this.$store.dispatch("zips/fetchAndAdd", {
      where: [["prodID", "==", this.$route.params.id]],
    });
    this.$store.dispatch("inspirations/fetchAndAdd", {
      where: [[`briefs.${this.$route.params.id}`,'==',true]]
    });
    this.$store.commit("archiveTemp/clearDelSelection");
  },
  methods: {
    //     deleteFile(fileId) {
    // this.$store.dispatch("files/delete", fileId);
    //     },
    changeField(value, field) {
      var payload = {};
      payload.id = this.prodID;
      payload[field] = value;
      this.$store.dispatch("briefs/patch", payload);
    },
    submit(value, field) {
      this.changeField(value, field);
      var newBrief = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("newBriefSlack");
      //remove whitespace and characters for slack requrements
      let name =
        this.prod.department.replace(/[\W_]+/g, "-").trim() +
        "-" +
        this.prod.title.replace(/[\W_]+/g, "-").trim();
      //truncate to 60 characters if longer than that
      name = name.substring(0, Math.min(60, name.length));
      name = name.toLowerCase();
      let users = this.ownerSlacks;
      newBrief({
        briefTitle: this.prod.title,
        name: name,
        productionID: this.prodID,
        users: this.ownerSlacks,
        url: `/brief/${this.prodID}`,
        department: this.prod.department,
      });
    },
  },
};
</script>
<style>
.tabBox {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.tab {
  cursor: pointer;
}
</style>

