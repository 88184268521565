import moment from 'moment'

export default (value) => {

  let v
  if (value._seconds) {
    v = new Date(value['_seconds'] * 1000)
  } else {
    v = value
  } 

  return moment(v).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
});
}
