<template>
  <div>
    <base-header
      class="pb-6 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Main</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <base-input
                    label="Display Name"
                    v-model="billing.label"
                    @change="changeField($event.target.value, 'label')"
                  />
                  <base-input label="Company">
                    <el-select
                      v-model="billing.company"
                      filterable
                      placeholder="Select"
                      @change="changeField($event, 'company')"
                    >
                      <el-option
                        v-for="option in config.producer"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input
                    label="Group"
                    v-model="billing.group"
                    @change="changeField($event.target.value, 'group')"
                  />
                  <div class="form-group row">
                  <base-input class="col" label="Start Date" v-model="billing.start" @change="changeField($event.target.value, 'start')" type="date"/>
                  <base-input class="col" label="End Date" v-model="billing.end" @change="changeField($event.target.value, 'end')" type="date"/>
                </div>
                  <base-input
                    label="Quota Days"
                    v-model="billing.quota"
                    type="number"
                    @change="changeField($event.target.value, 'quota')"
                  />
                  <base-input
                    label="Day Rate £"
                    v-model="billing.dayRate"
                    type="number"
                    @change="changeField($event.target.value, 'dayRate')"
                  />
                  <div class="form-group row">
                  <label class="col-auto form-control-label">Chargeable</label>
                  <div class="col">
                    <base-switch
                      v-model="billing.invoice"
                      type="warning"
                      on-text="Yes"
                      off-text="No"
                      @input="changeField($event, 'invoice')"
                    ></base-switch>
                  </div>
                </div>
                <base-input label="Colour">
                    <el-select
                      v-model="billing.colour"
                      placeholder="Select"
                      @change="changeField($event, 'colour')"
                    >
                      <el-option
                        v-for="option in colours"
                        :key="option.value"
                        :label="option.text"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <div class="form-group row">
                  <label class="col-auto form-control-label">Active</label>
                  <div class="col">
                    <base-switch
                      v-model="billing.active"
                      type="warning"
                      on-text="Yes"
                      off-text="No"
                      @input="changeField($event, 'active')"
                    ></base-switch>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col">Attached to {{ attachedResources.length }} resources</div>
                  <div class="col" v-if="!billing.active">
                    <base-button type="danger" @click="stripFromResources">Remove from all resources</base-button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import isFriday from 'date-fns/is_friday/index';
export default {
  name: "resource",
  data: function () {
    return {
      colours: [{text: 'Rate Card', value: '#fb6340'}, {text: 'Retainer', value: '#5e72e4'}],
 
    };
  },
  computed: {
    billingId() {
      return this.$route.params.id;
    },
    users() {
      return this.$store.getters["users/users"];
    },
    billing() {
      return this.$store.getters["billings/byId"](this.$route.params.id);
    },
    billings() {
      return this.$store.getters["billings/billings"]
    },
    config() {
      return this.$store.getters["config/config"];
    },
    resources() {
      return this.$store.getters["resources/resources"]
    },
    attachedResources() {
      return this.$store.getters["resources/withBilling"](this.$route.params.id);
    }
  },
  methods:{
    changeField(value, field) {
      var payload = {};
      payload.id = this.billingId;
      payload[field] = value;
      this.$store.dispatch("billings/patch", payload);
    },
    stripFromResources() {
      this.attachedResources.forEach(res => {
        let payload = {}
        payload.id = res.id
        let arr = res.availableBillings
        payload.availableBillings = arr.filter(e => e !== this.$route.params.id); //take this page id out of those arrays
// if default
        if(res.defaultBilling === this.$route.params.id) {
          payload.defaultBilling = ''
        }
        //commit
        this.$store.dispatch("resources/patch", payload)
      })

    }
  },
};
</script>
