<template>
  <div class="card smaller-text">
    <div v-if="currentFile">
      <file-preview :file="currentFile"></file-preview>
    </div>

    <div class="container draggableCard" draggable="true" @dragstart="dragStart($event)" @mouseover="hover = true"
        @mouseleave="hover = false">
      <div class="row" v-if="uploadable">
        <div class="col mt-3">
          <deliverable-upload-wrapper boxHeight="90px" message="Upload File" :deliverable="deliverable"
            :production="prod"></deliverable-upload-wrapper>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="editFolderMode">
          <el-select v-model="deliverable.folder" placeholder="Folder" clearable
            @change="changeField($event, deliverable.id, 'folder')">
            <el-option v-for="option in delFolders" :key="option.id" :label="option.display" :value="option.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="col-form-label">
            <span class="h4">{{ deliverable.title }}</span>
            <span v-if="hover && user.roles.production_drafts"><el-tooltip content="Edit Deliverable" placement="top">
                <i class="fas fa-edit ml-3 editIcon" @click="editDel"></i>
              </el-tooltip>
            </span>
          </div>
        </div>
      </div>
      <div class="row" v-if="deliverable.state !== 'signed'">
        <div class="col col-form-label">
          <span v-if="deliverable.state == 'feedback'"><i class="fa fa-comments text-warning mr-2"></i></span>
          <span>{{ stateDisplay }}</span>
        </div>
      </div>
      <div class="row" v-if="deliverable.state !== 'signed'">
        <div class="col">
          <span class="mr-2" v-if="deliverable.type">
            <badge type="info">
              {{ deliverable.type }}
            </badge>
          </span>
          <span class="mr-2" v-if="deliverable.type != 'Image' && deliverable.desiredDuration">
            <!-- <el-tooltip content="Desired Duration" placement="top"> -->
            <badge type="warning" class="mr-1">
              {{ deliverable.desiredDuration }}
            </badge>
            <!-- </el-tooltip> -->
          </span>
          <span class="mr-2" v-if="deliverable.format">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="success" class="mr-1">
              {{ deliverable.format }}
            </badge>
            <!-- </el-tooltip> -->
          </span>
          <span class="mr-2" v-if="deliverable.brand">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="secondary" class="mr-1">
              {{ deliverable.brand }}
            </badge>
            <!-- </el-tooltip> -->
          </span>
          <span class="mr-2" v-if="deliverable.subs">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="danger" class="mr-1">
              Burnt-in Subs
            </badge>
            <!-- </el-tooltip> -->
          </span>
          <span class="mr-2" v-if="deliverable.srt">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="danger" class="mr-1">
              srt
            </badge>
            <!-- </el-tooltip> -->
          </span>
        </div>
      </div>
      <div class="row mt-2" v-if="hasDescription && deliverable.state !== 'signed'">
        <div class="col">
          <el-tooltip :content="deliverable.description" placement="top">
            <span class="btn-group-toggle" data-toggle="buttons">
              <label class="btn" :class="[{ active: descriptionShow }, 'btn-sm']">
                <input v-model="descriptionShow" type="checkbox" checked="" autocomplete="off" />
                <i class="fas fa-info mr-2" />
                Note
              </label>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div class="row" v-if="descriptionShow">
        <div class="col">
          <small>
            {{ deliverable.description }}
          </small>
        </div>
      </div>
      <div class="row" v-if="deliverable.state !== 'held' && deliverable.state !== 'signed'">
        <div class="col">
          <div v-if="deliverable.state === 'production' ||
            deliverable.state === 'reworking'
            ">
            <!-- <el-tooltip content="ETA" placement="top">
              <div
                class="col-form-label"
                @click="alert('test')"
                v-if="deliverable.eta"
              >
                <i class="fa fa-plane-arrival mr-1"></i>
                {{ deliverable.eta | dateTime }}
              </div>
              <div class="col-form-label" v-else>
                <i class="fa fa-plane-arrival mr-1"></i> Not Set
              </div>
            </el-tooltip> -->

            <el-tooltip content="Deadline" placement="top">
              <div class="col-form-label" v-if="deliverable.deadline">
                <i class="fa fa-clock mr-1"></i>
                {{ deliverable.deadline | dateTime }}
              </div>
            </el-tooltip>
          </div>
          <div class="row" v-if="deliverable.state === 'feedback'">
            <el-tooltip content="Deadline" placement="top">
              <div class="col col-form-label" v-if="deliverable.deadline">
                <i class="fa fa-clock mr-1"></i>
                {{ deliverable.deadline | dateTime }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <div class="row mb-2 mt-1" v-if="hasFiles">
        <div class="col-auto" v-if="user.roles.production_drafts">
          <a class="btn btn-sm" :href="goTo">
            <i class="fas fa-images worklistIcon mr-1"></i>Feedback
          </a>
        </div>
        <div class="col-auto" v-if="deliverable.state === 'signed'">
          <a download class="btn btn-sm" :href="currentFile.sourceDownloadURL">
            <i class="fa fa-download mr-1"></i>Download
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FeedbackersUpdate from "./FeedbackersUpdate";
import DeliverableUploadWrapper from "./DeliverableUploadWrapper";
import router from "@/routes/router";
export default {
  props: ["deliverable", "previews", "editFolderMode"],
  data() {
    return {
      descriptionShow: false,
      hover: false,
    };
  },
  components: {
    flatPicker,
    FeedbackersUpdate,
    "deliverable-upload-wrapper": DeliverableUploadWrapper,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    hasDescription() {
      if (this.deliverable.description) {
        return this.deliverable.description.length > 2 ? true : false;
      } else {
        return false;
      }
    },
    // delFolders() {
    //   return this.$store.getters["deliverableFolders/foldersByProdID"](
    //     this.deliverable.production
    //   );
    // },
    prod() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id);
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    state() {
      const state = this.deliverable.state;
      const statuses = this.statuses;
      let s = statuses.filter((r) => r.key === state)[0];
      return s;
    },
    stateDisplay() {
      if (this.state.key === "reworking") {
        return "Working on v" + (this.deliverable.currentVersion + 1);
      } else if (this.state.key === "feedback") {
        return "Awaiting feedback for v" + this.deliverable.currentVersion;
      } else if (this.state.key === "production") {
        return null;
      } else if (this.state.key === "held") {
        return "On Hold";
      } else {
        return null;
      }
    },
    uploadable() {
      return this.deliverable.state === "signed"
        ? false
        : true;
    },
    feedback() {
      return this.deliverable.state === "feedback" ? true : false;
    },
    goTo() {
      return `/#/brief/${this.deliverable.production}/deliverable/${this.deliverable.id}`;
    },
    files() {
      return this.$store.getters["files/filesByDelId"](this.deliverable.id);
    },
    hasFiles() {
      return this.files.length;
    },
    currentFile() {
      return this.$store.getters["files/fileById"](
        this.deliverable.currentFileID
      );
    },
    // classObj() {
    //   if (this.deliverable.state == "signed") {
    //     return "signedOff";
    //   } else {
    //     return "";
    //   }
    // },
  },
  methods: {
    editDel() {
      this.$emit("editDel", this.deliverable);
    },
    changeField(value, key, field) {
      var payload = {};
      payload.id = key;
      payload[field] = value;
      this.$store.dispatch("deliverables/patch", payload);
    },
    dragStart: function (ev) {
      let folder = ''
      if(this.deliverable.folderName){
        folder = this.deliverable.folderName
      }

      let j = JSON.stringify({delId: this.deliverable.id, currentFolder: folder})
      ev.dataTransfer.setData("text/plain", j);
      // console.log(ev.dataTransfer)
    },

  },
};
</script>

<style>
.status {
  height: 55px;
  width: 100%;
}

.smaller-text {
  font-size: 0.9em;
}

/* .signedOff {
  font-weight: bolder;
} */

.editIcon {
  cursor: pointer;
}

.editIcon:hover {
  color: tomato;
}

.draggableCard:hover {
  cursor: grab;
}
</style>
