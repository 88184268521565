<template>
  <div>
    <h3 v-if="this.resourceData">{{ this.resourceData.displayName }}</h3>
    <form class="new-event--form" @submit.prevent="saveEvent">
      <base-input label="Production">
        <el-select input-classes="form-control-alternative new-event--production" v-model="model.production" filterable
          remote :remote-method="search" :loading="loading" placeholder="Select here">
          <el-option v-for="prod in searchResults" :key="prod._source.id"
            :label="prod._source.department + ': ' + prod._source.title" :value="prod._source.id">
          </el-option>
        </el-select>
      </base-input>
      <base-checkbox class="mt--3 mb-4" v-model="showCompletedProductions">
        Include Completed Productions
      </base-checkbox>

      <base-input label="Duration" default="1">
        <el-select input-classes="form-control-alternative new-event--production" v-model="model.days" filterable
          placeholder="Select here">
          <el-option v-for="option in durations" :key="option.display" :label="option.display" :value="option.value">
          </el-option>
        </el-select>
      </base-input>

      <base-input label="Billing">
        <el-select input-classes="form-control-alternative" v-model="model.billing" filterable
          placeholder="Select here">
          <el-option v-for="prod in this.billings" :key="prod.id" :label="prod.label" :value="prod.id">
          </el-option>
        </el-select>
      </base-input>

      <div class="form-control-label" v-if="model.manualCost === false">Cost: £{{ cost }}</div>
      <base-input v-else label="Manual Cost" class="mb--4" v-model="model.manualCostAmount" type="number" />

      <base-checkbox class="mb-3 mt-1" v-model="model.manualCost">
        Over-ride default cost
      </base-checkbox>



      <base-input label="Budget" v-if="billingDetail.invoice">
        <el-select input-classes="form-control-alternative new-event--production" v-model="model.budget" filterable
          placeholder="Select here">
          <el-option v-for="prod in this.budgets" :key="prod.id" :label="prod.name" :value="prod.id">
          </el-option>
        </el-select>
      </base-input>

    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <button type="submit" class="btn btn-primary new-event--add" @click="saveEvent('production')">
        Add Session
      </button>
      <!-- <button type="warning" class="btn btn-warning new-event--add" @click="saveEvent('unavailable')">Mark as Unavailable</button> -->
      <base-dropdown>
        <base-button slot="title-container" type="secondary" class="dropdown-toggle">
          Off
        </base-button>
        <a class="dropdown-item" @click="saveEvent('unavailable')">Unavailable</a>
        <a class="dropdown-item" @click="saveEvent('holiday')">Holiday</a>
      </base-dropdown>
      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Firebase } from "@/firebase";

export default {
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
      model: {
        billing: "",
        shiftId: "",
        days: 1,
        production: "",
        budget: "",
        manualCost: false,
        manualCostAmount: 0,
      },
    };
  },
  props: ["durations", "resource", "start"],
  computed: {
    // productions() {
    //   var newArray = this.$store.getters["briefs/productions"];
    //   var filteredOnce = newArray;
    //   if (!this.showCompletedProductions) {
    //     filteredOnce = filteredOnce.filter(function (el) {
    //       return !el.completed;
    //     });
    //   }
    //   return filteredOnce;
    // },
    production() {
      return this.searchResults.filter(
        (a) => a._id === this.model.production
      )[0]["_source"];
    },
    resourceData() {
      return this.$store.getters["resources/byId"](this.resource);
    },
    billings() {
      let output = [];
      let bills = this.resourceData.availableBillings;
      bills.forEach((b) => {
        output.push(this.$store.getters["billings/byId"](b));
      });
      return output;
    },
    billingDetail() {
      return this.$store.getters["billings/byId"](this.model.billing);
    },
    budgets() {
      return this.$store.getters["budgets/active"];
    },
    cost() {
      if (this.model.manualCost) {
        return this.model.manualCostAmount
      }
      else if (this.billingDetail && this.model.days) {
        return this.model.days * this.billingDetail.dayRate;
      } else {
        return 0;
      }
    },
  },
  watch: {
    resource(newRes, oldRes) {
      // console.log(newRes);
      let res = this.$store.getters["resources/byId"](newRes);
      // console.log(res);
      if (res.defaultBilling) {
        this.model.billing = res.defaultBilling;
      }
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    closeModals() {
      this.model = {
        days: 1,
        shiftId: "",
        production: "",
        billing: "",
        budget: "",
        manualCost: false,
        manualCostAmount: 0,
      };
      (this.model.production = ""), this.$emit("closeModals");
    },
    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },
    saveEvent(type) {
      var start = moment(this.start);
      var end = moment(this.start);
      end.add(1, "days");

      let payload = {
        start: this.strDate(start),
        end: this.strDate(end),
        date: this.start,
        resourceId: this.resource,
        days: this.model.days,
      };
      // console.log(payload)

      if (!this.model.production && type === "production") {
        return false;
      }
      if (this.model.production && type === "production") {
        payload.productionId = this.model.production;
        payload.type = "production";
        payload.title = this.production.title;

        // console.log(payload)
        if (this.model.billing) {
          payload.billing = this.model.billing;
          payload.cost = this.cost
          payload.manualCost = this.model.manualCost;
          payload.backgroundColor = this.billingDetail.colour;
          payload.budget = this.model.budget;

          if (this.billingDetail.invoice) {
            payload.invoiceable = true;
          } else {
            payload.invoiceable = false;
          }
        }

      } else if (type === "unavailable") {
        payload.productionId = "unavailable";
        payload.type = "unavailable";
        payload.title = "Unavailable";
      } else if (type === "holiday") {
        payload.productionId = "unavailable";
        payload.type = "holiday";
        payload.title = "Holiday";
      }
      // console.log(payload);
      this.$store.dispatch("shifts/insert", payload);
      this.closeModals();
    },
    async search(query) {
      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
                must_not: [{ match: { status: "Draft" } }],
              },
            },
          },
        },
      };

      if (!this.showCompletedProductions) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (query) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: query, fuzziness: "AUTO" } },
        });
      }

      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
};
</script>
