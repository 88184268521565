<template>
    <form class="edit-user--form" @submit.prevent="editUser">
        <div class="h3 mb-3">
            Number of selected users: {{ selection.length }}
        </div>
        <base-input label="Module Access">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <role-selector role="creative_catalogue_access" label="Creative Catalogue: Access"
                            :users="selection" colour="info"></role-selector>
                        <role-selector role="production_access" label="Production: Access" colour="success"
                            :users="selection"></role-selector>
                        <role-selector role="inspirations_access" label="Inspirations: Access" colour="success"
                            :users="selection"></role-selector>

                    </div>
                    <div class="col-6">
                        <role-selector role="archive_access" label="Match Archive: Access" :users="selection"
                            colour="danger"></role-selector>
                        <role-selector role="archive_search" label="Match Archive: Search" :users="selection"
                            colour="danger"></role-selector>
                        <role-selector role="fcc_access" label="FCC Video: Access" :users="selection"
                            colour="danger"></role-selector>
                        <role-selector role="livestream_access" label="Livestreaming: Access" colour="primary"
                            :users="selection"></role-selector>
                    </div>
                </div>
            </div>
        </base-input>

        <base-input label="Priviledges">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <role-selector role="production_drafts" label="Production: Drafts" colour="success"
                            :users="selection"></role-selector>
                        <role-selector role="production_feedback" label="Production: Feedbacker" colour="success"
                            :users="selection"></role-selector>
                        <role-selector role="production_shifts" label="Production: Scheduler" colour="success"
                            :users="selection"></role-selector>
                        <role-selector role="production_admin" label="Production: Admin" colour="success"
                            :users="selection"></role-selector>
                    </div>
                    <div class="col-6">
                        <el-tooltip placement="left" content="Create signed off deliverables by uploading files to briefs">
                            <role-selector role="production_new_deliberables"
                                label="Production: Upload Signed Off Deliverables" :users="selection"
                                colour="success"></role-selector>
                        </el-tooltip>
                        <role-selector role="archive_uploader" label="Match Archive: Admin" colour="danger"
                            :users="selection"></role-selector>

                        <role-selector role="user_admin" label="Users: Admin" colour="warning"
                            :users="selection"></role-selector>
                    </div>
                </div>
            </div>
        </base-input>

        <div class="row">
            <div class="col">
                <div class="card shadow">
                    <div class="card-header">
                        <h4>Creative Catalogue Restrictions</h4>
                        <small>Users can access all content unless restrictions are specified - if so, then only the
                            selected areas
                            can be accessed</small>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <base-input label="Departments">
                                    <el-select input-classes="form-control" v-model="departmentAccess" clearable multiple
                                        filterable placeholder="Access Everything"
                                        @change="changeField($event, 'departmentAccess')">
                                        <el-option v-for="prod in this.config.departments" :key="prod" :label="prod"
                                            :value="prod">
                                        </el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col">
                                <base-input label="Brands">
                                    <el-select input-classes="form-control" v-model="brandAccess" clearable filterable
                                        multiple placeholder="Access Everything"
                                        @change="changeField($event, 'brandAccess')">
                                        <el-option v-for="prod in config.brand" :key="prod" :label="prod" :value="prod">
                                        </el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col">
                                <base-input label="Partners">
                                    <el-select input-classes="form-control" v-model="parnterAccess" clearable filterable
                                        multiple placeholder="Access Everything"
                                        @change="changeField($event, 'partnerAccess')">
                                        <el-option v-for="prod in config.partners" :key="prod" :label="prod" :value="prod">
                                        </el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card shadow">
                    <div class="card-header">
                        <h4>Match Video Catalogue Restrictions</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="form-group row">
                                    <label class="col-auto form-control-label">Internationals</label>
                                    <div class="col">
                                        <base-switch v-model="accessInt" type="warning" on-text="Yes" off-text="No"
                                            @input="changeField($event, 'accessInt')"></base-switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group permission-form" v-if="accessInt">

                            <div class="row mb-3">
                                <label class="col-auto form-control-label">Access games from the current year</label>
                                <div class="col">
                                    <base-switch v-model="accessIntCurrent" type="warning" on-text="Yes" off-text="No"
                                        @input="changeField($event, 'accessIntCurrent')"></base-switch>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <base-input label="Restrict user to:">
                                        <el-select v-model="accessIntFields" filterable multiple
                                            placeholder="Add Access Controls"
                                            @change="changeField($event, 'accessIntFields')">
                                            <el-option v-for="option in internationalFilterOptionsArray" :key="option.field"
                                                :label="option.label" :value="option.field">
                                            </el-option>
                                        </el-select>
                                    </base-input>

                                    <div class="row align-items-center">
                                        <div class="col-4" v-for="filter in accessIntFields" :key="filter">
                                            <filter-selector v-if="accessIntPermissions"
                                                :selection="internationalFilterOptions[filter]"
                                                @valueChange="intFilterValueChange($event, filter)">
                                            </filter-selector>
                                            <filter-selector v-else :selection="internationalFilterOptions[filter]"
                                                @valueChange="intFilterValueChange($event, filter)">
                                            </filter-selector>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group row">
                                    <label class="col-auto form-control-label">Domestic</label>
                                    <div class="col">
                                        <base-switch v-model="accessDom" type="warning" on-text="Yes" off-text="No"
                                            @input="changeField($event, 'accessDom')"></base-switch>
                                    </div>
                                </div>
                                <div class="form-group permission-form" v-if="accessDom">

                                    <div class="row mb-3">
                                        <label class="col-auto form-control-label">Access games from the current
                                            year</label>
                                        <div class="col">
                                            <base-switch v-model="accessDomCurrent" type="warning" on-text="Yes"
                                                off-text="No"
                                                @input="changeField($event, 'accessDomCurrent')"></base-switch>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <base-input label="Restrict user to:">
                                                <el-select v-model="accessDomFields" filterable multiple
                                                    placeholder="Add Access Controls"
                                                    @change="changeField($event, 'accessDomFields')">
                                                    <el-option v-for="option in domesticFilterOptionsArray"
                                                        :key="option.field" :label="option.label" :value="option.field">
                                                    </el-option>
                                                </el-select>
                                            </base-input>

                                            <div class="row align-items-center">
                                                <div class="col-4" v-for="filter in accessDomFields" :key="filter">
                                                    <filter-selector v-if="accessDomPermissions"
                                                        :selection="domesticFilterOptions[filter]"
                                                        @valueChange="domFilterValueChange($event, filter)">
                                                    </filter-selector>
                                                    <filter-selector v-else :selection="domesticFilterOptions[filter]"
                                                        @valueChange="domFilterValueChange($event, filter)">
                                                    </filter-selector>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import RoleSelector from "./RoleSelector.vue";
import FilterSelector from "../../Archive/Components/FilterSelector";
export default {
    data() {
        return {
            departmentAccess: '',
            brandAccess: '',
            parnterAccess: '',
            accessInt: false,
            accessIntCurrent: false,
            accessIntFields: '',
            accessIntPermissions: '',
            accessDom: false,
            accessDomCurrent: false,
            accessDomFields: '',
            accessDomPermissions: '',
            internationalFilterOptions: {
                cricketFormat: {
                    field: "cricketFormat",
                    label: "Format",
                    collection: "matchFormats",
                    collectionLabel: "name",
                    collectionGetter: "formats",
                    type: "multi-select",
                },
                cricketType: {
                    field: "cricketType",
                    label: "Type",
                    collection: "matchFormats",
                    collectionLabel: "name",
                    collectionGetter: "types",
                    type: "multi-select",
                },
                teamsMap: {
                    field: "teamsMap",
                    collection: "teams",
                    collectionLabel: "teamName",
                    label: "Home or Away Teams",
                    type: "multi-select",
                },
                ground: {
                    field: "ground",
                    collection: "venues",
                    collectionLabel: "name",
                    label: "Venue",
                    type: "multi-select",
                },
            },
            domesticFilterOptions: {
                cricketFormat: {
                    field: "cricketFormat",
                    label: "Format",
                    collection: "matchFormats",
                    collectionLabel: "name",
                    collectionGetter: "formats",
                    type: "multi-select",
                },
                cricketType: {
                    field: "cricketType",
                    label: "Type",
                    collection: "matchFormats",
                    collectionLabel: "name",
                    collectionGetter: "types",
                    type: "multi-select",
                },
                teamId1: {
                    field: "teamId1",
                    collection: "teams",
                    collectionLabel: "teamName",
                    label: "Home Team Only",
                    type: "multi-select",
                },
                teamsMap: {
                    field: "teamsMap",
                    collection: "teams",
                    collectionLabel: "teamName",
                    label: "Home or Away Teams",
                    type: "multi-select",
                },
                ground: {
                    field: "ground",
                    collection: "venues",
                    collectionLabel: "name",
                    label: "Venue",
                    type: "multi-select",
                },
            },
        };
    },
    components: {
        "role-selector": RoleSelector,
        "filter-selector": FilterSelector,
    },
    computed: {
        config() {
            return this.$store.getters["config/config"];
        },
        internationalFilterOptionsArray() {
            return Object.values(this.internationalFilterOptions);
        },
        domesticFilterOptionsArray() {
            return Object.values(this.domesticFilterOptions);
        },
    },
    props: ["selection"],
    methods: {
        changeField(value, field) {

            this.selection.forEach((user) => {
                var payload = {};
                payload.id = user.id;
                payload[field] = value
                console.log(payload);
                this.$store.dispatch("users/patch", payload);
            });
        },
        intFilterValueChange(value, filter) {
            this.selection.forEach((user) => {
                let payload = {}
                if (user.accessIntPermissions) {
                    console.log("incoming " + value)
                    console.log("existing " + user.accessIntPermissions[filter])
                    if (user.accessIntPermissions[filter] !== value) {
                        // copy existing fields
                        payload = { id: user.id, accessIntPermissions: user.accessIntPermissions }
                        // update new field
                        payload.accessIntPermissions[filter] = value
                        this.$store.dispatch("users/patch", payload)
                    }
                } else { // first one
                    payload = { id: user.id, accessIntPermissions: {} }
                    payload.accessIntPermissions[filter] = value
                    this.$store.dispatch("users/patch", payload)
                }
            })
        },
        domFilterValueChange(value, filter) {
            this.selection.forEach((user) => {
                let payload = {}
                if (user.accessDomPermissions) {
                    console.log("incoming " + value)
                    console.log("existing " + user.accessDomPermissions[filter])
                    if (user.accessDomPermissions[filter] !== value) {
                        // copy existing fields
                        payload = { id: user.id, accessDomPermissions: user.accessDomPermissions }
                        // update new field
                        payload.accessDomPermissions[filter] = value
                        this.$store.dispatch("users/patch", payload)
                    }
                } else { // first one
                    payload = { id: user.id, accessDomPermissions: {} }
                    payload.accessDomPermissions[filter] = value
                    this.$store.dispatch("users/patch", payload)
                }
            })
        },
    }
}
</script>