<template>
  <div class="container-fluid">
    <div class="row mb-4" v-if="user.roles.archive_uploader">
                <div class="col">
                  <highlight-upload-wrapper
                    boxHeight="90px"
                    :match=match.id
                  ></highlight-upload-wrapper>
                </div>
              </div>
  <div class="row">
    <div v-for="(reel, index) in reels" :key="index" class="col-4">
      <!-- <a href="/#/archive/match/recording">{{recording.name}}</a> -->
      <!-- <router-link class="btn" :to="'/archive/recording/' + recording.id"
        >Day: {{ recording.day }}, Part: {{ recording.part }}</router-link
      > -->
<highlight-card :file="reel" :matchData="match">
</highlight-card>

    </div>
  </div>
    </div>
</template>

<script>
import router from "@/routes/router";
import HighlightCard from './HighlightsCardv2.vue'
import HighlightsUploader from "./HighlightsUploadWrapper";
export default {
  data() {
    return {};
  },
  props: ["match"],
  computed: {
    reels() {
      var arr = this.$store.getters["files/whatByMatch"]({what:'match-reel',id:this.match.id});
      return arr
    },
        user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {

  },
  components: {
    'highlight-card': HighlightCard,
        "highlight-upload-wrapper": HighlightsUploader,
  },
  mounted() {
    this.$store.dispatch("files/fetchAndAdd", {
      where: [
        ["what", "==", "match-reel"],
        ["match", "==", this.match.id],
      ],
    });
  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>