const inspirationTags = {
  firestorePath: 'config/inspirationTags',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'inspirationTags',
  statePropName: 'data',
  namespaced: true, // automatically added
  
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    tags: state => state.data,
  },
  mutations: {},
  actions: {
  }
}

export default inspirationTags