<template>
  <div>
    <base-header
      class="pb-6 content__title content__title--calendar"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>

    <div v-if="loading === false">
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col">
            <!-- Fullcalendar -->
            <div class="card card-calendar">
              <!-- Card header -->
              <div class="card-header">
                <!-- Title -->
                <div class="row align-items-center py-0">
           
                  <div class="col-9"></div>
                  <div class="col-3 text-right">
                    <a
                      href="#"
                      @click.prevent="prev"
                      class="fullcalendar-btn-prev btn btn-sm btn-default"
                    >
                      <i class="fas fa-angle-left"></i>
                    </a>
                    <a
                      href="#"
                      @click.prevent="next"
                      class="fullcalendar-btn-next btn btn-sm btn-default"
                    >
                      <i class="fas fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <!-- Card body -->
              <div class="card-body p-0 card-calendar-body">
                <full-calendar
                  :events="shifts"
                  :views="views"
                  :plugins="calendarPlugins"
                  :editable="true"
                  :firstDay="1"
                  contentHeight="auto"
                  resourceLabelText=" "
                  resourceAreaWidth="18%"
                  resourceOrder="group"
                  :theme="false"
                  :nowIndicator="true"
                  :selectable="true"
                  :selectHelper="true"
                  ref="fullCalendar"
                  class="calendar"
                  :defaultView="defaultView"
                  @dateClick="onDateClick"
                  @eventClick="onEventClick"
                  @eventResize="onEventInteraction"
                  resourceGroupField="group"
                  @eventDrop="onEventInteraction"
                  @datesRender="datesRender"
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                >
                </full-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="showAddModal" modal-classes="modal-scrollable">
      <shift-add-form
        :durations="durations"
        :start="selectedStart"
        :resource="selectedResource"
        @closeModals="closeModals"
      ></shift-add-form>
    </modal>

    <modal :show.sync="showEditModal" modal-classes="modal-scrollable">
      <shift-edit-form
        :durations="durations"
        :shiftId="selectedShift"
        @closeModals="closeModals"
      ></shift-edit-form>
    </modal>

    <modal
      :show.sync="showAddShootModal"
      size="lg"
      modal-classes="modal-scrollable"
    >
      <shoot-add-form
        :start="selectedStart"
        @closeModals="closeModals"
      ></shoot-add-form>
    </modal>

    <modal
      :show.sync="showEditShootModal"
      size="lg"
      modal-classes="modal-scrollable"
    >
      <shoot-edit-form
        :shootId="selectedShift"
        isBriefForm="false"
        @closeModals="closeModals"
      ></shoot-edit-form>
    </modal>
  </div>
</template>
<script>
import moment from "moment";
import Modal from "@/components/Modal";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";


import ShiftAddForm from "./Components/ShiftAddForm";
import ShiftEditForm from "./Components/ShiftEditForm";
import ShootAddForm from "./Components/ShootAddForm";
import ShootEditForm from "./Components/ShootEditForm";

export default {
  name: "calendar",
  components: {
    Modal,
    FullCalendar,
    "shift-add-form": ShiftAddForm,
    "shift-edit-form": ShiftEditForm,
    "shoot-add-form": ShootAddForm,
    "shoot-edit-form": ShootEditForm,
  },
  data() {
    return {
      durations: [
        {
          value: 0.25,
          display: "1/4 Day",
        },
        {
          value: 0.5,
          display: "1/2 Day",
        },
        {
          value: 0.75,
          display: "3/4 Day",
        },
        {
          value: 1,
          display: "1 Day",
        },
      ],
      calendarPlugins: [interactionPlugin, resourceTimelinePlugin],
      defaultView: "resourceTimelineWeek",

      views: {
        week: {
          //              titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
          allDaySlot: true,
          firstDay: 1,
          slotLabelFormat: [
            {
              month: "long",
              year: "numeric",
            }, // top level of text
            {
              weekday: "short",
              day: "2-digit",
            }, // lower level of text
          ],
          type: "resourceTimeline",
          duration: {
            days: 7,
          },
          //              minTime: '09:00:00',
          //              maxTime: '18:00:00',
          slotDuration: "24:00",
          //              buttonText: 'Days',
          //              dateIncrement: {days: 3}
        },
      },
      title: "calendar",
      showAddShootModal: false,
      showEditShootModal: false,
      showAddModal: false,
      showEditModal: false,
      showCompletedProductions: false,
      selectedStart: "",
      selectedResource: "",
      selectedShift: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["user/loading"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    resources() {
      return this.$store.getters["resources/active"];
    },
    billings() {
      return this.$store.getters["billings/billings"];
    },

    shifts() {
      var shifts = this.$store.getters["shifts/shifts"];
      shifts.forEach((shift) => {
        if (!shift.title.includes("\n")) {
          if (shift.billing) {
            let billingName = this.$store.getters["billings/byId"](
              shift.billing
            );
            if (billingName) {
              shift.title = shift.title + "\n" + billingName.label;
            }
          }
        }
      });
      var dels = this.$store.getters["briefs/productions"];
      dels.forEach((del) => {
        // var emoji = "";
        // switch (del.state) {
        //   case "signed":
        //     emoji = "✔️";
        //     break;
        //   case "feedback":
        //     emoji = "💬";
        //     break;
        //   default:
        //     emoji = "⏳";
        // }

        var event = {};

        if (!del.completed) {
          event.resourceId = "deadlines";
          // event.title = `${emoji} ${del.productionTitle} - ${del.title} - ${del.format}`;
          event.title = `${del.title} - ${del.signedDels}/${del.totalDels}`;
          event.start = del.nextDeadline;
          event.editable = false;
          event.deadline = true;
          event.productionId = del.id;
          event.eventStartEditable = false;
          event.droppable = false;
          event.className = "deadline";
          event.constraint = {
            resourceIds: ["deadlines"],
          };

          shifts.push(event);
        }
      });

      return shifts;
    },
    rotaTypes() {
      return this.config.calendarEvents;
    },
  },
  // watch: {
  //   users(val) {
  //     //        if users change - so page loads before users do, then it rebuilds the resource chain
  //     this.addResources();
  //   },
  // },
  mounted() {
    var start = new Date();
    start.setMonth(start.getMonth() - 4);

    this.$store.dispatch("briefs/fetchAndAdd", {
      where: [["created_at", ">=", start]],
      limit: 0,
    });

    // console.log(start);
    this.$store
      .dispatch("shifts/openDBChannel", { where: [["date", ">=", start]] })
      .catch(console.error);
    // this.$store
    //   .dispatch("shoots/openDBChannel", { where: [["date", ">=", start]] })
    //   .catch(console.error);
    this.$store
      .dispatch("billings/fetchAndAdd", { where: [["active", "==", true]] })
      .catch(console.error);
    this.$store
      .dispatch("budgets/fetchAndAdd", { where: [["active", "==", true]] })
      .catch(console.error);
    this.$store
      .dispatch("resources/fetchAndAdd", { where: [["active", "==", true]] })
      .then(() => {
        this.addResources();
      })
      .catch(console.error);
  },
  methods: {
  
    addResources() {
      let resList = this.resources;

      // resList.push({
      //   active: true,
      //   displayName: "Deadlines",
      //   group: " ",
      //   id: "deadlines",
      // });
      // resList.push({
      //   active: true,
      //   displayName: "Shoots",
      //   group: " ",
      //   id: "shoots",
      // });

      // only show your company resources unless ECB

      if (this.user.company === "ECB") {
        for (var i = 0; i < resList.length; i++) {
          var name = resList[i].company
            ? // resList[i].company + " - " + resList[i].group
              resList[i].group
            : resList[i].group;
          this.calendarApi().addResource({
            id: resList[i].id,
            title: resList[i].displayName,
            group: name,
          });
        }
      } else {
        for (var i = 0; i < resList.length; i++) {
          if (this.user.company === resList[i].company) {
            this.calendarApi().addResource({
              id: resList[i].id,
              title: resList[i].displayName,
              // -add company name in group: resList[i].company + " - " + resList[i].group,
              group: resList[i].group,
            });
          }
        }
      }
      this.calendarApi().addResource({
        id: "deadlines",
        title: "Deadlines",
        group: " ",
      });
      // this.calendarApi().addResource({
      //   id: "shoots",
      //   title: "Shoots",
      //   group: " ",
      // });
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    changeView(viewType) {
      this.defaultView = viewType;
      this.calendarApi().changeView(viewType);
    },
    next() {
      this.calendarApi().next();
    },
    prev() {
      console.log("prev");
      this.calendarApi().prev();
    },
    datesRender(info) {
      this.title = info.view.title;
    },
    onDateClick({ date, resource }) {
      this.selectedStart = date;

      if (resource.id === "shoots") {
        //open the shoots modal
        this.showAddShootModal = true;
      } else {
        //open the shift modal
        this.showAddModal = true;
        this.selectedResource = resource.id;
      }
    },
    onEventClick({ el, event }) {
      console.log(event);
      this.selectedShift = event.id;
      if (event.extendedProps.type === "shoot") {
        // this.$router.push(`/brief/${event.extendedProps.productionId}`);
        this.showEditShootModal = true;
      } else if (!event.extendedProps.deadline) {
        this.showEditModal = true;
      } else if (event.extendedProps.deadline) {
        this.$router.push(`/brief/${event.extendedProps.productionId}`);
      }
    },

    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },

    closeModals() {
      this.selectedResource = "";
      this.selectedStart = "";
      this.selectedShift = "";
      this.showAddModal = false;
      this.showEditModal = false;
      this.showAddShootModal = false;
      this.showEditShootModal = false;
    },
    onEventInteraction({ el, event }) {
      if (!event.extendedProps.deadline) {
        const calcs = this.timeCalc(event);

        // get billing rate
        const billingDetail = this.$store.getters["billings/byId"](
          event.extendedProps.billing
        );

        const payload = {
          start: calcs.start,
          end: calcs.end,
          resourceId: event._def.resourceIds[0],
          id: event.id,
          days: calcs.days,
          date: calcs.date,
        };

        if (event.extendedProps.type === "shoot") {
          this.$store.dispatch("shoots/patch", payload);
        } else {
          payload.cost = calcs.days * billingDetail.dayRate;
          this.$store.dispatch("shifts/patch", payload);
        }
      }
    },

    timeCalc(event) {
      var start = moment(event.start);
      var end = moment(event.end);

      var diff = end.diff(start, "days");
      console.log(diff);

      return {
        start: this.strDate(start),
        end: this.strDate(end),
        date: event.start,
        days: diff,
      };
    },
    zoomer() {
      let z = Math.floor(this.zoom);
      console.log(z);
      this.views.week.duration.days = z;
      this.calendarApi().setOption("duration", {
        days: z,
      });
    },
    // notifyVue() {
    //   // console.log(event)
    //   this.$notify({
    //     message: event.target.innerText,
    //     timeout: 2000,
    //     type: "danger",
    //     overlap: true,
    //     verticalAlign: "top",
    //     horizontalAlign: "right",
    //   });
    // },
    // mouseEnter(event) {
    //   console.log(event);
    //   this.notifyVue(event);
    // },
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/timeline/main.css";
@import "~@/assets/sass/core/vendors/fullcalendar";
@import "~@fullcalendar/resource-timeline/main.css";

.noUi-horizontal .noUi-connect {
  background: #172b4d;
}

.noUi-horizontal .noUi-handle {
  background-color: #172b4d;
}

.fc-unthemed .fc-divider {
  background: #f6f9fc;
}

.fc-ltr .fc-resource-area .fc-cell-text {
  height: 34px;
}

.fc-event .fc-title {
  white-space: break-spaces !important;
}

.quarterDays {
  min-height: 25px;
}

.halfDays {
  min-height: 52px;
}

.threeQuarterDays {
  min-height: 75px;
}

.fullDays {
  min-height: 106px;
}
.unavailable {
  background: #989898;
}
.holiday {
  background: #b9b9b9;
}
.pencilled {
  background: #9d9d9d;
}
.confirmed {
  background: #343434;
}
.cancelled {
  background: #7f7f7f;
}
.cancelled div span{
  text-decoration: line-through;
}
.deadline {
  background: #172b4d;
}
</style>
