<template>
  <div>
    <NewFile
      :boxHeight="boxHeight"
      :multiple="false"
      :message="message"
      :accepted="allowedFileTypes"
      :fileName="fileName"
      :basePath="basePath"
      @success="success"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-deliverable",
  props: ["deliverable", "production", "message", "boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    allowedFileTypes() {
      if (this.deliverable.type) {
        const delType = this.deliverable.type;
        const allTypes = this.config.delTypes;
        let thisType = allTypes.filter((f) => f.display === delType);
        return thisType[0].allowedFiles;
      } else {
        return "*";
      }
    },
    basePath() {
      if (this.deliverable && this.production) {
      const prodTitle = this.production.title.replace(/[\W_]+/g, "_");
      const delTitle = this.deliverable.title.replace(/[\W_]+/g, "_");
      let year = new Date().getFullYear();
      let department = this.production.department;
      const unique = this.deliverable.id;
      return `files/${year}/${department}/${prodTitle}/${delTitle}/${unique}`;
      }
      else {
      return ''
    }
    },
    fileName() {
      if (this.deliverable && this.production) {
      const prodTitle = this.production.title.replace(/[\W_]+/g, "_");
      const delTitle = this.deliverable.title.replace(/[\W_]+/g, "_");
      let format = "";
      if (this.deliverable.format) {
        format = this.deliverable.format;
      }
      let filenameFront = prodTitle + "_" + delTitle + "_" + format;
      filenameFront = filenameFront.replace(/[\W_]+/g, "_");
      let version = this.deliverable.currentVersion + 1;
      return `${filenameFront}-v${version}`;
    } else {
      return ''
    }
    },
  },
  methods: {
    success(payload) {
      payload.what = "deliverable";
      payload.version = this.deliverable.currentVersion + 1;
      payload.delID = this.deliverable.id;
      payload.delTitle = this.deliverable.title;
      payload.prodTitle = this.production.title;
      payload.prodID = this.deliverable.production;
      payload.department = this.production.department;
      payload.status = "review";
      payload.events = [
        {
          action: "Uploaded",
          user: this.user.name,
          date: new Date(),
        },
      ];
      // update via slack
      let slack = {};
      slack.production = this.production;
      slack.deliverable = this.deliverable;
      slack.version = payload.version;
      slack.bucketName = payload.sourceBucket;
      slack.srcFilename = payload.sourceFileName;
      if (payload.assetType === "image") {
        slack.isImage = true;
      } else {
        slack.isImage = false;
      }
      this.$store.dispatch("files/awaitingFeedbackSlack", slack);
      // commit file to DB
      this.$store.dispatch("files/insert", payload).then((result) => {
        //update deliverable state
        let delPayload = {};
        delPayload.id = this.deliverable.id;
        delPayload.state = "feedback";
        delPayload.currentFileID = result;
        delPayload.currentVersion = payload.version;
        delPayload.reviewStatus = {};
        this.$store.dispatch("deliverables/patch", delPayload);
      });
    },
  },
};
</script>

<style scoped>
</style>