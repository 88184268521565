<template>
  <div>
    {{ resource.name }}
  </div>
</template>

<script>
export default {
  name: "resource",
  props: ["res"],
  computed: {
    resource() {
      return this.$store.getters["users/byId"](this.res);
    },
  },
};
</script>

<style></style>
