import { Firebase } from '../../firebase.js'
import router from '../../routes/router'

const user = {
  firestorePath: 'users/{userId}',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'user',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    preventInitialDocInsertion: true,
  },

  // you can also add state/getters/mutations/actions
  state: { entry: '/briefs', loading: false, error: false },
  getters: {
    entry: state => state.entry,
    user: state => state.data,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status
    },
    setEntry(state, page) {
      state.entry = page.path
    },
    setError(state, message) {
      state.error = message
    }
  },
  actions: {
    signUserInGoogle({ commit, dispatch }) {
      commit('setLoading', true)
      var provider = new Firebase.auth.GoogleAuthProvider();
      Firebase.auth().signInWithPopup(provider).then(function (result) {
        commit('setLoading', true)
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        dispatch('entryURL')
      }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(error)
        commit('setError', errorMessage)
        commit('setLoading', false)
      });
    },
    signUserInMicrosoft({ commit, dispatch }) {
      commit('setLoading', true)
      var microsoftProvider = new Firebase.auth.OAuthProvider('microsoft.com');
      microsoftProvider.setCustomParameters({
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: 'ecb.co.uk'
      });
      Firebase.auth().signInWithPopup(microsoftProvider).then(function (result) {
        commit('setLoading', true)
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        dispatch('entryURL')

      }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(error)
        commit('setError', errorMessage)
        commit('setLoading', false)
      });
    },
    linkAccountWithPassword({ state }, email) {
      const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}|:<>?-=[];,./';
      const secureRandomArray = new Uint32Array(12);
      window.crypto.getRandomValues(secureRandomArray);

      let password = '';
      for (let i = 0; i < 12; i++) {
        const randomIndex = secureRandomArray[i] % allowedChars.length;
        password += allowedChars[randomIndex];
      }

      console.log(email)

      var credential = Firebase.auth.EmailAuthProvider.credential(email, password);
      console.log(credential)
      Firebase.auth().currentUser.linkWithCredential(credential).then(function (result) {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;
        console.log(user)
        Firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            console.log("password sent")
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            // ..
          });

      }).catch(function (error) {
        // Handle Errors here.

        console.log(error)
      });
    },
    passwordReset({state}, email) {
      Firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            console.log("password sent")
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorMessage)
          });
    },
    linkAccountWithGoogle() {
      var googleProvider = new Firebase.auth.GoogleAuthProvider();
      Firebase.auth().currentUser.linkWithPopup(googleProvider).then(function (result) {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;
        console.log(user)

      }).catch(function (error) {
        // Handle Errors here.

        console.log(error)
      });
    },
    linkAccountWithMicrosoft() {
      var microsoftProvider = new Firebase.auth.OAuthProvider('microsoft.com');
      microsoftProvider.setCustomParameters({
        // Optional "tenant" parameter in case you are using an Azure AD tenant.
        // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
        // or "common" for tenant-independent tokens.
        // The default value is "common".
        tenant: 'ecb.co.uk'
      });
      Firebase.auth().currentUser.linkWithPopup(microsoftProvider).then(function (result) {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;
        console.log(result)

      }).catch(function (error) {
        // Handle Errors here.

        console.log(error)
      });
    },
    signUserIn({ state, commit, dispatch
    }, payload) {
      Firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(
          user => {
            console.log(user)
            dispatch('entryURL')
          }
        )
        .catch(
          error => {
            console.log(error)
            commit('setError', error.message)
          }
        )
    },
    entryURL({ state }) {
      const u = state.data
      if (u.roles.production_access) { router.push('/briefs') }
      else if (u.roles.creative_catalogue_access) { router.push('/content') }
      else if (u.roles.archive_access) { router.push('/archive') }
      else if (u.roles.jimstagram_access) { router.push('/jimstagram') }
      else if (u.roles.livestream_access) { router.push('/livestreams/streams') }
      else { router.push('/user') }

    },
    // whoAmI({ commit }) {
    //   console.log(Firebase.auth().currentUser)
    //   return 
    // },
    unlink({ commit }, provider) {
      Firebase.auth().currentUser.unlink(provider).then(function () {
        // Auth provider unlinked from account
        // ...
        console.log('unlinked')
      }).catch(function (error) {
        // An error happened
        // ...
        console.log(error)
      });
    },
    logOut({
      commit, dispatch
    }, payload) {
      commit('setEntry', payload)
      Firebase.auth().signOut()
      dispatch('closeDBChannel', { clearModule: true })
      dispatch('config/closeDBChannel', {}, { root: true })
      dispatch('users/closeDBChannel', {}, { root: true })
      dispatch('briefs/closeDBChannel', {}, { root: true })
      dispatch('deliverables/closeDBChannel', {}, { root: true })
      dispatch('files/closeDBChannel', {}, { root: true })
      console.log('logout')
      router.push('/login')
    }
  }
}

export default user