<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <div class="container mt--9">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-body">
              <form class="edit-user--form" @submit.prevent="editUser">
                <div class="row">
                  <div class="col">
                    <base-input label="Display Name" v-model="user.name"
                      @change="changeField($event.target.value, 'name')" />
                  </div>
                  <div class="col">
                    <base-input label="Email" v-model="user.email" disabled />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <base-input label="Company">
                      <el-select input-classes="form-control" v-model="user.company" filterable allow-create
                        placeholder="Select here" @change="changeCompany($event)">
                        <el-option v-for="prod in config.companies" :key="prod" :label="prod" :value="prod">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col">
                    <base-input label="Slack ID" v-model="user.slack"
                      @change="changeField($event.target.value, 'slack')" />
                  </div>
                </div>

                <!-- <base-input label="Department Default">
                  <el-select
                    input-classes="form-control"
                    v-model="user.department"
                    clearable
                    placeholder="Default department on filters"
                    @change="changeField($event, 'department')"
                  >
                    <el-option
                      v-for="prod in this.config.departments"
                      :key="prod"
                      :label="prod"
                      :value="prod"
                    >
                    </el-option>
                  </el-select>
                </base-input> -->







                <!-- <base-input label="Scheduling / Assignments">
                  <div class="container">
                    <div class="row">
                      <div class="col-6">
                        <base-checkbox
                          v-model="user.resource"
                          @change="changeField($event, 'resource')"
                        >
                          Add to work planner
                        </base-checkbox>
                      </div>
                    </div>
                  </div>
                </base-input> -->

                <base-input label="Module Access">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <role-selector role="creative_catalogue_access" label="Creative Catalogue: Access"
                          :userId="this.user.id" colour="info"></role-selector>
                        <role-selector role="production_access" label="Production: Access" colour="success"
                          :userId="this.user.id"></role-selector>
                        <role-selector role="inspirations_access" label="Inspirations: Access" :userId="this.user.id"
                          colour="success"></role-selector>
                      </div>
                      <div class="col-auto">
                        <role-selector role="archive_access" label="Match Archive: Access" :userId="this.user.id"
                          colour="danger"></role-selector>
                          <role-selector role="archive_search" label="Match Archive: Recordings Clipping" :userId="this.user.id"
                          colour="danger"></role-selector>
                        <role-selector role="fcc_access" label="FCC Video: Access" :userId="this.user.id"
                          colour="danger"></role-selector>
                        <role-selector role="livestream_access" label="Livestreaming: Access" colour="primary"
                          :userId="this.user.id"></role-selector>
                      </div>
                    </div>
                  </div>
                </base-input>
                <base-input label="Priviledges">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <el-tooltip placement="left" content="See drafts of deliverables">
                          <role-selector role="production_drafts" label="Production: Draft and Create Deliverables" colour="success"
                            :userId="this.user.id"></role-selector>
                        </el-tooltip>
                        <el-tooltip placement="left"
                          content="Add feedback to draft deliverables and write briefs, sign off deliverables">
                          <role-selector role="production_feedback" label="Production: Feedbacker" colour="success"
                            :userId="this.user.id"></role-selector>
                        </el-tooltip>
                        <el-tooltip placement="left" content="See and edit the work planner">
                          <role-selector role="production_shifts" label="Production: Scheduler" colour="success"
                            :userId="this.user.id"></role-selector>
                        </el-tooltip>
                        <el-tooltip placement="left" content="Submit briefs">
                          <role-selector role="production_admin" label="Production: Admin" colour="success"
                            :userId="this.user.id"></role-selector>
                        </el-tooltip>
                      </div>
                      <div class="col-auto">
                        <el-tooltip placement="left"
                          content="Create auto-signed-off deliverables by uploading files to briefs">
                          <role-selector role="production_new_deliberables"
                            label="Production: Upload Signed Off Deliverables" :userId="this.user.id"
                            colour="success"></role-selector>
                        </el-tooltip>
                        <role-selector role="archive_uploader" label="Match Archive: Admin" colour="danger"
                          :userId="this.user.id"></role-selector>


                        <role-selector role="user_admin" label="Users: Admin" colour="warning"
                          :userId="this.user.id"></role-selector>
                      </div>
                    </div>
                  </div>
                </base-input>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="user.roles.creative_catalogue_access || user.roles.production_access">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <h4>Creative Catalogue Restrictions</h4>
              <small>Users can access all content unless restrictions are specified - if so, then only the selected areas
                can be accessed</small>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <base-input label="Departments">
                    <el-select input-classes="form-control" v-model="user.departmentAccess" clearable multiple filterable
                      placeholder="Access Everything" @change="changeField($event, 'departmentAccess')">
                      <el-option v-for="prod in this.config.departments" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Brands">
                    <el-select input-classes="form-control" v-model="user.brandAccess" clearable filterable multiple
                      placeholder="Access Everything" @change="changeField($event, 'brandAccess')">
                      <el-option v-for="prod in config.brand" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Partners">
                    <el-select input-classes="form-control" v-model="user.partnerAccess" clearable filterable multiple
                      placeholder="Access Everything" @change="changeField($event, 'partnerAccess')">
                      <el-option v-for="prod in config.partners" :key="prod" :label="prod" :value="prod">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="row" v-if="user.roles.archive_access">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <h4>Match Video Catalogue Access</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Internationals</label>
                    <div class="col">
                      <base-switch v-model="user.accessInt" type="warning" on-text="Yes" off-text="No"
                        @input="changeField($event, 'accessInt')"></base-switch>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group permission-form" v-if="user.accessInt">

                <div class="row mb-3">
                  <label class="col-auto form-control-label">Access games from the current year</label>
                  <div class="col">
                    <base-switch v-model="user.accessIntCurrent" type="warning" on-text="Yes" off-text="No"
                      @input="changeField($event, 'accessIntCurrent')"></base-switch>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <base-input label="Restrict user to:">
                      <el-select v-model="user.accessIntFields" filterable multiple placeholder="Add Access Controls"
                        @change="changeField($event, 'accessIntFields')">
                        <el-option v-for="option in internationalFilterOptionsArray" :key="option.field"
                          :label="option.label" :value="option.field">
                        </el-option>
                      </el-select>
                    </base-input>

                    <div class="row align-items-center">
                      <div class="col-4" v-for="filter in user.accessIntFields" :key="filter">
                        <filter-selector v-if="user.accessIntPermissions" :selection="internationalFilterOptions[filter]"
                          :preValue="user.accessIntPermissions[filter]"
                          @valueChange="intFilterValueChange($event, filter)">
                        </filter-selector>
                        <filter-selector v-else :selection="internationalFilterOptions[filter]"
                          @valueChange="intFilterValueChange($event, filter)">
                        </filter-selector>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label class="col-auto form-control-label">Domestic</label>
                    <div class="col">
                      <base-switch v-model="user.accessDom" type="warning" on-text="Yes" off-text="No"
                        @input="changeField($event, 'accessDom')"></base-switch>
                    </div>
                  </div>
                  <div class="form-group permission-form" v-if="user.accessDom">

                    <div class="row mb-3">
                      <label class="col-auto form-control-label">Access games from the current year</label>
                      <div class="col">
                        <base-switch v-model="user.accessDomCurrent" type="warning" on-text="Yes" off-text="No"
                          @input="changeField($event, 'accessDomCurrent')"></base-switch>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <base-input label="Restrict user to:">
                          <el-select v-model="user.accessDomFields" filterable multiple placeholder="Add Access Controls"
                            @change="changeField($event, 'accessDomFields')">
                            <el-option v-for="option in domesticFilterOptionsArray" :key="option.field"
                              :label="option.label" :value="option.field">
                            </el-option>
                          </el-select>
                        </base-input>

                        <div class="row align-items-center">
                          <div class="col-4" v-for="filter in user.accessDomFields" :key="filter">
                            <filter-selector v-if="user.accessDomPermissions" :selection="domesticFilterOptions[filter]"
                              :preValue="user.accessDomPermissions[filter]"
                              @valueChange="domFilterValueChange($event, filter)">
                            </filter-selector>
                            <filter-selector v-else :selection="domesticFilterOptions[filter]"
                              @valueChange="domFilterValueChange($event, filter)">
                            </filter-selector>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-body">
              <div class="row">
                <div class="col-auto">
                  Last login:
                </div>
                <div class="col-3" v-if="user.last_log_in">{{ user.last_log_in | dateAndTime }}</div>
                <div class="col-3" v-else>Unknown</div>
                <div class="col-3">
                  <base-button block type="warning" @click="deleteUser">Delete User</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import router from "@/routes/router";
import RoleSelector from "./Components/RoleSelector.vue";
import RolesLength from "./Components/RolesLength.vue";
import FilterSelector from "../Archive/Components/FilterSelector";
import dateAndTime from "../../../filters/dateAndTime";
import { Firebase } from "@/firebase";
export default {
  data() {
    return {
      internationalFilterOptions: {
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "multi-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "multi-select",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home or Away Teams",
          type: "multi-select",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "multi-select",
        },
      },
      domesticFilterOptions: {
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "multi-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "multi-select",
        },
        teamId1: {
          field: "teamId1",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home Team Only",
          type: "multi-select",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Home or Away Teams",
          type: "multi-select",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "multi-select",
        },
      },
    };
  },
  beforeMount() {
    this.$store.dispatch("users/fetchById", this.$route.params.id);
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.getters["users/byId"](this.id);
    },
    config() {
      return this.$store.getters["config/config"];
    },
    producers() {
      let pA = this.config.producer;
      let arr = [];
      if (pA) {
        pA.forEach(function (entry) {
          arr.push({
            text: entry,
            value: entry,
          });
        });
        return arr;
      } else {
        return [];
      }
    },
    internationalFilterOptionsArray() {
      return Object.values(this.internationalFilterOptions);
    },
    domesticFilterOptionsArray() {
      return Object.values(this.domesticFilterOptions);
    },
  },
  components: {
    "roles-length": RolesLength,
    "role-selector": RoleSelector,
    "filter-selector": FilterSelector,
  },
  methods: {
    changeField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;
      this.$store.dispatch("users/patch", payload);
    },
    changeCompany(value) {
      var payload = {};
      payload.id = this.user.id;
      payload.company = value;
      this.$store.dispatch("users/patch", payload);
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("calculateCompanies");
      publish()
    },
    intFilterValueChange(value, filter) {
      let payload = {}
      if (this.user.accessIntPermissions) {
        console.log("incoming " + value)
        console.log("existing " + this.user.accessIntPermissions[filter])
        if (this.user.accessIntPermissions[filter] !== value) {
          // copy existing fields
          payload = { id: this.user.id, accessIntPermissions: this.user.accessIntPermissions }
          // update new field
          payload.accessIntPermissions[filter] = value
          this.$store.dispatch("users/patch", payload)
        }
      } else { // first one
        payload = { id: this.user.id, accessIntPermissions: {} }
        payload.accessIntPermissions[filter] = value
        this.$store.dispatch("users/patch", payload)
      }
    },
    domFilterValueChange(value, filter) {
      let payload = {}
      if (this.user.accessDomPermissions) {
        console.log("incoming " + value)
        console.log("existing " + this.user.accessDomPermissions[filter])
        if (this.user.accessDomPermissions[filter] !== value) {
          // copy existing fields
          payload = { id: this.user.id, accessDomPermissions: this.user.accessDomPermissions }
          // update new field
          payload.accessDomPermissions[filter] = value
          this.$store.dispatch("users/patch", payload)
        }
      } else { // first one
        payload = { id: this.user.id, accessDomPermissions: {} }
        payload.accessDomPermissions[filter] = value
        this.$store.dispatch("users/patch", payload)
      }
    },
    deleteUser() {
      this.$store.dispatch("users/delete", this.user.id);
      this.$router.push({ path: "/users" });
    },
  },
};
</script>

<style scoped>
/*
.card{
  position: inherit;
} */
.permission-form {
  padding: 0px 0px 0px 30px;
}
</style>
