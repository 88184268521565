<template>
<div class="card-body">
                <dl class="row">
                  <dt class="col-sm-4">Size</dt>
                  <dd class="col-sm-8">{{ file.metadata.size | fileSize }}</dd>
                  <dt class="col-sm-4" v-if="file.metadata.file_created">
                    File Created
                  </dt>
                  <dd class="col-sm-8" v-if="file.metadata.file_created">
                    {{ file.metadata.file_created }}
                  </dd>
                  <dt class="col-sm-4">Format</dt>
                  <dd class="col-sm-8">{{ file.metadata.format }}</dd>
                  <dt class="col-sm-4">Original Upload Name:</dt>
                  <dd class="col-sm-8">{{ file.sourceFileNameAsUploaded }}</dd>
                </dl>
                <dl class="row" v-if="file.assetType === 'video'">
                  <dt class="col-sm-4">Total Bitrate</dt>
                  <dd class="col-sm-8">
                    {{ file.metadata.bitrate | fileSize }}/s
                  </dd>

                  <dt class="col-sm-4">Duration</dt>
                  <dd class="col-sm-8">
                    {{ file.metadata.duration | duration }}
                  </dd>
                  <dt class="col-sm-4">Timecode</dt>
                  <dd class="col-sm-8">{{ file.metadata.timecode }}</dd>
                  <dt class="col-sm-4">Aspect Ratio</dt>
                  <dd class="col-sm-8">
                    {{ file.metadata.display_aspect_ratio }}
                  </dd>
                  <dt class="col-sm-4">Width</dt>
                  <dd class="col-sm-8">{{ file.metadata.width }}</dd>
                  <dt class="col-sm-4">Height</dt>
                  <dd class="col-sm-8">{{ file.metadata.height }}</dd>
                  <dt class="col-sm-4">Video Codec</dt>
                  <dd class="col-sm-8">{{ file.metadata.video.codec }}</dd>
                  <dt class="col-sm-4">Video Bitrate</dt>
                  <dd class="col-sm-8">
                    {{ file.metadata.video.bitrate | fileSize }}/s
                  </dd>
                  <dt class="col-sm-4">Frame Rate</dt>
                  <dd class="col-sm-8">
                    {{ file.metadata.video.avg_frame_rate }}
                  </dd>
                  <dt class="col-sm-4">Profile</dt>
                  <dd class="col-sm-8">{{ file.metadata.video.profile }}</dd>
                  <div class="col mt-4 mb--4">
                    <dl
                      class="row"
                      v-for="(channel, index) in file.metadata.audio"
                      :key="index"
                    >
                      <dt class="col-sm-4">Audio {{ channel.index }}:</dt>
                      <dd class="col-sm-8">{{ channel.channel_layout }}</dd>
                      <dt class="col-sm-4">Codec:</dt>
                      <dd class="col-sm-8">{{ channel.codec_name }}</dd>
                    </dl>
                  </div>
                </dl>
                <dl class="row" v-if="file.assetType === 'image'">
                  <dt class="col-sm-4">Compression</dt>
                  <dd class="col-sm-8">{{ file.metadata.compression }}</dd>
                  <dt class="col-sm-4">Width</dt>
                  <dd class="col-sm-8">{{ file.metadata.width }}</dd>
                  <dt class="col-sm-4">Height</dt>
                  <dd class="col-sm-8">{{ file.metadata.height }}</dd>
                </dl>
              </div>
</template>
<script>
export default {
    props: ['file']
}
</script>