<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar logo="172b4d">
      <template slot="links">
        <sidebar-item v-if="user.roles.creative_catalogue_access" :link="{
          name: 'Creative Library',
          icon: 'fa fa-images text-info',
        }">
          <sidebar-item :link="{
            name: 'Search',
            path: '/content',
          }"></sidebar-item>
          <sidebar-item :link="{
            name: 'Collections',
            path: '/collections',
          }"></sidebar-item>

          <!-- <sidebar-item
            v-if="user.roles.creative_catalogue_upload"
            :link="{
              name: 'Upload',
              path: '/content-upload',
            }"
          ></sidebar-item> -->
        </sidebar-item>

        <sidebar-item v-if="user.roles.production_access" :link="{
          name: 'Production',
          icon: 'fa fa-pencil-ruler text-success',
        }">
          <sidebar-item :link="{
            name: 'Briefs',
            icon: 'fa fa-file-alt text-primary',
            path: '/briefs',
          }">
          </sidebar-item>
          <sidebar-item v-if="user.roles.production_feedback" :link="{
            name: 'Priority Queue',
            icon: 'fa fa-file-import text-default',
            path: '/worklist',
          }">
          </sidebar-item>
          <sidebar-item v-if="user.roles.production_feedback" :link="{
            name: 'New Brief',
            icon: 'fa fa-file-import text-default',
            path: '/new-brief',
          }">
          </sidebar-item>
          <sidebar-item v-if="user.roles.production_feedback" :link="{
            name: 'Draft Briefs',
            icon: 'fa fa-file-signature text-purple',
            path: '/drafts',
          }">
          </sidebar-item>
          <sidebar-item v-if="user.roles.production_feedback" :link="{
            name: 'Feedback Queue',
            icon: 'fa fa-pencil-ruler text-danger',
            path: '/feedback',
          }">
          </sidebar-item>
          <sidebar-item v-if="user.roles.production_shifts" :link="{
            name: 'Work Planner',
            icon: 'fa fa-calendar-week text-success',
            path: '/effort',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.production_shifts" :link="{
            name: 'Shoots',
            icon: 'fa fa-file-signature text-purple',
            path: '/shoots',
          }">
          </sidebar-item>
          <!-- <sidebar-item
            :link="{
              name: 'Content Planner',
              icon: 'ni ni-calendar-grid-58 text-orange',
              path: '/scheduler',
            }"
          ></sidebar-item> -->
        </sidebar-item>
        <sidebar-item v-if="user.roles.archive_access" :link="{
          name: 'Match Video',
          icon: 'ni ni-archive-2 text-danger',
        }">
          <sidebar-item :link="{
            name: 'Matches',
            path: '/archive/matches',
          }"></sidebar-item>
          <!-- <sidebar-item v-if="user.roles.archive_search" :link="{
            name: 'Deliveries Search',
            path: '/archive/search',
          }"></sidebar-item> -->
          <sidebar-item v-if="user.roles.archive_search" :link="{
            name: 'Ready Clipped Moments',
            path: '/archive/clips',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.archive_search" :link="{
            name: 'Clip Request Queue',
            path: '/archive/clipping-requests',
          }"></sidebar-item>

          <sidebar-item v-if="user.roles.archive_uploader" :link="{
            name: 'Match Recordings Admin',
            path: '/archive/admin',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.archive_uploader" :link="{
            name: 'Match ID Admin',
            path: '/archive/data/matches',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.archive_uploader" :link="{
            name: 'Player ID Admin',
            path: '/archive/data/players',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.archive_uploader" :link="{
            name: 'Competition ID Admin',
            path: '/archive/data/competitions',
          }"></sidebar-item>
        </sidebar-item>

        <!-- <sidebar-item
          v-if="user.roles.jimstagram_access"
          :link="{
            name: 'Jimstagram',
            icon: 'ni ni-spaceship text-default',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Jimstas',
              path: '/jimstagram/jimstas',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Create New',
              path: '/jimstagram/new',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Render Queues',
              path: '/jimstagram/render-queues',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="user.roles.jimstagram_template"
            :link="{
              name: 'Template Admin',
              path: '/jimstagram/templates',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="user.roles.jimstagram_template"
            :link="{
              name: 'Music',
              path: '/jimstagram/music',
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="user.roles.jimstagram_template"
            :link="{
              name: 'Team Logos',
              path: '/jimstagram/logos',
            }"
          ></sidebar-item>
        </sidebar-item> -->
        <sidebar-item v-if="user.roles.livestream_access" :link="{
          name: 'Livestreams',
          icon: 'ni ni-world-2 text-primary',
        }">
          <sidebar-item :link="{
            name: 'Stream Channels',
            path: '/livestreams/channels',
          }"></sidebar-item>
          <!-- <sidebar-item
            :link="{
              name: 'New Stream',
              path: '/livestreams/new',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            :link="{
              name: 'Watermarks',
              path: '/livestreams/watermarks',
            }"
          ></sidebar-item> -->
          <sidebar-item :link="{
            name: 'Rights FAQs',
            path: '/livestreams/rights-faq',
          }"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="user.roles.inspirations_access" :link="{
          name: 'Inspirations',
          icon: 'fa fa-lightbulb text-yellow',
          path: '/inspirations',
        }">

          <sidebar-item :link="{
            name: 'Browse',
            path: '/inspirations',
          }"></sidebar-item>
          <sidebar-item :link="{
            name: 'Favourites',
            path: '/favourite-inspirations',
          }"></sidebar-item>
          <sidebar-item :link="{
            name: 'Add New',
            path: '/add-new-inspiration',
          }"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="user.roles.production_shifts" :link="{
          name: 'Billing',
          icon: 'fa fa-file-invoice-dollar text-indigo',
        }">
          <sidebar-item v-if="user.roles.production_admin" :link="{
            name: 'Work Planner Resources',
            path: '/resources',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.production_shifts" :link="{
            name: 'Resource Billables',
            path: '/billings',
          }"></sidebar-item>
          <sidebar-item v-if="user.roles.production_shifts" :link="{
            name: 'Work Attribution',
            path: '/ratecard-work',
          }"></sidebar-item>
          <!-- <sidebar-item
            v-if="user.roles.production_shifts"
            :link="{
              name: 'Expenses',
              path: '/expenses',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            v-if="user.roles.production_shifts"
            :link="{
              name: 'Expenses Approval',
              path: '/expenses-admin',
            }"
          ></sidebar-item> -->
          <sidebar-item v-if="user.roles.production_shifts" :link="{
            name: 'Budgets',
            path: '/budgets',
          }"></sidebar-item>

        </sidebar-item>
        <!-- <sidebar-item v-if="user.roles.fcc_access" :link="{
          name: 'FCC Video',
          icon: 'fas fa-video text-red',
        }">
          <sidebar-item :link="{
            name: 'Highlights',
            path: '/fcc/highlights',
          }"></sidebar-item> -->
          <!-- <sidebar-item
            :link="{
              name: 'Clips',
              path: '/fcc/clips',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            v-if="user.roles.archive_uploader"
            :link="{
              name: 'Upload',
              path: '/fcc/upload',
            }"
          ></sidebar-item> -->
        <!-- </sidebar-item> -->
        <sidebar-item v-if="user.roles.user_admin" :link="{
          name: 'Admin',
          icon: 'ni ni-atom text-pink',
        }">
          <sidebar-item :link="{
            name: 'Users',
            path: '/users',
          }"></sidebar-item>
          <!-- <sidebar-item
            v-if="user.roles.production_admin"
            :link="{
              name: 'Brands',
              path: '/brands',
            }"
          ></sidebar-item> -->

        </sidebar-item>


        <sidebar-item class="last-item" :link="{
          name: user.name,
          icon: 'ni ni-badge text-warning',
          path: '/user',
        }">
        </sidebar-item>
       
        <!-- <sidebar-item v-if="user.company!='Freelancer'"
          :link="{
            name: 'Endboards',
            icon: 'fa fa-fast-forward text-info',
            path: '/config/transcodeprofiles'
          }"
        ></sidebar-item>

          <hr class="my-3">
           <sidebar-item v-if="haveFeedback"
          :link="{
            name: feedbackLength + ' items to feedback',
            icon: 'fa fa-comments text-danger',
            path: '/feedback'
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-content">
      <!-- <jimstagram-navbar v-if="$route.meta.jimstagram"></jimstagram-navbar> -->
      <dashboard-navbar v-if="!$route.meta.hideNav"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <!--      <content-footer v-if="!$route.meta.hideFooter"></content-footer>-->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./Navbar.vue";
// import JimstagramNavbar from "./JimstagramNavbar.vue";
//import DashboardContent from '../Layout/Content.vue';
import { FadeTransition } from "vue2-transitions";

export default {
  name: "Layout",
  components: {
    DashboardNavbar,
    // JimstagramNavbar,
    FadeTransition,
  },
  computed: {
    haveFeedback() {
      return this.feedbackLength !== 0 ? true : false;
    },
    feedbackLength() {
      return 0; //this.$store.getters.myFeedback.length
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("scrollbar-inner");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
};
</script>

<style>
.navbar-inner{
  height: calc(100% - 78px);
}
.navbar-nav{
  height: 100%;
}
.last-item{
  margin-top: auto !important;
}
</style>
