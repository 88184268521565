<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">Query</div>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col-4">
                  <base-input>
                    <el-select
                      v-model="filters"
                      filterable
                      multiple
                      placeholder="Add filters"
                    >
                      <el-option
                        v-for="option in filterOptionArray"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-4" v-for="filter in filters" :key="filter">
                  <filter-selector
                    :selection="filterOptions[filter]"
                    @valueChange="filterValues[filter] = $event"
                  >
                  </filter-selector>
                </div>
                <div class="col-auto" v-if="filters.length > 0">
                  <base-input>
                    <base-button @click="runQuery">Fetch And Add</base-button>
                  </base-input>
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-auto form-group">
                  Matches Loaded: {{ matches.length }}
                </div>
                <div class="col-auto form-group">
                  Files Loaded: {{ files.length }}
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-auto form-group">
                  <base-checkbox v-model="ready"
                    >Show Only Ready
                  </base-checkbox>
                </div>

                <div class="col-auto form-group">
                  <base-button size="sm" @click="previews()"
                    >Generate All Missing Previews</base-button
                  >
                </div>
                <div class="col-auto form-group">
                  <base-button size="sm" @click="metadata()"
                    >Generate All Missing Metadatas</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush list event-list">
                <li
                  v-for="file in files"
                  class="list-group-item px-0"
                  :key="file.id"
                >
                  <awaiting :file="file"> </awaiting>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelector from "./Components/FilterSelector";
import AwaitingFile from "./Components/AwaitingFile";
import { Firebase } from "@/firebase";

export default {
  data() {
    return {
      filters: [],
      filterValues: {},
      filterOptions: {
        // startDate: { field: "startDate", label: "Match Start", type: "date-range" },
        year: {
          field: "year",
          label: "Year",
          type: "number",
        },
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "single-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "single-select",
        },
        int: {
          field: "int",
          label: "International",
          type: "boolean",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Teams",
          type: "multi-select",
        },
        venue: {
          field: "venue",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "single-select",
        },
      },
      ready: false,
      pagination: {
        default: 1,
        chunkSize: 25,
      },
    };
  },
  components: {
    awaiting: AwaitingFile,
    "filter-selector": FilterSelector,
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    matches() {
      return this.$store.getters["matches/array"];
    },
    teams() {
      return this.$store.getters["teams/array"];
    },
    venues() {
      return this.$store.getters["venues/array"];
    },
    files() {
      let files = this.$store.getters["files/archiveAwaiting"];
      if (this.ready) {
        return files.filter((f) => f.hasPreview && f.hasOwnProperty("metadata"));
      } else {
        return files;
      }
    },
    matchFormats() {
      return this.$store.getters["matchFormats/array"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
  },
  beforeMount() {
    this.$store.dispatch("teams/fetchAndAdd", {
        clauses: { where, limit: 0 },
      });
    this.$store.dispatch("venues/fetchAndAdd");
    this.$store.dispatch("matchFormats/fetchAndAdd");
    this.$store.dispatch("files/openDBChannel", {
      where: [["what", "==", "archive-awaiting"]]
    });
  },
  methods: {
    runQuery() {
      this.$store.commit("matches/RESET_VUEX_EASY_FIRESTORE_STATE");
      let filters = this.filters;
      let where = [];
      filters.forEach((field) => {
        // check to make sure the filter has a value
        const value = this.filterValues[field];
        const option = this.filterOptions[field];
        if (option.type === "single-select" || option.type === "boolean") {
          where.push([field, "==", value]);
        } else if (option.type === "multi-select") {
          value.forEach((v) => {
            where.push([`${field}.${v}`, "==", true]);
          });
        } else if (option.type === "number") {
          const date1 = parseInt(value);
          where.push([field, "==", date1]);
        }
      });
      console.log(where);

      this.$store.dispatch("matches/fetchAndAdd", {
        clauses: { where, limit: 1000 },
      });
    },
    metadata() {
      let count = 0;
      this.files.forEach((file) => {
        if (!file.hasOwnProperty("metadata")) {
            count += 1;
            var metadata = Firebase.app()
              .functions("europe-west2")
              .httpsCallable("metadataOnRequest");
            metadata(file);
        }
      });
      console.log(count);
    },
    previews() {
      let count = 0;
      this.files.forEach((file) => {
        if (!file.hasPreview) {
          count += 1;
          var metadata = Firebase.app()
            .functions("europe-west2")
            .httpsCallable("generatePreviewRequest");
          metadata(file);
        }
      });
      console.log(count);
    },
  },
};
</script>

<style>
</style>