<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <!-- <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">Query</div>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
               
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <h3 class="card-header mb-0">Clip Queue</h3>
            <ul class="list-group list-group-flush list">
              <li
                v-for="clip in requests"
                class="list-group-item px-0"
                :key="clip.id"
              >
                <div class="container">
                  <div class="row">
                    <div class="col">
                      {{ clip.status }}
                    </div>
                                   <div class="col">
                  {{ clip.event.bookmark }}
                                   </div>
                                              <div class="col">
                  {{ clip.event.bookmarkPlayer }}
                                   </div>
                    <div class="col">
                      {{ clip.event.username }}
                    </div>

                    <div class="col">{{ clip.created_at | dateAndTime }}</div>
                    <div class="col">
                      <a
                        :href="`/#/archive/recording/${clip.file}/${clip.event.id}`"
                        >Go to</a
                      >
                    </div>
                    <div class="col-2">
                      <base-button
                        block
                        type="danger"
                        v-if="clip.status === 'requested'"
                        size="sm"
                        @click="cancel(clip.id, clip.event.id)"
                        >Cancel</base-button
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveClip from "./Components/ArchiveClip.vue";
export default {
  data() {
    return {};
  },
  components: {
    // reels: ReelsExpander,
    'archive-clip': ArchiveClip,
  },
  computed: {
    requests() {
      return this.$store.getters["clippingRequests/array"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    matches() {
      return this.$store.getters["matches/array"];
    },
    matchFormats() {
      return this.$store.getters["matchFormats/formats"];
    },
    matchTypes() {
      return this.$store.getters["matchFormats/types"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.matches.length;
    },
  },
  beforeMount() {
    this.$store.dispatch("clippingRequests/fetchAndAdd", {
        clauses: { orderBy: ["created_at", "desc"], limit: 10 },
      })
      .catch(console.error);
  },

  methods: {
    cancel(id, eventId) {
      this.$store.dispatch("archiveEvents/patch", {
        id: eventId,
        clipStatus: "cancelled",
      });
      this.$store.dispatch("clippingRequests/patch", {
        id: id,
        status: "cancelled",
      });
    },
  },
};
</script>

<style >
.el-table__expand-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card .el-table td,
.card .el-table th {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
.select-danger .el-select .el-tag {
  background: #f5365c;
}
</style>