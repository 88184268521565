<template>
<div>
  <el-tooltip content="Signed Off Deliverables" placement="top">
    <a class="btn btn-sm" :href="link.sourceDownloadURL"
            ><i class="fas fa-file-download mr-2"></i>Download Zip
          </a>
  </el-tooltip>
</div>
</template>

<script>
    export default {
        data () {
            return {
              
            }
        },
        props: ['zip'],
        computed: {
          link() {
            return this.$store.getters["files/fileById"](this.zip.file)
          }
        },
        
      }
</script>

<style>
  .noteSigned{color: #bababa}
</style>
