<template>
  <div>
    <base-input>
      <textarea
        class="form-control"
        v-model="response"
        box
        placeholder="Note text here..."
        type="text"
        rows="10"
      />
    </base-input>
    <base-button @click="submit">Submit</base-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      response: "",
    };
  },
  name: "response-form",
  props: ["noteId", "fileId", "delID"],
  computed: {
        user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {},
  methods: {
    submit() {
      var payload = {};
      payload.file = this.fileId;
      payload.text = this.response;
      payload.delID = this.delID;
      payload.response = true;
      payload.note = this.noteId
      payload.done = false;
      payload.tc = false;
      payload.seconds = 0;
      payload.author = this.user.name;
      console.log(payload)
      this.$store.dispatch("notes/insert", payload);
      this.response = "";
      this.$emit('submit')

    },
  },
};
</script>

<style>
</style>
