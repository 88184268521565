import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../views/Layout/Layout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';

import NotFound from '@/views/Public/NotFoundPage.vue';
const Login = () => import( /* webpackChunkName: "pages" */ '@/views/Public/Login.vue');

import Users from '../views/Pages/Admin/Users.vue';
import User from '../views/Pages/Admin/User.vue';
import UserPage from '../views/Pages/Admin/UserPage.vue';
// import Brands from '../views/Pages/Admin/Brands.vue';

//production
import Briefs from '../views/Pages/Production/Briefs/Briefs.vue';
import Worklist from '../views/Pages/Production/Worklist.vue';
import DraftBriefs from '../views/Pages/Production/Briefs/DraftsBriefs2.vue';
import FeedbackQueue from '../views/Pages/Production/FeedbackQueue.vue';
import Deliverable from '../views/Pages/Production/Deliverable/Deliverable.vue';
import NewInspiration from '../views/Pages/Production/Inspirations/NewInspiration.vue';
import Inspiration from '../views/Pages/Production/Inspirations/Inspiration.vue';
import Inspirations from '../views/Pages/Production/Inspirations/Inspirations.vue';
import FavouriteInspirations from '../views/Pages/Production/Inspirations/FavouriteInspirations.vue';
import Resources from '../views/Pages/Production/Resources.vue';
import Resource from '../views/Pages/Production/Resource.vue';
import Billings from '../views/Pages/Production/Billings.vue';
import Billing from '../views/Pages/Production/Billing.vue';
import Budgets from '../views/Pages/Production/Budgets.vue';
import Budget from '../views/Pages/Production/Budget.vue';
import UnpaidShifts from '../views/Pages/Production/UnpaidShifts.vue'
import Shoots from '../views/Pages/Production/Calendars/ShootsAll.vue';
import WorkCalendar from '../views/Pages/Production/Calendars/WorkCalendar.vue';
import ContentCalendar from '../views/Pages/Production/Calendars/ContentCalendar.vue';
import Brief from '../views/Pages/Production/Briefs/Brief.vue';
import BriefBuilder from '../views/Pages/Production/Briefs/BriefBuilder.vue';
import NewBrief from '../views/Pages/Production/Briefs/NewBrief.vue';

//catalogue
import ContentCatalogue from '../views/Pages/Catalogue/ContentCatalogue.vue';
import ContentCollections from '../views/Pages/Catalogue/ContentCollections.vue';
import ContentCollection from '../views/Pages/Catalogue/ContentCollection.vue';
// import ContentUploader from '../views/Pages/Catalogue/zzContentUploader.vue';

//archive module
import MatchBrowser from '@/views/Pages/Archive/MatchBrowser.vue';
import VideoViewer from '@/views/Pages/Archive/VideoViewer.vue';
import MatchPage from '@/views/Pages/Archive/MatchPage.vue'
import ArchiveSearch from '@/views/Pages/Archive/ArchiveSearch.vue';
import ClipSearch from '@/views/Pages/Archive/ClipSearch.vue';
import ClippingRequests from '@/views/Pages/Archive/ClippingRequests.vue';
import ArchiveAdmin from '@/views/Pages/Archive/ArchiveAdmin.vue';
import MatchData from '@/views/Pages/Archive/MatchData.vue';
import PlayerData from '@/views/Pages/Archive/PlayerBrowser.vue';
import CompetitionData from '@/views/Pages/Archive/CompetitionBrowser.vue';
import CollectionFinder from '@/views/Pages/Archive/CollectionFinder.vue';

//FCC module
// import Highlights from '@/views/Pages/FCC/HighlightsBrowser.vue';
import Recent from '@/views/Pages/FCC/Recent.vue';
// import HighlightsUploader from '@/views/Pages/FCC/HighlightsUpload.vue';

//jimstagram module
// import JimstaEditTemplate from '../views/Pages/Jimsta/EditTemplate.vue';
// import JimstaEditEmailTemplate from '../views/Pages/Jimsta/EditEmailTemplate.vue';
// import JimstaTemplates from '../views/Pages/Jimsta/Templates.vue';
// import JimstaTemplateFamilies from '../views/Pages/Jimsta/TemplateFamilies.vue';
// import Jimsta from '../views/Pages/Jimsta/Jimsta.vue';
// import Jimstas from '../views/Pages/Jimsta/Jimstas.vue';
// import NewJimsta from '../views/Pages/Jimsta/NewJimsta.vue';
// // import SlotEditor from '../views/Pages/Jimsta/SlotEditor.vue';
// import RenderQueues from '../views/Pages/Jimsta/RenderQueues.vue';
// import Music from '../views/Pages/Jimsta/Music.vue';
// import TeamLogos from '../views/Pages/Jimsta/TeamLogos.vue';

//livestreaming
import Livestreams from '../views/Pages/Livestream/Livestreams.vue';
import MediaLiveChannels from '../views/Pages/Livestream/MediaLiveChannels.vue';
import Livestream from '../views/Pages/Livestream/Livestream.vue';
import NewStream from '../views/Pages/Livestream/NewStream.vue';
import Rights from '../views/Pages/Livestream/Rights.vue';
import Watermarks from '../views/Pages/Livestream/Watermarks.vue';

import
store
  from '../store'

const guard = async (to, from, next) => {
  const user = store.getters["user/user"]
  if (user.active) {

      if (to.meta.role) {
        // is role based access on this route
        const role = to.meta.role
        if (user.roles[role]) {
          // user has the right role
          next()
        } else {
          // user does not have the right role
          store.commit('user/setError', 'Your account does not have access to the requested page.')
          next('/user')
        }
      } else {
        // no role based access on this route
        next()
      }

  } else { //user is not authenthicated
    var payload = {
      path: to.path
    }
    store.commit('user/setEntry', payload)
    // store.commit('user/setError', 'Your account is pending approval by an Admin.')
    next('/login')
  }
};

// import { useUserStore } from '../stores/user'

// const guard = async (to, from, next) => {
//   const userStore = useUserStore()
//   const user = userStore.userData
//     if (user.active) {
  
//         if (to.meta.role) {
//           // is role based access on this route
//           const role = to.meta.role
//           if (user.roles[role]) {
//             // user has the right role
//             next()
//           } else {
//             // user does not have the right role
//             store.commit('user/setError', 'Your account does not have access to the requested page.')
//             next('/user')
//           }
//         } else {
//           // no role based access on this route
//           next()
//         }
  
//     } else { //user is not authenthicated
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath }
//       })
//     }
//   };


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/content',
      component: Layout,
      beforeEnter: guard,
      children: [
        {
          path: 'feedback',
          name: 'Awaiting Feedback',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_feedback'
          },
          components: {
            default: FeedbackQueue
          }
        },
        {
          path: 'briefs',
          name: 'briefs',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_access'
          },
          components: {
            default: Briefs
          }
        },
        {
          path: 'content',
          name: 'Content Catalogue',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-info navbar-dark',
            headerClass: 'info',
            role: 'creative_catalogue_access'

          },
          components: {
            default: ContentCatalogue
          }
        },
        {
          path: 'collections',
          name: 'Content Collections',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-info navbar-dark',
            headerClass: 'info',
            role: 'creative_catalogue_access'

          },
          components: {
            default: ContentCollections
          }
        },
        {
          path: 'collection/:id',
          name: 'Collection',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-info navbar-dark',
            headerClass: 'info',
            role: 'creative_catalogue_access'
          },
          components: {
            default: ContentCollection
          }
        },
        // {
        //   path: 'content-upload',
        //   name: 'Content Upload',
        //   beforeEnter: guard,
        //   meta: {
        //     navbarClasses: 'bg-info navbar-dark',
        //     headerClass: 'info',
        //     role: 'creative_catalogue_upload'
        //   },
        //   components: {
        //     default: ContentUploader
        //   }
        // },
        {
          path: 'brief/:id',
          props: true,
          name: 'brief',
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_access'
          },
          components: {
            default: Brief
          }
        },
        {
          path: 'brief/builder/:id',
          props: true,
          name: 'brief',
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_feedback'
          },
          components: {
            default: BriefBuilder
          }
        },
        {
          path: 'brief/:prodId/deliverable/:id',
          name: 'deliverable',
          props: true,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_drafts',
            hideNav: true,
          },
          components: {
            default: Deliverable
          }
        },
        {
          path: 'new-brief',
          name: 'New Brief',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_feedback'
          },
          components: {
            default: NewBrief
          }
        },
        {
          path: 'drafts',
          name: 'drafts',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_drafts'
          },
          components: {
            default: DraftBriefs
          }
        },
        {
          path: 'worklist',
          name: 'Priority',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_access'
          },
          components: {
            default: Worklist
          }
        },
        {
          path: 'shoots',
          name: 'Shoots',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_access'
          },
          components: {
            default: Shoots
          }
        },
        {
          path: 'effort',
          name: 'Work Planner',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_shifts'
          },
          components: {
            default: WorkCalendar
          }
        },
        {
          path: 'scheduler',
          name: 'Content Planner',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-success navbar-dark',
            headerClass: 'success',
            role: 'production_access'
          },
          components: {
            default: ContentCalendar
          }
        },
        {
          path: 'resources',
          name: 'Resources',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_admin'
          },
          components: {
            default: Resources
          }
        },
        {
          path: 'resource/:id',
          name: 'Resource',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_admin'
          },
          components: {
            default: Resource
          }
        },
        {
          path: 'billings',
          name: 'Billings',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_shifts'
          },
          components: {
            default: Billings
          }
        },
        {
          path: 'billing/:id',
          name: 'Billing',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_admin'
          },
          components: {
            default: Billing
          }
        },
        {
          path: 'budgets',
          name: 'Budgets',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_shifts'
          },
          components: {
            default: Budgets
          }
        },
        {
          path: 'budget/:id',
          name: 'Budget',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_shifts'
          },
          components: {
            default: Budget
          }
        },
        {
          path: 'ratecard-work',
          name: 'Work Attribution',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-warning navbar-dark',
            headerClass: 'warning',
            role: 'production_shifts'
          },
          components: {
            default: UnpaidShifts
          }
        },
        {
          path: '/inspiration/:id',
          name: 'Inspiration',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-yellow navbar-light',
            headerClass: 'yellow',
          },
          components: {
            default: Inspiration
          }
        },
        {
          path: '/inspirations',
          name: 'Inspirations',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-yellow navbar-light',
            headerClass: 'yellow',
            role: 'inspirations_access'
          },
          components: {
            default: Inspirations
          }
        },
        {
          path: '/favourite-inspirations',
          name: 'Favourited',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-yellow navbar-light',
            headerClass: 'yellow',
            role: 'inspirations_access'
          },
          components: {
            default: FavouriteInspirations
          }
        },
        {
          path: '/add-new-inspiration',
          name: 'New Inspiration',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-yellow navbar-light',
            headerClass: 'yellow',
            role: 'inspirations_access'
          },
          components: {
            default: NewInspiration
          }
        },
        {
          path: 'users',
          name: 'Users',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'user_admin'
          },
          components: {
            default: Users
          }
        },
        {
          path: 'user/:id',
          name: 'Users',
          beforeEnter: guard,
          props: true,
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'user_admin'
          },
          components: {
            default: UserPage
          }
        },
        // {
        //   path: 'brands',
        //   name: 'brands',
        //   meta: {
        //     navbarClasses: 'bg-primary navbar-dark',
        //     headerClass: 'primary',
        //     role: 'production_admin'
        //   },
        //   components: {
        //     default: Brands
        //   }
        // },
        {
          path: 'user',
          name: 'My Account',
          beforeEnter: guard,
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
          },
          components: {
            default: User
          }
        },
        // {
        //   path: 'config/transcodeprofiles',
        //   name: 'Transcode Profiles',
        //   meta: {
        //     navbarClasses: 'bg-primary navbar-dark',
        //     headerClass: 'primary',
        //     requiresAuth: true
        //   },
        //   components: {
        //     default: TranscodeProfiles
        //   }
        // }
      ]
    },
    {
      path: '/archive/',
      name: 'archive',
      redirect: '/matches',
      component: Layout,
      beforeEnter: guard,
      children: [
        {
          path: 'matches',
          name: 'Match Browser',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: MatchBrowser
          }
        },
        {
          path: 'match/:id',
          name: 'Match Assets',
          props: true,
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: MatchPage
          }
        },
        {
          path: 'recording/:id/:clip?',
          name: 'Video Viewer',
          props: true,
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: VideoViewer
          }
        },
        {
          path: 'search',
          name: 'Archive Search',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: ArchiveSearch
          }
        },
        {
          path: 'clips',
          name: 'Clips',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: ClipSearch
          }
        },
        {
          path: 'clipping-requests',
          name: 'Clipping Requests',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: ClippingRequests
          }
        },
        {
          path: 'collections',
          name: 'Add To Collections',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_access'
          },
          components: {
            default: CollectionFinder
          }
        },
        {
          path: 'admin',
          name: 'Archive Admin',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_uploader'
          },
          components: {
            default: ArchiveAdmin
          }
        },
        {
          path: 'data/matches',
          name: 'Match ID Admin',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_uploader'
          },
          components: {
            default: MatchData
          }
        },
        {
          path: 'data/competitions',
          name: 'Competition ID Admin',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_uploader'
          },
          components: {
            default: CompetitionData
          }
        },
        {
          path: 'data/players',
          name: 'Player ID Admin',
          meta: {
            navbarClasses: 'bg-danger navbar-dark',
            headerClass: 'danger',
            role: 'archive_uploader'
          },
          components: {
            default: PlayerData
          }
        }
      ]
    },
    {
      path: '/fcc/',
      name: 'fcc',
      redirect: '/highlights',
      component: Layout,
      beforeEnter: guard,
      children: [
        // {
        //   path: 'highlights',
        //   name: 'Highlights',
        //   meta: {
        //     navbarClasses: 'bg-yellow navbar-light',
        //     headerClass: 'yellow',
        //     role: 'fcc_access'
        //   },
        //   components: {
        //     default: Highlights
        //   }
        // },
        {
          path: 'highlights',
          name: 'Fixtures',
          meta: {
            navbarClasses: 'bg-yellow navbar-light',
            headerClass: 'yellow',
            role: 'fcc_access'
          },
          components: {
            default: Recent
          }
        },
        // {
        //   path: 'recent',
        //   name: 'Fixtures',
        //   meta: {
        //     navbarClasses: 'bg-yellow navbar-light',
        //     headerClass: 'yellow',
        //     role: 'fcc_access'
        //   },
        //   components: {
        //     default: Recent
        //   }
        // },
        // {
        //   path: 'upload',
        //   name: 'Highlights Upload',
        //   meta: {
        //     navbarClasses: 'bg-yellow navbar-light',
        //     headerClass: 'yellow',
        //     role: 'archive_uploader'
        //   },
        //   components: {
        //     default: HighlightsUploader
        //   }z
        // },
      ]
    },
   /* {
      path: '/jimstagram/',
      name: 'jimstagram',
      redirect: '/jimstagram/jimstas',
      component: Layout,
      beforeEnter: guard,
      children: [
        {
          path: 'edit/:id',
          name: 'Jimsta Page',
          props: true,
          meta: {
            jimstagram: true,
            role: 'jimstagram_access'
          },
          components: {
            default: Jimsta
          }
        },
        // {
        //   path: 'slot/:id',
        //   name: 'Asset Editor',
        //   props: true,
        //   meta: {
        //     jimstagram: true,
        //     role: 'jimstagram_access'
        //   },
        //   components: {
        //     default: SlotEditor
        //   }
        // },
        {
          path: 'render-queues',
          name: 'Renders',
          meta: {
            jimstagram: true,
            role: 'jimstagram_access'
          },
          components: {
            default: RenderQueues
          }
        },
        {
          path: 'jimstas',
          name: 'Jimstagrams',
          meta: {
            jimstagram: true,
            role: 'jimstagram_access'
          },
          components: {
            default: Jimstas
          }
        },
        {
          path: 'music',
          name: 'Music',
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: Music
          }
        },
        {
          path: 'logos',
          name: 'Team Logos',
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: TeamLogos
          }
        },
        {
          path: 'new',
          name: 'Create Jimsta',
          meta: {
            jimstagram: true,
            role: 'jimstagram_access'
          },
          components: {
            default: NewJimsta
          }
        },
        {
          path: 'template/:id',
          name: 'Configure Template',
          props: true,
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: JimstaEditTemplate
          }
        },
        {
          path: 'email-template/:id',
          name: 'Configure Template',
          props: true,
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: JimstaEditEmailTemplate
          }
        },
        {
          path: 'templates',
          name: 'Templates',
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: JimstaTemplates
          }
        },
        {
          path: 'template-families',
          name: 'Template Families',
          meta: {
            jimstagram: true,
            role: 'jimstagram_template'
          },
          components: {
            default: JimstaTemplateFamilies
          }
        },
      ]
    }, */
    {
      path: '/livestreams/',
      name: 'livestreams',
      redirect: 'streams',
      component: Layout,
      beforeEnter: guard,
      children: [
        {
          path: 'streams',
          name: 'Stream Browser',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: Livestreams
          }
        },
        {
          path: 'channels',
          name: 'County Stream Encoders',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: MediaLiveChannels
          }
        },
        {
          path: 'stream/:id',
          name: 'Stream Config',
          props: true,
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: Livestream
          }
        },
        {
          path: 'new',
          name: 'New Stream',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: NewStream
          }
        },
        {
          path: 'watermarks',
          name: 'Watermarks',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: Watermarks
          }
        },
        {
          path: 'rights-faq',
          name: 'Rights FAQs',
          meta: {
            navbarClasses: 'bg-primary navbar-dark',
            headerClass: 'primary',
            role: 'livestream_access'
          },
          components: {
            default: Rights
          }
        },
      ]
    },
    {
      path: '/',
      component: AuthLayout,
      name: 'Authentication',
      children: [
        {
          path: '/login',
          name: 'Login',
          component: Login
        },
        // {
        //   path: '*',
        //   component: NotFound
        // }
      ]
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return {
      x: 0,
      y: 0
    };
  }
});

export default router
