<template>
  <div>
    <div class="row">
      <div v-if="previews" class="col-4">
        <file-preview
        :file="currentFile"
        ></file-preview>
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="productionTitle">
              {{ del.productionTitle }}
            </div>
            <div class="deliverableTitle">
              <span>{{ del.title }}</span>
            </div>
            <div class="department">
              <span>{{ del.department }}</span>
            </div>
            <div>
              <span class="mr-3" v-if="del.type">
                <badge type="info">
                  {{ del.type }}
                </badge>
              </span>
              <span class="mr-3" v-if="del.format">
                <badge type="success">
                  {{ del.format }}
                </badge>
              </span>
              <span class="mr-3" v-if="del.brand">
                <badge type="secondary">
                  {{ del.brand }}
                </badge>
              </span>
            </div>
            <div>
              <div v-if="del.due === false">No Deadline Set</div>
              <div class="overdue" v-else-if="del.overdue">
                Due {{ del.due }}
              </div>
              <div v-else>Due {{ del.due }}</div>
            </div>
          </div>

          <!-- <div class="col">
            <FeedbackersUpdate :file="currentFile" :del="del" v-if="currentFile"></FeedbackersUpdate>
          </div> -->

          <div class="col-3">
            <div v-if="del.state != 'production'">
              <el-tooltip content="Assets" placement="top">
                <a class="btn btn-block btn-primary mb-2" :href="goToAssets">
                  <i class="fas fa-images worklistIcon"></i> Feedback
                </a>
              </el-tooltip>
            </div>
            <div >
              <el-tooltip content="Brief" placement="top">
                <a class="btn btn-block btn-warning mb-2" :href="goToBrief">
                  <i class="fas fa-file-alt worklistIcon"></i> Brief
                </a>
              </el-tooltip>
            </div>
            <div >
              <el-tooltip content="Sign Off" placement="top">
                <a class="btn btn-block btn-success mb-0" @click="signOff">
                  <i class="ni ni-like-2 worklistIcon"></i> Sign Off
                </a>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/routes/router";

// import FeedbackersUpdate from "../../Briefs/Components/FeedbackersUpdate";

// import { Select, Option } from "element-ui";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

export default {
  data() {
    return {
    };
  },
  props: ["del", "previews"],
  computed: {
    goToAssets() {
      return `/#/brief/${this.del.production}/deliverable/${this.del.id}`;
    },
    goToBrief() {
      return `/#/brief/${this.del.production}`;
    },
    delID() {
      return this.del.id;
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    state() {
      const state = this.del.state;
      const statuses = this.statuses;
      let s = statuses.filter((r) => r.key === state)[0];
      return s;
    },
    currentFile() {
      let f = this.$store.getters["files/fileById"](
        this.del.currentFileID
      );
      // if (f) {
        return f;
      // } else {
      //   this.$store.dispatch("files/fetchById", this.deliverable.currentFileID);
      // }
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  components: {
    // FeedbackersUpdate
  },
  beforeMount() {
   this.$store.dispatch("files/fetchById", this.del.currentFileID);
  },
  methods: {
    signOff() {
          // add note to file log

          var payload = {};
      payload.action = "Signed off";
      payload.user = this.user.name;
      payload.date = new Date();

      this.$store.dispatch("files/patch", {
        events: arrayUnion(payload),
        id:  this.del.currentFileID,
        status: "signed",
      });
      // change deliverable setting
      this.$store.dispatch("deliverables/patch", {
        id: this.delID,
        state: "signed",
      });
      var payload = {};
      payload.deliverable = this.del.id;
      payload.production = this.del.production;
      payload.version = this.currentFile.version;
      this.$store.dispatch("files/signOffToSlack", payload);
      // this.$router.push(`/brief/${this.del.production}`)
    }
  },
};
</script>

<style>
.smaller-text {
  font-size: 0.9em;
}

.overdue {
  color: tomato;
}

.halfBoxRight {
  margin-top: 8px;
}

.worklistIcon {
  font-size: 1.2em;
}

.productionTitle {
  font-weight: 600;
  margin-bottom: 8px;
}
.department {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 0.9em;
  font-weight: 100;
}
.deadline {
  margin-top: 8px;
}
.m-xy-auto {
  margin-top: auto;
  margin-bottom: auto;
}
</style>