<template>
  <card class="bg-default text-white">
    <file-preview

      :file="file"
      :useRaw="true"
    ></file-preview>

       <base-input

          v-model="file.label"
          placeholder="Add Label"
          @change="changeName($event.target.value)"
          
        />
    <div class="card-title mb-2">{{ file.sourceFileName }}</div>
    <small>{{ file.created_at | date }}</small>
    <base-button @click="kill">Delete</base-button>

    <!-- <div class="mt-3">
      <a download class="btn btn-sm" :href="file.sourceDownloadURL">
        <i class="fa fa-download mr-1"></i>Download
      </a>
    </div> -->
  </card>
</template>

<script>
import router from "@/routes/router";
export default {
  data() {
    return {};
  },
  props: ["file"],
  computed: {
        user() {
      return this.$store.getters["user/user"];
    },
    fileID() {
      return this.file.id;
    },
  },
  components: {},
  methods: {
    changeName(value) {
       var payload = {};
      payload.id = this.file.id;
      payload.label = value;
      this.$store.dispatch("files/patch", payload);
    },
    kill(){
this.$store.dispatch("files/delete", this.file.id);
    },
  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>