<template>
  <div>
    {{format.name}}
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["formatID"],
  computed: {
    format() {
      return this.$store.getters["matchFormats/formatById"](this.formatID);
    },
  },
  components: {},
  mounted() {

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>