<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">

    </base-header>
    <div class="container-fluid mt--9">

      <div class="row">
        <div class="col-12">

          <div class="card" body-classes="px-0 pb-1" footer-classes="pb-2">
            <div class="card-header mb--4 pb-1">
              <div class="row align-items-center">
                <div class="col">
                  <form @submit.prevent="search">
                    <base-input v-model="searchText" placeholder="Search" prepend-icon="fa fa-search" />
                  </form>
                </div>
                
                <div class="col-3 text-right">
                  <base-input>
                    <el-select v-model="departmentFilter" placeholder="Department Filter" clearable
                      @change="setUserField($event, 'department')">
                      <el-option v-for="option in departments" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>

                <div class="col-2">
                  <base-checkbox class="mb-4" v-model="completed" @input="filterPersist()">
                    Include Completed
                  </base-checkbox>
                </div>
              </div>
            </div>

<div class="card-body pt-5">
  <brief-list 
              
              v-for="prod in searchResults" :key="prod.id" :prod="prod" class="brief-list smaller-text">

       </brief-list>
</div>
           
            <div v-if="searchResults.length === 0"><br>
            </div>
            <div slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap px-4 py-2 mt-3">
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.from + 1 }} to {{ pagination.to }} of {{ searchHits }} entries
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="currentPage" :per-page="pagination.chunkSize"
                :total="searchHits"></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";
import { Firebase } from "@/firebase";

import DeliverablesWidget from "./Components/DeliverablesWidget";
import BriefListItem from './Components/BriefListEntry.vue'

export default {
  components: {
    DeliverablesWidget,
    "brief-list": BriefListItem,
  },
  data() {
    return {
      searchText: "",
      freshSearch: false,
      mine: false,
      completed: false,
      departmentFilter: "",
      producerFilter: "",
      sortProp: "updated_at._seconds",
      sortOrder: "desc",
      searchResults: [],
      searchHits: 0,
      currentPage: 1,
      searchPages: 0,
      pagination: {
        default: 1,
        chunkSize: 20,
        from: 0,
        to: 0,
      },
    };
  },
  computed: {
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    brandAccess() {
      if (this.user.brandAccess) {
        if (this.user.brandAccess.length > 0) {
          return this.user.brandAccess;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    partnerAccess() {
      if (this.user.partnerAccess) {
        if (this.user.partnerAccess.length > 0) {
          return this.user.partnerAccess;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    departmentAccess() {
      if (this.user.departmentAccess) {
        if (this.user.departmentAccess.length > 0) {
          return this.user.departmentAccess;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    departments() {
      if (this.isLoaded) {
        if (this.user.hasOwnProperty('departmentAccess')) {
          if (this.user.departmentAccess.length > 0) { // return the restricted departmnets
            return this.user.departmentAccess
          } else {  // has had department access restrcitions, but not currently
            let pA = this.config.departments;
            let arr = [];
            pA.forEach(function (entry) {
              arr.push({
                text: entry,
                value: entry,
              });
            });
            return this.config.departments;
          }
        }
        else { // hasn't yet had a department access applied, so object hasn't created this prop
          let pA = this.config.departments;
          let arr = [];
          pA.forEach(function (entry) {
            arr.push({
              text: entry,
              value: entry,
            });
          });
          return this.config.departments;
        }
      }
    },
  },
  mounted() {
    if (localStorage.briefsArchive) {
      this.completed = JSON.parse(localStorage.briefsArchive);
    }
    // if department, set it
    this.departmentFilter = this.user.department ? this.user.department : "";
    // if producer, set it
    this.producerFilter = this.user.productionCompany
      ? this.user.productionCompany
      : "";

    if (localStorage.briefsMine) {
      this.mine = JSON.parse(localStorage.briefsMine);
    }
    this.search(false);
  },
  watch: {
    currentPage: function (value) {
      console.log(value - 1);
      this.pagination.from = (value - 1) * this.pagination.chunkSize;
      this.search(false);
    },
    searchText: function () {
      this.search(true)
    },
    departmentFilter: function () {
      this.search(true)
    },
    completed: function () {
      this.search(true)
    },
  },
  methods: {
    brief(prod) {
      router.push(`/brief/${prod.id}`);
    },
    onDelete(row) {
      alert(`You want to delete ${row.name}`);
    },
    filterPersist() {
      localStorage.briefsArchive = this.completed;

      localStorage.briefsMine = this.mine;
    },
    setUserField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;
      this.$store.dispatch("user/patch", payload);
    },
    // openClose(prod) {
    //   if (this.openBrief === prod) {
    //     this.openBrief = "";
    //   } else {
    //     this.openBrief = prod;
    //   }
    // },
    sortChange({ column, prop, order }) {

      console.log(column)
      console.log(prop)
      console.log(order)

      if (order === 'ascending') {
        this.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.sortOrder = 'desc'
      }

      this.sortProp = prop

      if (prop === 'created_at_seconds') {
        this.sortProp = 'created_at._seconds'
      } else if (prop === 'updated_at_seconds') {
        this.sortProp = 'updated_at._seconds'
      }

      this.search(true) //doesn't require resort
    },
    async search(reset) {
      let starter = reset ? 0 : this.pagination.from;
      this.pagination.from = starter;
      // console.log(starter);
      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {

              },
            ],
            from: starter,
            size: this.pagination.chunkSize,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [
                ],
                must_not: [{ match: { status: 'Draft' } }]
              },
            },
          },
        },
      };
      //sort
      payload.request.body.sort[0][this.sortProp] = {
        order: this.sortOrder,
      }

      if (this.departmentFilter) {
        payload.request.body.query.bool.filter.push({
          match: { department: this.departmentFilter },
        });
      } else if (this.departmentAccess) {

        payload.request.body.query.bool.minimum_should_match = 1
        this.departmentAccess.forEach((dep) => {
            console.log(dep)
          payload.request.body.query.bool.should.push({
            match: { department: dep },
          });
        });

      }
      if (this.brandFilter) {
        payload.request.body.query.bool.filter.push({
          match: { brand: this.brandFilter },
        });
      } else if (this.brandAccess) {
        this.brandAccess.forEach((brand) => {
          payload.request.body.query.bool.filter.push({
            match: { brand: brand },
          });
        });
      }
      if (this.partnerAccess) {
        this.partnerAccess.forEach((partner) => {
            console.log(partner)
          payload.request.body.query.bool.filter.push({
            match: { partner: partner },
          });
        });
      }
      if (!this.completed) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (this.searchText) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: this.searchText, fuzziness: "AUTO" } },
        });
      }
      console.log(payload)
      this.$store.commit("user/setLoading", true);
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
      // console.log(result.id);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {

            console.log(d.response)
            // Do things
            // flatten the objects
            this.searchResults = []
            d.response.hits.hits.forEach(res => {
              let obj = res._source
              //flatten the time object
              if (obj.updated_at) {

                obj.updated_at_seconds = obj.updated_at._seconds

              }

              if (obj.created_at) {


                obj.created_at_seconds = obj.created_at._seconds
              }
              if (obj.created) {


                obj.created_at_seconds = obj.created._seconds
              }

              this.searchResults.push(obj)

            })

            this.searchHits = d.response.hits.total.value;
            this.searchReturn = d.response.hits.hits.length;
            this.pagination.to = this.pagination.from + this.searchReturn;
            this.searchPages = Math.ceil(
              this.searchHits / this.pagination.chunkSize
            );
            this.$store.commit("user/setLoading", false);
            // this.freshSearch = false;

            // set the sort order for the table
            // var order = this.sortOrder === "asc" ? "ascending" : "descending"
            // var prop = this.sortProp === "updated_at._seconds" ? "updated_at_seconds" : "created_at_seconds"
            // // console.log(this.$refs.tableRef)
            // console.log(prop)
            // console.log(order)
            // if (needsSort) {
            //   console.log("sort")
            //   this.$refs.tableRef.sort(prop, order)
            // }

          }
        });
    },
  },
  beforeMount() {
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("resources/fetchAndAdd", {
      where: [["active", "==", true]],
    });
  },
};
</script>
<style>

.worklistIcon {
  font-size: 1.2em;
}

.table-card {
  border-radius: 0.375em
}

.brief-list{
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}
</style>
