<template>
  <div>
    <NewFile
      :boxHeight=boxHeight
      multiple="false"
      message="Drop file or click to browse"
      :accepted="allowedFileTypes"
      :fileNameSuffix="suffix"
      :basePath="basePath"
      @success="success"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-inspiration",
  props: ["boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    allowedFileTypes() {
      return "image/png,image/jpeg,image/jpg,image/gif,video/mp4,video/quicktime,video/m4v";
    },
    basePath() {
      let year = new Date().getFullYear();
      return `inspirations/${year}`;
    },
    suffix() {
      return '-' + Date.now()
    }
  },
  methods: {
    success(payload) {
      payload.what = "inspiration";
      this.$store.dispatch("files/insert", payload).then((result) => {
      this.$emit("success", result);

      });
    },
  },
};
</script>

<style scoped>
</style>