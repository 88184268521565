<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <modal
      :show.sync="showEditModal"
      size="lg"
      modal-classes="modal"
      v-if="selectedStream.videocloud"
    >
      <form class="edit-user--form" @submit.prevent="editUser">
        <div class="mb-3">{{ selectedStream.videocloud.video.name }}</div>
   
       
        <base-input
          label="Stream Protocol"
          v-model="selectedStream.protocol"
          disabled
        />

        <base-input
          label="Stream Address (paste this into the encoder)"
          v-model="selectedStream.stream.url"
          id="address"
          @focus="copyText('address')"
        />
        <base-input
          label="Stream Key"
          v-model="key"
          id="key"
          @focus="copyText('key')"
        />

        <base-input label="Watermark">
          <el-select
            input-classes="form-control"
            v-model="temp.logo"
            filterable
            clearable
            placeholder="Chose Player"
          >
            <el-option
              v-for="file in watermarks"
              :key="file.id"
              :label="file.label"
              :value="file.sourceDownloadURL"
            >
            </el-option>
          </el-select>
        </base-input>

        <!-- <base-input label="Embed Code for Match Centres">
          <el-select
            input-classes="form-control"
            v-model="temp.player"
            filterable
            placeholder="Chose Player"
          >
            <el-option
              v-for="prod in players"
              :key="prod"
              :label="prod"
              :value="prod"
            >
            </el-option>
          </el-select>
        </base-input> -->
        <!-- <div class="row">
          <div class="col">
            <div class="col-form-label form-control-label">
              NV Match Centre Code
            </div>
            <base-input>
              <textarea
                class="form-control"
                rows="4"
                v-model="nvCode"
                id="nv"
                @focus="copyEmbed('nv')"
              ></textarea>
            </base-input>
          </div>
          <div class="col">
            <div class="col-form-label form-control-label">
              Direct Embed Code
            </div>
            <base-input>
              <textarea
                class="form-control"
                rows="4"
                v-model="embedCode"
                id="embed"
                @focus="copyEmbed('embed')"
              ></textarea>
            </base-input>
          </div>
        </div> -->

        <label class="form-control-label">Downstream Feeds</label>
        <ul class="list-group list-group-flush list mb-3">
          <li
            v-for="(rtmp, index) in selectedStream.rtmp_outputs"
            class="list-group-item px-0"
            :key="index"
          >
            <rtmp :rtmp="rtmp" @stop="stopRTMP($event)"></rtmp>
          </li>
        </ul>
        <!-- <base-input label="Add New Destination">
          <el-select
            input-classes="form-control"
            v-model="temp.newFeed"
            filterable
            clearable
            placeholder="Chose Channel"
          >
            <el-option
              v-for="prod in rtmpOutputs"
              :key="prod.id"
              :label="prod.name"
              :value="prod.id"
            >
            </el-option>
          </el-select>
        </base-input> -->
        <base-button v-if="temp.newFeed" @click="addRTMP">Add</base-button>
      </form>
    </modal>
    <div class="container-fluid mt--9" v-if="current.length > 0">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <span class="h3">Recent Streams</span>
            </div>

            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              lazy
              :data="current"
              v-if="loading === false"
            >
              <el-table-column
                label="Name"
                min-width="250px"
                prop="videocloud.video.name"
                sortable
              />
              <el-table-column
                label="State"
                prop="state"
                min-width="100px"
                sortable
              />
              <el-table-column
                label="Created At"
                min-width="150px"
                prop="created_at"
                sortable
              >
                <div slot-scope="{ row }" class="table-actions">
                  {{ row.created_at | dateTime }}
                </div>
              </el-table-column>
              <el-table-column align="center" min-width="110px">
                <div slot-scope="{ row }" class="table-actions">
                  <!-- <base-button
                    outline
                    size="sm"
                    type="default"
                    @click.prevent="onEdit(row)"
                  >
                    Configure
                  </base-button> -->
                   <a
                    class="btn btn-block btn-sm btn-outline btn-primary mb-0"
                    :href="`/#/livestreams/stream/${row.id}`"
                  >
                    <!-- <i class="fas fa-file-alt worklistIcon mr-1"></i> -->
                    Configure
                  </a>
                  <!-- <base-button
                    outline
                    size="sm"
                    type="danger"
                    @click.prevent="onEdit(row)"
                  >
                    Stop
                  </base-button> -->
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>

<script>
import RTMP from "./Components/RTMP";
import { Firebase } from "@/firebase.js";

export default {
  components: { rtmp: RTMP },
  name: "streams",
  data() {
    return {
      key: "alive",
      showEditModal: false,
      model: {},
      playbackToken: "a",
      brightcovePlayer: "axQX0FjyfN",
      temp: { player: "", newFeed: "" },
      selectedStream: {},
      loadedStreams: false,
      players: ["NV Play Widget", "Direct Embed Code"],
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    // streams() {
    //   return this.$store.getters["livestreams/array"];
    // },
    current() {
      return this.$store.getters["livestreams/current"];
    },
    rtmpOutputs() {
      return this.$store.getters["rtmpOutputs/array"];
    },
    watermarks() {
      return this.$store.getters["files/byWhat"]("livestream-county-logo");
    },
    // players() {
    //   return this.$store.getters["brightcovePlayers/array"];
    // },
    // player() {
    //   return this.$store.getters["brightcovePlayers/byId"](this.temp.player);
    // },
    loading() {
      return this.$store.getters["user/loading"];
    },
    newFeed() {
      return this.$store.getters["rtmpOutputs/byId"](this.temp.newFeed);
    },
    bbc() {
      if (this.selectedStream.videocloud.tags) {
        const currentTags = this.selectedStream.videocloud.tags;
        if (currentTags.includes("bbc-commentary")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    nvCode() {
      if (this.selectedStream.videocloud && this.playbackToken) {
        const playerID = this.brightcovePlayer;
        const videoID = this.selectedStream.job_videocloud_asset_id;
        let base =
          '<div style="max-width: 960px;"><video-js id="overlayVideoPlayer" data-account="4854911276001" data-player="' +
          playerID +
          '" data-embed="default" data-bbc="' +
          this.bbc +
          '" controls="" data-video-id="' +
          videoID +
          '" data-logo="' +
          this.temp.logo +
          '" data-ad-config-id="' +
          this.playbackToken +
          '" data-application-id="" class="vjs-fluid"></video-js></div><scr' +
          'ipt src="https://players.brightcove.net/4854911276001/' +
          playerID +
          '_default/index.min.js"></scr' +
          "ipt>";
        return base;
      }
    },
    embedCode() {
      if (this.selectedStream.videocloud && this.playbackToken) {
        const playerID = this.brightcovePlayer;
        const videoID = this.selectedStream.job_videocloud_asset_id;
        let base =
          '<div style="max-width: 960px;"><video-js id="overlayVideoPlayer" data-account="4854911276001" data-player="' +
          playerID +
          '" data-embed="default" data-bbc="' +
          this.bbc +
          '" controls="" data-video-id="' +
          videoID +
          '" data-logo="' +
          this.temp.logo +
          '" data-ad-config-id="' +
          this.playbackToken +
          '" data-application-id="" class="vjs-fluid"></video-js>   <div style="display:none" id="optInBlock"><div id="bbcInfoFlex"><div id="bbcInfoPanel"><div id="bbcInfoIcon"></div><div id="bbcInfoText"><strong>BBC Radio Commentary is available separately</strong><div>Would you like to add this?</div></div></div><div id="bbcInfoButtons"> <button onclick="allow()">YES</button><button onclick="allow()">HIDE</button></div></div></div></div><scr' +
          'ipt src="https://players.brightcove.net/4854911276001/' +
          playerID +
          '_default/index.min.js"></scr' +
          'ipt><link href="https://storage.googleapis.com/ecb-echo.appspot.com/livestreaming/widget/logo-overlay-muting.css" rel="stylesheet"><scr' +
          'ipt src="https://storage.googleapis.com/ecb-echo.appspot.com/livestreaming/widget/logo-overlay-muting.js"></scr' +
          "ipt>";
        return base;
      }
    },
  },
  mounted() {
    this.$store.dispatch("livestreams/getCurrent");
    // this.$store.dispatch("brightcovePlayers/fetchAndAdd");
    this.$store.dispatch("rtmpOutputs/fetchAndAdd");
    this.$store.dispatch("files/fetchAndAdd", {
      where: [["what", "==", "livestream-county-logo"]],
    });
  },
  methods: {
    copyText(id) {
      var copier = document.getElementById(id);
      var copyBit = copier.querySelector("input");

      /* Select the text field */
      copyBit.select();
      copyBit.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$notify({
        message: "Address Copied",
        timeout: 2000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
    copyEmbed(id) {
      var copyBit = document.getElementById(id);
      /* Select the text field */
      copyBit.select();
      copyBit.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$notify({
        message: "Code Copied",
        timeout: 2000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    addRTMP() {
      let payload = { url: this.newFeed.url, streamID: this.selectedStream.id };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("addRTMP");
      this.$notify({
        message: "Please wait for this to be set up",
        timeout: 3000,
        type: "info",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload)
        .then((result) => {
          console.log(result);
          this.showEditModal = false;
          this.$store.dispatch("livestreams/getCurrent");
          this.$notify({
            message: "Destination Added",
            timeout: 3000,
            type: "success",
            overlap: true,
            verticalAlign: "bottom",
            horizontalAlign: "right",
          });
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
    stopRTMP(id) {
      console.log(id);
      let payload = {
        rtmpID: id.rtmp_out_id,
        streamID: this.selectedStream.id,
      };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("stopRTMP");
      this.$notify({
        message: "Cancelling...",
        timeout: 3000,
        type: "info",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload)
        .then((result) => {
          console.log(result);
          this.showEditModal = false;
          this.$store.dispatch("livestreams/getCurrent");
          this.$notify({
            message: "Cancelled",
            timeout: 3000,
            type: "success",
            overlap: true,
            verticalAlign: "bottom",
            horizontalAlign: "right",
          });
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
    onEdit(row) {
      this.showEditModal = true;
      this.selectedStream = row;

      let payload = { streamID: this.selectedStream.id };

      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("playbackToken");
      publish(payload)
        .then((result) => {
          this.playbackToken = result.data.playback_token;
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>