const inspirationComments = {
  firestorePath: 'inspirationComments',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'inspirationComments',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    byId: state => (id) => {
      return state.data[id]
    },
    byInspiration: state => (id) => {
      let files = Object.values(state.data)
      let sort = files.filter(f => (f.inspiration === id))
      return sort.sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1
        }
        if (a.created_at < b.created_at) {
          return 1
        }
        if (!a.created_at) {
          return -1
        }
        return 0
      })
    }
  },
  mutations: {},
  actions: {
  }
}

export default inspirationComments