<template>
  <div @mouseover="hover = true" @mouseleave="hover = false">
    <div class="row smaller-text">
      <div class="col">
        <div class="row">
      <div class="col-auto selectionIcon">
            <base-checkbox v-model="selected"></base-checkbox>
          </div>
          <div class="col-5 firstCol">
         

            <div class="col-form-label">
              <!-- <span v-if="deliverable.state == 'signed'"
                ><i class="ni ni-check-bold text-primary mr-2"></i
              ></span>
              <span v-if="deliverable.state == 'feedback'"
                ><i class="fa fa-comments text-warning mr-2"></i
              ></span> -->
              <span>{{ deliverable.title }}</span>
              <span class="ml-3" v-if="deliverable.type">
                <badge type="info">
                  {{ deliverable.type }}
                </badge>
              </span>
              <span
                class="ml-3"
                v-if="
                  deliverable.type != 'Image' && deliverable.desiredDuration
                "
              >
                <el-tooltip content="Desired Duration" placement="top">
                  <badge type="warning" class="mr-1">
                    {{ deliverable.desiredDuration }}
                  </badge>
                </el-tooltip>
              </span>
              <span class="ml-3" v-if="deliverable.format">
                <el-tooltip content="Dimensions" placement="top">
                  <badge type="success" class="mr-1">
                    {{ deliverable.format }}
                  </badge>
                </el-tooltip>
              </span>
              <span class="ml-3" v-if="deliverable.brand">
                <el-tooltip content="Brand" placement="top">
                  <badge type="secondary" class="mr-1">
                    {{ deliverable.brand }}
                  </badge>
                </el-tooltip>
              </span>
                              <span class="mr-2" v-if="deliverable.subs">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="danger" class="mr-1">
              Burnt-in Subs
            </badge>
            <!-- </el-tooltip> -->
          </span>
                <span class="mr-2" v-if="deliverable.srt">
            <!-- <el-tooltip content="Dimensions" placement="top"> -->
            <badge type="danger" class="mr-1">
              srt
            </badge>
            <!-- </el-tooltip> -->
          </span>
              <span class="ml-3" v-if="hasDescription">
                <el-tooltip :content="deliverable.description" placement="top">
                  <span class="btn-group-toggle" data-toggle="buttons">
                    <label
                      class="btn"
                      :class="[{ active: descriptionShow }, 'btn-sm']"
                    >
                      <input
                        v-model="descriptionShow"
                        type="checkbox"
                        checked=""
                        autocomplete="off"
                      />
                      <i class="fas fa-info mr-2" />
                    </label>
                  </span>
                </el-tooltip>
              </span>
              <span v-if="hover"
                ><el-tooltip content="Edit Deliverable" placement="top">
                  <i class="fas fa-edit ml-3 editIcon" @click="editDel"></i>
                </el-tooltip>
              </span>
            </div>
            <div v-if="previews && currentFile" class="col-4">
        <file-preview :file="currentFile"></file-preview>
      </div>
         
          </div>

          <div class="col-3 middleCols">
            <div
              class="row"
           >
            

              <el-tooltip content="Deadline" placement="top">
                <div class="col col-form-label" v-if="deliverable.deadline">
                  <i class="fa fa-clock mr-1"></i>
                  {{ deliverable.deadline | dateTime }}
                </div>
              </el-tooltip>

              <el-tooltip content="Last Updated" placement="top">
                <div
                  class="col col-form-label"
                  v-if="deliverable.updated_at"
                >
         
                  {{ deliverable.updated_at | dateAndTime }}
                </div>
            
              </el-tooltip>
            </div>
        
          </div>
          <!-- <div class="col-3" v-if="deliverable.state==='feedback'">
              <FeedbackersUpdate :file="highestVersion" :del="deliverable" v-if="highestVersion"></FeedbackersUpdate>
          </div> -->
          <div class="col middleCols">
            <div v-if="uploadable">
              <deliverable-upload-wrapper
                boxHeight="44px"
                :message="stateDisplay"
                :deliverable="deliverable"
                :production="prod"
              ></deliverable-upload-wrapper>
            </div>
            <div v-else>
              <div class="col-form-label">{{ stateDisplay }}</div>
            </div>
          </div>
          <div class="col-auto" v-if="hasFiles">
            <el-tooltip content="Files and Feedback" placement="top">
              <a class="btn btn-block btn-primary mb-0" :href="goTo">
                <i class="fas fa-images"></i>
              </a>
            </el-tooltip>
          </div>
        </div>

        <div class="row" v-if="descriptionShow">
          <div class="col">
            <small>
              {{ deliverable.description }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FeedbackersUpdate from "./FeedbackersUpdate";
import DeliverableUploadWrapper from "./DeliverableUploadWrapper";
import router from "@/routes/router";
export default {
  props: ["deliverable", "previews", "editFolderMode"],
  data() {
    return {
      descriptionShow: false,
      hover: false,
    };
  },
  components: {
    flatPicker,
    FeedbackersUpdate,
    "deliverable-upload-wrapper": DeliverableUploadWrapper,
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    hasDescription() {
      if (this.deliverable.description) {
        return this.deliverable.description.length > 2 ? true : false;
      } else {
        return false;
      }
    },
    selected: {
      get() {
        let test = this.$store.getters["archiveTemp/delState"](
          this.deliverable.id
        );
        return test ? true : false;
      },
      set() {
        this.$store.commit("archiveTemp/delSelect", {
          id: this.deliverable.id,
          value: !this.selected,
        });
      },
    },
    delFolders() {
      return this.$store.getters["deliverableFolders/foldersByProdID"](
        this.deliverable.production
      );
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    state() {
      const state = this.deliverable.state;
      const statuses = this.statuses;
      let s = statuses.filter((r) => r.key === state)[0];
      return s;
    },
    stateDisplay() {
      if (this.state.key === "reworking") {
        return "Working on v" + (this.deliverable.currentVersion + 1);
      } else if (this.state.key === "feedback") {
        return "Awaiting feedback for v" + this.deliverable.currentVersion;
      } else if (this.state.key === "production") {
        return "Drop a file or click to browse";
      } else {
        return this.state.display;
      }
    },
    uploadable() {
      return this.deliverable.state === "production" ||
        this.deliverable.state === "reworking"
        ? true
        : false;
    },
    feedback() {
      return this.deliverable.state === "feedback" ? true : false;
    },
    goTo() {
      return `/#/brief/${this.deliverable.production}/deliverable/${this.deliverable.id}`;
    },
    files() {
      return this.$store.getters["files/filesByDelId"](this.deliverable.id);
    },
    hasFiles() {
      return this.files.length;
    },
    prod() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id);
    },
    currentFile() {
      return this.$store.getters["files/fileById"](
        this.deliverable.currentFileID
      );
    },
    // classObj() {
    //   if (this.deliverable.state == "signed") {
    //     return "signedOff";
    //   } else {
    //     return "";
    //   }
    // },
  },
  methods: {
    editDel() {
      this.$emit("editDel", this.deliverable);
    },
    changeField(value, key, field) {
      var payload = {};
      payload.id = key;
      payload[field] = value;
      this.$store.dispatch("deliverables/patch", payload);
    },
  },
};
</script>

<style scoped>
.status {
  height: 55px;
  width: 100%;
}

.smaller-text {
  font-size: 0.9em;
}

/* .signedOff {
  font-weight: bolder;
} */

.editIcon {
  cursor: pointer;
}

.editIcon:hover {
  color: tomato;
}
.selectionIcon {
  display:flex;
  align-items: center;
}
</style>
