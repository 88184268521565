<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <span class="h3">Watermarks</span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <watermark-upload-wrapper
                    boxHeight="90px"
                  ></watermark-upload-wrapper>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <masonry
                    :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
                    :gutter="15"
                  >
                    <watermark
                      v-for="file in watermarks"
                      :file="file"
                      :key="file.id"
                    ></watermark>
                  </masonry>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WatermarkCard from "./Components/WatermarkCard";
import WatermarkUploader from "./Components/WatermarkUploadWrapper";

export default {
  components: {
    watermark: WatermarkCard,
    "watermark-upload-wrapper": WatermarkUploader,
  },
  name: "watermarks",
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    logos() {
      return this.$store.getters["files/streamLogos"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    watermarks() {
      return this.$store.getters["files/byWhat"]("livestream-county-logo");
    },
  },
  mounted() {
    this.$store.dispatch("files/fetchAndAdd", {
      where: [["what", "==", "livestream-county-logo"]],
    });
  },
  methods: {},
};
</script>

<style>
</style>