<template>
  <div>
    {{venue.name}}
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["venueID"],
  computed: {
    venue() {
      return this.$store.getters["venues/byId"](this.venueID);
    },
  },
  components: {},
  mounted() {

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>