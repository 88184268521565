const budgets = {
  firestorePath: 'budgets',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'budgets',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    budgets: state => Object.values(state.data),
    active: state => Object.values(state.data).filter(a => a.active),
    byId: (state) => (id) => {
      return state.data[id]
    },
  },
  mutations: {},
  actions: {},
}

export default budgets