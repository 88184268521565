import { Firebase } from '../../firebase.js'

const archiveEvents = {
  firestorePath: 'archiveEvents',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'archiveEvents',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    // convertTimestamps: {
    //   optaTime: '%convertTimestamp%',
    // },
  },
  state: {

  },
  getters: {
    array: state => Object.values(state.data),
    arraySortedSeconds: state => {
      return Object.values(state.data).sort((a, b) => {
        if (a.tcIn < b.tcIn) {
          return -1
        }
        if (a.tcIn > b.tcIn) {
          return 1
        }
        if (!a.tcIn) {
          return -1
        }
        return 0
      })
    },
    obj: state => state.data,
    byMatchId: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.match === id))
    },
    byId: (state) => (id) => {
      return state.data[id]
    },

  },
  mutations: {
  },
  actions: {
    clipIt({ state }, event) {

      var clipIt = Firebase.app().functions('europe-west2').httpsCallable('createClip-createClip')
      clipIt({
        archiveEvent: event.id
      })
    }
  },
}

export default archiveEvents