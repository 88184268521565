import { Firebase } from '../../firebase.js'

const inspirations = {
  firestorePath: 'inspirations',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'inspirations',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },

  // you can also add state/getters/mutations/actions
  state: { loading: false },
  getters: {
    inspirations: state => {
      let items = Object.values(state.data)
      return items.sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1
        }
        if (a.created_at < b.created_at) {
          return 1
        }
        if (!a.created_at) {
          return -1
        }
        return 0
    })},
    loading: state => state.loading,
    byId: state => (id) => {
      return state.data[id]
    },
    byBrief: state => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.briefs[id]))
    }
  },
  mutations: {
    loading(state, payload) {
      console.log('mutation ', payload)
      state.loading = payload
    },
  },
  actions: {
    refreshTags() {
      console.log('refresh')
      var getLink = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("refreshInspirationTags");
      getLink()
    },
    loadInspiration({ commit, dispatch }, inspiration) {
      commit('loading', true)

      var getLink = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("getInspiration");
      console.log(inspiration);
      getLink(inspiration)
        .then((data) => {
          commit('loading', false)
          console.log(data.data);
          if (data.data === null) {
            console.log('nothing')

            dispatch("patch", { id: inspiration.id, error: true });

          } else {

            let payload = {
              scraped: true,
              extImages: data.data.images,
              extImageChoice: 0,
              id: inspiration.id,
            };

            if (data.data.mediaType === 'article') {
              payload.type = 'Article'
              payload.author = data.data.siteName
            }

            if (data.data.siteName) {
              payload.platform = data.data.siteName
              let platform = data.data.siteName.toLowerCase()
              let tempTitle = data.data.title

              switch (platform) { // adjust data by platform
                case 'twitter':
                  payload.title = data.data.description.slice(1, -1);  //remove speech quotation
                  payload.author = tempTitle.slice(0, tempTitle.indexOf('on Twitter') - 1)
                  payload.type = ''
                  break;
                case 'instagram':
                  payload.title = tempTitle.slice(tempTitle.indexOf('on Instagram') + 15, -1);
                  payload.author = tempTitle.slice(0, tempTitle.indexOf('on Instagram') - 1)
                  break;
                case 'youtube':
                  payload.type = 'Video'
                  payload.title = tempTitle
                  break;
                default:
                  payload.title = data.data.title
              }

            } else {
              // facebook
              if (data.data.url.includes('facebook.com')) {
                console.log('facebook')
                payload.platform = 'Facebook'
                let tempTitle = data.data.title
                payload.title = data.data.title
                payload.author = tempTitle.slice(tempTitle.lastIndexOf('By') + 3, tempTitle.lastIndexOf('|') - 1)
              }

              //linkedin
              if (data.data.url.includes('linkedin.com')) {
                console.log('LinkedIn')
                payload.platform = 'LinkedIn'
                let tempTitle = data.data.title
                payload.title = data.data.description
                payload.type = ''
                payload.author = tempTitle.slice(0, tempTitle.indexOf('on LinkedIn') - 1)
              }


            }


            dispatch("patch", payload);
          }
        })
        .catch((error) => {
          commit('loading', false)
          console.error(error)
        });
    }
  }
}

export default inspirations