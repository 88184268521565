<template>
    <div class="py-3">
    <div class="row py-1">
        <div class="col-auto m-xy-auto">
            <div class="btn px-2" @click="open = !open"><i v-if="!open" class="fas fa-chevron-circle-down text-primary"></i>
                <i v-else class="fas fa-chevron-circle-up text-primary"></i>
            </div>
        </div>
        <div class="col-5 m-xy-auto">
            <div class="row">
                <div class="col h4">
                    <a :href="`/#/brief/${prod.id}`">
                        {{ prod.title }}
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>{{ prod.department }}</div>
                </div>
            </div>
            <!-- <span v-if="prod.bau"> <el-tooltip content="BAU work" placement="top"><i
                            class="fas fa-redo mr-2"></i></el-tooltip></span> -->

        </div>
        <div v-if="prod.completed" class="col-3 m-xy-auto">
            <span><i class="fas fa-check text-primary"></i> Completed</span>
        </div>
        <div v-else-if="prod.totalDels" class="col-3 m-xy-auto">
            <el-tooltip content="Deliverables Completed" placement="top">
                <base-progress class="mt-3" showLabel="true" type="primary" :height="8"
                    :value="(prod.signedDels / prod.totalDels) * 100"
                    :label="`${prod.signedDels} out of ${prod.totalDels}`"></base-progress>
            </el-tooltip>
        </div>
        <div class="col-3" v-else></div>
      

        <div class="col m-xy-auto">

            <div><user-name :res="prod.contact"> </user-name></div>
        </div>
        <div class="col m-xy-auto">
            <el-tooltip content="Last Updated" placement="top">
                <div>
                    {{ prod.updated_at | dateTime }}</div>

            </el-tooltip>
        </div>
    </div>
    <div class="row" v-if="open">
        <div class="col-1"></div>
        <div class="col m-xy-auto" v-if="user.roles.production_shifts">
            <el-tooltip content="Days Spent/Booked" placement="top">
                <div>
                    Days: {{ prod.totalDays }}
                </div>
            </el-tooltip>
            <badge type="secondary" v-for="resource in prod.resources">
                <resource-name :res="resource"> </resource-name>
            </badge>
        </div>
    </div>
</div>
</template>

<script>
import UserName from './UserName.vue'
import ResourceName from './ResourceName.vue'
export default {
    props: ["prod"],
    components: {
        "resource-name": ResourceName,
        "user-name": UserName,
    },
    computed: {
        user() {
            return this.$store.getters["user/user"];
        },
    },
    data() {
        return {
            open: false
        }
    }
}
</script>

<style scoped>

.m-xy-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.progress-percentage {
    display: none;
}

.progress-label {
    margin-bottom: 5px;
    margin-top: -5px;
}</style>