<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">Query</div>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col-4">
                  <base-input class="select-danger">
                    <el-select
                      v-model="filters"
                      filterable
                      multiple
                      placeholder="Add filters"
                    >
                      <el-option
                        v-for="option in filterOptionArray"
                        class="select-danger"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-4" v-for="filter in filters" :key="filter">
                  <filter-selector
                    :selection="filterOptions[filter]"
                    @valueChange="filterValues[filter] = $event"
                  >
                  </filter-selector>
                </div>
                <div class="col-auto" v-if="filters.length > 0">
                  <base-input>
                    <base-button @click="runQuery">Fetch</base-button>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush list event-list">
                <li
                  v-for="match in competitions"
                  class="list-group-item px-0"
                  :key="match.id"
                >
                  <match :match="match" />
                </li>
              </ul>
            </div>
          </div> -->

          <card
            class="no-border-card"
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
          >
            <el-table
              class="competitionsTable"
              :data="competitions"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >
    
              <el-table-column
                label="Name"
                min-width="200px"
                prop="competitionName"
                sortable
              >
              </el-table-column>
       
        
              <!--    <el-table-column label="Format" min-width="100px" prop="cricketFormat" sortable>
                <template v-slot="{ row }">
                  {{ row.cricketFormat }}
                </template>
              </el-table-column> -->
              <el-table-column label="PulseID" min-width="200px" prop="pulseID">
                <template v-slot="{ row }">
                  <base-input
                    type="number"
                    v-model="row.pulseID"
                    @input="pulseID($event, row.id)"
                  >
                  </base-input>
                </template>
              </el-table-column>
            
            </el-table>

          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchCardTable from "./Components/MatchCardTable";
import FilterSelector from "./Components/FilterSelector";
export default {
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      filters: [],
      filterValues: {},
      filterOptions: {
        // startDate: { field: "startDate", label: "Match Start", type: "date-range" },
        year: {
          field: "year",
          label: "Year",
          type: "number",
        },
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "single-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "single-select",
        },
        int: {
          field: "int",
          label: "International",
          type: "boolean",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Teams",
          type: "multi-select",
        },
        venue: {
          field: "venue",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "single-select",
        },
      },
    };
  },
  components: {
    match: MatchCardTable,
    "filter-selector": FilterSelector,
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    competitions() {
      return this.$store.getters["competitions/array"].reverse();
    },
    matchFormats() {
      return this.$store.getters["matchFormats/formats"];
    },
    matchTypes() {
      return this.$store.getters["matchFormats/types"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  beforeMount() {
    this.$store.dispatch("teams/fetchAndAdd");
    this.$store.dispatch("venues/fetchAndAdd");
      this.$store.dispatch("competitions/fetchAndAdd", { limit: 500 });
    // this.$store.dispatch("matchFormats/fetchAndAdd");
  },
  methods: {
    pulseID(value, id) {
      console.log(value);
      console.log(id);
      this.$store.dispatch("competitions/patch", { id: id, pulseID: value });
    },
    runQuery() {
      this.$store.commit("competitions/RESET_VUEX_EASY_FIRESTORE_STATE");
      let filters = this.filters;
      let where = [];
      filters.forEach((field) => {

          // check to make sure the filter has a value
          const value = this.filterValues[field];
          const option = this.filterOptions[field];
          if (option.type === "single-select" || option.type === 'boolean') {
       
            where.push([field, "==", value]);
          } else if (option.type === "multi-select") {
            value.forEach((v) => {
              where.push([`${field}.${v}`, "==", true]);
            });
          } else if (option.type === "number") {
            const date1 = parseInt(value)
            where.push([field, "==", date1]);
          }
      });
      console.log(where);

      this.$store.dispatch("competitions/fetchAndAdd", {
        clauses: { where, limit: 500 },
      });
    },
  },
};
</script>

<style>
.el-table__expand-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.select-danger .el-select .el-tag {
  background: #f5365c;
}
</style>