<template>
  <div class="row">
    <div class="col">
      <base-input
                    v-model="payment.total"
                    type="number"
   
                    @change="changeField($event.target.value, 'total')"
                  ><template slot="prepend">£</template></base-input>

      </div>
        <div class="col">
          <div class="row">
    <div class="col-12">
          <small>Created: {{ payment.created_at  | dateTime }} by {{ creater }}</small>
            </div>
        <div class="col-12" v-if="payment.updated_at">
          <small>Updated: {{ payment.updated_at | dateTime }} by {{ updater }}</small>
        </div>
        </div>
        </div>
      </div>
 
</template>

<script>
export default {
  name: "payment",
  props: ["payment"],
  data() {
    return {};
  },
  beforeMount() {
    this.$store.dispatch("users/fetchById", this.payment.created_by);
    this.$store.dispatch("users/fetchById", this.payment.updated_by);
  },
  computed: {
    creater() {
   return this.$store.getters["users/byId"](this.payment.created_by).name;

    },
    updater() {
      if(this.payment.updated_by){
        return this.$store.getters["users/byId"](this.payment.updated_by).name;
      } else {
        return null
      }
    },

  },
  methods: {
    changeField(value, field) {
      var payload = {};
      payload.id = this.payment.id;
      payload[field] = value;
      this.$store.dispatch("payments/patch", payload);
    },
  },
};
</script>