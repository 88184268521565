const briefs = {
  firestorePath: 'productions',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'briefs',
  statePropName: 'data',
  namespaced: true, // automatically added
  // sync: {
  //   where: [['active', '==', true]]
  // },

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    productions(state) {
      const items = Object.values(state.data).sort((a, b) => {
        if (a.updated_at > b.updated_at) {
          return -1
        }
        if (a.updated_at < b.updated_at) {
          return 1
        }
        if (!a.updated_at) {
          return -1
        }
        return 0
      })
      return items.filter(brief => brief.status !== 'Draft')
    },
    drafts(state) {
      const items = Object.values(state.data)
      return items.filter(brief => brief.status === 'Draft')
    },
    briefById: (state) => (id) => {
      const briefs = Object.values(state.data)
      return briefs.filter(b => b.id === id)[0]
    },
    byId: (state) => (id) => {
      return state.data[id]
    },
    asObj: (state) => {return state.data},
    // briefsByDept: (state) => (id) => {
    //   let briefs = Object.values(state.data).filter(b => b.department === id)
    //   var now = new Date()
    //   briefs.forEach(function (brief, index, object) {
        
    //     if (brief.created_at) {
    //       brief.sortCreated = new Date(brief.updated_at.seconds * 1000)
    //     } else if (brief.created) {
    //       brief.sortCreated = new Date(brief.created.seconds * 1000)
    //     } else {
    //       console.log('error timestamping: ' + brief.id + ' ' + brief.title)
    //       brief.sortCreated = now
    //     }
    //   })
    //   var nowThreeMonthsAgo = new Date(now.setMonth(now.getMonth()-3))
    //   briefs = briefs.filter(b => b.sortCreated > nowThreeMonthsAgo)

    //     let sorted = briefs.sort((a, b) => {
    //       if (a.sortCreated > b.sortCreated) {
    //         return -1
    //       }
    //       if (a.sortCreated < b.sortCreated) {
    //         return 1
    //       }
    //       if (!a.sortCreated) {
    //         return -1
    //       }
    //       return 0
    //     })
    //     return sorted
    //   }
  },
    mutations: {},
    actions: {},
  }

export default briefs