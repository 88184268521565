<template>
  <div :class="classes">
    <div class="fileName">{{ filename }}</div>
    <div class="upP">{{ progress }}%</div>
    <base-button
      outline
      type="white"
      class="cancelButton btn-icon-only btn-sm"
      @click="cancelUpload()"
      v-if="state == 'uploading'"
    >
      <span><i class="fas fa-times-circle"></i></span>
    </base-button>
    <base-button
      outline
      type="white"
      class="cancelButton btn-icon-only btn-sm"
      @click="remove()"
      v-if="state == 'complete'"
    >
      <span><i class="fas fa-check"></i></span>
    </base-button>
  </div>
</template>

<script>
import { Firebase, defaultBucket } from "@/firebase";
export default {
  props: ["file", "multiple", "basePath"],
  data: function () {
    return {
      state: false,
      progress: 0,
      filename: "",
      uploadCancel: false,
    };
  },
  computed: {
    classes() {
      if (this.multiple) {
        return "multi base bg-gradient-default";
      } else {
        return "single base bg-gradient-default ";
      }
    },
  },
  mounted() {
    this.fileUploader(this.file);
  },
  methods: {
    cancelUpload() {
      this.uploadCancel = true;
    },
    remove() {
      this.$emit("cancel");
    },
    fileUploader(f) {
      // console.log(f);
      const file = f.file;
      const uploadName = f.uploadName;
      if (file.type === "application/mxf") {
        var type = "video";
      } else if (file.type === "application/postscript") {
        var type = "image";
      } else {
        var type = file.type.slice(0, file.type.lastIndexOf("/"));
      }
      let ext = file.name.slice(file.name.lastIndexOf("."));
      this.state = "uploading";
      this.filename = file.name;
      this.uploadCancel = false;
      var metadata = {
        contentDisposition: `attachment; filename=${file.name}`,
      };
      var storage = Firebase.storage();
      var uploadTask = storage
        .ref()
        .child(`${this.basePath}/${uploadName}${ext}`)
        .put(file, metadata);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (this.uploadCancel) {
            uploadTask.cancel();
            this.state = "cancelled";
            this.uploadingCancel = false;
            this.$emit("cancel");
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              console.log("no permissions");
              break;

            case "storage/canceled":
              // User canceled the upload
              console.log("cancelled");
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              console.log("unknown error");
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            //console.log("File available at", downloadURL);

            var theNewFile = {
              assetType: type,
              sourceDownloadURL: downloadURL,
              sourceFileNameAsUploaded: file.name,
              //sourceBasePath: this.basePath,
              sourcePath: `${this.basePath}/${uploadName}${ext}`,
              sourceStorageClass: "Live",
              sourceBucket: defaultBucket,
              sourceExtention: ext,
              sourceFileName: `${uploadName}`,
              hasPreview: false,
            };
            this.state = "complete";
            this.progress = 100;
            this.$emit("successfulUpload", theNewFile);
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.base {
  color: white;
  border-radius: 6px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}

.single {
  height: 100%;
  width: 100%;
  position: absolute;

  top: 0;
  left: 0;
}
.multi {
  height: 40px;
  margin: 10px;

  bottom: 0;
  left: 0;
}

.fileName {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
