<template>
<div>
  <div @click="edit" :class="{ floatOver: isAuthor }">
    <small>
      <i class="far fa-comment mr-1"></i> {{ note.author }}:</small
    >
    <small>
      {{ note.text }}
    </small>

  </div>
         <modal :show.sync="editModal">
      <template slot="header">
        <h5 class="modal-title">Edit Note</h5>
      </template>
      <div>
        <base-input>
          <textarea
            @change="changeNote"
            class="form-control"
            v-model="note.text"
            box
            type="text"
            rows="10"
          />
        </base-input>
      </div>
      <template slot="footer">
        <base-button type="danger" @click="deleteNote()"
          >Delete Note</base-button
        >
        <base-button type="primary" @click="editModal = false"
          >Done</base-button
        >
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
        editModal: false
    };
  },
  props: ["note", "delID"],
  computed: {
        user() {
      return this.$store.getters["user/user"];
    },
    isAuthor() {
        return this.user.id === this.note.created_by ? true : false
    }
  },
  components: {},
  methods: {
    edit(){
        if (this.isAuthor) {
            this.editModal = true
        }
    },
     deleteNote() {
      this.editModal = false;
     this.$store.dispatch("notes/delete", this.note.id);
    },
    changeNote() {
      var payload = {};
      payload.id = this.note.id;
      //payload.delID = this.delID
      payload.text = this.note.text;
      payload.done = false;
      //console.log(payload)
      this.$store.dispatch("notes/patch", payload);
    },
  },
};
</script>

<style>
.floatOver{
  cursor: pointer;
}
</style>
