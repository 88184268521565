const brands = {
    firestorePath: 'config/default/brands',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'brands',
    statePropName: 'data',
    namespaced: true, // automatically added
  
    // this object is your store module (will be added as '/myModule')
    // you can also add state/getters/mutations/actions
    state: {
    },
    getters: {
      array: state => Object.values(state.data),
      coloursByBrandID: (state) => (id) => {
        let brand = state.data[id]
        return brand.colours
      },
      byId: (state) => (id) => {
        return state.data[id]
      },

    },
    mutations: {},
    actions: {},
  }
  
  export default brands