<template>
  <div>
    <base-header
      class="pb-6 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row" v-if="insp.error">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="col">Error</div>
                <div class="col">
                  {{ insp.url }}
                </div>
                <div class="col text-right">
                  <base-button
                    size="sm"
                    type="secondary"
                    @click.prevent="del()"
                  >
                    Delete
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="loading">
        <div class="col">
          <div class="card">
            <div class="row justify-content-md-center">
              <div class="col">
                <loading-spinner></loading-spinner>
              </div>
            </div>
            <div class="row justify-content-md-center mb-4">
              <div class="col-auto">Please Wait</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <!-- Form controls -->
          <!-- Card header -->
          <card>
            <div class="row">
              <div class="col-6" v-if="insp.what === 'file'">
                <file-preview :file="file"></file-preview>
              </div>
              <div class="col-6" v-else>
                <div v-if="insp.platform === 'YouTube'">
                  <iframe
                    width="100%"
                    height="400px"
                    :src="`https://www.youtube-nocookie.com/embed/${youtubeId}?start=0`"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
  
                <div v-else-if="insp.extImages.length > 0">
                  <file-preview
                    class="mb-3"
                    :fileID="insp.chosenImage"
                    useRaw="true"
                  ></file-preview>

                  <!-- <base-button
                    @click.prevent="loadImage()"
                    type="primary"
                    class="mb-4"
                    v-if="!chosenImage"
                  >
                    Load Image
                  </base-button> -->
                  <!-- <img v-if="!chosenImage" class="card-img mb-3" :src="insp.extImages[insp.extImageChoice]" /> -->
                </div>

                <form>
                  <base-input
                    :label="imageChoiceText"
                    v-if="capturedImages.length > 1"
                  >
                    <el-select
                      v-model="insp.chosenImage"
                      @input="changeField($event, 'chosenImage')"
                    >
                      <el-option
                        v-for="option in capturedImages"
                        :key="option.id"
                        :label="option.sourceFileName"
                        :value="option.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>

        
                  <a v-bind:href="insp.url">{{ insp.url }}</a>
                  <div>
                    <button
                      @click.prevent="reload()"
                      class="btn btn-success mt-4"
                      v-if="insp.scraped"
                    >
                      Re-Query URL
                    </button>
                  </div>
                </form>
              </div>
          
              <div class="col-6">
                <form>
                  <base-input label="Description">
                    <textarea
                      label="Note"
                      v-model="insp.title"
                      @change="changeField($event.target.value, 'title')"
                      class="form-control"
                      rows="7"
                    />
                  </base-input>
                  <base-input label="Type">
                    <el-select
                      v-model="insp.type"
                      filterable
                      @input="changeField($event, 'type')"
                    >
                      <el-option
                        v-for="option in types"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input
                    v-model="insp.platform"
                    label="Platform"
                    @change="changeField($event.target.value, 'platform')"
                  />
                  <base-input
                    v-model="insp.author"
                    label="Author"
                    @change="changeField($event.target.value, 'author')"
                  />
                  <base-input label="Tags">
                    <el-select
                      v-model="thisTags"
                      filterable
                      multiple
                      clearable
                      allow-create
                    >
                      <el-option
                        v-for="option in tags"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </form>
              </div>
            </div>
            <div class="row">
  <div class="col" v-if="poster">
                    Posted by:
                    {{ poster.name }} | {{ insp.created_at | dateTime }}
                  </div>
</div>
            <div class="row mt-2 mb--2">

              <div class="col">
                <div
                  size="sm"
                  type="secondary"
                  class="btn"
                  @click="toggleFavourite"
                >
                  <i class="fa fa-heart text-danger" v-if="isFavourite"></i>
                  <i class="fa fa-heart" v-else></i>
                </div>
              </div>
              <div class="col text-right">
                <base-button size="sm" type="secondary" @click.prevent="del()">
                  Delete
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Form controls -->
          <!-- Card header -->

          <div class="card">
            <modal :show.sync="addComment" modal-classes="modal">
              <base-input>
                <textarea
                  placeholder="Leave comment here"
                  v-model="comment"
                  class="form-control"
                  rows="4"
                />
              </base-input>
              <div class="modal-footer px-0 py-0 mt-4">
                <button
                  type="submit"
                  class="btn btn-primary new-event--add"
                  @click="post()"
                >
                  Post
                </button>
                <!-- <button type="warning" class="btn btn-warning new-event--add" @click="saveEvent('unavailable')">Mark as Unavailable</button> -->

                <button
                  type="button"
                  class="btn btn-link ml-auto"
                  @click="addComment = false"
                >
                  Close
                </button>
              </div>
            </modal>
            <div class="card-header">
              <div class="row mb-0">
                <div class="col mb-0">
                  <h4 class="mb-0">Comments</h4>
                </div>
                <div class="col mb-0 text-right">
                  <base-button
                    size="sm"
                    type="primary"
                    class="mb-0"
                    @click="addComment = true"
                    >Add</base-button
                  >
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <li class="list-group list-group-flush">
                    <ul
                      class="list-group-item"
                      v-for="(comment, index) in comments"
                      :key="index"
                    >
                      <inspiration-comment
                        :inspiration="insp"
                        :comment="comment"
                      ></inspiration-comment>
                    </ul>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <!-- Form controls -->
          <!-- Card header -->

          <div class="card">
            <modal :show.sync="briefBrowser" modal-classes="modal">
              <brief-add-form
                :inspiration="insp"
                @closeModals="briefBrowser = false"
              ></brief-add-form>
            </modal>
            <div class="card-header">
              <div class="row mb-0">
                <div class="col mb-0">
                  <h4 class="mb-0">Attached to Briefs</h4>
                </div>
                <div class="col mb-0 text-right">
                  <base-button
                    size="sm"
                    type="primary"
                    class="mb-0"
                    @click="briefBrowser = true"
                    >Browse</base-button
                  >
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col">
                  <li class="list-group list-group-flush">
                    <ul
                      class="list-group-item"
                      v-for="(brief, index) in briefs"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col">
                          <a v-bind:href="`/#/brief/${brief.id}`"
                            >{{ brief.title }} - {{ brief.department }} -
                            <span v-if="brief.created_at">{{
                              brief.created_at | dateTime
                            }}</span></a
                          >
                        </div>
                        <div class="col-auto">
                          <div class="btn btn-sm">Remove</div>
                        </div>
                      </div>
                    </ul>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Select,
  Option,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";

import router from "@/routes/router";
import BriefAddForm from "./Components/InspirationBriefFinder.vue";
import InspirationComment from "./Components/InspirationComment.vue";
// import {useInspirationsStore} from '@/stores/inspirations'

export default {
  name: "inspiration",
  data: function () {
    return {
      refreshKey: 0,
      briefBrowser: false,
      addComment: false,
      comment: "",
      types: [
        "Video",
        "Illustration",
        "Design",
        "Animation",
        "Motion Graphic",
        "Article",
        "Photo",
        "Game",
        "App/Utility",
        "Web3",
        "Event",
        "Other",
      ],
    };
  },
  async beforeMount() {
   await this.$store.dispatch("inspirations/fetchById", this.inspId); //get record

  // useInspirationsStore().fetchById(this.inspId)

    if (!this.insp.scraped && !this.insp.file) {
      await this.$store.dispatch("inspirations/loadInspiration", this.insp); //scrape
    }
    if (this.insp.file && !this.file) {
       await this.$store.dispatch("files/fetchById", this.insp.file);
    }
    await this.$store.dispatch("files/fetchById", this.insp.file);
    this.$store.dispatch("files/openDBChannel", {
      where: [["inspiration", "==", this.inspId]],
    });
    this.$store.dispatch("inspirationComments/fetchAndAdd", {
      where: [["inspiration", "==", this.inspId]],
    });
    this.$store.dispatch("inspirationTags/openDBChannel"); //get record
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BriefAddForm,
    InspirationComment,
  },
  computed: {
    inspId() {
      return this.$route.params.id;
    },
    insp() {
      this.refreshKey;
      console.log(this.refreshKey);
      return this.$store.getters["inspirations/byId"](this.inspId);
      // const insp = useInspirationsStore().byId(this.inspId)
      //const insp = useInspirationsStore().arr().filter(insp => insp.id === this.inspId)
      // console.log(insp)
      // return insp
    },
    // allIns() {
    //   return useInspirationsStore().arr()
    // },
    youtubeId() {
      if(this.insp){
      if (this.insp.platform === "YouTube") {
        let str = this.insp.url;
        let end = str.includes("&") ? str.indexOf("&") : str.length;
        return str.substring(str.indexOf("?v=") + 3, end);
      }
    }},
    imageChoiceText() {
      return `Image Choice: ${this.capturedImages.length} available images`;
    },
    poster() {
      if (this.insp) {
        if (this.insp.created_by) {
          let user = this.$store.getters["users/byId"](this.insp.created_by)
          if (user) {
            return user;
          } else {
            this.$store.dispatch("users/fetchById", this.insp.created_by);
          }
        } else {
        return false;
      }
      } else {
        return false;
      }
    },
    user() {
      return this.$store.getters["user/user"];
    },
    file() {
      if (this.insp) {
      if (this.insp.file) {
        let f = this.$store.getters["files/byId"](this.insp.file);
        return f;
      } else {
        return false;
      }
    }
    },
    thisTags: {
      get() {
        return this.insp.tags
      },
      set(value) {
        var payload = {};
      payload.id = this.inspId;
      payload.tags = value;
      this.$store.dispatch("inspirations/patch", payload);
      this.$store.dispatch("inspirations/refreshTags");
      }
  },
    tags() {
      let tags = this.$store.getters["inspirationTags/tags"].tags;
      if (tags) {
        let keys = Object.keys(tags);
        return keys.sort();
      }
    },
    capturedImages() {
      return this.$store.getters["files/byInspiration"](this.inspId);
    },
    chosenImage() {
      this.refreshKey;
      this.insp;
      console.log(this.refreshKey);
      let obj = this.$store.getters["files/fileById"](this.insp.chosenImage);

      return obj;
    },
    comments() {
      return this.$store.getters["inspirationComments/byInspiration"](
        this.inspId
      );
    },
    loading() {
      return this.$store.getters["inspirations/loading"];
    },
    isFavourite() {
      if (this.insp.favourites) {
        if (this.insp.favourites[this.user.id]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    briefs() {
      if(this.insp){
      let briefs = this.insp.briefs;
      if (briefs) {
        let bs = [];
        let briefsCollected = [];

        for (const [key, value] of Object.entries(briefs)) {
          console.log(`${key}: ${value}`);
          if (value) {
            bs.push(key);
          }
        }

        for (let i = 0; i < bs.length; i++) {
          //get the full documents
          if (this.$store.getters["briefs/byId"](bs[i])) {
            //if already available
            briefsCollected.push(this.$store.getters["briefs/byId"](bs[i]));
          } else {
            //get it and then add it
            this.$store.dispatch("briefs/fetchById", bs[i]);
            briefsCollected.push(this.$store.getters["briefs/byId"](bs[i]));
          }
        }
        return briefsCollected.sort((a, b) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (a.created_at < b.created_at) {
            return 1;
          }
          if (!a.created_at) {
            return -1;
          }
          return 0;
        });
      } else {
        return false;
      }
    }
    },
  },
  methods: {
    changeField(value, field) {
      var payload = {};
      payload.id = this.inspId;
      payload[field] = value;
      console.log(payload);
      this.$store.dispatch("inspirations/patch", payload);
    },
    del() {
      if (this.insp.file) {
        this.$store.dispatch("files/delete", this.insp.file);
      }
      this.$store.dispatch("inspirations/delete", this.inspId);
      this.$router.push("/inspirations");
    },
    reload() {
      this.$store.dispatch("inspirations/loadInspiration", this.insp);
    },
    async loadImage() {
      await this.$store.dispatch("files/fetchAndAdd", {
        where: [["inspiration", "==", this.inspId]],
      });
      this.refreshKey++;
    },
    post() {
      this.$store.dispatch("inspirationComments/insert", {
        inspiration: this.inspId,
        text: this.comment,
      });
      this.comment = "";
      this.addComment = false;
    },
    toggleFavourite() {
      var payload = {};
      payload.id = this.inspId;
      payload.favourites = {};
      if (this.insp.favourites) {
        if (this.insp.favourites[this.user.id]) {
          payload.favourites[this.user.id] =
            !this.insp.favourites[this.user.id];
        } else {
          payload.favourites[this.user.id] = true;
        }
      } else {
        payload.favourites[this.user.id] = true;
      }
      console.log(payload);
      this.$store.dispatch("inspirations/patch", payload);
    },
  },
};
</script>
<style scoped>
.list-group-item {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
