<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card card-calendar">
            <div class="card-header">
              <!-- Title -->
              <div class="row">
                <div class="col-3">
                  <h5 class="h3">{{ title }}</h5>
                </div>
                <div class="col-9 text-right">
                  <a
                    href="#"
                    @click.prevent="prev"
                    class="fullcalendar-btn-prev btn btn-sm btn-default"
                  >
                    <i class="fas fa-angle-left"></i>
                  </a>
                  <a
                    href="#"
                    @click.prevent="next"
                    class="fullcalendar-btn-next btn btn-sm btn-default"
                  >
                    <i class="fas fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body p-0 card-calendar-body">
              <full-calendar
                :events="shoots"
                defaultView="dayGridMonth"
                :plugins="calendarPlugins"
                :editable="false"
                :firstDay="1"
                contentHeight="auto"
                :theme="false"
                :nowIndicator="true"
                :selectable="true"
                :selectHelper="true"
                ref="fullCalendar"
                class="calendar"
                @datesRender="datesRender"
                @eventClick="edit($event.event.id)"

                resourceGroupField="group"
              >
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <div class="card-header">
                <div class="col-auto text-right">
                  <base-button
                    type="secondary"
                    icon
                    size="sm"
                    @click="shootAddForm = true"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-plus-square"></i
                    ></span>
                    <span class="btn-inner--text">Add Shoot</span>
                  </base-button>
                </div>
                <modal
                  :show.sync="shootAddForm"
                  size="lg"
                  modal-classes="modal-scrollable"
                >
                  <shoot-add-form
                    :productionId="$route.params.id"
                    @closeModals="shootAddForm = false"
                  ></shoot-add-form>
                </modal>
                <modal
                  :show.sync="shootEditForm"
                  size="lg"
                  modal-classes="modal-scrollable"
                >
                  <shoot-edit-form
                    :shootId="shootEditId"
                    :isBriefForm="true"
                    @closeModals="shootEditForm = false"
                  ></shoot-edit-form>
                </modal>
              </div>

              <!-- Card body -->
              <div class="card-body">
                <div class="row" v-if="shoots.length === 0">
                  <div class="col">No Shoots Planned</div>
                </div>
                <li class="list-group list-group-flush">
                  <ul
                    class="list-group-item"
                    v-for="(shoot, index) in shoots"
                    :key="index"
                  >
                    <shoot-list
                      :shoot="shoot"
                      @edit="edit(shoot.id)"
                    ></shoot-list>
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ShootList from "./Components/ShootListItem";
import ShootAddForm from "./Components/ShootAddForm.vue";
import ShootEditForm from "./Components/ShootEditForm.vue";

export default {
  name: "shoots",
  components: {
    FullCalendar,
    ShootList,
    ShootAddForm,
    ShootEditForm,
  },
  data() {
    return {
      shootAddForm: false,
      shootEditForm: false,
      shootEditId: "",
      calendarPlugins: [interactionPlugin, dayGridPlugin],
      title: "Calendar",
    };
  },
  computed: {
    shoots() {
      let shoots = this.$store.getters["shoots/loaded"];
      shoots.forEach((entry) => {
        entry.title = entry.prodTitle + " - " + entry.shootTitle;

        if (entry.shootStatus === "pencilled") {
          entry.className = "pencilled";
        }
        if (entry.shootStatus === "confirmed") {
          entry.className = "confirmed";
        }
        if (entry.shootStatus === "cancelled") {
          entry.className = "cancelled";
        }

        if (entry.shooter === 'Danny') {
          if (entry.shootStatus === "pencilled") {
          entry.className = "danny-pencilled";
        }
        if (entry.shootStatus === "confirmed") {
          entry.className = "danny-confirmed";
        }

        }
        if (entry.shooter === 'Brandon') {
          if (entry.shootStatus === "pencilled") {
          entry.className = "brandon-pencilled";
        }
        if (entry.shootStatus === "confirmed") {
          entry.className = "brandon-confirmed";
        }

        }



      });
      return shoots
    },
  },
  mounted() {
    // this.$store.commit("shoots/RESET_VUEX_EASY_FIRESTORE_STATE");
    var start = new Date();
    start.setDate(start.getDate() - 60);

    console.log(start);
    this.$store
      .dispatch("shoots/openDBChannel", { where: [["date", ">=", start]] })
      .catch(console.error);
  },
  methods: {
    edit(shootId) {
      this.shootEditId = shootId;
      this.shootEditForm = true;
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    datesRender(info) {
      this.title = info.view.title;
    },
    next() {
      this.calendarApi().next();
    },
    prev() {
      this.calendarApi().prev();
    },
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@/assets/sass/core/vendors/fullcalendar";

.danny-pencilled {
  background: #ff9090;
}
.danny-confirmed {
  background: #ff1313;
}
.brandon-pencilled {
  background: #8be6ff;
}
.brandon-confirmed {
  background: #0569ff;
}

</style>