<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
  </base-header>
  <div class="container-fluid mt--9">
    <div class="row">
      <div class="col">


  <div class="card">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-6">
          <span>
            <label class="small preview-label">Include Inactive</label></span
          >
          <span>
            <base-switch
              class="preview-switch"
              v-model="includeInactive"
              offText="No"
              onText="Yes"
            ></base-switch
          ></span>
        </div>
            <div class="col-6 text-right">
              <el-tooltip content="New" placement="top">
                <base-button type="warning" icon size="sm" @click="addResource">
                  <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
                  <span class="btn-inner--text">Add New Resource</span>
                </base-button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- Card body -->

        <el-table
            class="
              table-responsive
              align-items-center
              table-flush table-striped
              jimTable
            "
            header-row-class-name="thead-light"
            :data="resources"
            :default-sort="{prop: 'displayName', order: 'ascending'}"
          >
            <el-table-column
              label="Label"
              min-width="200"
              prop="displayName"
              sortable
            >
            <div slot-scope="{ row }" class="table-actions">
            <a :href="`/#/resource/${row.id}`">{{ row.displayName }}</a>
           
           </div> </el-table-column>
            <el-table-column label="Company" prop="company" :filters="producers" :filter-method="filterHandler"> </el-table-column>

            <el-table-column label="Group" prop="group" sortable> </el-table-column>
            <el-table-column label="Available Billings" prop="availableBillings.length" sortable> </el-table-column>
            <el-table-column label="Default Billing" prop="defaultBilling" sortable>
              <div slot-scope="{ row }" class="table-actions">
                <billing-name :bill="row.defaultBilling"></billing-name>
              </div>

            </el-table-column>
 
            <el-table-column label="User?" prop="isPerson" sortable> </el-table-column>
       

            <el-table-column label="User" prop="user" sortable>
            </el-table-column>
          
            <el-table-column label="Active" prop="status" width="70" sortable>
              <div slot-scope="{ row }" class="table-actions">
                <base-checkbox disabled v-model="row.active"> </base-checkbox>
              </div>
            </el-table-column>
    
          </el-table>
</div>
</div>
    </div>
  </div>

  </div>
</template>
<script>

  import BillingName from './Components/BillingName.vue'


  export default {
    name: 'brief',
    data: function() {
      return {
        includeInactive: false,
      }
    },
    // props: ['id'],
    components: {

      BillingName

    },
beforeMount() {
  this.$store.commit("resources/RESET_VUEX_EASY_FIRESTORE_STATE");
this.$store.dispatch("resources/fetchAndAdd")
this.$store.dispatch("billings/fetchAndAdd");
},
    computed: {
      loading() {
        return this.$store.getters['user/loading']
      },
      config() {
        return this.$store.getters['config/config']
      },
      user() {
        return this.$store.getters['user/user']
      },
      resources() {
       let res = this.$store.getters['resources/resources']
       if(this.includeInactive){
        return res
       } else {
        return res.filter(a => a.active === true)
       }
      },
      groups() {
        const resources = this.resources
        let groups = []
        for (const element of resources) {
          groups.push(element.group)
        }
        const groupsInter = [...new Set(groups)]
        let groupsTwo = []
        for (const element of groupsInter) {
          groupsTwo.push({
            text: element,
            value: element
          })
        }
        return groupsTwo
      },
      producers() {
        let groupsTwo = []
        for (const element of this.config.producer) {
          groupsTwo.push({
            text: element,
            value: element
          })
        }
        return groupsTwo
      }
    },
    methods: {
      changeResourceDetail(value, key, field) {
        var payload = {}
        payload.id = key
        payload[field] = value
        this.$store.dispatch('resources/patch', payload)
      },
      async addResource() {
        let id = await this.$store.dispatch('resources/insert', {displayName: 'New Resource', active: false})
        this.$router.push(`/resource/${id}`)
      },
      filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
      }
    }
  }

</script>
<style>


</style>
