<template>
  <div>
    <file-preview :file="file"></file-preview>

    <div class="mt-1 mb-3">{{ file.name }}</div>
    <div v-if="file.metadata">
      Duration: {{ file.metadata.duration | duration }}
    </div>
    <!-- <div class="card-title mb-2">{{ file.sourceFileName }}</div> -->
    <small>{{ file.created_at | dateAndTime }}</small>
    <div>
      <base-input
        label="Day"
        v-model="file.day"
        type="number"
        placeholder="Day"
        @change="changeField($event.target.value, 'day')"
      />
    </div>

    <div class="mt-3">
      <a download class="btn btn-sm" :href="file.sourceDownloadURL">
        <i class="fa fa-download mr-1"></i>Download
      </a>
        <div
        class="btn btn-sm"
        @click="sendToBC"
        v-if="
          file.day && user.roles.archive_uploader
        "
      >
        <i class="fa fa-paper-plane mr-1"></i>Brightcove
      </div>
      <base-button
        v-if="user.roles.archive_uploader"
        size="sm"
        type="danger"
        @click="deleteHighlight()"
        >Delete</base-button
      >
  
   
        </div>
  </div>
</template>

<script>
import router from "@/routes/router";
export default {
  data() {
    return {};
  },
  props: ["file", "matchData"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    fileID() {
      return this.file.id;
    },
  },
  components: {},
  methods: {
    deleteHighlight() {
      this.$store.dispatch("files/delete", this.fileID);
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.file.id;
      payload[field] = value;
      this.$store.dispatch("files/patch", payload);
    },
    sendToBC() {
      const data = {
        uploadName: `Highlights | ${this.matchData.label} - Day ${this.file.day} - ${this.matchData.competitionName}`,
        file: {
          sourceBucket: this.file.sourceBucket,
          sourcePath: this.file.sourcePath,
        },
      };
      this.$store.dispatch("files/sendToBC", data);
      this.$notify({
        message: "Submitted",
        timeout: 4000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>