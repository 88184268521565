<template>
  <div>
        <a download class="btn btn-sm" :href="fileGetter.sourceDownloadURL">
          <i class="fa fa-download worklistIcon"></i>
        </a>
  </div>
</template>

<script>
import { db } from "@/firebase";

export default {
  props: ["file", "fileID",],
  name: "downloader",
  data() {
    return {
      // file: {}
    };
  },
  computed: {

    fileGetter() {
      if (this.fileID) {
        return this.$store.getters["files/fileById"](this.fileID);
      } else {
        return this.file;
      }
    },

  },

  components: {  },
};
</script>

<style scoped>

</style>
