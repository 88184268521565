<template>
  <div>
    <NewFile
      :boxHeight="boxHeight"
      :multiple="true"
      message="Drop files or click to browse, to create deliverables for this brief"
      :accepted="allowedFileTypes"
      :fileName="false"
      :basePath="basePath"
      @success="success"
      :randomSuffix="true"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-content",
  props: ["colID", "type", "brand", "dimensions", "department", "boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    allowedFileTypes() {
      if (this.type) {
        const delType = this.type;
        const allTypes = this.config.delTypes;
        let thisType = allTypes.filter((f) => f.display === delType);
        return thisType[0].allowedFiles;
      } else {
        return "*";
      }
    },
    collection() {
      return this.$store.getters["collections/byId"](this.colID);
    },
    basePath() {
      const colName = this.collection.name.replace(/[\W_]+/g, "_");
      let year = new Date().getFullYear();
      return `files/${year}/${this.department}/collections/${this.colID}`;
    },
  },
  methods: {
    success(payload) {
      payload.what = "collection-file";
      payload.version = 1;
      payload.colID = this.colID;
      payload.department = this.department;
      payload.status = "signed";
      payload.events = [
        {
          action: "Uploaded",
          user: this.user.name,
          date: new Date(),
        },
      ];
      // commit file to DB
      this.$store.dispatch("files/insert", payload).then((fileID) => {
        //update deliverable state
        let delPayload = {};
        delPayload.state = "signed";
        delPayload.currentFileID = fileID;
        delPayload.title = payload.sourceFileNameAsUploaded;
        delPayload.collections = [this.colID]
        delPayload.collectionUpload = true
        delPayload.brand = this.brand
        delPayload.folderName = ''
        delPayload.type = this.type
        delPayload.format = this.dimensions
        this.$store
          .dispatch("deliverables/insert", delPayload)
          .then((delID) => {
            let filePayload = {};
            filePayload.delID = delID;
            filePayload.id = fileID;
            this.$store.dispatch("files/patch", filePayload);
          });
      });
    },
  },
};
</script>

<style scoped>
</style>