<template>
    <div class="row">
        <div class="col">
            <h3>{{shoot.shootTitle}}</h3>
            <h4>{{shoot.shootStatus}}</h4>
                <h5>{{shoot.start}} - days: {{shoot.days}}</h5>
            <div class="row">
                <div class="col">
</div>
    

            </div>
            <div class="row">
                <div class="col">Location: {{shoot.location}}</div>
            </div>
            <div class="row">
                <div class="col">Crew: {{shoot.crew}}</div>
            </div>
            <div class="row">
                <div class="col">Times: {{shoot.timings}}</div>
            </div>

                    
    </div>
    <div class="col-auto">
        <base-button type="primary" @click="edit">Edit</base-button>
        </div>
    </div>
</template>

<script>
export default {
  name: "shoot-list",
  props: ['shoot'],
  data() {
    return {};
  },
  computed: {

  },
  methods: {
    edit() {
        this.$emit('edit')
    }
  }
};
</script>