import { Firebase } from '../../firebase.js'

const livestreams = {
  firestorePath: 'livestreams',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'livestreams',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    // debounceTimerMs: 50
    // defaults to 1000
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {
    current: {},
    stream: {},
    token: ''
  },
  getters: {
    current: (state) => { return state.current },
    stream: (state) => { return state.stream },
    token: (state) => { return state.token }
  },
  mutations: {
    setCurrentStreams(state, payload) {
      state.current = payload
    },
    setStream(state, payload) {
      state.stream = payload
    },
    setToken(state, payload) {
      state.token = payload
    }
  },
  actions: {
    getCurrent({ commit }) {
      var publish = Firebase.app().functions('europe-west2').httpsCallable('currentStreams')
      publish().then(function (result) {
        // console.log(result)
        commit('setCurrentStreams', result.data.jobs)
      }).catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(error)
      });
    },
    getStream({ commit }, payload) {

      var publish = Firebase.app().functions('europe-west2').httpsCallable('getStream')
      publish(payload).then(function (result) {

        var rtmp = result.data.job.rtmp_outputs
//         console.log(rtmp)
//         for (var i = 0; i <= rtmp.length; i++) {
          
//           if(rtmp[i].state === 'finished') {
//             console.log(i)
//             rtmp.splice(i, 1) 
//           }
//         }

// console.log(rtmp)
        commit('setStream', result.data.job)
      }).catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(error)
      });
      var publishToken = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("playbackToken");
      publishToken({ streamID: payload.id })
        .then((result) => {
          console.log(result)
          commit('setToken', result.data.playback_token)
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    }
  }
}
export default livestreams