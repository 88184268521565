<template>
  <div class="content">
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <modal :show.sync="newCollection" size="lg" modal-classes="modal">
      <form>
        <base-input
          label="Name"
          v-model="model.name"
        />
      </form>
      <div class="row">
        <div class="col-3">
          <base-button block type="secondary" @click="newCollection = false"
            >Close</base-button
          >
        </div>
        <div class="col-3">
          <base-button block type="success" @click="addCollection"
            >Add Collection</base-button
          >
        </div>
      </div>
    </modal>
    <div class="container-fluid mt--9">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <!-- <template slot="header">
            <h3 class="mb-0">Paginated tables</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template> -->
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <!-- <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select> -->

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                >
                </base-input>
              </div>
              <div>
                <base-button @click="newCollection = true"
                  >New Collection
                </base-button>
              </div>
            </div>
            <el-table
              :data="queriedData"
              :default-sort = "{prop: 'name', order: 'ascending'}"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column type="selection"> </el-table-column>

              <el-table-column
                label="Name"
                min-width="300px"
                prop="name"
                sortable
              >
                <template v-slot="{ row }">
                  <a
                    :href="'#/collection/' + row.id"
                    class="font-weight-bold"
                    >{{ row.name }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Size"
                min-width="200px"
                prop="size"
                sortable
              >
              </el-table-column>
              <el-table-column
                label="Restrictions"
                min-width="200px"
                prop="restrictions"
              >
                <template v-slot="{ row }"> </template>
              </el-table-column>

              <el-table-column
                label="Created By"
                min-width="200px"
                prop="created_by"
                sortable
              >
                <template v-slot="{ row }">
                 <user-name :id="row.created_by"></user-name>
                </template>
              </el-table-column>
              <el-table-column
                label="Created At"
                min-width="200px"
                prop="created_at"
                sortable
              >
                <template v-slot="{ row }">
                  {{ row.created_at | date }}
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components";
import clientPaginationMixin from "./Mixins/clientPaginationMixin";
import UserName from '@/echoComponents/UserName.vue'
import swal from "sweetalert2";

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    UserName
  },
  data() {
    return {
      propsToSearch: ["name"],
      newCollection: false,
      model: { name: "" },
      tableColumns: [
        {
          prop: "size",
          label: "Assets",
          minWidth: 160,
          sortable: true,
        },

        {
          prop: "Restrictions",
          label: "Restrictions",
          minWidth: 220,
          sortable: true,
        },
      ],
      selectedRows: [],
    };
  },
  computed: {
    collections() {
      return this.$store.getters["collections/array"];
    },
  },
  beforeMount() {
    this.$store.dispatch("collections/fetchAndAdd", { limit: 0 });
  },
  methods: {
    addCollection() {

      this.$store.dispatch("collections/insert", {name: this.model.name});
      this.newCollection = false
      this.model.name = ''
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
