<template>
  <div>
    {{ billing.label }}
  </div>
</template>

<script>
export default {
  name: "billing-name",
  props: ["bill"],
  computed: {
    billing() {
      return this.$store.getters["billings/byId"](this.bill);
    },
  },
};
</script>

<style></style>
