<template>
 
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Criteria</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <base-input
                    label="Project Title"
                    v-model="prod.title"
                    @change="changeSyncedField($event.target.value, 'title')"
                  />
                  <base-input
                    label="Audience"
                    v-model="prod.audience"
                    @change="changeField($event.target.value, 'audience')"
                  />
                  <base-input
                    label="Objective"
                    v-model="prod.objective"
                    @change="changeField($event.target.value, 'objective')"
                  />
                  <div class="form-group row">
                    <label class="col-md-4 form-control-label">BAU</label>
                    <div class="col-md-8">
                      <base-switch
                        v-model="prod.bau"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'bau')"
                      ></base-switch>
                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <label class="col-md-4 form-control-label"
                      >Extra Budget</label
                    >
                    <div class="col-md-8">
                      <base-switch
                        v-model="prod.extra"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'extra')"
                      ></base-switch>
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <label class="col-md-4 form-control-label"
                      >Partner Content</label
                    >
                    <div class="col-md-8">
                      <base-switch
                        v-model="prod.commercial"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="commercialField($event)"
                      ></base-switch>
                    </div>
                  </div>
                  <div v-if="prod.commercial">
                    <base-input label="Partner">
                      <el-select
                        v-model="prod.partner"
                        filterable
                        placeholder="Select"
                        @change="changeSyncedField($event, 'partner')"
                      >
                        <el-option
                          v-for="option in config.partners"
                          :key="option"
                          :label="option"
                          :value="option"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h3 class="mb-0">Production</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <form>
                <base-input label="Production Company">
                  <el-select
                    v-model="prod.producer"
                    filterable
                    placeholder="Select"
                    @change="changeField($event, 'producer')"
                  >
                    <el-option
                      v-for="option in config.producer"
                      :key="option"
                      :label="option"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <!-- <div class="form-group row">
                    <label class="col-md-4 form-control-label"
                      >Restrict Access</label
                    >
                    <div class="col-md-8">
                      <base-switch
                        v-model="prod.openAccess"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeAccess($event)"
                      ></base-switch>
                    </div>
                  </div> -->

                <base-input label="Department">
                  <el-select
                    v-model="prod.department"
                    filterable
                    placeholder="Select"
                    @change="changeSyncedField($event, 'department')"
                  >
                    <el-option
                      v-for="option in config.departments"
                      :key="option"
                      :label="option"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <base-input class="select-warning" label="Point of Contact">
                  <el-select
                    class="select-warning"
                    v-model="prod.contact"
                    filterable
                    placeholder="Select"
                    @change="changeField($event, 'contact')"
                  >
                    <el-option
                      class="select-warning"
                      v-for="option in this.admins"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <base-input
                  class="select-warning"
                  label="Default Feedbackers for Deliverables"
                >
                  <el-select
                    class="select-warning"
                    v-model="prod.owners"
                    multiple
                    filterable
                    placeholder="Select"
                    @change="changeField($event, 'owners')"
                  >
                    <el-option
                      class="select-warning"
                      v-for="option in this.admins"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </form>
            </div>
          </div>
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <h3 class="mb-0">Status</h3>
            </div>
            <!-- Card body -->
            <div class="card-body">
              <!-- <ul class="list-group list-group-flush list">
              <li
                v-for="file in files"
                class="list-group-item px-0"
                :key="file.id"
              >
               {{file.sourceFileNameAsUploaded}} <base-button @click="deleteFile(file.id)">delete</base-button>
              </li>

            </ul> -->
              <form>
                <div class="form-group row">
                  <label class="col-md-4 form-control-label">Completed</label>
                  <div class="col-md-8">
                    <base-switch
                      v-model="prod.completed"
                      type="warning"
                      on-text="Yes"
                      off-text="No"
                      @input="complete($event)"
                    ></base-switch>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Outline</h3>
              </div>
              <!-- Card body -->
              <div class="card-body mb--4">
                <div class="mb-3" v-if="prod.slackChannelID">
                  <a
                    class="btn btn-info"
                    :href="
                      'slack://channel?id=' +
                      prod.slackChannelID +
                      '&team=TTF1Y8GEM'
                    "
                    ><span class="mr-2 btn-inner--icon"
                      ><i class="fab fa-slack"></i></span
                    >Open Slack: {{ prod.slackChannelName }}</a
                  >
                </div>
                <div
                  class="mb-3"
                  v-else-if="
                    user.roles.production_admin && prod.status === 'Submitted'
                  "
                >
                  <base-button
                    type="secondary"
                    block
                    aria-label="Slack Create"
                    @click="MakeSlack()"
                  >
                    Make Slack Channel
                  </base-button>
                </div>

                <form>
                  <base-input>
                    <!-- <textarea
                      placeholder="Detailed description"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="18"
                      v-model="prod.description"
                      @change="changeField($event.target.value, 'description')"
                    ></textarea> -->
                    <html-editor
                      v-model="prod.description"
                      @input="changeField($event, 'description')"
                    ></html-editor>
                  </base-input>
                </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Briefing Documents and Files</h3>
              </div>

              <div class="card-body">
                <brief-reference-uploader
                  :production="prod"
                  boxHeight="100px"
                  v-if="user.roles.production_feedback"
                ></brief-reference-uploader>
                <ul class="referenceArea">
                  <li
                    v-for="file in references"
                    :key="file.id"
                    class="reference"
                  >
                    <div>
                      <a :href="file.sourceDownloadURL">{{
                        file.sourceFileNameAsUploaded
                      }}</a>
                    </div>
                    <div v-if="user.roles.production_feedback">
                      <base-button
                        type="secondary"
                        class="btn-icon-only"
                        @click="deleteReference(file)"
                      >
                        <span><i class="fas fa-trash"></i></span>
                      </base-button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="mb-0">Add To Creative Library</h3>
              </div>
              <div class="card-body">
                <div class="mb-2">
                  Sync all deliverables to these collections:
                </div>
                <base-input>
                  <el-select
                    filterable
                    multiple
                    v-model="prod.collections"
                    placeholder="Select"
                    @change="changeSyncedField($event, 'collections')"
                  >
                    <el-option
                      v-for="option in collections"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </div>
      </div>

</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

import { Firebase } from "@/firebase.js";
import BriefReferenceUploadWrapper from "./Components/BriefReferenceUploadWrapper";


export default {
  name: "brief-form",
  data: function () {
    return {
      dialog: false,
      // platform: '',
      date: "",
      menu: false,
      deadlinePicker: false,
      newTitle: "",
    };
  },
  components: {
    flatPicker,
    HtmlEditor,
    "brief-reference-uploader": BriefReferenceUploadWrapper,

  },
  computed: {
    prodID() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      let users = this.$store.getters["users/users"];
      if (users) {
        return users;
      } else {
        return [];
      }
    },
    admins() {
      let users = this.$store.getters["users/feedbackers"];
      if (users) {
        return users;
      } else {
        return [];
      }
    },
    collections() {
      return this.$store.getters["collections/array"];
    },
    deliverables() {
      return this.$store.getters["deliverables/delsByProdID"](
        this.$route.params.id
      );
    },
    ownerSlacks() {
      let o = this.prod.owners;
      let ids = "";
      o.forEach((f) => {
        let user = this.users.filter((p) => p.id === f)[0];
        if (ids !== "") {
          // if not blank, insert a comma
          ids = ids + ",";
        }
        if (user.slack) {
          ids = ids + user.slack;
        }
      });
      return ids;
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    prod() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id);
    },
    // files() {
    //   return this.$store.getters["files/byBriefId"](this.$route.params.id);
    // },
    references() {
      const list = this.$store.getters["files/briefReferenceByProdId"](
        this.prod.id
      );
      return list;
    },
  },
  
  methods: {
    //     deleteFile(fileId) {
    // this.$store.dispatch("files/delete", fileId);
    //     },
    changeField(value, field) {
      var payload = {};
      payload.id = this.prodID;
      payload[field] = value;
      this.$store.dispatch("briefs/patch", payload);
    },
    changeSyncedField(value, field) {
      // these are fields that are replicated on the deliverable
      this.changeField(value, field);
      this.deliverables.forEach((element) => {
        let payload = {};
        payload.id = element.id;
        if (field === "title") {
          payload.productionTitle = value;
        } else {
          payload[field] = value;
        }
        this.$store.dispatch("deliverables/patch", payload);
      });
    },
    complete(value) {
      this.changeField(value, 'completed');
      this.deliverables.forEach((element) => {
        let payload = {};
        payload.id = element.id;
       payload.active = !value;
        this.$store.dispatch("deliverables/patch", payload);
      });
    },
    MakeSlack() {
      var newBrief = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("newBriefSlack");
      //remove whitespace and characters for slack requrements
      let name =
        this.prod.department.replace(/[\W_]+/g, "-").trim() +
        "-" +
        this.prod.title.replace(/[\W_]+/g, "-").trim();
      //truncate to 60 characters if longer than that
      name = name.substring(0, Math.min(60, name.length));
      name = name.toLowerCase();
      let users = this.ownerSlacks;
      newBrief({
        briefTitle: this.prod.title,
        name: name,
        productionID: this.prodID,
        users: this.ownerSlacks,
        url: `/brief/${this.prodID}`,
        department: this.prod.department,
      });
    },
    commercialField(value) {
      this.changeField(value, "commercial");
      if (!value) {
        this.changeSyncedField("", "partner");
      }
    },
    deleteReference(file) {
      this.$store.dispatch("files/delete", file.id);
    },
  },
};
</script>
<style>
.form-control {
  color: #606266;
}

.select-warning .el-select .el-tag {
  background: #fb6340;
}

.wider-select {
  width: 100%;
}

.form-control:focus {
  color: black;
}

.referenceArea {
  margin-bottom: 0px;
  margin-top: 15px;
  padding: 0px;
}

.reference:nth-child(even) {
  background: #fafafa;
  border-radius: 6px;
}

.reference {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  padding-left: 15px;
}
</style>

