<template>
  <card :class="selectedClass">
    <video-player
      v-if="clip.file"
      slot="image"
      class="card-img-top"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @ready="playerReadied"
    ></video-player>
    <!-- Card body -->
    <div class="selector" @click="selectMe">
      <div class="h4 card-title mb-0">{{ description }}</div>
      <div class="h4 card-title mb-0" v-if="clip.bookmark">
        {{ clip.bookmark }}
      </div>
      <div class="h4 card-title mb-0" v-if="bookmarkPlayer">
        {{ bookmarkPlayer.fullName }}
      </div>
      <div class="h4 card-title mb-0" v-if="bookmarkTeam">
        {{ bookmarkTeam.teamName }}
      </div>
      <div class="h5 card-title mb-0" v-if="clip.source === 'user'">
        {{ clip.username }}
      </div>
         <div v-if="clip.note">{{ clip.note }}</div>
      <!-- <small class="text-muted">{{ clip.created_at | dateAndTime }}</small> -->
      <div>
        <div class="text-success" v-if="clip.runsBat">
          <!-- <i class="fas fa-running"></i> {{ clip.runs }} -->
          Runs: {{ clip.runsBat }}
        </div>
        <div class="text-danger" v-if="clip.wicket">Wicket</div>
        <div v-if="teams">
          <match-look-up :matchID="clip.match"></match-look-up>
        </div>
        <div v-if="competition">{{ competition }}</div>
        <div v-if="displayDate">{{ clip.date }}</div>
        <div v-if="format">{{ format }}</div>

        <div v-if="venue">{{ venue }}</div>
        <div v-for="(field, index) in displayFields" :key="index">
          <small>{{ clip[field] }}</small>
        </div>
      </div>
    </div>
    <div class="mt-2 mb--2">
      <a :href="goToAssets"><i class="ni ni-curved-next"></i></a>
    </div>
  </card>
</template>

<script>
import router from "@/routes/router";
import MatchLookUp from "./MatchLookUp";
export default {
  data() {
    return {};
  },
  props: ["clip"],
  computed: {
    playerOptions() {
      if (this.file) {
        return {
          // videojs options
          muted: true,
          fluid: true,
          sources: [
            {
              type: "video/mp4",
              src: `${this.file.previewDownloadURL}`,
            },
          ],
        };
      } else {
        return {
          // videojs options
          muted: true,
          fluid: true,
        };
      }
    },
    player() {
      return this.$refs.videoPlayer.player;
    },
    goToAssets() {
      return `/#/archive/recording/${this.clip.file}/${this.clip.id}`;
    },
    clipID() {
      return this.clip.id;
    },
    file() {
      if (this.clip.clipped) {
        return this.$store.getters["files/fileById"](this.clip.clipFile);
      } else {
        return this.$store.getters["files/fileById"](this.clip.file);
      }
    },
    duration() {
      return this.clip.tcOut - this.clip.tcIn + " secs";
    },
    battingTeam() {
      return this.$store.getters["teams/byId"](this.clip.battingTeam);
    },
    bookmarkPlayer() {
      if (this.clip.bookmarkPlayer) {
        var id = this.clip.bookmarkPlayer;
        var player = this.$store.getters["players/byId"](
          this.clip.bookmarkPlayer
        );
        if (player) {
          return player;
        } else {
          this.$store.dispatch("players/fetchById", this.clip.bookmarkPlayer);
          return false;
        }
      } else {
        return false;
      }
    },
        bookmarkTeam() {
      if (this.clip.bookmarkTeam) {
        var id = this.clip.bookmarkTeam;
        var team = this.$store.getters["teams/byId"](
          this.clip.bookmarkTeam
        );
        if (team) {
          return team;
        } else {
          this.$store.dispatch("teams/fetchById", this.clip.bookmarkTeam);
          return false;
        }
      } else {
        return false;
      }
    },
    bowler() {
      return this.$store.getters["players/byId"](this.clip.bowler);
    },
    batter() {
      return this.$store.getters["players/byId"](this.clip.batter);
    },
    description() {
      if (this.batter && this.bowler) {
        return `${this.bowler.scorecardName} to ${this.batter.scorecardName}`;
      }
    },
    displayFields() {
      return this.$store.getters["archiveTemp/displayFields"];
    },
    matchDisplayFields() {
      return this.$store.getters["archiveTemp/matchDisplayFields"];
    },
    displayDate() {
      if (this.matchDisplayFields.includes("date")) {
        return this.clip.date;
      } else {
        return false;
      }
    },
    teams() {
      if (this.matchDisplayFields.includes("teams")) {
        return true;
      } else {
        return false;
      }
    },
    competition() {
      if (this.matchDisplayFields.includes("competition")) {
        var id = this.$store.getters["matches/byId"](this.clip.match);
        if (id) {
          return id["competition"];
        } else {
          this.$store.dispatch("matches/fetchById", this.clip.match);
        }
      } else {
        return false;
      }
    },
    venue() {
      if (this.matchDisplayFields.includes("ground")) {
        var id = this.$store.getters["venues/byId"](this.clip.ground);
        if (id) {
          return id.name;
        } else {
          this.$store.dispatch("venues/fetchById", this.clip.ground);
        }
      } else {
        return false;
      }
    },
    format() {
      if (this.matchDisplayFields.includes("format")) {
        return this.$store.getters["matchFormats/formatById"](
          this.clip.cricketFormat
        )["name"];
      } else {
        return false;
      }
    },
    bowlingTeam() {
      return this.$store.getters["teams/byId"](this.clip.bowlingTeam);
    },
    selected() {
      let test = this.$store.getters["archiveTemp/eventState"](this.clip.id);
      return test ? true : false;
    },
    selectedClass() {
      return this.selected ? "bg-yellow" : "bg-white";
    },
  },
  mounted() {},
  components: {
    "match-look-up": MatchLookUp,
  },
  methods: {
    playerReadied(player) {
      // console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
      player.currentTime(this.clip.tcIn - 8);
    },
    selectMe() {
      this.$store.commit("archiveTemp/eventSelect", {
        id: this.clip.id,
        value: !this.selected,
      });
    },
  },
};
</script>

<style scoped>
.selector {
  cursor: pointer;
}
</style>