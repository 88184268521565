const payments = {
  firestorePath: 'payments',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'payments',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    payments: state => Object.values(state.data),
    byId: (state) => (id) => {
      return state.data[id]
    },
    byBudget: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.budgetId === id)
    },
  },
  mutations: {},
  actions: {},
}

export default payments