<template>
  <div class="row">
    <div class="col">
      <div class="card-wrapper">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-5">
                <h3>{{ status }}</h3>
              </div>
              <div class="col-4">
                <base-button
                  v-if="
                    file.status == 'signed' && user.roles.production_feedback
                  "
                  type="warning"
                  @click="recall"
                  ><i class="fa fa-ban mr-1"></i>Revoke sign-off</base-button
                >
              </div>
              <div class="col-3" v-if="file.hasPreview">
                <base-input class="mb-0">
                  <el-select
                    v-model="previewOrSource"
                    placeholder="Select"
                    size="mini"
                    @input="this.reloadVideo"
                  >
                    <el-option
                      v-for="option in previewOrSourceOptions"
                      :key="option"
                      :label="option"
                      :value="option"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div v-if="file.assetType === 'video'">
                  <video :id="file.id" controls crossorigin="crossorigin">
                    <source
                      :src="this.previewOrSourceSwitch"
                      type="video/mp4"
                    />
                    <track
                      v-if="file.subsFileID"
                      label="English"
                      kind="subtitles"
                      srclang="en"
                      :src="subsFile.sourceDownloadURL"
                      default
                    />
                    Your browser does not support the video element.
                  </video>
                </div>
                <div v-else>
                  <div
                    v-if="
                      file.sourceExtention === '.psd' &&
                      this.previewOrSource === 'Original Upload'
                    "
                  >
                    Can't display PSD files, switch to preview
                  </div>
                  <div v-else-if="file.sourceExtention === '.pdf'">
                    <pdf
                      :src="this.previewOrSourceSwitch"
                      @num-pages="pageCount = $event"
                      @page-loaded="currentPage = $event"
                      :page="currentPage"
                    ></pdf>
                    <div class="row mt-2" v-if="pageCount > 1">
                      <div class="col">
                        Page {{ currentPage }} of
                        {{ pageCount }}
                      </div>
                      <div class="col-auto">
                        <base-pagination
                          class="pagination-no-border"
                          v-model="currentPage"
                          per-page="1"
                          :total="pageCount"
                        >
                        </base-pagination>
                      </div>
                    </div>

                  </div>

                  <div v-else>
                    <img
                      :id="file.id"
                      :src="this.previewOrSourceSwitch"
                      class="imageFit"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    file.status == 'review' && user.roles.production_feedback
                  "
                >
                  <div class="mt-3">
                    <base-input>
                      <textarea
                        class="form-control"
                        v-model="temp"
                        box
                        placeholder="Add a new note"
                        type="text"
                        rows="4"
                      />
                    </base-input>
                  </div>
                  <div class="row mt--3">
                    <div class="col-6">
                      <base-checkbox
                        v-model="noteTimeCodeOn"
                        v-if="file.assetType === 'video'"
                      >
                        Add current timecode to note
                      </base-checkbox>
                    </div>
                    <div class="col-6">
                      <base-button
                        class="float-right"
                        size="sm"
                        type="primary"
                        @click="addNote(file.id)"
                        >Add Note</base-button
                      >
                    </div>
                  </div>
                  <div class="text-center mt-4">
                    <base-button type="warning" @click="submit"
                      ><i class="fa fa-comments mr-1"></i>
                      <span>Reject File</span></base-button
                    >
                    <base-button type="success" @click="signOff"
                      ><i class="ni ni-like-2 mr-1"></i>Sign Off</base-button
                    >
                  </div>
                </div>
                <div
                  class="text-center mt-4"
                  v-if="file.status == 'edit' && user.roles.production_feedback"
                >
                  <base-button type="primary" @click="recall"
                    ><i class="fa fa-undo mr-1"></i>Return to feedback
                    mode</base-button
                  >
                </div>
              </div>
              <div class="col-4 notesList" v-if="showNotes">
                <div class="row mb-4" v-if="file.status != 'review'">
                  <label class="col-6 form-control-label"
                    >Hide Completed Notes</label
                  >
                  <div class="col-6">
                    <base-switch
                      v-model="hideDoneNotes"
                      type="warning"
                      on-text="Yes"
                      off-text="No"
                    ></base-switch>
                  </div>
                </div>

                <div
                  v-if="
                    file.status === 'review' && !user.roles.production_feedback
                  "
                ></div>
                <div v-else>
                  <div v-for="(note, index) in notes" :key="index">
                    <NoteBlock
                      :note="note"
                      :delID="delID"
                      :fileStatus="file.status"
                      :assetType="file.assetType"
                      :feedbacker="user.roles.production_feedback"
                      @deleteNote="deleteNote(note.id)"
                      @jumpTo="goToVideoTime(file.id, note.seconds)"
                      @pdfPage="currentPage = $event"
                    ></NoteBlock>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div class="card-wrapper" v-if="user.roles.production_feedback">
            <div class="card">
              <div class="card-header">
                <h3>Feedbackers</h3>
              </div>
              <div class="card-body">
                <FeedbackersUpdate :file="file" :del="del"></FeedbackersUpdate>
              </div>
            </div>
          </div> -->
          <div class="card-wrapper" v-if="del.subsTrack">
            <div class="card">
              <div class="card-header">
                <h3>Subtitle Track</h3>
              </div>
              <div class="card-body">
                <subtitle-upload-wrapper
                  v-if="!file.subsFileID"
                  boxHeight="70px"
                  :videoFile="file"
                  :deliverable="del"
                  :production="production"
                  message="Add WebVTT Subtitle Track"
                ></subtitle-upload-wrapper>
                <div v-else>
                  <a :href="subsFile.sourceDownloadURL"
                      >  {{ subsFile.sourceFileNameAsUploaded
                  }}</a
                    >
                
                  <base-button class="float-right" type="danger" @click="deleteSubs"
                    >Delete</base-button
                  >
            
                </div>
              </div>
            </div>
          </div>
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3>Timeline</h3>
              </div>
              <div class="card-body">
                <div
                  class="timeline timeline-one-side"
                  data-timeline-content="axis"
                  data-timeline-axis-style="dashed"
                >
                  <div
                    v-for="(event, index) in file.events"
                    :key="index"
                    class="timeline-block"
                  >
                    <span
                      v-if="event.action == 'Uploaded'"
                      class="timeline-step"
                      :class="`badge-default`"
                    >
                      <i class="ni ni-cloud-upload-96"></i>
                    </span>
                    <span
                      v-if="event.action == 'Created'"
                      class="timeline-step"
                      :class="`badge-default`"
                    >
                      <i class="fa fa-cogs"></i>
                    </span>
                    <span
                      v-if="event.action == 'Pushed to Brightcove'"
                      class="timeline-step"
                      :class="`badge-success`"
                    >
                      <i class="fa fa-upload"></i>
                    </span>
                    <span
                      v-if="event.action == 'Submitted notes'"
                      class="timeline-step"
                      :class="`badge-info`"
                    >
                      <i class="fa fa-comments"></i>
                    </span>
                    <span
                      v-if="event.action == 'Recalled for review'"
                      class="timeline-step"
                      :class="`badge-warning`"
                    >
                      <i class="fa fa-undo"></i>
                    </span>
                    <span
                      v-if="event.action == 'Signed off'"
                      class="timeline-step"
                      :class="`badge-success`"
                    >
                      <i class="ni ni-like-2"></i>
                    </span>
                    <span
                      v-if="event.action == 'Seen'"
                      class="timeline-step"
                      :class="`badge-secondary`"
                    >
                      <i class="fa fa-eye"></i>
                    </span>
                    <div class="timeline-content">
                      <div class="d-flex pt-1">
                        <div>
                          <span class="text-muted text-sm font-weight-bold"
                            >{{ event.action }} by {{ event.user }} -
                            {{ event.date | dateAndTime }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card-wrapper">
            <div class="card">
              <div class="card-header">
                <h3>Distribute</h3>
              </div>
              <div class="card-body">
                <!-- <div v-if="file.status=='signed'" class="mb-2">
                 <base-button block type="default" @click="brandedModal = true"><i class="ni ni-single-copy-04 mr-1"></i>Add Watermark + Endboard</base-button>
                 <base-button block type="success" @click="publishModal = true"><i class="fa fa-satellite-dish mr-1"></i>Send To Brightcove</base-button>
                 </div>
                   -->

                <div class="row mb-2">
                  <div class="col-auto">Download link:</div>
                  <div class="col">
                    <a :href="file.sourceDownloadURL"
                      >{{ production.title }} - {{ del.title }} -
                      {{ file.version }}</a
                    >
                  </div>
                  <div class="col-auto">
                    <base-button type="info" size="sm" @click="copyLink">
                      <i class="ni ni-world-2 mr-1"></i>Copy to Clipboard
                    </base-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <!-- <a
                    download
                    class="btn btn-info btn-block"
                    :href="file.sourceDownloadURL"
                  >
                    <i class="fa fa-download mr-1"></i>Download File
                  </a> -->
                    <base-button
                      block
                      type="danger"
                      v-if="deleteable"
                      @click="deleteFile"
                    >
                      <i class="fa fa-trash mr-1"></i>Delete File
                    </base-button>
                    <!-- <base-button
                    block
                    type="secondary"
                    @click="createMetadata"
                  >
                    <i class="fa fa-info mr-1"></i>Get Metadata
                  </base-button> -->
                  </div>
                </div>
                <div class="row mt-4" v-if="(del.type === 'video' || del.type === 'Video') && file.status === 'signed'">
                  <div class="col">
                    <base-button block type="success" @click="brightcove">
                    Push to Brightcove
                    </base-button>
                  </div>
                  <div class="col">Pushed On:</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-wrapper" v-if="file.metadata">
            <div class="card">
              <div class="card-header">
                <h3>Metadata</h3>
              </div>
              <MetaDataDisplay :file="file"></MetaDataDisplay>
            </div>
          </div>
     
        </div>
      </div>
    </div>
    <!-- <div class="col-4" v-if="notes.length > 0">
      <div class="card-wrapper">
        <div class="card">
          <div class="card-header">
            <h3>
              Notes
              </h3>
              <small v-if="file.status == 'review' &&
              user.roles.production_feedback">
                (draft - visible to those with feedback permissions)</small
              >

          </div>
          <div
            class="card-body"
            v-if="
              file.status == 'review' &&
              user.roles.production_feedback
            "
          >
            <div v-for="(note, index) in notes" :key="index">
              <NoteBlock
                :note="note"
                :delID="delID"
                @deleteNote="deleteNote(note.id)"
                @jumpTo="goToVideoTime(file.id, note.seconds)"
              ></NoteBlock>
            </div>

          </div>
          <div class="card-body" v-if="file.status == 'edit'">
            <div v-for="(note, index) in notes" :key="index">
              <NoteDisplay
                :note="note"
                :delID="delID"
                @deleteNote="deleteNote(note.id)"
                @jumpTo="goToVideoTime(file.id, note.seconds)"
              ></NoteDisplay>
            </div>
          </div>
          <div class="card-body" v-if="file.status == 'signed'">
            <div v-for="(note, index) in notes" :key="index">
              <NoteSigned
                :note="note"
                :delID="delID"
                @deleteNote="deleteNote(note.id)"
                @jumpTo="goToVideoTime(file.id, note.seconds)"
              ></NoteSigned>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import NoteBlock from "./NoteBlock";
// import NoteDisplay from "./NoteDisplay";
// import NoteSigned from "./NoteSigned";
import MetaDataDisplay from "./MetaDataDisplay";
// import FeedbackersUpdate from "./FeedbackersUpdate";
import { Select, Option } from "element-ui";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";
import SubtitleUploadWrapper from "./SubtitleUploadWrapper.vue";

// js import
import swal from "sweetalert2";

// style import
import "sweetalert2/dist/sweetalert2.css";

import pdf from "vue-pdf";

export default {
  data() {
    return {
      temp: "",
      noteTimeCodeOn: true,
      publishModal: false,
      shareModal: false,
      previewOrSource: "Preview",
      previewOrSourceOptions: ["Preview", "Original Upload"],
      brandedModal: false,
      hideDoneNotes: false,
      brandingModel: {
        name: "",
        watermark: "",
        endslate: "",
        encodeProfile: "",
      },
      currentPage: 1,
      pageCount: 0,
    };
  },
  props: [
    "file",
    "fileID",
    "delID",
    "del",
    "isOwner",
    "platforms",
    "production",
  ],
  components: {
    NoteBlock,
    // NoteDisplay,
    // NoteSigned,
    MetaDataDisplay,
    // FeedbackersUpdate,
    [Select.name]: Select,
    [Option.name]: Option,
    SubtitleUploadWrapper,
    pdf,
  },
  computed: {
    notes() {
      let notes = this.$store.getters["notes/notesByFileID"](this.fileID);

      if (this.file.sourceExtention === ".pdf") {
        notes = notes.sort((a, b) => {
          if (a.pdfPage > b.pdfPage) {
            return 1;
          }
          if (a.pdfPage < b.pdfPage) {
            return -1;
          }
          if (!a.pdfPage) {
            return -1;
          }
          return 0;
        });
      }

      if (this.hideDoneNotes) {
        return notes.filter((b) => !b.done);
      } else {
        return notes;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    showNotes() {
      //to decide if to show the notes block
      let notes = this.$store.getters["notes/notesByFileID"](this.fileID);
      if (notes.length > 0) {
        //check if there are notes, if not hide it
        if (this.file.status == "review") {
          // check to see if the file is in review mode - if not, show to all users
          if (this.user.roles.production_feedback) {
            //check to see if a feedbacker. if so, show - if not, hide
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    deleteable() {
      let notes = this.$store.getters["notes/notesByFileID"](this.fileID);
      //logic to see if the user can delete a file
      if (this.file.status == "signed") {
        // no one can delete signed off clips
        return false;
      } else if (this.user.roles.production_feedback && notes.length > 0) {
        // clip has notes and user is a feedbacker
        return true;
      } else if (notes.length > 0) {
        //  clip has notes and user is not a feedbacker
        return false;
      } else {
        true; //so clip is not signed off, and has no notes
      }
    },
    previewOrSourceSwitch() {
      if (this.file.hasPreview && this.previewOrSource === "Preview") {
        return this.file.previewDownloadURL;
      } else {
        return this.file.sourceDownloadURL;
      }
    },
    status() {
      switch (this.file.status) {
        case "signed":
          return "Signed Off";
          break;
        case "review":
          return "Awaiting Feedback";
          break;
        case "edit":
          return "Sent back to Editor";
          break;
        default:
          return "Unknown status";
      }
    },
    subsFile() {
      return this.$store.getters["files/fileById"](this.file.subsFileID);
    },
  },
  mounted() {
    if (this.file.sourceExtention === ".psd") {
      this.previewOrSource = "Preview";
    } else if (this.file.assetType === "image") {
      this.previewOrSource = "Original Upload";
    } else {
      this.previewOrSource = "Preview";
    }
  },
  methods: {
    createMetadata() {
      //this is just for testing
      this.$store.dispatch("files/createMetadata", this.file);
    },
    goToVideoTime(video, time) {
      let myvideo = document.getElementById(video);
      myvideo.currentTime = time;
    },
    reloadVideo() {
      if (this.file.assetType === "video") {
        let myvideo = document.getElementById(this.fileID);
        myvideo.load();
      }
    },
    addFileEvent(action, user) {
      var payload = {};
      payload.action = action;
      payload.user = user;
      payload.date = new Date();

      this.$store.dispatch("files/patch", {
        id: this.file.id,
        events: arrayUnion(payload),
      });
    },
    submit() {
      //set file to edit mode
      this.$store.dispatch("files/patch", {
        id: this.fileID,
        status: "edit",
      });
      // add note to file log
      this.addFileEvent("Submitted notes", this.user.name);
      // change deliverable setting
      this.$store.dispatch("deliverables/patch", {
        id: this.delID,
        state: "reworking",
      });
      //send slack message
      var payload = {};
      payload.deliverable = this.del;
      payload.production = this.production;
      payload.version = this.file.version;
      this.$store.dispatch("files/feedbackToSlack", payload);
    },
    signOff() {
      // add note to file log
      this.addFileEvent("Signed off", this.user.name);
      //set file status
      this.$store.dispatch("files/patch", {
        id: this.fileID,
        status: "signed",
      });
      // change deliverable setting
      this.$store.dispatch("deliverables/patch", {
        id: this.delID,
        state: "signed",
      });
      var payload = {};
      payload.deliverable = this.del;
      payload.production = this.production;
      payload.version = this.file.version;
      this.$store.dispatch("files/signOffToSlack", payload);
      // this.$router.push(`/brief/${this.del.production}`)
    },
    recall() {
      // add note to file log
      this.addFileEvent("Recalled for review", this.user.name);
      // //set file status
      this.$store.dispatch("files/patch", {
        id: this.fileID,
        status: "review",
      });
      // change deliverable setting
      this.$store.dispatch("deliverables/patch", {
        id: this.delID,
        state: "feedback",
      });
    },
    copyLink() {
      const el = document.createElement("a");
      el.href = this.file.sourceDownloadURL;
      el.text =
        this.production.title +
        " - " +
        this.del.title +
        " - v" +
        this.file.version;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      // console.log(el)
      // el.select();
      // document.execCommand("copy");
      window.getSelection().removeAllRanges();

      var range = document.createRange();
      range.selectNode(el);
      window.getSelection().addRange(range);

      // [5.1]
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$notify({
        verticalAlign: "bottom",
        type: "warning",
        horizontalAlign: "right",
        message: "Link copied to clipboard",
      });
      this.shareModal = false;
    },
    deleteFile() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes, delete it.",
        })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch("files/delete", this.file.id);
            swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
    },
    deleteSubs() {
      this.$store.dispatch("files/patch", {id: this.file.id, subsFileID: ''});
      this.$store.dispatch("files/delete", this.subsFile.id);
    },
    addNote(vid) {
      console.log(vid);
      // check if this is a video
      let noteTC = false;
      var payload = {};
      payload.file = vid;
      payload.text = this.temp;
      payload.delID = this.delID;
      payload.done = false;
      payload.tc = false;
      payload.seconds = 0;
      payload.pdf =
        this.file.sourceExtention === ".pdf" && this.pageCount > 1
          ? true
          : false;
      payload.pdfPage = this.currentPage;
      payload.author = this.user.name;
      if (this.file.assetType === "video") {
        if (this.noteTimeCodeOn) {
          let myvideo = document.getElementById(vid);
          myvideo.pause();
          payload.tc = true;
          payload.seconds = myvideo.currentTime;
        }
      }
      //        console.log(payload)
      this.$store.dispatch("notes/insert", payload);
      this.temp = "";
    },
    deleteNote(noteID) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Not undo-able.",
          type: "warning",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes, delete it.",
        })
        .then((result) => {
          if (result.value) {
            // var payload = {};
            // payload.id = noteID;
            // payload.delID = this.delID;
            //          console.log(payload)
            this.$store.dispatch("notes/delete", noteID);
          }
        });
    },
    brightcove() {
      const data = {
        deliverable: this.del.id,
        uploadName: `${this.production.title} - ${this.del.title} - v${this.file.version}`,
        user: this.user.id,
        file: {
          sourceBucket: this.file.sourceBucket,
          sourcePath: this.file.sourcePath,
          id: this.file.id,
        },
      };
      // this.$store.dispatch("deliverables/patch", { id: this.del.id });
      this.$store.dispatch("files/sendToBC", data);
      // add note to file log
      this.addFileEvent("Pushed to Brightcove", this.user.name);
      this.$notify({
        message: "Submitted",
        timeout: 4000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
  },
};
</script>

<style>
video {
  width: 100%;
  max-height: 90vh;
}

.el-select--mini .el-input--mini .el-input__inner {
  height: calc(1em + 1rem + 2px);
}

.imageFit {
  margin-left: auto;
  margin-right: auto;
  max-height: 90vh;
  max-width: 100%;
  display: block;
}

.notesList {
  max-height: 800px;
  overflow-y: scroll;
}
</style>
