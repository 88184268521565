const shifts = {
  firestorePath: 'shifts',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'shifts',
  statePropName: 'data',
  namespaced: true, // automatically added


  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    byId: (state) => (id) => {
      return state.data[id]
    },
    byResourceId: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.resourceId === id)
    },
    byBriefId: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.productionId === id && b.type === 'production')
    },
    byBudget: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.budget === id)
    },
    unAtributed: (state) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.invoiceable && b.budget === '')
    },
    shifts: (state, getters, rootState, rootGetters) => {
      var rotas = []
      const briefs = rootState.briefs.data
      const resourcesData = rootGetters['resources/active']
     let resources = []
     resourcesData.forEach(a => {
      resources.push(a.id)
     })
      var stateList = Object.values(state.data)
      stateList.forEach(function (entry) {
        entry.shiftId = entry.id
             entry.constraint = {
               resourceIds: resources
             }
             if(entry.manualCost){
              if (!entry.title.includes('£')){
                entry.title = entry.title + ' - £' + entry.cost
              }
            }
            switch (entry.days) {
              case 0.25:
                entry.className = 'quarterDays'
                break
              case 0.5:
                entry.className = 'halfDays'
                break
              case 0.75:
                entry.className = 'threeQuarterDays'
                break
              default:
                entry.className = 'fullDays'
                break
          }
        // if (entry.colour) {
        //   entry.backgroundColor = entry.colour
        // }

        if (entry.type === 'unavailable') {
          entry.className = 'unavailable'
        }
        if (entry.type === 'holiday') {
          entry.className = 'holiday'
        }

        rotas.push(entry)
      })

      // var shootList = Object.values(rootState.shoots.data)
      // shootList.forEach(function (entry) {
      //   // entry.shiftId = entry.id
      //   entry.title = entry.prodTitle + ' - ' + entry.shootTitle

      //     entry.constraint = {
      //       resourceIds: ["shoots"]
      //   }

      //   if ( entry.shootStatus === 'pencilled') {
      //     entry.className = 'pencilled'
      //   }
      //   if ( entry.shootStatus === 'confirmed') {
      //     entry.className = 'confirmed'
      //   }
      //   if ( entry.shootStatus === 'cancelled') {
      //     entry.className = 'cancelled'
      //   }
      //   rotas.push(entry)
      // })


      return rotas
    }
  },
  mutations: {},
  actions: {},
}

export default shifts
