const billings = {
  firestorePath: 'billings',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'billings',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    billings: state => Object.values(state.data).sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }
      if (a.label < b.label) {
        return -1
      }
      if (!a.label) {
        return -1
      }
      return 0
    }),
    byId: (state) => (id) => {
      return state.data[id]
    },
    active: (state) => {
      let b = Object.values(state.data)
      b = b.filter(f => (f.active))
      return b.sort((a, b) => {
        if (a.label > b.label) {
          return 1
        }
        if (a.label < b.label) {
          return -1
        }
        if (!a.label) {
          return -1
        }
        return 0
    })
  }
  },
  mutations: {},
  actions: {},
}

export default billings