<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row">
        <div class="col">
          <stats-card
            title="Awaiting Feedback"
            type="gradient-orange"
            :sub-title="feedbackCount"
            icon="fa fa-comments"
          >
          </stats-card>
        </div>
        <div class="col">
          <stats-card
            title="Overdue"
            type="gradient-red"
            :sub-title="overdueCount"
            icon="fa fa-exclamation"
          >
          </stats-card>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <button-radio-group
            :options="radioOptions"
            button-classes="btn-group-colors btn-secondary thirdWidth"
            v-model="buttonRadioGroup"
          >
          </button-radio-group>
        </div>
        <div class="col-4" v-if="buttonRadioGroup === 'department'">
          <base-input>
            <el-select
              v-model="departmentFilter"
              placeholder="Department Filter"
              clearable
              @change="filterPersist()"
            >
              <el-option
                v-for="option in departments"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              >
                <span style="float: left">{{ option.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  option.size
                }}</span>
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9" v-if="isLoaded">
      <div class="row">
        <div class="col">
          <ul class="list-group">
            <li
              class="list-group-item smaller-text"
              v-for="del in delPaginator[pagination.default - 1]"
              :key="del.id"
            >
              <div class="row">
                <div class="col">
                  <worklist-card
                    :del="del"
                    :previews="previews"
                  ></worklist-card>
                </div>
              </div>
            </li>
          </ul>

          <base-alert class="mt-4" type="default" v-if="emptyQueue && isLoaded">
            <span class="alert-inner--icon"
              ><i class="fas fa-glass-cheers"></i
            ></span>
            <span class="alert-inner--text"
              ><strong> Cheers!</strong> Nothing to feedback</span
            >
          </base-alert>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col"></div>
        <div class="col-auto">
          <base-pagination
            :page-count="delPaginator.length"
            v-model="pagination.default"
          ></base-pagination>
        </div>
        <div class="col"></div>
      </div>
    </div>
    <div v-else>
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>
<script>
import WorklistCard from "./Components/WorklistCard";

export default {
  name: "feedback",
  components: {
    "worklist-card": WorklistCard,
  },
  data() {
    return {
      departmentFilter: "",
      radioOptions: [
        { value: "mine", label: "My Queue" },
        { value: "all", label: "Show All" },
        { value: "department", label: "By Department" },
      ],
      buttonRadioGroup: "mine",

      previews: true,
      pagination: {
        default: 1,
        chunkSize: 10,
      },
    };
  },
  computed: {
    emptyQueue() {
      if (this.deliverables.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    allProds() {
      return this.$store.getters["briefs/productions"];
    },
    allDels() {
      return this.$store.getters["deliverables/dels"];
    },
    deliverables() {
      if (this.isLoaded) {
        let dels = this.$store.getters["deliverables/delsMerged"];
        // let prods = this.$store.getters["briefs/asObj"];

        // dels.forEach((del) => {
        //   del.productionObject = prods[del.production];
        // });

        // dels = dels.filter((p) => !p.productionObject.completed);

        if (this.buttonRadioGroup === "department") {
          if (this.departmentFilter !== "") {
            const result = dels.filter(
              (p) => p.department === this.departmentFilter
            );
            dels = result;
          }
        }
        //filter for feedback
        let status = dels.filter((p) => p.state === "feedback");

        if (this.buttonRadioGroup === "mine") {
          status = status.filter((del) => del.owners.includes(this.user.id));
          let legacy = status.filter(
            (del) => !del.hasOwnProperty("reviewStatus")
          );
          let reviewStatus = status.filter((del) =>
            del.hasOwnProperty("reviewStatus")
          );
          let noReviewStatus = reviewStatus.filter(
            (del) => !del.reviewStatus.hasOwnProperty(this.user.id)
          );
          status = noReviewStatus.concat(legacy);
        }

        // let s = status.filter((o) =>
        //   o.combiTitle.toLowerCase().includes(this.search.toLowerCase())
        // );
        let s = status.slice().sort((a, b) => {
          if (a.sortCreated > b.sortCreated) {
            return 1;
          }
          if (a.sortCreated < b.sortCreated) {
            return -1;
          }
          if (!a.sortCreated) {
            return -1;
          }
          return 0;
        });
        return s;
      } else {
        return [];
      }
    },
    delPaginator() {
      let s = this.deliverables;
      let pages = [];
      var i,
        j,
        temparray,
        chunk = this.pagination.chunkSize;
      for (i = 0, j = s.length; i < j; i += chunk) {
        temparray = s.slice(i, i + chunk);
        pages.push(temparray);
      }
      return pages;
    },
    paginatorLength() {
      return this.delPaginator.length;
    },
    departments() {
      if (this.isLoaded) {
        let pA = this.deliverables;
        let arr = [];
        pA.forEach(function (entry) {
          arr.push(entry.department);
        });
        var counts = {};

        for (var i = 0; i < arr.length; i++) {
          var num = arr[i];
          counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        let end = [];

        for (const [key, value] of Object.entries(counts)) {
          end.push({
            label: `${key}`,
            size: `${value}`,
            value: `${key}`,
          });
        }
        return end;
      }
    },
    feedbackCount() {
      const dels = this.deliverables;
      let count = dels.filter((p) => p.state === "feedback");
      return count.length.toString();
    },
    overdueCount() {
      const dels = this.deliverables;
      let count = dels.filter((p) => p.overdue === true);
      return count.length.toString();
    },
  },
  methods: {
    filterPersist() {
      localStorage.worklistDepartmentFilter = this.departmentFilter;
      localStorage.worklistMine = this.mine;
    },
  },
  beforeMount() {
    this.$store.commit("deliverables/RESET_VUEX_EASY_FIRESTORE_STATE");
    this.$store.commit("user/setLoading", true);
    // this.$store.dispatch("briefs/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store
      .dispatch("deliverables/fetchAndAdd", {
        where: [["state", "==", "feedback"],["active",'==',true]],
        limit: 0,
      })
      .then(() => {
        this.$store.commit("user/setLoading", false);
      });
  },

  mounted() {
    if (localStorage.worklistDepartmentFilter) {
      this.departmentFilter = localStorage.worklistDepartmentFilter;
    }
    if (localStorage.worklistMine) {
      this.mine = JSON.parse(localStorage.worklistMine);
    }
  },
};
</script>
<style >
.thirdWidth {
  width: 33.33%;
}
</style>
