<template>
  <div>
    <div class="row">
      <span class="col m-xy-auto">
        <a :href="`/#/brief/${brief.id}`">
          {{ brief.title }}
        </a>
      </span>
       </div>
    <div class="row">
      <div class="col m-xy-auto">
        <!-- <badge type="primary" class="col m-xy-auto">   </badge> -->

        <el-tooltip content="Deliverables Completed" placement="top">
          <base-progress
            class="mt-3"
            :showLabel="true"
            type="primary"
            :height="8"
            :value="(brief.signedDels / brief.totalDels) * 100"
            :label="`${brief.signedDels} out of ${brief.totalDels}`"
          ></base-progress>
        </el-tooltip>
      </div>



      <div class="col m-xy-auto">
        <div v-if="brief.nextEta">
          <i class="fa fa-plane-arrival mr-1"></i>
          {{ brief.nextEta | dateTime }}
        </div>
        <div v-if="brief.nextDeadline">
          <i class="fa fa-clock mr-1"></i> {{ brief.nextDeadline | dateTime }}
        </div>
        <!-- <div v-else-if="prod.updated_at">
                     Updated: {{ prod.updated_at | dateTime }}
                    </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["briefID", "index"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    goToBrief() {
      return `/#/brief/${this.brief}`;
    },
        brief() {
      return this.$store.getters["briefs/briefById"](this.briefID);
    },
  },
  components: {},
  beforeMount() {},
  methods: {},
};
</script>

<style>
.smaller-text {
  font-size: 0.9em;
}

.overdue {
  color: tomato;
}

.halfBoxRight {
  margin-top: 8px;
}

.worklistIcon {
  font-size: 1.2em;
}

.productionTitle {
  font-weight: 600;
  margin-bottom: 8px;
}
.department {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 0.9em;
  font-weight: 100;
}
.deadline {
  margin-top: 8px;
}
.m-xy-auto {
  margin-top: auto;
  margin-bottom: auto;
}
</style>