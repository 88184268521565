const preApprovedUsers = {
  firestorePath: 'config/default/preApprovedUsers',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'preApprovedUsers',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    users: state => {
      return state.data
    }
  },
  mutations: {},
  actions: {


  },
}

export default preApprovedUsers