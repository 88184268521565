<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col mb-4">

          <a :href="`/#/brief/${production.id}`"
            class="h2 navbar-link text-white d-inline-block mb-0">{{ production.title }}</a>
          <h6 class="h2 d-inline-block text-white mb-0">&nbsp;> {{ deliverable.title }}</h6>
        </div>
        <div class="col-auto" v-if="deliverable.state!=='signed'"><base-button type="secondary" @click="dialog=true">Upload New Version</base-button></div>
        </div>
     <!-- <div class="col-2">
                  <base-input>
                      <el-select
                        v-model="v"
                      >
                        <el-option
                          v-for="file in files"
                          :key="file.id"
                          :label="'v' + file.version"
                          :value="file.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                </div>  -->
        <!-- <div class="col"> 
                    <dl class="row">
                      <dt class="col-sm-4">Type</dt>
                      <dd class="col-sm-8">{{ deliverable.type }}</dd>

                      <dt class="col-sm-4">Format</dt>
                      <dd class="col-sm-8">{{ deliverable.format }}</dd>
                      <dt class="col-sm-4" v-if="deliverable.desiredDuration">
                        Desired Duration
                      </dt>
                      <dd class="col-sm-8" v-if="deliverable.desiredDuration">
                        {{ deliverable.desiredDuration }}
                      </dd>
                      <dt class="col-sm-4">Deadline</dt>
                      <dd class="col-sm-8">{{ deliverable.deadline }}</dd>
                      <dt class="col-sm-4">Status</dt>
                      <dd class="col-sm-8">{{ state.display }}</dd>
                    </dl>
                  </div> -->

        <modal :show.sync="dialog" size="lg" modal-classes="modal">
          <deliverable-upload-wrapper boxHeight="160px" message="Upload New Version Here" :deliverable="deliverable"
            :production="production"></deliverable-upload-wrapper>
        </modal>




        <!-- <div class="row">
                  <div class="col" v-if="deliverable.state == 'signed'">
                    <base-input label="Reporting">
                      <base-button type="default" @click="reportingModal = true"
                        >Add Platform IDs</base-button
                      >
                    </base-input>
                  </div>
                </div> -->

        <!-- <modal :show.sync="reportingModal" size="lg">
                  <h6
                    slot="header"
                    class="modal-title"
                    id="modal-title-default"
                  >
                    Published IDs
                  </h6>
                  <div class="row">
                    <div
                      class="col"
                      v-for="(platform, index, key) in deliverable.publishedIDs"
                      :key="key"
                    > -->
        <!--           for the reporting IDs        -->
        <!-- <base-input
                        label="Platform"
                        disabled
                        :value="config.platforms[platform.platform]['display']"
                      >
                      </base-input>

                      <base-input
                        label="Platform ID"
                        v-model="platform.id"
                        disabled
                      />
                      <base-input
                        label="Publish Date"
                        v-model="platform.date"
                        disabled
                      />
                      <base-button
                        size="lg"
                        block
                        type="warning"
                        @click="deleteReportingID(platform)"
                        >Delete</base-button
                      >
                    </div>
                    <div class="col"> -->
        <!--           for the reporting IDs        -->
        <!-- <base-input label="Platform">
                        <el-select
                          v-model="newID.platform"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="option in config.platforms"
                            :key="option.id"
                            :label="option.display"
                            :value="option.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>

                      <base-input label="Platform ID" v-model="newID.id" />
                      <el-tooltip
                        content="Optional - used to override Day 1 on reports."
                        placement="bottom"
                      >
                        <base-input label="Publish Date" v-model="newID.date" />
                      </el-tooltip>
                      <base-button
                        size="lg"
                        block
                        type="success"
                        @click="addReportingID"
                        >Add</base-button
                      >
                    </div>
                  </div>
                </modal>  -->
        <!-- <div class="row" v-if="v">
        <div class="col">
<Feedback
                class="mt-4"
                :file="selectedFile"
                :fileID="selectedFile.id"
                :delID="delID"
                :del="deliverable"
                :isOwner="isOwner"
                :platforms="deliverable.platforms"
                :production="production"
              ></Feedback>
    
        </div>
      </div> -->


        <div class="row" v-if="production">
          <div class="col">
            <tabs tabNavClasses="nav-pills">
              <tab v-for="file in files" :key="file.sourcePath" :id="file.sourcePath">
                <span slot="title">
                  v{{ file.version }}
                  <span v-if="file.branded">{{ file.brandedTag }}</span>
                </span>
                <Feedback class="mt-3" :file="file" :fileID="file.id" :delID="delID" :del="deliverable" :isOwner="isOwner"
                  :platforms="deliverable.platforms" :production="production"></Feedback>
              </tab>
            </tabs>
          </div>
        </div>
      </div>
    </div>    
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import router from "@/routes/router";
import DeliverableUploadWrapper from "../Briefs/Components/DeliverableUploadWrapper";
import Feedback from "./Components/FeedbackWidget";
import tabs from "@/components/Tabs/Tabs";
import tab from "@/components/Tabs/Tab";

// js import
import swal from "sweetalert2";

// style import
import "sweetalert2/dist/sweetalert2.css";

// import firebase from 'firebase'

export default {
  name: "Deliverable",
  data: function () {
    return {
      dialog: false,
      platform: "",
      date: "",
      menu: false,
      deadlinePicker: false,
      platformid: "",
      newTitle: "",
      reportingModal: false,
      v: false,
      newID: {
        platform: "",
        id: "",
        date: "",
      },
    };
  },
  components: {
    flatPicker,
    "deliverable-upload-wrapper": DeliverableUploadWrapper,
    Feedback,
    tabs,
    tab,
  },
  watch: {
    currentFileID() {
      this.v = this.currentFileID
    }
  },
  computed: {
    delID() {
      return this.$route.params.id;
    },
    currentFileID() {
      if (this.deliverable) {
        return this.deliverable.currentFileID
      }
    },
    selectedFile() {
      return this.$store.getters["files/byId"](this.v)
    },
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    admins() {
      const result = this.users.filter(
        (usr) => usr.roles.production_feedback
      );
      return result;
    },
    // isAdmin() {
    //   if (this.user.roles.production_feedback) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    config() {
      return this.$store.getters["config/config"];
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    state() {
      const state = this.deliverable.state;
      const statuses = this.statuses;
      let s = statuses.filter((r) => r.key === state)[0];
      return s;
    },
    deliverable() {
      return this.$store.getters["deliverables/delById"](this.$route.params.id);
    },
    production() {
      return this.$store.getters["briefs/briefById"](this.$route.params.prodId);
    },
    isOwner() {
      const feedbackers = this.deliverable.owners;
      const userID = this.user.id;
      return feedbackers.includes(userID);
    },
    files() {
      return this.$store.getters["files/filesByDelId"](this.$route.params.id)
        .slice()
        .sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    },
    filesLength() {
      return this.files.length;
    },
    allowedFileTypes() {
      if (this.deliverable) {
        const delType = this.deliverable.type;
        const allTypes = this.config.delTypes;
        let thisType = allTypes.filter((f) => f.display === delType);
        return thisType[0].allowedFiles;
      }
    },
  },


  beforeMount() {

    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store
      .dispatch("deliverables/fetchById", this.$route.params.id)
      .catch(console.error);
    this.$store
      .dispatch("briefs/fetchById", this.$route.params.prodId)
      .catch(console.error);
    this.$store.dispatch("files/openDBChannel", {
      where: [["delID", "==", this.$route.params.id]],
    });
    this.$store.dispatch("notes/openDBChannel", {
      where: [["delID", "==", this.$route.params.id]],
    });
    // this.v = this.deliverable.currentVersion
  },
  methods: {
    uploaded() {
      //router.push(`/brief/${this.$route.params.prodId}`)
    },
  },
};
</script>
<style>
.nav-pills .nav-link {
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}
</style>
