<template>
  <div>
    <base-header class="pb-6 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <div class="container mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <!--  <div class="card-header">
           <el-steps :active="step" finish-status="success" align-center>
                  <el-step title="Outline"></el-step>
                  <el-step title="Description"></el-step>
                  <el-step title="Files / References"></el-step>
                  <el-step title="Distribution"></el-step>
                  <el-step title="Budget / People"></el-step>
                  <el-step title="Deliverables"></el-step>
                </el-steps>
                <base-button type="primary" @click="step--">Back</base-button>
                <base-button type="primary" @click="step++">Next</base-button> 
               
              </div> -->
              <!-- Card body -->
              <div class="card-body">

                <form>
                  <base-input label="Project Title" v-model="title" />
                  <base-input label="Department" v-if="title">
                    <el-select v-model="department" filterable placeholder="Select">
                      <el-option v-for="option in config.departments" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Template" v-if="department">
                    <el-select v-model="template" filterable placeholder="Select">
                      <el-option value="Content">Content or advertising
                      </el-option>
                      <el-option value="Licencing">Licenced products
                      </el-option>
                      <el-option value="In-Stadia">In-Stadia media
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Brands" v-if="template">
                    <el-select v-model="brands" multiple filterable placeholder="Select">
                      <el-option v-for="option in config.brand" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                  <div class="form-group row" v-if="brands.length > 0">
                    <label class="col-md-6 form-control-label"
                      >Is this project for/with an ECB partner? (commercial, FCC or charity)</label
                    >
                    <div class="col-md-6">
                      <base-radio v-model="commercial"
                                  name=true
                                  class="mb-3">
                        Yes
                      </base-radio>
                      <base-radio v-model="commercial"
                                  name=false
                                  class="mb-3">
                        No
                      </base-radio>
                      </div>
                      </div>
                      <base-input label="Partner" v-if="commercial === 'true'">
                    <el-select v-model="partner" filterable clearable placeholder="Select">
                      <el-option v-for="option in config.partners" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>

                 
               
                      <!-- <base-input label="Where" v-if="channelsKnown"> <el-select v-model="where" multiple filterable placeholder="Select">
                      <el-option v-for="option in channels" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input> -->
                  <!-- <base-input label="Type">
                    <el-select v-model="what" filterable placeholder="Select">
                      <el-option value="Task">
                        <span style="font-weight: bold;">Task Brief</span>
                        <span style="color: #8492a6"> - BAU</span>
                      </el-option>
                      <el-option value="Creative"> <span style="font-weight: bold;">Creative Brief</span>
                        <span style="color: #8492a6"> - Creative Solution to challenge required</span>
                      </el-option>

                    </el-select>
                  </base-input> -->
<!-- 
                  <base-input label="Description">
          
                    <html-editor v-model="description"></html-editor>
                  </base-input> -->
                </form>
                <base-input class="select-warning" label="Point of Contact">
                  <el-select
                    class="select-warning"
                    v-model="contact"
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      class="select-warning"
                      v-for="option in this.admins"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <base-input
                  class="select-warning"
                  label="Default Feedbackers for Deliverables"
                >
                  <el-select
                    class="select-warning"
                    v-model="owners"
                    multiple
                    filterable
                    placeholder="Select"
                  >
                    <el-option
                      class="select-warning"
                      v-for="option in this.admins"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <div class="row justify-content-md-center" v-if="formIsValid">

                  <div class="col-auto">
                    <base-button @click="newProduction()" class="px-3" size="lg" type="primary"> 
                    <!-- <i
                        class="ni ni-curved-next"></i> -->
                      Continue
                    </base-button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>


    </div>
  </div>
</template>
<script>
// import {

//   Steps,
//   Step,

// } from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import HtmlEditor from "@/components/Inputs/HtmlEditor";


export default {
  name: 'new-brief',
  data() {
    return {
      title: '',
      department: '',
      template: '',
      commercial: null,
      // description: '',
      brands: [],
      partner: '',
      owners: [],
      contact: '',
      // channelsKnown: null,
      // deliverablesKnown: null,
      // messagingKnown: null,
      // intention: '',
      // intentionDetail: '',
      // audience: '',
      goals: ['Inform', 'Engage', 'Take action'],
      }
  },
  components: {
    // [Steps.name]: Steps,
    // [Step.name]: Step,
    flatPicker,
    HtmlEditor,
  },
  computed: {
    formIsValid() {

        return this.title !== '' && this.department !== '' && this.brands.length > 0 && this.commercial !== null && this.contact && this.owners

    },
    user() {
      return this.$store.getters['user/user']
    },
    users() {
      return this.$store.getters['users/users']
    },
    config() {
      return this.$store.getters['config/config']
    },
    admins() {
      const result = this.users.filter(usr => usr.roles.production_feedback)
      return result
    }
  },
  methods: {
    // next() {
    //   return this.step++
    // },
    newProduction() {
      // if (!this.formIsValid) {
      //   return
      // }
      var title = this.title
      const production = {
        template: this.template,
        title: title,
        department: this.department,
        authorName: this.user.name,
        status: 'Draft',
        brands: this.brands,
        contact: this.contact,
        owners: this.owners,
        collections: [],
        completed: false,
        partner: this.partner
      }

production.commercial = this.commercial === 'true' ? true : false


      // if (this.template === 'Content') {
      //   production.messagingKnown = this.messagingKnown === 'true' ? true : false
      //   production.channelsKnown = this.channelsKnown === 'true' ? true : false
      //   production.deliverablesKnown = this.deliverablesKnown === 'true' ? true : false
      //   production.intention = this.intention
      //   production.intentionDetail = this.intentionDetail
      //   production.audience = this.audience
      // }

      this.$store.dispatch('briefs/insert', production)
        .then((data) => {
          console.log(data)
          this.$router.push('/brief/' + data)
        })
    }
  },
  beforeMount() {
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
  },
  watch: {
    commercial(val) {
      // resets partner field is commercial is switched off
      if (val === false) {
        this.partner = ''
      }
    }
  }
}

</script>
<style>
.form-control {
  color: #606266;
}

.el-step .is-success {
  color: #5e72e4;
  border-color: #5e72e4
}
</style>
