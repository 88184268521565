<template>
  <div>
    <form class="new-event--form" @submit.prevent="attach">
      <base-input label="Production">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="brief"
          filterable
          remote
          :remote-method="search"
          :loading="loading"
          placeholder="Select here"
        >
          <el-option
            v-for="prod in searchResults"
            :key="prod._source.id"
            :label="prod._source.department + ': ' + prod._source.title"
            :value="prod._source.id"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-checkbox class="mt--3 mb-4" v-model="showCompletedProductions">
        Include Completed Productions
      </base-checkbox>

    
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <button
        type="submit"
        class="btn btn-primary new-event--add"
        @click="attach()"
      >
        Attach
      </button>
      <!-- <button type="warning" class="btn btn-warning new-event--add" @click="saveEvent('unavailable')">Mark as Unavailable</button> -->
   
      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Firebase } from "@/firebase";

export default {
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
     brief: ''
    };
  },
  props: ["inspiration"],
  computed: {
    // productions() {
    //   var newArray = this.$store.getters["briefs/productions"];
    //   var filteredOnce = newArray;
    //   if (!this.showCompletedProductions) {
    //     filteredOnce = filteredOnce.filter(function (el) {
    //       return !el.completed;
    //     });
    //   }
    //   return filteredOnce;
    // },
    production() {
      return this.searchResults.filter(
        (a) => a._id === this.model.production
      )[0]["_source"];
    },
   
  },
  mounted() {
    this.search();
  },
  methods: {
    closeModals() {
      this.brief = ''
      this.$emit("closeModals");
    },
    attach() {
      let payload = {}
      payload.id = this.inspiration.id
      if (this.inspiration.briefs) {
        payload.briefs = this.inspiration.briefs
      } else {
        payload.briefs = {}
      }
      payload.briefs[this.brief] = true
      this.$store.dispatch("inspirations/patch", payload);
      this.closeModals();
    },
    async search(query) {
      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
                must_not: [{ match: { status: "Draft" } }],
              },
            },
          },
        },
      };

      if (!this.showCompletedProductions) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (query) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: query, fuzziness: "AUTO" } },
        });
      }

      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
};
</script>

