<template>
  <div>
    <form class="new-event--form">
      <base-input label="Production" v-if="!isBriefForm">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shift.productionId"
          filterable
          remote
          :remote-method="search"
          :loading="loading"
          placeholder="Select here"
          @change="changeProduction($event)"
        >
          <el-option
            v-for="prod in searchResults"
            :key="prod._source.id"
            :label="prod._source.department + ': ' + prod._source.title"
            :value="prod._source.id"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-checkbox
        class="mt--3 mb-4"
        v-model="showCompletedProductions"
        v-if="!isBriefForm"
      >
        Include Completed Productions
      </base-checkbox>

      <base-input label="Duration" default="1" v-if="!isBriefForm">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shift.days"
          filterable
          placeholder="Select here"
          @change="changeField($event, 'days')"
        >
          <el-option
            v-for="option in durations"
            :key="option.display"
            :label="option.display"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </base-input>

      <base-input label="Billing">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shift.billing"
          filterable
          placeholder="Select here"
          @change="changeBilling($event)"
        >
          <el-option
            v-for="prod in this.billings"
            :key="prod.id"
            :label="prod.label"
            :value="prod.id"
          >
          </el-option>
        </el-select>
      </base-input>

      <div class="form-control-label"  v-if="shift.manualCost === false" >Cost: £{{ shift.cost }}</div>

      <base-input
            v-else
            label="Manual Cost"
            v-model="shift.cost"
            type="number"
            @input="changeField($event, 'cost')"
          ></base-input>

          <base-checkbox class="mb-4" v-model="shift.manualCost" @input="changeField($event, 'manualCost')">
            Over-ride default cost
          </base-checkbox>


      <base-input label="Budget" v-if="shift.invoiceable" class="form-control-row">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shift.budget"
          filterable
          placeholder="Select here"
          @change="changeField($event, 'budget')"
        >
          <el-option
            v-for="prod in this.budgets"
            :key="prod.id"
            :label="prod.name"
            :value="prod.id"
          >
          </el-option>
        </el-select>
      </base-input>
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <base-button
        v-if="this.shift.days > 1"
        native-type="submit"
        type="info"
        class="new-event--add"
        @click="splitShift"
        >Split Into Individual Days</base-button
      >
      <base-button type="danger" @click="deleteShift()">Delete</base-button>
      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Firebase } from "@/firebase";

export default {
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
    };
  },
  props: ["durations", "shiftId", "isBriefForm"],
  computed: {
    shift() {
      if (this.shiftId) {
        return this.$store.getters["shifts/byId"](this.shiftId);
      } else {
        return {};
      }
    },
    production() {
      return this.searchResults.filter(
        (a) => a._id === this.shift.productionId
      )[0]["_source"];
    },
    resourceData() {
      return this.$store.getters["resources/byId"](this.shift.resourceId);
    },
    billings() {
      let output = [];
      let bills = this.resourceData.availableBillings;
      bills.forEach((b) => {
        let bill = this.$store.getters["billings/byId"](b);
        if (bill) {
          output.push(bill);
        } else {
          this.$store.dispatch("billings/fetchById", b);
        }
      });
      return output;
    },
    billingDetail() {
      if (this.shift.billing !== "") {
        return this.$store.getters["billings/byId"](this.shift.billing);
      } else {
        return "";
      }
    },
    budgets() {
      return this.$store.getters["budgets/active"];
    },
    costCalc() {
     if (this.billingDetail && this.shift.days) {
        return this.shift.days * this.billingDetail.dayRate;
      } else {
        return 0;
      }
    },
  },
  watch: {
    async shiftId(newRes, oldRes) {
      // on open form, query for the production details

      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [{ match: { id: this.shift.productionId } }],
                should: [],
                filter: [],
              },
            },
          },
        },
      };
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
  methods: {
    closeModals() {
      this.$emit("closeModals");
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.shiftId;
      payload[field] = value;
      this.$store.dispatch("shifts/patch", payload);
    },
    changeProduction(value) {
      var payload = {};
      payload.id = this.shiftId;
      payload.productionId = value;
      payload.title = this.production.title;
      this.$store.dispatch("shifts/patch", payload);
    },
    changeDays(value) {
      let payload = {};
      payload.id = this.shiftId;
      payload.days = value;
      if (!this.shift.manualCost && this.shift.billing) {
        payload.cost = this.billingDetail.dayRate * value;
      }
      this.$store.dispatch("shifts/patch", payload);
    },
    changeBilling(value) {
      var payload = {};
      payload.id = this.shiftId;
      payload.billing = value;
      payload.backgroundColor = this.billingDetail.colour;
      payload.cost = this.billingDetail.dayRate * this.shift.days;
      if (this.billingDetail.invoice) {
        payload.invoiceable = true;
      } else {
        payload.invoiceable = false;
      }
      this.$store.dispatch("shifts/patch", payload);
    },
    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },
    async splitShift() {
      let event = JSON.parse(JSON.stringify(this.shift));
      var start = moment(event.start);
      var end = moment(event.end);

      delete event.shiftId;
      delete event.created_at;
      delete event.created_by;
      delete event.updated_at;
      delete event.updated_by;
      delete event.className;

      var days = end.diff(start, "days", true);
      var i;

      for (i = 0; i < days; i++) {
        delete event.id;
        let thisDayStart = start.clone();
        let thisDayEnd = start.clone();

        console.log(this.billingDetail);

        event.start = this.strDate(thisDayStart.add(i, "d"));
        event.end = this.strDate(thisDayEnd.add(i + 1, "d"));
        event.date = thisDayStart.toDate();
        event.days = 1;

        if (!this.shift.manualCost && this.billingDetail) {
          event.cost = this.billingDetail.dayRate;
        }

        this.$store.dispatch("shifts/insert", event);
      }
      this.$store.dispatch("shifts/delete", this.shiftId);
      this.closeModals();
    },
    deleteShift() {
      this.$store.dispatch("shifts/delete", this.shiftId);
      this.closeModals();
    },
    async search(query) {
      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
                must_not: [{ match: { status: "Draft" } }],
              },
            },
          },
        },
      };

      if (!this.showCompletedProductions) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (query) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: query, fuzziness: "AUTO" } },
        });
      }
      this.loading = true;
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            this.loading = false;
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
};
</script>

