<template>
  <div>
    <NewFile
      :boxHeight=boxHeight
      :multiple="true"
      message="Drop files or click to browse"
      :fileName="false"
            :basePath="basePath"
      @success="success"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-brief-reference",
  props: ["production", "boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    allowedFileTypes() {
      return "*/*";
    },
    basePath() {
      const prodTitle = this.production.title.replace(/[\W_]+/g, "_");
      let year = new Date().getFullYear();
      let department = this.production.department;
      return `files/${year}/${department}/${prodTitle}/References`;
      // return "test";
    },
  },
  methods: {
    success(payload) {
      payload.what = "briefReference";
      payload.prodTitle = this.production.title;
      payload.prodID = this.production.id;
      payload.department = this.production.department;

      this.$store.dispatch("files/insert", payload).then((result) => {});
    },
  },
};
</script>

<style scoped>
</style>