<template>
  <div class="row">
    <div class="col">
      <div class="row">
              <div class="col-4">
          <stats-card title="Total Days Logged"
                      type="gradient-red"
                      :sub-title="totalDays"
                      icon="fas fa-calendar-day">

            <!-- <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-4">
          <stats-card title="Gap from First to Last"
                      type="gradient-orange"
                      :sub-title="totalSpan"
                      icon="fas fa-business-time">
<!-- 
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-4">
          <stats-card title="Effort Cost"
                      type="gradient-yellow"
                      :sub-title='totalCost'
                      icon="fas fa-money-bill-wave">
<!-- 
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
            </div>
            <modal
              :show.sync="shiftEditForm"
              size="lg"
              modal-classes="modal-scrollable"
            >
              <shift-edit-form
                :shiftId="shiftEditId"
                :isBriefForm="true"
                @closeModals="shiftEditForm = false"
              ></shift-edit-form>
            </modal>
      <div class="card-wrapper mt--2">
        <!-- Form controls -->
        <div class="card">
        
        
    

          <!-- Card body -->


            <div class="row" v-if="shifts.length === 0">
              <div class="col">No Bookings Yet</div>
            </div>
            <!-- <li class="list-group list-group-flush">
              <ul
                class="list-group-item"
                v-for="(shift, index) in shifts"
                :key="index"
              >
                <shift-list :shift="shift" @edit="edit(shift.id)"></shift-list>
              </ul>
            </li> -->

            <el-table
            class="
              table-responsive
              align-items-center
              table-flush table-striped
              jimTable
            "
            header-row-class-name="thead-light"
            :data="shifts"
            :default-sort="{prop: 'start', order: 'ascending'}"
          >

          <el-table-column label="Start" prop="start" sortable   min-width="100"> </el-table-column>
            <el-table-column label="Days" prop="days"> </el-table-column>
            <el-table-column
              label="Label"
              min-width="120"
              prop="resourceName"
              :filters="resources"
              :filter-method="filterHandler"
            >
            </el-table-column>
            <el-table-column label="Rate" prop="billing"          min-width="200"        :filters="billings"
              :filter-method="filterHandler">
              <div slot-scope="{ row }">
                <billing-name :bill="row.billing"></billing-name>
              </div>
            </el-table-column>
            <el-table-column label="Cost" prop="cost">
              <div slot-scope="{ row }">
                {{Number(row.cost).toFixed(2)}}
                </div>
            </el-table-column>
            <el-table-column label="budget" min-width="170">
              <div slot-scope="{ row }" >
                <div v-if="row.invoiceable">{{budgetName(row.budget)}}</div>
                <div v-else>Retainer</div>
              </div>
            </el-table-column>
  
            <el-table-column label="Logged" prop="created_at" sortable>
              <div slot-scope="{ row }" >
              {{ row.created_at  | dateTime }}
              </div>
            </el-table-column>
          
            <el-table-column class="table-actions" width="70">
              <div slot-scope="{ row }" >
                <base-button outline size="sm" @click="edit(row)">Edit</base-button>
                </div>
                </el-table-column>
  
 
    
          

    
          </el-table>
          </div>
 
      </div>
    </div>
  </div>
</template>

<script>
import ShiftList from "../Calendars/Components/ShiftListItem";
// import ShiftAddForm from "../Production/Components/ShiftAddForm.vue";
import ShiftEditForm from "../Calendars/Components/ShiftEditForm.vue";
import BillingName from '../Components/BillingName.vue'


export default {
  name: "shifts",
  components: {
    ShiftList,
    // ShiftAddForm,
    ShiftEditForm,
    BillingName
  },
  data() {
    return {
      // shiftAddForm: false,
      shiftEditForm: false,
      shiftEditId: "",
    };
  },
beforeMount() {
  this.$store.dispatch("budgets/fetchAndAdd", {where: [['active', '==', true]]})
},
  computed: {
    shifts() {
      let shifts = this.$store.getters["shifts/byBriefId"](this.$route.params.id);
      shifts.forEach(a => {
        let name = this.$store.getters["resources/byId"](a.resourceId)
        if(name){
        a.resourceName = name.displayName
        } else {
        
          this.$store.dispatch("resources/fetchById", a.resourceId)
        } 
        if (a.budget) {
        let budget = this.$store.getters["budgets/byId"](a.budget)
        if(!budget){
          this.$store.dispatch("budgets/fetchById", a.budget)
        } 
      }       
      })
      return shifts.sort((a,b) => a.date - b.date)
    },
    totalDays() {
      const sum = this.shifts.reduce((accumulator, object) => {
        return accumulator + object.days;
      }, 0);
      return sum.toString();
    },
    totalSpan() {
      var min = Math.min(...this.shifts.map(item => new Date(item.start)));
      var max = Math.max(...this.shifts.map(item => new Date(item.end)));
      min = new Date(min)
      max = new Date(max)
        // To calculate the time difference of two dates
    var Difference_In_Time = max.getTime() - min.getTime();
      
      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return 'Days: ' + Difference_In_Days

    },
    totalCost() {
      const sum = this.shifts.reduce((accumulator, object) => {
        return accumulator + Number(object.cost);
      }, 0);
      return '£' + sum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    resources() {
      let res = {}
      this.shifts.forEach(a => {
        res[a.resourceId] = true
      })
      let resArr = Object.keys(res)
      let filters = []
      resArr.forEach(b => {
        let name = this.$store.getters["resources/byId"](b)
        if(name){
        filters.push({text: name.displayName, value: b})
        } else {
          return false // resource doesn't exist anymore
        }
      })
      return filters
    },
    billings() {
      let res = {}
      this.shifts.forEach(a => {
        res[a.billing] = true
      })
      let resArr = Object.keys(res)
      let filters = []
      resArr.forEach(b => {
        let name = this.$store.getters["billings/byId"](b)
        if(!name) {
          this.$store.dispatch("billings/fetchById", b)
        } else {
        filters.push({text: name.label, value: b})
        }
      })
      return filters
    },
  },
  methods: {
    edit(shiftId) {
      console.log(shiftId)
      this.shiftEditId = shiftId.id;
      this.shiftEditForm = true;
    },
    filterHandler(value, row, column) {
        const property = column['property'];
        return row[property] === value;
      },
      budgetName(budgetId) {
        let name = this.$store.getters["budgets/byId"](budgetId)
        if(name) {
          return name.name
        } else {
          return ''
      }
    },
  },
};
</script>
