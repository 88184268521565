<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <span class="h3">Last Updated 23rd March 2021</span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">

<h3>Contents</h3>
<ul>
  <li>
    <a href="#streaming">Live streaming</a>
  </li>
    <li>
    <a href="#clips">In play clips</a>
  </li>
      <li>
    <a href="#highlights">Highlights</a>
  </li>
      <li>
    <a href="#syndication">Syndication</a>
  </li>
      <li>
    <a href="#domArchive">Domestic Archive</a>
  </li>
      <li>
    <a href="#intArchive">International Archive</a>
  </li>
      <li>
    <a href="#nonCounty">Non-County Teams</a>
  </li>
      <li>
    <a href="#covid">Covid-19</a>
  </li>
</ul>
<br>


                  <h3 id="streaming">Live streaming</h3>
<br>
                  <table>
                    <tr>
                      <th></th>
                      <th>Non-Televised with BBC Comms</th>
                      <th>Non-Televised no BBC Comms</th>
                      <th>Televised FCC matches</th>
                    </tr>
                    <tr>
                      <th>Available</th>
                      <td>Yes</td>
                      <td>No</td>
                      <td>No</td>
                    </tr>

                    <tr>
                      <th>Sponsorship/advertising allowed</th>
                      <td>Only with viewer opt-in</td>
                      <td>Yes</td>
                      <td>N/A</td>
                    </tr>
                  </table>
<br>
                  <p>
                    <strong
                      >Can Counties live stream publicly on their YouTube
                      channel or other County owned/operated social
                      platforms?</strong
                    >
                    <br />
                    Yes, but not Twitch.
                  </p>
                  <p>
                    <strong>
                      Can Counties sell access to the live stream or withhold
                      the stream to paid county members?</strong
                    >
                    <br />No
                  </p>
                  <p>
                    <strong>
                      Can Counties offer enhanced quality streams to paid
                      members, whilst also making a freely available basic
                      stream?</strong
                    >
                    <br />No
                  </p>
                  <p>
                    <strong>
                      Can Counties apply a reg-wall to the streams, if free and
                      broadly accessible?</strong
                    >
                    <br />Yes
                  </p>
                  <p>
                    <strong>
                      Can commercial logos/ads appear on the stream without BBC
                      commentary?
                    </strong>
                    <br /><ul><li>Counties may include one watermark logo (at a time)
                    on the live-stream.</li><li>Counties may include pre and post roll
                    ads, of unlimited quantity.</li><li>Counties may include in
                    play/game ads.</li><li>All ads must be non-competitors of ECB
                    commercial partners. Competitor brands must be blacklisted.
                    </li>
                    </ul>
                  </p>
                  <p>
                    <strong>
                      Can commercial/partner logos or messages appear on or
                      accompanying the stream with BBC commentary?
                    </strong>
                    <br />The BBC are permitting the use of commercial visual
                    and audio-visual messaging or branding concurrently with BBC
                    commentary on the condition that the commentary is not
                    available until the viewer opts-in to pair BBC commentary
                    with the stream. We therefore have to present the stream
                    mute or with atmosphere audio, until the viewer requests the
                    BBC commentary. The viewer only has to opt-in once. We are
                    building a solution for this via Brightcove and the NV
                    widgets that we invite you to use for your match centres,
                    and can an alternative embed code you can directly place
                    into your websites if you do not wish to use the NV widgets.
                    If you wish to commission your own solution, please discuss
                    with us to ensure it adheres to the BBC’s requirements. You
                    are welcome to propose solutions with social platforms that
                    could similarly comply. Essentially - no partner logos can
                    be displayed accompanying BBC commentary unless the viewer
                    has actively opt-ed in to pairing BBC commentary with the
                    stream. It is important to flag that the BBC has the right
                    to withdraw the use of the commentary if the rules are
                    broken – which will apply to the content for all clubs.
                  </p>
                  <p>
                    <strong>
                      Can commercial messages/ads appear during the intervals of
                      a stream that also has BBC commentary?
                    </strong>
                    <br />Yes for video players that comply with the opt-in
                    rules. For non-opted in viewers, e.g. social channels or
                    embedded social players, ads/messages/logos can occur at
                    lunch or tea breaks when no players are on the pitch, and
                    the BBC has finished commentary or yet to start. In
                    addition, they require a 3 second minimum neutral bumper to
                    clearly demarcate the cricket footage with commercial
                    material.
                  </p>
                  <p>
                    <strong>
                      Can the stream be commercially labelled e.g. ‘brought to
                      you by’ or ‘in association with’?
                    </strong>
                    <br />• Yes for feeds without BBC content. • Yes for feeds
                    with BBC content for which the viewer has opted-in. • No for
                    feeds with BBC content for which the viewer has not
                    opted-in.
                  </p>
                  <p>
                    <strong>
                      Can Counties stream / clip matches while another match is
                      being broadcast by Sky?
                    </strong>
                    <br />Yes – But not the same match being broadcast. Under
                    previous broadcast arrangements, if Sky were showing a test
                    match, for example, then no cricket streams were allowed at
                    all. That is not the case now. However, you cannot stream a
                    match through your own system if Sky are also televising
                    that same match.
                  </p>
                  <p>
                    <strong>
                      Can County website assets surrounding the stream include
                      commercial partners? i.e on the website footer?
                    </strong>
                    <br />Yes
                  </p>
                  <p>
                    <strong>
                      Can more than two cameras be used for live-streaming
                      non-televised games?
                    </strong>
                    <br />Yes
                  </p>
                  <p>
                    <strong>Can the camera(s) move to track the ball? </strong>
                    <br />Yes
                  </p>
                  <p>
                    <strong>
                      Can Counties live stream their own non-televised away
                      matches?
                    </strong>
                    <br />Yes, but this must be agreed with both counties
                    involved, and we are facilitating this with a shared
                    streaming platform, see later pages.
                  </p>
                  <p>
                    <strong>
                      Can Counties live stream other home matches hosted by the
                      club e.g. Tourist Matches, 2nd XI, Academy, age-group
                      cricket, Disability Cricket?
                    </strong>
                    <br />Different circumstances depending on the specific
                    match being hosted. Counties should contact the ECB for
                    confirmation on a case-by-case basis.
                  </p>
                  <p>
                    <strong
                      >Can Counties use score graphics on live streams?
                    </strong>
                    <br />Yes
                  </p>
                  <p>
                    <strong>
                      Can Counties promote the live stream social media?
                    </strong>
                    <br />Yes, this is encouraged.
                  </p>
                  <p>
                    <strong>
                      Do Counties have to geo-block internationally?
                    </strong>
                    <br />No, they can be globally available.
                  </p>
<br>
                  <h3 id="clips">In play clips</h3>

          
                  <br>
                  <table>
                    <tr>
                      <th></th>
                      <th>Non-Televised with BBC Comms</th>
                      <th>Non-Televised no BBC Comms</th>
                      <th>Televised FCC matches</th>
                    </tr>
                    <tr>
                      <th>Available</th>
                      <td>6 x 30 sec clips per hour,
                    for use on web, app, and social </td>
                      <td>6 x 30 sec clips per hour,
                    for use on web, app, and social</td>
                      <td>No</td>
                    </tr>

                    <tr>
                      <th>Sponsorship/advertising allowed</th>
                      <td>Only with viewer opt-in</td>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                  </table>
<br>
                  <p>
                    <strong> Can Counties publish match clips? </strong> <br /><ul><li>Only for non-televised games and not outside of County owned
                    and operated channels i.e their own official website and
                    social channels.</li><li>Six, 30 second clips per hour, per
                    County, on corresponding County website, app and social</li><li>
                    Televised matches cannot be clipped at all.</li></ul>
                  </p>
                  <p>
                    <strong>
                      Can Counties use clips from other competitions e.g. IPL,
                      Big Bash available on PCS Pro Cricket?
                    </strong>
                    <br />No
                  </p>
                  <p>
                    <strong>
                      Can Counties use clips featuring other teams?
                    </strong>
                    <br />No – Counties can only use clips from their own teams
                    and only from non-televised games.
                  </p>
                  <p>
                    <strong>
                      Can Counties use clips from their home and away games?
                    </strong>
                    <br />Yes if the host county agrees to supply, or available
                    through the shared streaming platform.
                  </p>
                  <p>
                    <strong
                      >Can clips / highlights be embedded in newsletters?
                    </strong>
                    <br />Yes
                  </p>
                  <p>
                    <strong>
                      Can clips have a pre-roll ad on social posts?
                    </strong>
                    <br />Yes, but only clips from non-televised games without
                    BBC commentary. Please be aware Facebook no longer allow
                    this, Twitter may follow.
                  </p>
                  <p>
                    <strong>
                      Can clips feature sponsorship and pre-roll ads on owned platforms?
                    </strong>
                    <br />For your own produced content yes, unless it features BBC commentary - in which case the opt-in has to apply.
                  </p>
<br>
                  <h3 id="highlights">Highlights</h3>
               <br>
                  <table>
                    <tr>
                      <th></th>
                      <th>Non-Televised with BBC Comms</th>
                      <th>Non-Televised no BBC Comms</th>
                      <th>Televised FCC matches</th>
                    </tr>
                    <tr>
                      <th>Available</th>
                      <td>1 x 5min package,
                    for use on web, app, and social </td>
                      <td>1 x 5min package,
                    for use on web, app, and social</td>
                      <td>1 x 10min package, not available until 6am the
                    following day</td>
                    </tr>

                    <tr>
                      <th>Sponsorship/advertising allowed</th>
                      <td>Only with viewer opt-in</td>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                  </table>
<br>
    
                  <p>
                    <strong> What highlights can be posted? </strong> <br />5
                    minute highlights package per day on corresponding County
                    website, app and social
                  </p>
                  <p>
                    <strong> When can highlights be posted? </strong> <br /><ul><li>
                    Non-TV games straight away.</li><li>TV games cannot be made
                    available for viewing until 06.00 hours the following
                    morning on corresponding County website, app and social.</li></ul>
                  </p>
                  <p>
                    <strong>
                      Can highlights feature sponsorship and pre-roll ads?
                    </strong>
                    <br />For your own produced content yes, unless it features BBC commentary - in which case the opt-in has to apply.
                  </p>
<br>
                  <h3 id="syndication">Syndication of content</h3>
                  <h4>(distributing to other publishers)</h4>

                  <p>
                    <strong> Can Counties share footage with BBC? </strong>
                    <br />As UK law, yes if it falls under the SNAC (Sports News
                    Access Code). For support with this please contact ECB media
                    team. SNAC pertains to the use of match footage in news TV
                    stories, eg, BBC local news needing footage of wickets to
                    paint a story of an upcoming local player, and is not to be
                    confused with entry to the ground to cover something
                    journalistically in person on match days – such enquiries
                    (both televised and non-televised) should be directed to the
                    ECB Events team: accreditation@ecb.co.uk.
                  </p>
                  <p>
                    <strong>
                      Can highlights and clips of non-televised games be
                      syndicated?
                    </strong>
                    <br />Non-tv highlights can be syndicated to the website of
                    a County’s sponsors and other commercial partners provided
                    that the partner is not a media entity (eg. Daily Telegraph,
                    Cricinfo etc).
                  </p>
                  <p>
                    <strong>
                      Can highlights and clips of televised games be syndicated?
                    </strong>
                    <br />No.
                  </p>
                  <p>
                    <strong>
                      Can livestreams be syndicated/sub-licensed/sold?
                    </strong>
                    <br />No, neither domestically nor internationally.
                  </p>
<br>
                  <h3 id="domArchive">Domestic Archive</h3>

                  <p>
                    Non-Televised with BBC Comms Non-Televised no BBC Comms
                    Televised FCC matches (Sky) Available Yes Yes Yes
                    Sponsorship/advertising allowed No Yes Yes
                  </p>
                  <p>
                    <strong>
                      Can Counties host an archive of their team’s home fixture
                      domestic cricket matches on their website?
                    </strong>
                    <br />Yes, from 1995 onwards.
                  </p>
                  <p>
                    <strong>
                      Can Counties host an archive of their team’s home fixture
                      domestic cricket matches on their social, eg, YouTube?
                    </strong>
                    <br />Short highlights only, not full/extended highlights –
                    you can use the same length as current highlights – so 5min
                    non TV and 10min TV.
                  </p>
                  <p>
                    <strong>
                      Can Counties use archive footage in video content on their
                      website and social media?
                    </strong>
                    <br />Yes, from 1995 onwards. Away fixture footage can be
                    used with consent from the host County.
                  </p>
                  <p>
                    <strong>
                      Can Counties stream historic home fixture domestic
                      matches, including synced BBC comms?
                    </strong>
                    <br />Yes, from 1995 onwards.
                  </p>
                  <p>
                    <strong
                      >Can Counties source archive from PCS/Feedback?
                    </strong>
                    <br />Yes.
                  </p>
                  <p>
                    <strong>
                      Can Counties buy archive footage of televised Sky matches?
                    </strong>
                    <br />
                    Yes, but please don’t approach Sky directly. We can
                    facilitate and aggregate requests – please contact Matt
                    Somerford.
                  </p>
                  <p>
                    <strong>
                      Can Counties buy archive footage of televised matches from
                      before Sky era?
                    </strong>
                    <br />Yes, Sunset+Vine can facilitate BBC and other
                    broadcaster cricket sales.
                  </p>
                  <p>
                    <strong>
                      Can Counties use archive they have sourced from other
                      means, eg filmed themselves.
                    </strong>
                    <br />Yes, as long as they own the rights to use it – so
                    since 1995, and home fixtures.
                  </p>
                  <br>
                  <h3 id="intArchive">International Archive</h3>
                  <p>
                    <strong>
                      Can Counties host an archive of international cricket
                      matches on their website?
                    </strong>
                    <br />No
                  </p>
                  <p>
                    <strong>
                      Can Counties use archive international cricket (so matches
                      from 1995 - last year) in video content on their website,
                      commercial partner websites, or social media?
                    </strong>
                    <br />Yes, by licensing the footage through Sunset & Vine
                    using the ECB preferred rate card.
                  </p>
                  <p>
                    <strong>
                      Can Counties use current term international cricket (so
                      footage from the current year) in video content on their
                      website, commercial partner websites, or social media?
                    </strong>
                    <br />Not generally, except in the specific use case of
                    using footage from earlier matches in the same series to
                    promote the sale of tickets for future matches in the
                    series. For example, if an international match has unsold
                    tickets, or a Test Match goes to day 5, a county can use
                    video from the current series in a ticket sales advert. The
                    video assets that feature this in should clearly be
                    advertisements rather than social engagement content (eg Top
                    Tens) and can run to 1 minute maximum. This allowance is for
                    the promotion of current series only, during the series.
                  </p>
               
<br>
                  <h3 id="nonCounty">Competitions not involving County teams</h3>
                  <p>
                    <strong>
                      Do these rights cover The Hundred and the Rachel
                      Heyhoe-Flint Trophy as well?
                    </strong>
                    <br />No - The reserved county rights in the Sky agreement
                    rights only relate to matches involving “an FCC’s team” the
                    Hundred and RHF teams are not county teams.
                  </p>
                  <p>
                    <strong>
                      Do these rights cover international tour matches?
                    </strong>
                    <br /><ul><li>Yes to County vs tourist match, these are shared
                    with ECB as tour organiser.</li><li>No to international team
                    intra-squad matches.</li></ul>
                  </p>
                  <br>
                  <h3 id="covid">Covid-19</h3>
                  <p>
                    <strong>
                      If the original host venue has changed, do the digital
                      rights and any usage agreements transfer over and apply to
                      the new fixture?
                    </strong>
                    <br />No.
                  </p>
                  <p>
                    <strong>
                      If matches are played in a ‘behind closed doors’ scenario
                      can venues still have their own staff on site for digital
                      purposes?
                    </strong>
                    <br />As per last year, this wasn’t generally allowed, but
                    may be allowable under some circumstances to be discussed
                    with events team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rights-faq",
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },

    loading() {
      return this.$store.getters["user/loading"];
    },
  },

  methods: {},
};
</script>

<style scoped>
table, th, td {
  border: 0.5px solid rgb(204, 204, 204);
}
th, td {
  padding: 10px;
}
</style>