const archiveTemp = {
  moduleName: 'archiveTemp',
  statePropName: 'data',
  namespaced: true, // automatically added
  // serverChange: {
  //   convertTimestamps: {
  //     dateTimeStart: '%convertTimestamp%',
  //   },
  // },
  state: {
    selection: {},
    delSelection: {},
    clipSearchFilters: [],
    clipSearchValues: {},
    deliverySearchFilters: [],
    deliverySearchValues: {},
    matchSearchFilters: [],
    matchSearchValues: {},
    bookmarkOptions: [
      "Ground View",
      "Beauty Shot",
      "Great Shot",
      "Great Delivery",
      "Team talk/huddles",
      "Celebrations",
      "Injury",
      "Funny Moment",
      "Outstanding Moment",
      "Trophy Lift",
      "Promo",
      "Sponsor Coverage",
      "Emotion: Happiness",
      "Emotion: Anger",
      "Emotion: Laughter",
      "Emotion: Sadness",
      "Poor Decision",
      "Close Up Detail",
      "Crowd Catch",
      "Crowd Funny",
      "Crowd Applause",
      "Crowd Other",
    ],
    matchDisplayOptions:[
      {
        field: 'teams',
        lookup: 'matches',
        label: 'Teams'
      },
      {
        field: 'ground',
        lookup: 'venues',
        label: 'Venue'
      },
      {
        field: 'competition',
        lookup: 'matches',
        label: 'Competition'
      },
      {
        field: 'date',
        label: 'Date'
      },
      {
        field: 'format',
        label: 'Format'
      },
    ],
    matchDisplayFields: [],
    displayFields: [],
    displayOptions: [
      {
        field: 'bowlLength',
        label: 'Bowling Length'
      },
      {
        field: 'bowlLine',
        label: 'Bowling Line'
      },
      {
        field: 'bowlMovment',
        label: 'Bowling Movement'
      },
      {
        field: 'bowlStyle',
        label: 'Bowling Style'
      },
      {
        field: 'footMove',
        label: 'Shot Footing'
      }, {
        field: 'shotType',
        label: 'Shot Type'
      },
      {
        field: 'howOut',
        label: 'How Out'
      },
      {
        field: 'extras',
        label: 'Extras'
      },
      {
        field: 'runs',
        label: 'Runs'
      },
      {
        field: 'fieldPos',
        label: 'Field Position'
      },
      {
        field: 'fieldAct',
        label: 'Fielded'
      },
    ],
  },
  getters: {
    // recordingSelection: (state) => (fileID) => {
    //   const dels = Object.values(state.selection)
    //   return dels.filter(del => del.file === fileID && del.selected)
    // },
    eventSelection: (state) => {
      let sel = []
      for (const key in state.selection) {
        if (state.selection[key]) {
          console.log(key)
          sel.push(key)
        }
      }
      return sel
    },
    eventState: (state) => (delID) => {
      return state.selection[delID]
    },
    clipSearchFilters: state => state.clipSearchFilters,
    clipSearchValues: state => state.clipSearchValues,
    deliverySearchFilters: state => state.deliverySearchFilters,
    deliverySearchValues: state => state.deliverySearchValues,
    matchSearchFilters: state => state.matchSearchFilters,
    matchSearchValues: state => state.matchSearchValues,
    matchDisplayFields: state => state.matchDisplayFields,
    matchDisplayOptions: state => state.matchDisplayOptions,
    displayFields: state => state.displayFields,
    displayOptions: state => state.displayOptions,
    bookmarkOptions: state => state.bookmarkOptions,
    delSelection: state => {
      let sel = []
      for (const key in state.delSelection) {
        if (state.delSelection[key]) {
          sel.push(key)
        }
      }
      return sel
    },
    delState: (state) => (delID) => {
      return state.delSelection[delID]
    },
  },
  mutations: {
    eventSelect(state, payload) {
      var id = payload.id
      var value = payload.value
      let newObj = {}
      newObj[id] = value
      state.selection = Object.assign({}, state.selection, newObj)
    },
    clearSelection(state){
      state.selection = {}
    },
    delSelect(state, payload) {
      var id = payload.id
      var value = payload.value
      // state.delSelection[id] = value
      let newObj = {}
      newObj[id] = value
      state.delSelection = Object.assign({}, state.delSelection, newObj)
    },
    displayFields(state, payload) {
      state.displayFields = payload
    },
    matchDisplayFields(state, payload) {
      state.matchDisplayFields = payload
    },
    clipSearchFilters(state, payload) {
      state.clipSearchFilters = payload
    },
    clipSearchValues(state, payload) {
      state.clipSearchValues[payload.filter] = payload.value
    },
    deliverySearchFilters(state, payload) {
      state.deliverySearchFilters = payload
    },
    deliverySearchValues(state, payload) {
      state.deliverySearchValues[payload.filter] = payload.value
    },
    matchSearchFilters(state, payload) {
      state.matchSearchFilters = payload
    },
    matchSearchValues(state, payload) {
      state.matchSearchValues[payload.filter] = payload.value
    },
    clearDelSelection(state){
      state.delSelection = {}
    }
  },
}

export default archiveTemp