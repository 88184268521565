<template>
  <div>
    <div class="row mb-2">
      <div class="col-auto">
        <base-button
          size="lg"
          type="secondary"
          class="btn-icon-only"
          @click="open = !open"
        >
          <span v-if="open"><i class="fas fa-folder-open"></i></span>
          <span v-else><i class="fas fa-folder"></i></span>
        </base-button>
      </div>

      <!-- <div class="col" v-if="editFolderMode">
            <base-input
              v-model="folder.display"
              @input="renameFolder($event, folder.id)"
            />
          </div> -->

      <div class="col-form-label">
         <span class="numberBlock">
            {{ dels.length }}
          </span>
          <span>
            {{ groupName }}
          </span>

      </div>
      <!-- <div class="col-auto" v-if="editFolderMode">
            <base-button
              type="danger"
              class="btn-icon-only"
              @click="deleteFolder(folder.id)"
            >
              <span><i class="fas fa-folder-minus"></i></span>
            </base-button>
          </div>-->
    </div>
    <div v-if="open">
      <div>
        <masonry
          :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
          :gutter="30"
        >
          <catalogue-card
            v-for="del in dels"
            :key="del.id"
            :del="del"
            :collection="true"
          >
          </catalogue-card>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueCard from "./CatalogueCard";

// style import
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "widgetGroups",
  data: function () {
    return {
      open: false,
    };
  },
  props: ["groupName", "dels"],
  components: {
    "catalogue-card": CatalogueCard,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },

    config() {
      return this.$store.getters["config/config"];
    },

    groups() {
      return Object.keys(this.groupedDels);
    },
  },
  beforeMount() {},
  methods: {

  },
};
</script>

<style>
ul.folders > li {
  background: #f8f8f8;
}
</style>
