<template>
  <div>
    <form class="new-event--form">
      <!-- <base-input label="Production" v-if="!isBriefForm">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shoot.productionId"
          filterable
          placeholder="Select here"
          @change="changeProduction($event)"
        >
          <el-option
            v-for="prod in this.productions"
            :key="prod.id"
            :label="prod.department + ': ' + prod.title"
            :value="prod.id"
          >
          </el-option>
        </el-select>
      </base-input>
      <base-checkbox class="mt--3 mb-4" v-model="showCompletedProductions" v-if="!isBriefForm">
        Include Completed Productions
      </base-checkbox> -->

      <base-input
        type="text"
        label="Shoot Title"
        v-model="shoot.shootTitle"
        @change="changeField($event.target.value, 'shootTitle')"
      />

      <base-input label="Status">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shoot.shootStatus"
          placeholder="Select here"
          @change="changeField($event, 'shootStatus')"
        >
          <el-option label="Pencilled" value="pencilled"> </el-option>
          <el-option label="Confirmed" value="confirmed"> </el-option>
          <el-option label="Cancelled" value="cancelled"> </el-option>
        </el-select>
      </base-input>

      <div class="row">
        <div class="col">
          <label class="col-form-label form-control-label">Date</label>
        </div>
        <div class="col">
          <label class="col-form-label form-control-label">Days</label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <base-input>
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="{ allowInput: true }"
              class="form-control datepicker"
              v-model="shoot.start"
              @input="changeDate($event)"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="col">
          <base-input type="number" v-model="shoot.days" @change="changeDate(shoot.start)"> </base-input>
        </div>
      </div>

      <base-input label="Timings">
        <textarea
          class="form-control"
          rows="2"
          v-model="shoot.timings"
          @change="changeField($event.target.value, 'timings')"
        ></textarea>
      </base-input>
      <base-input label="Shooter">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="shoot.shooter"
          placeholder="Select here"
          clearable
          @change="changeField($event, 'shooter')"
        >
          <el-option label="Brandon" value="Brandon"> </el-option>
          <el-option label="Danny" value="Danny"> </el-option>
          <el-option label="Other" value="Other"> </el-option>
        </el-select>
      </base-input>
      <base-input label="Crew">
        <textarea
          class="form-control"
          rows="3"
          v-model="shoot.crew"
          @change="changeField($event.target.value, 'crew')"
        ></textarea>
      </base-input>
      <base-input label="Location Details">
        <textarea
          class="form-control"
          rows="3"
          v-model="shoot.location"
          @change="changeField($event.target.value, 'location')"
        ></textarea>
      </base-input>
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <base-button
        v-if="this.shoot.days > 1"
        native-type="submit"
        type="info"
        class="new-event--add"
        @click="splitShift"
        >Split Into Individual Days</base-button
      >
      <base-button type="danger" @click="deleteShift()">Delete</base-button>
      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  data() {
    return {
      showCompletedProductions: false,
    };
  },
  props: ["shootId", "isBriefForm"],
  components: {
    flatPicker,
  },
  computed: {
    shoot() {
      if (this.shootId) {
        return this.$store.getters["shoots/byId"](this.shootId);
      } else {
        return {};
      }
    },
    production() {
      if (this.shoot) {
        return this.$store.getters["briefs/byId"](this.shoot.productionId);
      } else {
        return {};
      }
    },
  },
  // watch: {
  //   shootId(newRes, oldRes) {
  //     this.$store.dispatch("briefs/fetchById", newRes);
  //   },
  // },
  methods: {
    closeModals() {
      this.$emit("closeModals");
    },
    changeField(value, field) {
      console.log(value)
      var payload = {};
      payload.id = this.shootId;

        payload[field] = value;

      this.$store.dispatch("shoots/patch", payload);
    },
    changeDate(value) {
      console.log(value)
      var payload = {};
      payload.id = this.shootId;
        payload.start = value;

      var start = moment(value);
      var end = moment(value);

      payload.start = this.strDate(start);
        payload.end = this.strDate(end.add(this.shoot.days, "d"));
        payload.date = start.toDate();
        payload.days = this.shoot.days

              // which month-year this covers
      // e.g. {mar2024: true}
      // for each day in the span

      let monthYear = {}
      for (let i = 0; i < payload.days; i++) {

        if (i > 0) {
          start = start.add(1, "d") // add a day to the date
        }

        const formattedDate = start.format('MM-YYYY')
        monthYear[formattedDate] = true
      }
      payload.monthYear = monthYear

      this.$store.dispatch("shoots/patch", payload);
    },
    // changeProduction(value) {
    //   var payload = {};
    //   payload.id = this.shootId;
    //   payload.productionId = value;
    //   payload.title = this.production.title;
    //   this.$store.dispatch("shoots/patch", payload);
    // },
    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },
    async splitShift() {
      let event = JSON.parse(JSON.stringify(this.shoot));

      var start = moment(event.start);
      var end = moment(event.end);

      delete event.shootId;
      delete event.created_at;
      delete event.created_by;
      delete event.updated_at;
      delete event.updated_by;
      delete event.className;

      var days = end.diff(start, "days", true);
      var i;

      console.log(event.id);

      for (i = 0; i < days; i++) {
        delete event.id;
        let thisDayStart = start.clone();
        let thisDayEnd = start.clone();

        event.start = this.strDate(thisDayStart.add(i, "d"));
        event.end = this.strDate(thisDayEnd.add(i + 1, "d"));
        event.date = thisDayStart.toDate();
        event.days = 1;

        this.$store.dispatch("shoots/insert", event);
      }
      this.$store.dispatch("shoots/delete", this.shootId);
      this.closeModals();
    },
    deleteShift() {
      this.$store.dispatch("shoots/delete", this.shootId);
      this.closeModals();
    },
  },
};
</script>
    