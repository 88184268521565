<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">Query</div>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col-4">
                  <base-input class="select-danger">
                    <el-select v-model="filters" filterable multiple placeholder="Add filters">
                      <el-option v-for="option in filterOptionArray" class="select-danger" :key="option.field"
                        :label="option.label" :value="option.field">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-4" v-for="filter in filters" :key="filter">
                  <filter-selector :selection="filterOptions[filter]" :preValue="filterValues[filter]"
                    @valueChange="filterValueChange($event, filter)">
                  </filter-selector>
                </div>
                <div class="col-auto" v-if="filters.length > 0">
                  <base-input>
                    <base-button class="mt-4" @click="runQuery">Fetch</base-button>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush list event-list">
                <li
                  v-for="match in matches"
                  class="list-group-item px-0"
                  :key="match.id"
                >
                  <match :match="match" />
                </li>
              </ul>
            </div>
          </div> -->

          <div class="card" body-classes="px-0 pb-3" footer-classes="pb-2">
            <el-table class="table-card" :data="matches" row-key="id" header-row-class-name="thead-light">
              <!-- <el-table-column type="expand">
                <template v-slot="{ row }">
                  <match :match="row"></match>
                </template>
              </el-table-column> -->
              <el-table-column width="80">
                <template v-slot="{ row }">
                  <a :href="`#/archive/match/${row.id}`" class="btn"
                    > <i class="fa fa-arrow-right"></i></a>
                </template>
              </el-table-column>
              <el-table-column label="Home" prop="team1Name">
              </el-table-column>
              <el-table-column label="Away" prop="team2Name">
              </el-table-column>
              <el-table-column label="Date" prop="startDate">
                <template v-slot="{ row }">
                  {{ row.startDate | dateTime }}
                </template>
              </el-table-column>
              <el-table-column label="venue" prop="ground">
                <template v-slot="{ row }">
                  <venue-look-up :venueID="row.ground"></venue-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Comp Stage" prop="competition">
              </el-table-column>
              <el-table-column label="Comp Name" prop="competitionName">
              </el-table-column>
              <el-table-column label="Type" prop="cricketType">
                <template v-slot="{ row }">
                  <type-look-up :typeID="row.cricketType"></type-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Format" prop="cricketFormat">
                <template v-slot="{ row }">
                  <format-look-up :formatID="row.cricketFormat"></format-look-up>
                </template>
              </el-table-column>
            </el-table>

            <div slot="footer" class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
                px-4 py-3
              ">
              <div class="">
                <p class="card-category">
                  Showing {{ pagination.from + 1 }} to {{ pagination.to }} of {{ searchHits }} entries


                </p>

              </div>
              <base-pagination class="pagination-no-border" v-model="currentPage" :per-page="pagination.chunkSize"
                :total="searchHits" @input="changePage($event)"></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchCardTable from "./Components/MatchCardTable";
import VenueLookUp from "./Components/VenueLookUp";
import TypeLookUp from "./Components/TypeLookUp";
import FormatLookUp from "./Components/FormatLookUp";
import FilterSelector from "./Components/FilterSelector";
import { Firebase } from "@/firebase";
export default {
  data() {
    return {
      matches: [],
      searchHits: 0,
      currentPage: 1,
      searchPages: 0,
      searchReturn: 0,
      pagination: {
        default: 1,
        chunkSize: 30,
        from: 0,
        to: 0,
      },
      filterOptions: {
        // startDate: { field: "startDate", label: "Match Start", type: "date-range" },
        year: {
          field: "year",
          label: "Year",
          type: "number",
        },
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "single-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "single-select",
        },
        int: {
          field: "int",
          label: "International",
          type: "boolean",
        },
        teamsMap: {
          field: "teamsMap",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Teams",
          type: "multi-select",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "single-select",
        },
        tv: {
          field: "tv",
          label: "TV Game",
          type: "boolean",
        },
      },
    };
  },
  components: {
    match: MatchCardTable,
    "venue-look-up": VenueLookUp,
    "type-look-up": TypeLookUp,
    "format-look-up": FormatLookUp,
    "filter-selector": FilterSelector,
    // reels: ReelsExpander,
  },
  computed: {
    filters: {
      // getter
      get: function () {
        return this.$store.getters["archiveTemp/matchSearchFilters"];
      },
      // setter
      set: function (newValue) {
        this.$store.commit("archiveTemp/matchSearchFilters", newValue);
      },
    },
    filterValues() {
      return this.$store.getters["archiveTemp/matchSearchValues"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    matchFormats() {
      return this.$store.getters["matchFormats/formats"];
    },
    matchTypes() {
      return this.$store.getters["matchFormats/types"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.matches.length;
    },
  },
  beforeMount() {
    this.$store.dispatch("teams/fetchAndAdd", {
        clauses: { limit: 0 },
      });
    this.$store.dispatch("venues/fetchAndAdd");
    // this.$store.dispatch("matchFormats/fetchAndAdd");
  },
  mounted() {
    this.runQuery(false)
  },
  methods: {
    changePage(value) {
      console.log(value)
      this.pagination.from = (value - 1) * this.pagination.chunkSize;
      this.runQuery(false)
    },
    filterValueChange(value, filter) {
      this.$store.commit('archiveTemp/matchSearchValues', { value: value, filter: filter })
    },
    async runQuery(reset) {
      // this.$store.commit("matches/RESET_VUEX_EASY_FIRESTORE_STATE");
      // this.$store.dispatch("matches/fetchAndAdd", {
      //   clauses: { where, limit: 500 },
      // });
      let starter = reset ? 0 : this.pagination.from;
      this.pagination.from = starter;
      let payload = {
        response: null,
        request: {
          index: "matches",
          body: {
            sort: [
              {
                "startDate._seconds": { order: "desc" }
              },
            ],
            from: starter,
            size: this.pagination.chunkSize,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
                must_not: [],
              },
            },
          },
        },
      };

      // query
      let filters = this.filters;
      filters.forEach((field) => {
        if (this.filterValues[field] || field === 'int') {
    
          // check to make sure the filter has a value
          const value = this.filterValues[field];
          const option = this.filterOptions[field];
          let req = {};
          if (
            option.type === "single-select" ||
            option.type === "single-search" ||
            option.type === "boolean"
          ) {
            req.match = {};
            req.match[field] = value;
            payload.request.body.query.bool.filter.push(req);

          } else if (option.type === "multi-select") {

            value.forEach((v) => {
              req = {}
              req.match = {};
              const f = `${field}.${v}`;
              req.match[f] = true
              payload.request.body.query.bool.filter.push(req);
            });


          } else if (option.type === "number") {
            req.match = {};
            req.match[field] = parseInt(value);
            payload.request.body.query.bool.filter.push(req);

          } else if (option.type === "text") {
            req.match = {};
            req.match[field] = { query: value, fuzziness: "AUTO" };
            payload.request.body.query.bool.filter.push(req);
          }
        }
      });


      let now = new Date();
          now.setDate(now.getDate() + 1)
          let year = now.getFullYear();

          let secondsNow = now.getTime() / 1000
          
// console.log(payload.request.body.query)


      //access control internationals
      if (this.user.accessInt) {


        let bool = {
          bool: {
            must: [],
            should: [],
            filter: [{range: {"startDate._seconds": {"lte": secondsNow}}}],
            must_not: [],
          }
        }

        let req = {}
        req.match = {};
        req.match["int"] = true
        bool.bool.filter.push(req)

        // check current year access

        if (!this.user.accessIntCurrent) {
          // if access is false, block current year from the query
          let req = {}
          req.match = { "year": year };
          bool.bool.must_not.push(req)
        }
        if (this.user.accessIntFields) {
          if (this.user.accessIntFields.length > 0) {
            this.user.accessIntFields.forEach(field => {

              let value = this.user.accessIntPermissions[field]
              // console.log(field)
              // console.log(value)


              if (field === 'teamsMap') {
                if (this.user.accessIntPermissions[field]) {
                  value.forEach((v) => {
                    let req = {}
                    req.match = {};
                    // console.log(v)
                    const f = `${field}.${v}`;
                    req.match[f] = true
                    bool.bool.should.push(req);
                  });
                }
              }
              else { // all other fields
                if (this.user.accessIntPermissions[field]) {
                  if (value.length > 1) {
                    // console.log('longer than 1')
                    let bool = { bool: { should: [], minimum_should_match: 1 } }
                    value.forEach((v) => {
                      let req = {}
                      req.match = {};
                      // console.log(v)
                      req.match[field] = v
                      bool.bool.should.push(req)
                    });
                    bool.bool.filter.push(bool);
                  } else {
                    value.forEach((v) => {
                      let req = {}
                      req.match = {};
                      // console.log(v)
                      req.match[field] = v
                      bool.bool.filter.push(req);
                    });
                  }
                }
              }
            })
          }
        }

        if (bool.bool.should.length > 0) {
          bool.bool.minimum_should_match = 1
        }

        payload.request.body.query.bool.should.push(bool);

      } else {
        // block internationals
        let req = {}
        req.match = {};
        req.match["int"] = true
        payload.request.body.query.bool.must_not.push(req);
      }// end of int access

      //access control domestic
      if (this.user.accessDom) {

        let bool = {
          bool: {
            must: [],
            should: [],
            filter: [{range: {"startDate._seconds": {"lte": secondsNow}}}],
            must_not: [],
          }
        }

        let req = {}
        req.match = {};
        req.match["int"] = false
        bool.bool.filter.push(req)

        // check current year access

        if (!this.user.accessDomCurrent) {
          // if access is false, block current year from the query
          let req = {}
          req.match = { "year": year };
          bool.bool.must_not.push(req)
        }

        if (this.user.accessDomFields) {
          if (this.user.accessDomFields.length > 0) {
            this.user.accessDomFields.forEach(field => {

              let value = this.user.accessDomPermissions[field]
              // console.log(field)
              // console.log(value)


              if (field === 'teamsMap') {
                if (this.user.accessDomPermissions[field]) {
                  value.forEach((v) => {
                    let req = {}
                    req.match = {};
                    // console.log(v)
                    const f = `${field}.${v}`;
                    req.match[f] = true
                    bool.bool.should.push(req);
                  });
                }
              }
              else { // all other fields
                if (this.user.accessDomPermissions[field]) {
                  if (value.length > 1) {
                    // console.log('longer than 1')
                    let bool = { bool: { should: [], minimum_should_match: 1 } }
                    value.forEach((v) => {
                      let req = {}
                      req.match = {};
                      // console.log(v)
                      req.match[field] = v
                      bool.bool.should.push(req)
                    });
                    bool.bool.filter.push(bool);
                  } else {
                    value.forEach((v) => {
                      let req = {}
                      req.match = {};
                      // console.log(v)
                      req.match[field] = v
                      bool.bool.filter.push(req);
                    });
                  }
                }
              }
            })
          }
        }


        if (bool.bool.should.length > 0) {
          bool.bool.minimum_should_match = 1
        }
        payload.request.body.query.bool.should.push(bool);

      } else {
        // block domestics
        let req = {}
        req.match = {};
        req.match["int"] = false
        payload.request.body.query.bool.must_not.push(req);
      }// end of dom access

      if (payload.request.body.query.bool.should.length > 0) {
        payload.request.body.query.bool.minimum_should_match = 1
      }
      // console.log(payload.request.body.query)
      // console.log(payload.request);
      // this.$store.commit("user/setLoading", true);
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);


      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          console.log(d.response)
          if (d.response !== null) {
            // Do things
            // this.matches = d.response.hits.hits;
            this.matches = []
            d.response.hits.hits.forEach(res => {

              //flatten the time object
              let obj = res._source
              obj.id = res._id
              this.matches.push(obj)
            })
            this.searchHits = d.response.hits.total.value;
            this.searchReturn = d.response.hits.hits.length;
            this.pagination.to = this.pagination.from + this.searchReturn;
            this.searchPages = Math.ceil(
              this.searchHits / this.pagination.chunkSize
            );
            // this.$store.commit("user/setLoading", false);
          }
        });
    },
  },
};
</script>

<style >
.el-table__expand-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card .el-table td,
.card .el-table th {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.select-danger .el-select .el-tag {
  background: #f5365c;
}

.table-card {
  border-radius: 0.375em
}
</style>
