<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body px-0 py-0">
              <el-table
                class="
                  matchesTable
                  table-responsive
                  align-items-center
                  table-flush
                "
                :data="matches"
                row-key="id"
                header-row-class-name="thead-light"
              >
                <el-table-column type="expand">
                  <template v-slot="{ row }">
                    <reels :match="row"></reels>
                  </template>
                  </el-table-column>
                  <!-- <el-table-column label="Ellipse" prop="ellipseMatchId" sortable>
                </el-table-column> -->
                <el-table-column label="Home" prop="team1Name" sortable>
                </el-table-column>
                <el-table-column label="Away" prop="team2Name" sortable>
                </el-table-column>
                <el-table-column label="Date" prop="startDate" sortable>
                  <template v-slot="{ row }">
                    {{ row.startDate | date }}
                  </template>
                </el-table-column>
                <el-table-column label="venue" prop="ground">
                  <template v-slot="{ row }">
                    <venue-look-up :venueID="row.ground"></venue-look-up>
                  </template>
                </el-table-column>
                <el-table-column label="Comp Stage" prop="competition" sortable>
                </el-table-column>
                <el-table-column
                  label="Comp Name"
                  prop="competitionName"
                  sortable
                >
                </el-table-column>
                <!-- <el-table-column label="Type" prop="cricketType">
                  <template v-slot="{ row }">
                    <type-look-up :typeID="row.cricketType"></type-look-up>
                  </template>
                </el-table-column> -->
                <el-table-column label="Format" prop="cricketFormat">
                  <template v-slot="{ row }">
                    <format-look-up
                      :formatID="row.cricketFormat"
                    ></format-look-up>
                  </template>
                </el-table-column>

                <!-- <el-table-column min-width="200px" prop="name">
                <template v-slot="{ row }">
                  <a
                    :href="'#/archive/match/' + row.id"
                    class="font-weight-bold"
                    >Go To Media</a
                  >
                </template>
              </el-table-column> -->

                <!-- <el-table-column min-width="200px" label="delete fixture" prop="name">
                <template v-slot="{ row }">
                <base-button @click="deleteFixture(row)">DELETE!</base-button>
                </template>
              </el-table-column> -->
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReelsExpander from "./Components/ReelsExpander";
import VenueLookUp from "../Archive/Components/VenueLookUp";
import TypeLookUp from "../Archive/Components/TypeLookUp";
import FormatLookUp from "../Archive/Components/FormatLookUp";

export default {
  name: "highlights",
  components: {
    reels: ReelsExpander,
    "venue-look-up": VenueLookUp,
    "type-look-up": TypeLookUp,
    "format-look-up": FormatLookUp,
  },
  data() {
    return {
      compFilter: "",
      homeFilter: "",
      awayFilter: "",
      roundFilter: "",
      dayFilter: "",
      teams: [
        "Derbyshire",
        "Durham",
        "Essex",
        "Glamorgan",
        "Gloucestershire",
        "Hampshire",
        "Kent",
        "Lancashire",
        "Leicestershire",
        "Middlesex",
        "Northamptonshire",
        "Nottinghamshire",
        "Somerset",
        "Surrey",
        "Sussex",
        "Warwickshire",
        "Worcestershire",
        "Yorkshire",
      ],
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    logos() {
      return this.$store.getters["files/streamLogos"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    matches() {

      // filter out hundred
      let matches = this.$store.getters["matches/selectQuery"];
      return matches.filter(f => f.cricketFormat != '4') //filters out The Hundred
    },
    // highlights() {
    //   let arr = this.$store.getters["files/byWhat"]("domestic-highlights");
    //   arr = this.compFilter
    //     ? arr.filter((p) => p.match.comp === this.compFilter)
    //     : arr;
    //   arr = this.homeFilter
    //     ? arr.filter((p) => p.match.home === this.homeFilter)
    //     : arr;
    //   arr = this.awayFilter
    //     ? arr.filter((p) => p.match.away === this.awayFilter)
    //     : arr;
    //   arr = this.roundFilter
    //     ? arr.filter((p) => p.match.round === this.roundFilter)
    //     : arr;
    //   arr = this.dayFilter
    //     ? arr.filter((p) => p.match.day === this.dayFilter)
    //     : arr;
    //   arr = arr.sort((a, b) => {
    //     if (a.created_at > b.created_at) {
    //       return -1;
    //     }
    //     if (a.created_at < b.created_at) {
    //       return 1;
    //     }
    //     if (!a.created_at) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    //   return arr.slice(0, 30);
    // },
  },
  mounted() {
    this.$store.dispatch("teams/fetchAndAdd");
    this.$store.dispatch("venues/fetchAndAdd");
    // this.$store.dispatch("files/openDBChannel", {
    //   where: [["what", "==", "domestic-highlights"]],
    //   limit: 0,
    // });

    let now = new Date();
    now.setDate(now.getDate()+1) // add a day on for less than query
    let year = now.getFullYear();


    this.$store.commit("matches/RESET_VUEX_EASY_FIRESTORE_STATE");
    let filters = this.filters;
    let where = [
      ["int", "==", false],
      // ["cricketFormat", "==", "4"],
      ["year", "==", year],
      ["startDate", "<", now],
    ];
// console.log(where)
    this.$store.dispatch("matches/fetchAndAdd", {
      clauses: { where, limit: 500 },
    });
  },
  methods: {
    deleteFixture(row) {
      console.log(row.id)
      this.$store.dispatch('matches/delete', row.id)
    }
  },
};
</script>

<style>
.matchesTable {
  border-radius: 0.375rem;
}
</style>
