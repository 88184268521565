<template>
  <div>
    <base-input v-if="selection.type == 'single-search'" :label="selection.label">
      <el-select filterable remote :placeholder="selection.label" :remote-method="remoteMethod" v-model="value"
        @input="$emit('valueChange', value)">
        <el-option v-for="option in searchResults" :key="option._id" :label="option._source[selection.searchField]"
          :value="option._id">
        </el-option>
      </el-select>
    </base-input>
    <base-input v-if="selection.type == 'single-select' && selection.collectionIsArray" :label="selection.label">
      <el-select filterable :placeholder="selection.label" v-model="value" @input="$emit('valueChange', value)">
        <el-option v-for="option in values" :key="option" :label="option" :value="option">
        </el-option>
      </el-select>
    </base-input>
    <base-input v-if="(selection.type == 'single-select') & !selection.collectionIsArray" :label="selection.label">
      <el-select filterable :placeholder="selection.label" v-model="value" @input="$emit('valueChange', value)">
        <el-option v-for="option in values" :key="option.id" :label="option[selection.collectionLabel]"
          :value="option.id">
        </el-option>
      </el-select>
    </base-input>
    <base-input v-if="selection.type == 'multi-select'" :label="selection.label">
      <el-select filterable multiple :placeholder="selection.label" v-model="value" @input="$emit('valueChange', value)">
        <el-option v-for="option in values" :key="option.id" :label="option[selection.collectionLabel]"
          :value="option.id">
        </el-option>
      </el-select>
    </base-input>
    <base-input type="number" v-model="value" v-if="selection.type == 'number'" @input="$emit('valueChange', value)"
      :label="selection.label">
    </base-input>
    <base-input type="text" v-model="value" v-if="selection.type == 'text'" @input="$emit('valueChange', value)"
      :label="selection.label">
    </base-input>
    <base-checkbox v-model="value" v-if="selection.type == 'boolean'" @input="$emit('valueChange', value)">{{
      selection.label }}
    </base-checkbox>
    <base-input v-if="selection.type == 'date-range'" :label="selection.label">
      <flat-picker slot-scope="{ focus, blur }" @on-open="focus" @on-close="blur"
        :config="{ allowInput: true, mode: 'range' }" class="form-control datepicker" v-model="value"
        :placeholder="selection.label" @input="$emit('valueChange', value)">
      </flat-picker>
    </base-input>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Firebase } from "@/firebase";
export default {
  data() {
    return { value: "", searchResults: [] };
  },
  props: ["selection", "preValue"],
  components: {
    flatPicker,
  },
  beforeMount() {
    if (this.selection.type === "boolean") {
      this.value = true;
      this.$emit("valueChange", true);
    }
    if (this.preValue) {
      this.value = this.preValue
    }
  },
  watch: {
    preValue: function (newOne) {
      // console.log(newOne)
      this.value = newOne
    }
  },
  computed: {
    values() {
      const col = this.selection.collection;
      const getter = this.selection.collectionGetter
        ? this.selection.collectionGetter
        : "array";
      return this.$store.getters[`${col}/${getter}`];
    },
  },
  methods: {
    async remoteMethod(query) {
      if (query !== "") {
        let payload = {
          response: null,
          request: {
            index: "players",
            body: {
              query: {
                bool: {
                  should: [
                    {
                      match: {},
                    },
                  ],
                },
              },
            },
          },
        };

        payload.request.body.query.bool.should[0].match[
          this.selection.searchField
        ] = { query: query, fuzziness: "AUTO" };

        const result = await Firebase.firestore()
          .collection("search")
          .add(payload);

        Firebase.firestore()
          .collection("search")
          .doc(result.id)
          .onSnapshot((doc) => {
            let d = doc.data();
            if (d.response !== null) {
              // Do things
              this.searchResults = d.response.hits.hits;
            }
          });
      }
    },
  },
};
</script>