<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="platformFilter"
                      placeholder="Platform"
                      clearable
                    >
                      <el-option
                        v-for="option in platforms"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="authorFilter"
                      placeholder="Author"
                      clearable
                    >
                      <el-option
                        v-for="option in authors"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
                   <masonry
            :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
            :gutter="30"
          >
           <inspiration
              v-for="insp in inspirations"
              :key="insp.id"
              :inspiration="insp"
            >
   
            </inspiration>
                   </masonry>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";
import inspiration from "./Components/InspirationCard.vue"
import moment from "moment";
export default {
  name: "Inspirations",
  components: {
    inspiration
  },
  data() {
    return {
      search: "",
      platformFilter: "",
      authorFilter: "",
    };
  },
  beforeMount() {
  this.$store.commit("inspirations/RESET_VUEX_EASY_FIRESTORE_STATE");
    this.$store.dispatch("inspirations/fetchAndAdd", {
      where: [[`favourites.${this.user.id}`,'==',true]]
    });
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      return this.$store.getters.config;
    },
    authors() {
      let authors = [];
      const ins = this.allIns;
      ins.forEach((i) => {
        authors.push(i.author);
      });
      let uniq = [...new Set(authors)];
      return uniq;
    },
    platforms() {
      let plats = [];
      const ins = this.allIns;
      ins.forEach((i) => {
        plats.push(i.platform);
      });
      let uniq = [...new Set(plats)];
      return uniq;
    },
    allIns () {
return this.$store.getters["inspirations/inspirations"]
    },
    inspirations() {
      const ins = this.allIns
      let plats
      if (this.platformFilter === '') {
        plats = ins
      } else {
        plats = ins.filter(p => p.platform === this.platformFilter)
      }
      let status
      if (this.authorFilter === '') {
        status = plats
      } else {
        status = plats.filter(p => p.author === this.authorFilter)
      }
      return status;
    },
  },
  methods: {},
};
</script>
<style scoped>
@media (min-width: 34em) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 48em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 75em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 100em) {
  .card-columns {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
}
</style>
