<template>
  <div>
    <form class="new-event--form">
      <base-input label="Type">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="cost.type"
          filterable
          placeholder="Select"
          @change="changeField($event, 'type')"
        >
          <el-option
            v-for="costType in costTypes"
            :value="costType"
          ></el-option>
        </el-select>
      </base-input>
      <base-input label="Shoot (if applicable)">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="cost.shoot"
          filterable
          placeholder="Select"
          @change="changeField($event, 'shoot')"
        >
          <el-option
            v-for="shoot in shoots"
            :label="shoot.shootTitle + ' | ' + shoot.start"
            :value="shoot.id"
          ></el-option>
        </el-select>
      </base-input>
      <div class="row">
        <div class="col">
          <label class="col-form-label form-control-label">Purchase Date</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input>
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="{ allowInput: true }"
              class="form-control datepicker"
              v-model="cost.date"
              @input="changeField($event, 'date')"
            >
            </flat-picker>
          </base-input>
        </div>
      </div>

      <base-input label="Amount" type="number" v-model="cost.amount"  @input="changeField($event, 'amount')"><template slot="prepend">£</template></base-input>
      <base-input label="Description" placeholder="Basic summary" v-model="cost.description"  @change="changeField($event.target.value, 'description')"> </base-input>
      <base-input label="Budget">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="cost.budget"
          filterable
          @change="changeField($event, 'budget')"

        >
          <el-option
            v-for="prod in this.budgets"
            :key="prod.id"
            :label="prod.name"
            :value="prod.id"
          >
          </el-option>
        </el-select>
      </base-input>
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <button

        type="submit"
        class="btn btn-danger new-event--add"
        @click="deleteCost()"
      >
      Delete
      </button>

      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Firebase } from "@/firebase";

export default {
  name:"cost-edit-form",
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
    };
  },
  components: { flatPicker },
  props: ["durations", "costId", "isBriefForm"],
  computed: {
    cost() {
      if (this.costId) {
        return this.$store.getters["costs/byId"](this.costId);
      } else {
        return {};
      }
    },
    costTypes() {
      return this.$store.getters["config/config"]["costTypes"];
    },
    shoots() {
      return this.$store.getters["shoots/byBriefId"](this.cost.productionId);
    },
    budgets() {
      return this.$store.getters["budgets/active"]
    },
  },

  methods: {
    closeModals() {
      this.$emit("closeModals");
    },
    changeField(value, field) {
      console.log(value)
      var payload = {};
      payload.id = this.costId;
      payload[field] = value;
      this.$store.dispatch("costs/patch", payload);
    },
    changeProduction(value) {
      var payload = {};
      payload.id = this.costId;
      payload.productionId = value;
      payload.title = this.production.title;
      this.$store.dispatch("costs/patch", payload);
    },
    changeDays(value) {
      let payload = {};
      payload.id = this.costId;
      payload.days = value;
      if (this.cost.billing) {
        payload.cost = this.billingDetail.dayRate * value;
      }
      this.$store.dispatch("costs/patch", payload);
    },
    changeBilling(value) {
      var payload = {};
      payload.id = this.costId;
      payload.billing = value;
      payload.backgroundColor = this.billingDetail.colour;
      payload.cost = this.billingDetail.dayRate * this.cost.days;
      if (this.billingDetail.invoice) {
            payload.invoiceable = true;
          } else {
            payload.invoiceable = false;
          }
      this.$store.dispatch("costs/patch", payload);
    },
    strDate(time) {
      if (time.hour() === 0) {
        return time.format("YYYY-MM-DD");
      } else {
        return time.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss");
      }
    },
    deleteCost() {
      this.$store.dispatch("costs/delete", this.costId);
      this.closeModals();
    },

  },
};
</script>

