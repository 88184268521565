<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="tagsFilter"
                      placeholder="Tags"
                  
                      clearable
                    >
                      <el-option
                        v-for="option in tags"
                        :key="option.text"
                        :label="`${option.text}: ${option.value}`"
                        :value="option.text"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="platformFilter"
                      placeholder="Platform"
                      clearable
                    >
                      <el-option
                        v-for="option in platforms"
                        :key="option.text"
                        :label="`${option.text}: ${option.value}`"
                        :value="option.text"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="authorFilter"
                      placeholder="Author"
                      clearable
                    >
                      <el-option
                        v-for="option in authors"
                        :key="option.text"
                        :label="`${option.text}: ${option.value}`"
                        :value="option.text"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <!-- <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="posterFilter"
                      placeholder="Poster"
                      clearable
                    >
                      <el-option
                        v-for="option in posters"
                        :key="option.text"
                        :label="`${option.text}: ${option.value}`"
                        :value="option.text"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <!-- <div class="col">
          Showing {{ pagination.from + 1 }} to {{ pagination.to }} of
          {{ inspirations.length }} files
        </div> -->
        <div class="col">
          <base-pagination
            class="pagination-no-border"
            align="center"
            v-model="pagination.currentPage"
            :perPage="pagination.chunkSize"
            :total="inspirations.length"
          >
          </base-pagination>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-3">
          <div class="card">
            <div class="card-header">Tags</div>
            <div class="card-body">
              <div
                class="btn-sm btn btn-secondary"
                v-for="(tag, index, key) in tags"
              >
                {{ index }}: {{ tag }}
              </div>
            </div>
          </div>
        </div> -->
        <div class="col">
          <masonry
            :cols="{ default: 4, 1400: 3, 1000: 2, 700: 1 }"
            :gutter="30"
          >
            <inspiration
              v-for="insp in inspirationsSliced"
              :key="insp.id"
              :inspiration="insp"
            >
            </inspiration>
          </masonry>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";
import inspiration from "./Components/InspirationCard.vue";
import moment from "moment";
// import {useInspirationsStore} from '@/stores/inspirations'

export default {
  name: "Inspirations",
  components: {
    inspiration,
  },
  data() {
    return {
      search: "",
      platformFilter: "",
      authorFilter: "",
      tagsFilter: "",
      pagination: {
        currentPage: 1,
        chunkSize: 25,
      }
    };
  },
  beforeMount() {
    this.$store.dispatch("inspirations/openDBChannel");
    this.$store.dispatch("inspirationTags/openDBChannel"); //get record
    // useInspirationsStore().fetchAndAdd();
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      return this.$store.getters.config;
    },
    allIns() {
      // return useInspirationsStore().arr
      return this.$store.getters["inspirations/inspirations"]; 
    },
    // posters() {
    //   let plats = {};
    //   let vars = [];
    //   const ins = this.inspirations;
    //   ins.forEach((i) => {
    //     if (plats[i.author]) {
    //       plats[i.author]++;
    //     } else {
    //       plats[i.author] = 1;
    //     }
    //   });
    //   for (const [key, value] of Object.entries(plats)) {
    //     vars.push({ text: key, value: value });
    //   }
    //   return vars.sort((a, b) => {
    //     if (a.value < b.value) {
    //       return 1;
    //     }
    //     if (a.value > b.value) {
    //       return -1;
    //     }
    //     if (!a.value) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    // },
    authors() {
      let plats = {};
      let vars = [];
      const ins = this.inspirations;
      ins.forEach((i) => {
        if (plats[i.author]) {
          plats[i.author]++;
        } else {
          plats[i.author] = 1;
        }
      });
      for (const [key, value] of Object.entries(plats)) {
        vars.push({ text: key, value: value });
      }
      return vars.sort((a, b) => {
        if (a.value < b.value) {
          return 1;
        }
        if (a.value > b.value) {
          return -1;
        }
        if (!a.value) {
          return -1;
        }
        return 0;
      });
    },
    platforms() {
      let plats = {};
      let vars = [];
      const ins = this.inspirations;
      ins.forEach((i) => {
        if (plats[i.platform]) {
          plats[i.platform]++;
        } else {
          plats[i.platform] = 1;
        }
      });
      for (const [key, value] of Object.entries(plats)) {
        vars.push({ text: key, value: value });
      }
      return vars.sort((a, b) => {
        if (a.value < b.value) {
          return 1;
        }
        if (a.value > b.value) {
          return -1;
        }
        if (!a.value) {
          return -1;
        }
        return 0;
      });
    },
    tags() {
      let plats = {};
      let vars = [];
      const ins = this.inspirations;
      ins.forEach((i) => {
        // for each inspiration
        if (i.tags) {
          // if it has tags
          i.tags.forEach((tag) => {
            // for each tag
            if (plats[tag]) {
              plats[tag]++;
            } else {
              plats[tag] = 1;
            }
          });
        }
      });
      for (const [key, value] of Object.entries(plats)) {
        vars.push({ text: key, value: value });
      }
      return vars.sort((a, b) => {
        if (a.value < b.value) {
          return 1;
        }
        if (a.value > b.value) {
          return -1;
        }
        if (!a.value) {
          return -1;
        }
        return 0;
      });
    },
    posters() {
      let plats = [];
      const ins = this.inspirations;
      ins.forEach((i) => {
        plats.push(i.created_by);
      });
      let uniq = [...new Set(plats)];
      return uniq;
    },
    inspirations() {
      const ins = this.allIns;
      let plats;
      if (this.platformFilter === "") {
        plats = ins;
      } else {
        plats = ins.filter((p) => p.platform === this.platformFilter);
      }
 
      if (this.authorFilter !== "") {
        plats = plats.filter((p) => p.author === this.authorFilter);
      }
      if (this.tagsFilter !== "") {
        plats = plats.filter((p) => {
          if(p.tags){
            return p.tags.includes(this.tagsFilter)
          }
          }
          );
      }
      return plats
    },
    inspirationsSliced() {
return this.inspirations.slice(this.pagination.chunkSize * (this.pagination.currentPage - 1), this.pagination.chunkSize * (this.pagination.currentPage));
    }
  },
  methods: {},
};
</script>
<style scoped>
@media (min-width: 34em) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 48em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 75em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 100em) {
  .card-columns {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
}
</style>
