<template>
<div>{{user.name}}
</div>
</template>
<script>
export default {
    props: ['id'],
    computed: {
        user() {
            return this.$store.getters['users/byId'](this.id)
        }
    }
}
</script>