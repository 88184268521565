<template>
  <div class="row">
    <div class="col">
      {{ rtmpDB.name }}
    </div>
    <div class="col">
      {{ rtmp.state }}
    </div>
    <!-- <div class="col-auto">
      <base-button outline size="sm" type="danger" @click.prevent="$emit('stop', rtmp)">
        Stop
      </base-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["rtmp"],
  computed: {
    url() {
      const r = this.rtmp.connection_info;
      const protocol = r.rtmps ? "rtmps://" : "rtmp://";
      return `${protocol}${r.host}/${r.application}/${r.streamName}`;
    },
    rtmpDB() {
      return this.$store.getters["rtmpOutputs/byKey"](this.rtmp.connection_info.streamName);
    },
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>