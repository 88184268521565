<template>
  <div>
    <div class="row mb-2" v-on:drop="drop" v-on:dragover="allowDrop">
      <div class="col-1">
        <base-button size="lg" type="secondary" class="btn-icon-only" @click="open = !open">
          <span v-if="open"><i class="fas fa-folder-open"></i></span>
          <span v-else><i class="fas fa-folder"></i></span>
        </base-button>
      </div>

      <!-- <div class="col" v-if="editFolderMode">
            <base-input
              v-model="folder.display"
              @input="renameFolder($event, folder.id)"
            />
          </div> -->

      <div class="col">
        <div class="col-form-label">
          <span class="numberBlock">
            {{ dels.length }}
          </span>
          <span>
            {{ groupName }}
          </span>
        </div>
      </div>
      <!-- <div class="col-auto" v-if="editFolderMode">
            <base-button
              type="danger"
              class="btn-icon-only"
              @click="deleteFolder(folder.id)"
            >
              <span><i class="fas fa-folder-minus"></i></span>
            </base-button>
          </div>-->
    </div>
    <div v-if="open">
      <div v-if="displayMode === 'spreadsheet'">
        <ul class="list-group deliverable">
          <li class="list-group-item" v-for="del in sortedDels" :key="del.id">
            <div class="row">
              <div class="col">
                <deliverable-row :deliverable="del" @editDel="editDel" :previews="previews">
                </deliverable-row>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else v-on:drop="drop" v-on:dragover="allowDrop">
        <masonry :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }" :gutter="30">
          <deliverable-card v-for="del in dels" :key="del.id" :deliverable="del" @editDel="editDel">
          </deliverable-card>
        </masonry>
      </div>
    </div>
  </div>
</template>

<script>
import DeliverableRow from "./DeliverableRow";
import DeliverableCard from "./DeliverableCard";

// style import
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "widgetGroups",
  data: function () {
    return {
      open: false,
    };
  },
  props: ["groupName", "dels", "displayMode", "sortProp", "previews"],
  components: {
    "deliverable-card": DeliverableCard,
    "deliverable-row": DeliverableRow,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    prodID() {
      return this.prod.id;
    },

    config() {
      return this.$store.getters["config/config"];
    },

    groups() {
      return Object.keys(this.groupedDels);
    },
    sortedDels() {
      return this.dels.sort((a, b) => {
        if (a[this.sortProp] < b[this.sortProp]) {
          return 1
        }
        if (a[this.sortProp] > b[this.sortProp]) {
          return -1
        }
        if (!a[this.sortProp]) {
          return 1
        }
        return 0
      })
    },
  },
  beforeMount() {
    console.log(this.groupName)
    if (!this.groupName) {
      this.open = true
    }
  },
  methods: {
    editDel(event) {
      this.$emit("editDel", event);
    },
    drop: function (ev) {
      ev.preventDefault()
      let data =  JSON.parse(ev.dataTransfer.getData("text/plain"))
      console.log(data)
if(data.currentFolder !== this.groupName ) {
      let payload = { id: data.delId }
      payload.folderName = this.groupName
      this.$store.dispatch("deliverables/patch", payload);
}

    },
    allowDrop: function (event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
ul.folders>li {
  background: #f8f8f8;
}

.numberBlock {
  width: 30px;
  display: inline-block;
}
</style>
