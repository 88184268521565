<template>
  <div class="container">
    <div class="row mb-5" v-if="user.roles.archive_uploader">
      <div class="col">
        <archive-upload-wrapper
          boxHeight="90px"
          :match="match.id"
        ></archive-upload-wrapper>
      </div>
    </div>
    <div class="row" v-if="reels.length>0">
      <div class="col-12">
        <h3 class="mb-3">Reels</h3>
      </div>
      <div v-for="(reel, index) in reels" :key="index" class="col-4 mb-4">
        <!-- <a href="/#/archive/match/recording">{{recording.name}}</a> -->
        <!-- <router-link class="btn" :to="'/archive/recording/' + recording.id"
        >Day: {{ recording.day }}, Part: {{ recording.part }}</router-link
      > -->
        <reel-card :file="reel" :matchData="match"> </reel-card>
      </div>
    </div>
    <div class="row" v-if="recordings.length>0">
        <div class="col-12">
        <h3 class="mb-3">Recordings</h3>
      </div>
      <div v-for="(recording, index) in recordings" :key="index" class="col-3">
        <!-- <a href="/#/archive/match/recording">{{recording.name}}</a> -->
        <router-link class="btn" :to="'/archive/recording/' + recording.id"
          >Day: {{ recording.day }}, Part: {{ recording.part }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/routes/router";
import ArchiveUploader from "./ArchiveUploadWrapper";
import ReelCard from "./ReelCard";

export default {
  data() {
    return {};
  },
  props: ["match"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    reels() {
      var arr = this.$store.getters["files/whatByMatch"]({
        what: "match-reel",
        id: this.match.id,
      });
      return arr;
    },
    recordings() {
      var arr = this.$store.getters["files/recordingsByMatch"](this.match.id);
      return arr.sort(function (a, b) {
        var keyA1 = parseInt(a.day),
          keyA2 = parseInt(a.part),
          keyB1 = parseInt(b.day),
          keyB2 = parseInt(b.part);

        // Compare the 2 keys
        if (keyA1 < keyB1) return -1;
        if (keyA1 > keyB1) return 1;
        if (keyA2 < keyB2) return -1;
        if (keyA2 > keyB2) return 1;
        return 0;
      });
    },
  },
  components: {
    "archive-upload-wrapper": ArchiveUploader,
    "reel-card": ReelCard,
  },
  mounted() {
        this.$store.dispatch("files/fetchAndAdd", {
      where: [
        ["what", "==", "match-reel"],
        ["match", "==", this.match.id],
      ],
    });
  
    this.$store.dispatch("files/fetchAndAdd", {
      where: [
        ["what", "==", "recording"],
        ["match", "==", this.match.id],
      ],
    });
  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>