<template>
  <base-checkbox :type="colour" v-model="roleValue" @input="setRole($event)">{{
    label
  }}</base-checkbox>
</template>

<script>
export default {
  name: "RoleSelector",
  props: ["role", "label", "userId", "users", "colour", ],
  data() {
    return {
      value: false,
    };
  },
  computed: {
    roleValue() {
      if (this.userId) {
        let roles = {};
        roles = this.$store.getters["users/roles"](this.userId);
        if (roles) {
          if (roles[this.role]) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (this.users) {
        return this.value;
      }
    },
  },
  methods: {
    setRole(event) {
      if (this.userId) {
        console.log(this.role);
        var payload = {};
        payload.id = this.userId;
        payload.roles = {};
        payload.roles[this.role] = event;
        this.$store.dispatch("users/patch", payload);
      } else if (this.users) {
        this.value = event;
        this.users.forEach((user) => {
          var payload = {};
          payload.id = user.id;
          payload.roles = {};
          payload.roles[this.role] = event;
          console.log(payload);
          this.$store.dispatch("users/patch", payload);
        });
      }
    },
  },
};
</script>

<style></style>
