<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header border-0">
              <div class="row">
                <div class="col-6">
                  <span>
                    <label class="small preview-label"
                      >Include Inactive</label
                    ></span
                  >
                  <span>
                    <base-switch
                      class="preview-switch"
                      v-model="includeInactive"
                      offText="No"
                      onText="Yes"
                    ></base-switch
                  ></span>
                </div>
                <div class="col-6 text-right" v-if="user.roles.production_admin">
                  <el-tooltip content="New" placement="top">
                    <base-button
                      type="warning"
                      icon
                      size="sm"
                      @click="addBudget"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fas fa-plus-square"></i
                      ></span>
                      <span class="btn-inner--text">Add New Budget</span>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <!-- Card body -->

            <el-table
              class="
                table-responsive
                align-items-center
                table-flush table-striped
                jimTable
              "
              header-row-class-name="thead-light"
              :data="budgets"
              :default-sort="{ prop: 'name', order: 'ascending' }"
            >
              <el-table-column
                label="Name"
                min-width="200"
                prop="name"
                sortable
              >
                <div slot-scope="{ row }" class="table-actions">
                  <a :href="`/#/budget/${row.id}`">{{ row.name }}</a>
                </div>
              </el-table-column>
              <el-table-column
                label="Company"
                prop="company"
                :filters="producers"
                :filter-method="filterHandler"
              >
              </el-table-column>

              <el-table-column label="Department" prop="department" sortable>
              </el-table-column>
            
              <el-table-column label="Active" prop="status" width="80">
                <div slot-scope="{ row }" class="table-actions">
                  <base-checkbox disabled v-model="row.active"> </base-checkbox>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BillingName from "./Components/BillingName.vue";

export default {
  name: "budgets",
  data: function () {
    return {
      includeInactive: false,
    };
  },
  // props: ['id'],
  // components: {
  //   BillingName,
  // },
  beforeMount() {
    this.$store.dispatch("budgets/fetchAndAdd");
  },
  computed: {
    loading() {
      return this.$store.getters["user/loading"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    budgets() {
      let res = this.$store.getters["budgets/budgets"];
      if (this.includeInactive) {
        return res;
      } else {
        return res.filter((a) => a.active === true);
      }
    },
    producers() {
      let groupsTwo = [];
      for (const element of this.config.producer) {
        groupsTwo.push({
          text: element,
          value: element,
        });
      }
      return groupsTwo;
    },
  },
  methods: {
    changeDetail(value, key, field) {
      var payload = {};
      payload.id = key;
      payload[field] = value;
      this.$store.dispatch("budgets/patch", payload);
    },
    async addBudget() {
      let id = await this.$store.dispatch("budgets/insert", {
        name: "New Budget",
        active: false,
      });
      this.$router.push(`/budget/${id}`);
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>
<style>
</style>
