const competitions = {
    firestorePath: 'competitions',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'competitions',
    statePropName: 'data',
    namespaced: true, // automatically added
    // serverChange: {
    //   convertTimestamps: {
    //     startDate: '%convertTimestamp%',
    //   },
    // },
    state: {
    },
    getters: {
      array: state => Object.values(state.data),
      obj: state => state.data,
      byId: (state) => (id) => {
        return state.data[id]
      },
    },
    mutations: {
    },
    actions: {
    },
  }
  
  export default competitions