<template>
  <div>
    <NewFile
      :boxHeight="boxHeight"
      :multiple="true"
      message="Drop files or click to browse, to create deliverables for this brief"
      :accepted="allowedFileTypes"
      :fileName="false"
      :basePath="basePath"
      @success="success"
      :randomSuffix="true"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-content",
  props: ["prodID", "type", "brand", "dimensions", "boxHeight", "folderName", "state"],
  components: {
    NewFile,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    allowedFileTypes() {
      if (this.type) {
        const delType = this.type;
        const allTypes = this.config.delTypes;
        let thisType = allTypes.filter((f) => f.display === delType);
        return thisType[0].allowedFiles;
      } else {
        return "*";
      }
    },
    production() {
      return this.$store.getters["briefs/briefById"](this.prodID);
    },
    basePath() {
      const prodTitle = this.production.title.replace(/[\W_]+/g, "_");
      let year = new Date().getFullYear();
      let department = this.production.department;
      let unique = this.ID
      return `files/${year}/${department}/${prodTitle}`;
    },
  },
  methods: {
    success(payload) {
      payload.what = "deliverable";
      payload.version = 1;
      payload.prodTitle = this.production.title;
      payload.prodID = this.prodID;
      payload.department = this.production.department;
      payload.status = this.state === 'feedback' ? 'review' : 'signed';
      payload.events = [
        {
          action: "Uploaded",
          user: this.user.name,
          date: new Date(),
        },
      ];
      // commit file to DB
      this.$store.dispatch("files/insert", payload).then((fileID) => {
        //update deliverable state
        let delPayload = {};
        delPayload.state = this.state;
        delPayload.currentFileID = fileID;
        delPayload.currentVersion = 1;
        delPayload.productionTitle = this.production.title;
        if (this.production.partner) {
        delPayload.partner = this.production.partner;
        }
        delPayload.department = this.production.department;
        delPayload.title = payload.sourceFileNameAsUploaded;
        delPayload.owners = this.production.owners
         if (this.production.collections) {
        delPayload.collections = this.production.collections;
        }
        delPayload.production = this.prodID;
        delPayload.brand = this.brand
        delPayload.type = this.type
        delPayload.folderName = this.folderName
        delPayload.format = this.dimensions
        delPayload.reviewStatus = {};
        this.$store
          .dispatch("deliverables/insert", delPayload)
          .then((delID) => {
            let filePayload = {};
            filePayload.delID = delID;
            filePayload.id = fileID;
            this.$store.dispatch("files/patch", filePayload);
          });
      });
    },
  },
};
</script>

<style scoped>
</style>