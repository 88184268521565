const costs = {
  firestorePath: 'costs',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'costs',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    costs: state => Object.values(state.data),
    byId: (state) => (id) => {
      return state.data[id]
    },
    byBrief: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.productionId === id)
    },
    byBudget: (state) => (id) => {
      const shifts = Object.values(state.data)
      return shifts.filter(b => b.budget === id)
    }
  },
  mutations: {},
  actions: {},
}

export default costs