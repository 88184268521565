<template>
  <div>
    {{player.fullName}}
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["id"],
  computed: {
    player() {
      return this.$store.getters["players/byId"](this.id);
    },
  },
  components: {},
  mounted() {

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>