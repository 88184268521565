<template>
  <div class="row">
    <div class="col">
      <h4 v-if="resource">{{ resource.displayName }}</h4>
      <div>{{ billing.label }}</div>
      <div>{{ shift.start }} - days: {{ shift.days }}</div>
      <div>£{{ cost }}</div>

      <div class="row">
        <div class="col">
          <small>Created: {{ shift.created_at  | dateTime }} by {{ creater }}</small>
            </div>
        <div class="col" v-if="shift.updated_at">
          <small>Updated: {{ shift.updated_at | dateTime }} by {{ updater }}</small>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <base-button type="primary" @click="edit">Edit</base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "shift-list",
  props: ["shift"],
  data() {
    return {};
  },
  computed: {
    cost () {
      return Number(this.shift.cost).toFixed(2);
    },
    creater() {
      return this.$store.getters["users/byId"](this.shift.created_by).name;
    },
    updater() {
        return this.$store.getters["users/byId"](this.shift.updated_by).name;
    },
    resource() {
        let res = this.$store.getters["resources/byId"](this.shift.resourceId);
        if(res) {
            return res
        } else {
            this.$store.dispatch("resources/fetchById", this.shift.resourceId);
        }
    },
    billing() {
        let res = this.$store.getters["billings/byId"](this.shift.billing);
        if(res) {
            return res
        } else {
            this.$store.dispatch("billings/fetchById", this.shift.billing);
        }
    }
  },
  methods: {
    edit() {
      this.$emit("edit");
    },
  },
};
</script>