const matchFormats = {
  firestorePath: 'config/default/matchFormats',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'matchFormats',
  statePropName: 'data',
  namespaced: true, // automatically added

  state: {
    types: [
      { id: '1', name: 'Men 1st XI' },
      { id: '2', name: 'Men 2nd XI' },
      { id: '3', name: "Women 1st XI" },
      { id: '4', name: "Men Disability" }
    ],
    formats: [
      { id: '1', name: 'Multi-Day' },
      { id: '2', name: 'One-Day' },
      { id: '3', name: 'T20' },
      { id: '4', name: 'The Hundred' }
    ],
    bowlLength: ["Back of a Length", "Beamer", "Bouncer", "Full", "Full Toss", "Half Tracker", "Half Volley", "Length Ball", "Short", "Yorker"],
    bowlLine: ["Down Leg", "Leg Stump", "Middle Stump", "Off Stump", "Outside Off", "Wide", "Wide Down Leg"],
    bowlMove: ["Angle Across", "Angle In", "Arm Ball", "Away Swinger", "Back of Hand", "Carrom Ball", "Chinaman", "Cross Seam", "Doosra", "Flipper", "Googly", "In Swinger", "Knuckle Ball", "Left-Arm Leg Spin", "Leg Cutter", "Leg Spinner", "No Movement", "No Turn", "Off Break", "Off Cutter", "Other Way", "Quicker Ball", "Reverse Swing", "Reverse Swing-In", "Reverse Swing-Out", "Seam Away", "Seam In", "Slider", "Slower Ball", "Stock Ball", "Top Spinner", "Turn Away", "Turn In", "Variable Bounce", "Variation", "Wobble Seam"],
    bowlStyle: ["Left Fast", "Left Fast/Medium", "Left Leg Break", "Left Medium", "Left Off Break", "Left Slow", "Pace", "Right Fast", "Right Fast/Medium", "Right Leg Break", "Right Medium", "Right Off Break", "Right Slow", "Spin"],
    fieldAct: ["Catch Assist", "Caught", "Direct Hit", "Dropped Catch", "Fielded", "Mis-Field", "Overthrow", "Run Out Assist", "Run Out Misfield", "Run Save", "Shy Attempt", "Shy Attempt (Out)", "Stumped", "Stumping Missed"],
    fieldPos: ["Backward Point", "Backward Square Leg", "Bowler", "Cover", "Deep Backward Point", "Deep Backward Square Leg", "Deep Cover", "Deep Extra Cover", "Deep Mid Wicket", "Deep Point", "Deep Square Leg", "Extra Cover", "Fine Leg", "First Slip", "Fourth Slip", "Gully", "Leg Gully", "Leg Slip", "Long Off", "Long On", "Mid Off", "Mid On", "Mid Wicket", "Point", "Second Slip", "Short Extra Cover", "Short Fine Leg", "Short Leg", "Short Mid Wicket", "Short Third Man", "Silly Mid Off", "Silly Mid On", "Silly Mid-Off", "Silly Point", "Slips", "Square Leg", "substitute", "Third Man", "Third Slip", "Wicketkeeper"],
    footMove: ["Back Foot", "Backs Away", "Deep in crease", "Down the Track", "Ducked", "Front Foot", "Moves Infront", "No Movement", "Swayed Away"],
    howOut: ["absent", "bowled", "caught", "handled the ball", "hit ball twice", "hit wicket", "lbw", "not out", "obstructing the field", "retired", "run out", "stumped", "timed out"],
    shotType: ["Backward Defensive", "Cut", "Drive", "Dropped", "Fended", "Flick", "Forward Defensive", "Glance", "Hook", "Horizontal Back Attack", "Horizontal Forward Attack", "Lap", "Late Cut", "Leave", "No Shot", "Padded", "Pull", "Pushed", "Reverse Sweep", "Scoop", "Shoulders Arms", "Slog", "Slog-sweep", "Steer", "Sweep", "Switch Hit", "Upper Cut", "Vertical Back Attack", "Worked", "Vertical Forward Attack"],

  },
  getters: {
    formats: state => state.formats,
    types: state => state.types,
    bowlLength: state => state.bowlLength,
    bowlLine: state => state.bowlLine,
    bowlMove: state => state.bowlMove,
    bowlStyle: state => state.bowlStyle,
    fieldAct: state => state.fieldAct,
    fieldPos: state => state.fieldPos,
    footMove: state => state.footMove,
    howOut: state => state.howOut,
    shotType: state => state.shotType,
    formatById: (state) => (id) => {
      return state.formats.filter(del => del.id === id)[0]
    },
    typeById: (state) => (id) => {
      return state.types.filter(del => del.id === id)[0]
    },
  },
  mutations: {},
  actions: {},
}

export default matchFormats




