<template>
  <card :class="selectedClass">
    <!-- <img slot="image" class="card-img-top" src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x900.jpg" alt="Image placeholder"> -->
    <file-preview
      slot="image"
      class="card-img-top"
      :file="currentFile"
    ></file-preview>
    <div class="selector" @click="selectMe">
      <div class="h4 card-title mb-1">{{ deliverable.productionTitle }}</div>
      <div class="h5 card-title mb-2">{{ deliverable.title }}</div>
      <div class="row">
        <div class="col">
          <small class="text-muted" v-if="deliverable.created_at">{{
            deliverable.created_at | elasticsearchDate
          }}</small>
        </div>
        <div class="col">
          <small class="text-muted" v-if="currentFile.metadata"><div v-if="currentFile.metadata.size">{{
        currentFile.metadata.size | fileSize
      }}</div></small>
        </div>
      </div>
      <div>
        <badge v-if="deliverable.type" type="secondary" class="mr-1">
          {{ deliverable.type }}
        </badge>
        <badge v-if="deliverable.brand" type="info" class="mr-1">
          {{ deliverable.brand }}
        </badge>
        <badge v-if="deliverable.partner" type="danger" class="mr-1">
          {{ deliverable.partner }}
        </badge>
      </div>
    </div>
    <div class="mt-3">
      <!-- <el-tooltip location="top" content="No Extra Restrictions">
        <a class="btn btn-sm">
          <i class="fas fa-lock-open worklistIcon"></i>
        </a>
      </el-tooltip> -->
      <!-- <el-tooltip location="top" content="Collections">
        <a class="btn btn-sm">
          <i class="fas fa-folder worklistIcon"></i>
        </a>
      </el-tooltip> -->
      <el-tooltip
        location="top"
        content="Brief"
        v-if="deliverable.production && user.roles.production_access"
      >
        <a class="btn btn-sm" :href="goToBrief">
          <i class="fas fa-file-alt worklistIcon"></i>
        </a>
      </el-tooltip>
      <el-tooltip
        location="top"
        content="Feedback"
        v-if="deliverable.production && user.roles.production_drafts"
      >
        <a class="btn btn-sm" :href="goToAssets">
          <i class="fas fa-comments worklistIcon"></i>
        </a>
      </el-tooltip>
      <el-tooltip location="top" content="Download" v-if="currentFile">
        <a download class="btn btn-sm" :href="currentFile.sourceDownloadURL">
          <i class="fa fa-download worklistIcon"></i>
        </a>
      </el-tooltip>
      <base-button
        v-if="deliverable.collectionUpload"
        size="sm"
        type="danger"
        @click="deleteMe"
        >Delete</base-button
      >
    </div>
  </card>
</template>

<script>
import router from "@/routes/router";

export default {
  components: {},
  data() {
    return {};
  },
  props: ["del", "collection"],
  beforeMount() {
    this.$store.dispatch("deliverables/fetchById", this.delID);
    this.$store.dispatch("files/fetchById", this.deliverable.currentFileID);
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    goToAssets() {
      return `/#/brief/${this.deliverable.production}/deliverable/${this.delID}`;
    },
    goToBrief() {
      return `/#/brief/${this.deliverable.production}`;
    },
    delID() {
      return this.del._id ? this.del._id : this.del.id; //either _id if from elasticsearch, or .id if from vuex
    },
    selected() {
      let test = this.$store.getters["archiveTemp/delState"](this.delID);
      return test ? true : false;
    },
    selectedClass() {
      return this.selected ? "bg-yellow" : "bg-white";
    },
    deliverable() {
      return this.del._id ? this.del["_source"] : this.del; //either use the obj in _source if from elasticsearch, or just the obj if from vuex
    },
    currentFile() {
      let f = this.$store.getters["files/fileById"](
        this.deliverable.currentFileID
      );
      // if (f) {
        return f;
      // } else {
      //   this.$store.dispatch("files/fetchById", this.deliverable.currentFileID);
      // }
    },
  },
  methods: {
    selectMe() {
      this.$store.commit("archiveTemp/delSelect", {
        id: this.delID,
        value: !this.selected,
      });
    },
    deleteMe() {
      this.$store.dispatch("deliverables/delete", this.delID);
    },
  },
};
</script>

<style scoped>
.selector {
  cursor: pointer;
}
</style>