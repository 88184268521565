const venues = {
    firestorePath: 'venues',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'venues',
    statePropName: 'data',
    namespaced: true, // automatically added
  
    state: {
    },
    getters: {
      array: state => Object.values(state.data),
      obj: state => state.data,
      byId: (state) => (id) => {
        return state.data[id]
      },
    },
    mutations: {},
    actions: {},
  }
  
  export default venues