import { Firebase } from '../../firebase.js'

const files = {
  firestorePath: 'files',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'files',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    files: state => Object.values(state.data),
    filesByDelId: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => f.delID === id && f.what != 'subtitle')
    },
    byBriefId: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => f.prodID === id)
    },
    briefReferenceByProdId: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.prodID === id && f.what === 'briefReference'))
    },
    byInspiration: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.inspiration === id))
    },
    recordingsByMatch: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.what === 'recording' && f.match === id))
    },
    whatByMatch: (state) => (payload) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.what === payload.what && f.match === payload.id))
    },
    byMatch: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.match === id))
    },
    archiveAwaiting: (state) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.what === 'archive-awaiting'))
    },
    byWhat: (state) => (what) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.what === what))
    },
    jimstaTeamLogos: (state) => {
      let files = Object.values(state.data)
      let fs = files.filter(f => (f.what === 'jimsta-team-logo'))
      return fs.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        if (!a.label) {
          return -1
        }
        return 0
      })
    },
    fileById: (state) => (id) => {
      return state.data[id]
    },
    byId: (state) => (id) => {
      return state.data[id]
    },
    jimstaBuildsByJimstaId: (state) => (id) => {
      let files = Object.values(state.data)
      return files.filter(f => (f.jimstaID === id && f.what === 'jimsta-build'))
    },
  },
  mutations: {},
  actions: {
    // createMetadata({dispatch}, payload) {
    //   var feedback = Firebase.app().functions('europe-west2').httpsCallable('metadataOnRequest')
    //   // console.log(payload)
    //   feedback(
    //     payload
    //   )
    // },
    sendToBC({ dispatch }, payload) {
      var feedback = Firebase.app().functions('europe-west2').httpsCallable('sendToBC')
      console.log(payload)
      feedback(payload)
    },
    feedbackToSlack({ dispatch }, payload) {
      var feedback = Firebase.app().functions('europe-west2').httpsCallable('slackDeliverableUpdates')
      // console.log(payload)
      feedback({
        link: `/brief/${payload.production.id}/deliverable/${payload.deliverable.id}`,
        message: `Feedback submitted for: `,
        linkText: `${payload.deliverable.title} - v${payload.version}`,
        group: `${payload.production.slackChannelName}`
      })
    },
    signOffToSlack({ dispatch }, payload) {
      var feedback = Firebase.app().functions('europe-west2').httpsCallable('slackDeliverableUpdates')
      // console.log(payload)
      feedback({
        link: `/brief/${payload.production.id}/deliverable/${payload.deliverable.id}`,
        message: `Signed Off: `,
        linkText: `${payload.deliverable.title} - v${payload.version}`,
        group: `${payload.production.slackChannelName}`
      })
    },
    awaitingFeedbackSlack({ dispatch }, payload) {
      var feedback = Firebase.app().functions('europe-west2').httpsCallable('awaitingFeedbackSlack')
      console.log(payload)
      feedback({
        link: `/brief/${payload.production.id}/deliverable/${payload.deliverable.id}`,
        linkText: `${payload.deliverable.title} - v${payload.version}`,
        channel: `${payload.production.slackChannelName}`,
        users: payload.deliverable.owners,
        bucketName: payload.bucketName,
        srcFilename: payload.srcFilename,
        isImage: payload.isImage
      })
    }
  }
}

export default files