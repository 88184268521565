<template>
  <div>
    <base-header
      class="pb-6 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <!--    New Pre-approved User-->
    <modal :show.sync="showAddModal" size="lg" modal-classes="modal">
      <div v-if="!creating">
        <form class="new-user--form">
          <!-- <base-input label="Player">
          <el-select
            input-classes="form-control"
            v-model="player"
            filterable
            placeholder="Select here"
          >
            <el-option
              v-for="play in players"
              :key="play.id"
              :label="play.label"
              :value="play.id"
            >
            </el-option>
          </el-select>
        </base-input> -->

          <base-checkbox v-model="bbcCommentary">BBC Commentary?</base-checkbox>
          <!-- <base-checkbox v-model="commercialContents"
          >Stream contains commercial messages, adverts or logos?</base-checkbox
        > -->
        </form>
        <div class="row">
          <div class="col-6" />
          <div class="col-3">
            <base-button block type="success" @click="createStream"
              >Create</base-button
            >
          </div>
          <div class="col-3">
            <base-button block type="secondary" @click="showAddModal = false"
              >Cancel</base-button
            >
          </div>
        </div>
      </div>
      <div v-else>
        <loading-spinner></loading-spinner>
      </div>
    </modal>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <h3 class="mb-0">Set Up</h3>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col">
                      <base-input label="Competition">
                        <el-select
                          v-model="tournament"
                          placeholder="Select"
                          @input="fetchFixtures()"
                        >
                          <el-option
                            v-for="option in tournaments"
                            :key="option.id"
                            :label="option.title"
                            :value="option.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="row align-items-center" v-if="!tournament">
                <div class="col">
                  <base-input
                  label="Or, set up a non-competition match"
                    v-model="adHocText"
                    placeholder="Enter a Title"
  
                  />
                </div>
                <div class="col-auto" v-if="adHocText">
             <base-button v-if="!adHocWaiting"
                        type="default"
                        @click="createAdHocStream()"
                        >Create</base-button
                      >  <div v-else>
        <loading-spinner></loading-spinner>
      </div>
                      
                </div>
                  </div>
                  <!-- <base-input label="Date From">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="dateFrom"
                      placeholder="Pick"
                    >
                    </flat-picker>
                  </base-input>
                  <base-input label="Date To">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="dateTo"
                      placeholder="Pick"
                    >
                    </flat-picker>
                  </base-input> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush list">
                <li
                  v-for="fixture in fixtures"
                  class="list-group-item px-0"
                  :key="fixture.id"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ fixture.scheduleEntry.matchDate | dateTime }}
                    </div>
                    <div class="col">
                      {{ fixture.scheduleEntry.team1.team.fullName }} v
                      {{ fixture.scheduleEntry.team2.team.fullName }}
                    </div>
                    <div class="col">
                      {{ fixture.scheduleEntry.venue.fullName }} -
                      {{ fixture.scheduleEntry.venue.city }}
                    </div>
                    <div class="col-auto">
                      <base-button
                        size="sm"
                        type="secondary"
                        @click="thisOne(fixture)"
                        >Setup</base-button
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import axios from "axios";
// import VueAxios from "vue-axios";
import { Firebase } from "@/firebase.js";
export default {
  name: "new-brief",
  components: {
    flatPicker,
  },
  data() {
    return {
      tournaments: [{ title: "The County Championship 2021", id: 22584 }],
      tournament: "",
      tournamentText: "",
      dateFrom: "",
      dateTo: "",
      fixtures: {},
      fixture: "",
      fixtureText: "",
      venueText: "",
      showAddModal: false,
      player: "",
      bbcCommentary: true,
      commercialContents: true,
      creating: false,
      adHocText: '',
      adHocWaiting: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
  },
  methods: {
    thisOne(f) {
      this.fixture = f;
      this.showAddModal = true;
    },
    createAdHocStream() {
      this.adHocWaiting = true;
      const payload = {
        name: this.adHocText,
        tournament: 'custom',
        bbcCommentary: this.bbcCommentary,
        commercialContents: this.commercialContents,
      };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("addStream");
      publish(payload)
        .then((result) => {
          console.log(result);
          this.adHocWaiting = false;
          this.$router.push(`/livestreams/stream/${result.data.id}`);
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
    createStream() {
      const f = this.fixture;
      this.creating = true;
      this.fixtureText = `${f.scheduleEntry.team1.team.fullName} v ${f.scheduleEntry.team2.team.fullName}`;

      const payload = {
        name: this.fixtureText,
        tournament: this.tournament,
        bbcCommentary: this.bbcCommentary,
        commercialContents: this.commercialContents,
      };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("addStream");
      publish(payload)
        .then((result) => {
          console.log(result.data.id);
          this.creating = false;
          this.$router.push(`/livestreams/stream/${result.data.id}`);
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
    fetchFixtures() {
      axios
        .get(
          `https://cricketapi-ecb.pulselive.com/fixtures?tournamentIds=${this.tournament}&matchStates=U&pageSize=500`
        )
        .then((response) => {
          console.log(response.data);
          this.fixtures = response.data.content;
        });
    },
  },
};
</script>
<style>
.form-control {
  color: #606266;
}
</style>
