<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <stats-card title="Total Cost" type="gradient-yellow" :sub-title="totalCost" icon="fas fa-money-bill-wave">
          </stats-card>
        </div>
      </div>
      <modal :show.sync="costAddForm" size="lg" modal-classes="modal-scrollable">
        <cost-add-form :productionId="productionId" @closeModals="costAddForm = false"></cost-add-form>
      </modal>
      <modal :show.sync="costEditForm" size="lg" modal-classes="modal-scrollable">
        <cost-edit-form :costId="costEditId" :isBriefForm="true" @closeModals="costEditForm = false"></cost-edit-form>
      </modal>
      <div class="card-wrapper mt--2">
        <!-- Form controls -->
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col" v-if="costs.length === 0">No Costs Yet</div>
              <div class="col"></div>

              <div class="col-auto">
                Create with XLSX:
                <input type="file" @change="handleFileUpload" accept=".xlsx" />
              </div>
              <div class="col-auto text-right">
                <base-button type="primary" icon size="sm" @click="costAddForm = true">
                  <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
                  <span class="btn-inner--text">Add Cost</span>
                </base-button>
              </div>
            </div>
          </div>

          <el-table v-if="costs.length > 0" class="table-responsive align-items-center table-flush table-striped jimTable"
            header-row-class-name="thead-light" :data="costs" :default-sort="{ prop: 'start', order: 'ascending' }">
            <el-table-column label="Date" prop="date" sortable>
            </el-table-column>

            <el-table-column label="Shoot" prop="shoot">
              <div slot-scope="{ row }">
                <div v-if="row.shoot">{{ shootName(row.shoot) }}</div>
                <div v-else></div>
              </div>
            </el-table-column>

            <el-table-column label="Type" prop="type" sortable :filters="typesFilter" :filter-method="filterHandler">
            </el-table-column>
            <el-table-column label="Description" prop="description" sortable>
            </el-table-column>

            <el-table-column label="Amount" prop="amount">
              <div slot-scope="{ row }">
                <base-input type="number" v-model="row.amount" @input="changeField($event, row, 'amount')"></base-input>
              </div>
            </el-table-column>
            <el-table-column label="budget" min-width="170">
              <div slot-scope="{ row }">
                <base-input>
                  <el-select input-classes="form-control-alternative" v-model="row.budget"
                    @change="changeField($event, row, 'budget')">
                    <el-option v-for="budget in budgets" :key="budget.id" :label="budget.name" :value="budget.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </el-table-column>

            <el-table-column label="Logged" prop="created_at" sortable>
              <div slot-scope="{ row }">
                {{ row.created_at | dateTime }}
              </div>
            </el-table-column>

            <el-table-column class="table-actions" width="70">
              <div slot-scope="{ row }">
                <base-button outline size="sm" @click="edit(row)">Edit</base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CostAddForm from "../Components/CostAddForm.vue";
import CostEditForm from "../Components/CostEditForm.vue";
import BillingName from "../Components/BillingName.vue";
import Moment from "moment";
import { read, utils } from "xlsx";

export default {
  name: "costs",
  components: {
    CostAddForm,
    CostEditForm,
    BillingName,
  },
  data() {
    return {
      costAddForm: false,
      costEditForm: false,
      costEditId: "",
    };
  },
  beforeMount() {
    this.$store.dispatch("budgets/fetchAndAdd",
      {
        where: [["active", "==", true]],
      }
    );
  },
  computed: {
    productionId() {
      return this.$route.params.id;
    },
    costs() {
      let costs = this.$store.getters["costs/byBrief"](this.$route.params.id);
      if (costs) {
        costs.forEach(a => {

          if (a.budget) {
          let budget = this.$store.getters["budgets/byId"](a.budget)
          if(!budget){
            this.$store.dispatch("budgets/fetchById", a.budget)
          }
        }
        })
        return costs.sort((a, b) => a.created_at - b.created_at);
      } else {
        return [];
      }
    },
    budgetsInBrief() {
      return [...new Set(this.costs.map(item => item.budget))];
    },
    totalCost() {
      const sum = this.costs.reduce((accumulator, object) => {
        return accumulator + Number(object.amount);
      }, 0);
      return (
        "£" +
        sum
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
    // shootFilter() {
    //   let shoots = {};
    //   let shootsNames = [];
    //   this.costs.forEach((cost) => {
    //     shoots[cost.shoot] = true;
    //   });

    //   let shootsArr = Object.keys(shoots);

    //   shootsArr.forEach((shootId) => {
    //     shootsNames.push({ text: this.shootName(shootId), value: shootId });
    //   });
    //   // console.log(shootsNames);
    //   return shootsNames;
    // },
    typesFilter() {
      let types = {};
      let typesNames = [];
      this.costs.forEach((cost) => {
        types[cost.type] = true;
      });

      let typesArr = Object.keys(types);

      typesArr.forEach((type) => {
        typesNames.push({ text: type, value: type });
      });
      // console.log(typesNames);
      return typesNames;
    },
    billings() {
      let res = {};
      this.costs.forEach((a) => {
        res[a.billing] = true;
      });
      let resArr = Object.keys(res);
      let filters = [];
      resArr.forEach((b) => {
        let name = this.$store.getters["billings/byId"](b);
        if (!name) {
          this.$store.dispatch("billings/fetchById", b);
        } else {
          filters.push({ text: name.label, value: b });
        }
      });
      return filters;
    },
    budgets() {
      let budgets = this.$store.getters["budgets/active"];
      //  add in budgets on this brief, unless already in there
      this.budgetsInBrief.forEach(a => {
       if (budgets.some(e => e.id === a)) {

       } else {
        budgets.push(this.$store.getters["budgets/byId"](a))
       }
      })
  
      return budgets
    },
  },
  methods: {
    changeField(event, row, field) {
      console.log(row);
      let payload = { id: row.id };
      payload[field] = event;
      this.$store.dispatch("costs/patch", payload);
    },
    edit(costId) {
      console.log(costId);
      this.costEditId = costId.id;
      this.costEditForm = true;
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    budgetName(budgetId) {
      let name = this.$store.getters["budgets/byId"](budgetId);
      if (name) {
        return name.name;
      } else {
        return "";
      }
    },
    shootName(shootId) {
      let name = this.$store.getters["shoots/byId"](shootId);
      if (name) {
        return name.shootTitle;
      } else {
        return "";
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = utils.sheet_to_json(sheet);

        // Do something with the parsedData
        let arr = parsedData;

        console.log(arr);

        arr.forEach((row) => {
          let d = Moment(row.Date);

          let payload = {
            production: this.productionId,
            date: d.toDate(),
            type: row.Type,
            amount: parseFloat(row.Amount),
            description: row.Description,
            budget: "",
            shoot: "",
          };
          console.log(payload);
        });
      };

      reader.readAsArrayBuffer(file);
    },
    // parseCSV(contents) {
    //   const lines = contents.split("\n"); // Split contents into lines
    //   const headers = lines[0].split(","); // Get headers from the first line
    //   const data = []; // Array to store parsed data

    //   // Loop through each line (excluding headers)
    //   for (let i = 1; i < lines.length; i++) {
    //     const obj = {}; // Object to store each row's data
    //     const currentLine = lines[i].split(","); // Split line into individual values

    //     // Loop through each value and create key-value pairs using headers
    //     for (let j = 0; j < headers.length; j++) {
    //       obj[headers[j]] = currentLine[j];
    //     }

    //     data.push(obj); // Push the row's data into the array
    //   }

    //   return data; // Return the parsed data as an array of objects
    // }
  },
};
</script>
