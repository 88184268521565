<template>
  <div>
    <NewFile
      :boxHeight=boxHeight
      :multiple="true"
      message="Drop files or click to browse"
      :fileName="false"
      :basePath="basePath"
      @success="success"
      :randomSuffix="true"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-watermark",
  props: ["boxHeight", "match"],
  components: {
    NewFile,
  },
  computed: {
    allowedFileTypes() {
      return "video/mp4";
    },
    basePath() {
      let year = new Date().getFullYear();
      return `match_reels/${year}`;
    },
  },
  methods: {
    success(payload) {
      payload.what = "match-reel";
      payload.match = this.match
      this.$store.dispatch("files/insert", payload).then((result) => {});
    },
  },
};
</script>

<style scoped>
</style>