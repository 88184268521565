var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":"172b4d"}},[_c('template',{slot:"links"},[(_vm.user.roles.creative_catalogue_access)?_c('sidebar-item',{attrs:{"link":{
        name: 'Creative Library',
        icon: 'fa fa-images text-info',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Search',
          path: '/content',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Collections',
          path: '/collections',
        }}})],1):_vm._e(),(_vm.user.roles.production_access)?_c('sidebar-item',{attrs:{"link":{
        name: 'Production',
        icon: 'fa fa-pencil-ruler text-success',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Briefs',
          icon: 'fa fa-file-alt text-primary',
          path: '/briefs',
        }}}),(_vm.user.roles.production_feedback)?_c('sidebar-item',{attrs:{"link":{
          name: 'Priority Queue',
          icon: 'fa fa-file-import text-default',
          path: '/worklist',
        }}}):_vm._e(),(_vm.user.roles.production_feedback)?_c('sidebar-item',{attrs:{"link":{
          name: 'New Brief',
          icon: 'fa fa-file-import text-default',
          path: '/new-brief',
        }}}):_vm._e(),(_vm.user.roles.production_feedback)?_c('sidebar-item',{attrs:{"link":{
          name: 'Draft Briefs',
          icon: 'fa fa-file-signature text-purple',
          path: '/drafts',
        }}}):_vm._e(),(_vm.user.roles.production_feedback)?_c('sidebar-item',{attrs:{"link":{
          name: 'Feedback Queue',
          icon: 'fa fa-pencil-ruler text-danger',
          path: '/feedback',
        }}}):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
          name: 'Work Planner',
          icon: 'fa fa-calendar-week text-success',
          path: '/effort',
        }}}):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
          name: 'Shoots',
          icon: 'fa fa-file-signature text-purple',
          path: '/shoots',
        }}}):_vm._e()],1):_vm._e(),(_vm.user.roles.archive_access)?_c('sidebar-item',{attrs:{"link":{
        name: 'Match Video',
        icon: 'ni ni-archive-2 text-danger',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Matches',
          path: '/archive/matches',
        }}}),(_vm.user.roles.archive_search)?_c('sidebar-item',{attrs:{"link":{
          name: 'Ready Clipped Moments',
          path: '/archive/clips',
        }}}):_vm._e(),(_vm.user.roles.archive_search)?_c('sidebar-item',{attrs:{"link":{
          name: 'Clip Request Queue',
          path: '/archive/clipping-requests',
        }}}):_vm._e(),(_vm.user.roles.archive_uploader)?_c('sidebar-item',{attrs:{"link":{
          name: 'Match Recordings Admin',
          path: '/archive/admin',
        }}}):_vm._e(),(_vm.user.roles.archive_uploader)?_c('sidebar-item',{attrs:{"link":{
          name: 'Match ID Admin',
          path: '/archive/data/matches',
        }}}):_vm._e(),(_vm.user.roles.archive_uploader)?_c('sidebar-item',{attrs:{"link":{
          name: 'Player ID Admin',
          path: '/archive/data/players',
        }}}):_vm._e(),(_vm.user.roles.archive_uploader)?_c('sidebar-item',{attrs:{"link":{
          name: 'Competition ID Admin',
          path: '/archive/data/competitions',
        }}}):_vm._e()],1):_vm._e(),(_vm.user.roles.livestream_access)?_c('sidebar-item',{attrs:{"link":{
        name: 'Livestreams',
        icon: 'ni ni-world-2 text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Stream Channels',
          path: '/livestreams/channels',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Rights FAQs',
          path: '/livestreams/rights-faq',
        }}})],1):_vm._e(),(_vm.user.roles.inspirations_access)?_c('sidebar-item',{attrs:{"link":{
        name: 'Inspirations',
        icon: 'fa fa-lightbulb text-yellow',
        path: '/inspirations',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Browse',
          path: '/inspirations',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Favourites',
          path: '/favourite-inspirations',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Add New',
          path: '/add-new-inspiration',
        }}})],1):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
        name: 'Billing',
        icon: 'fa fa-file-invoice-dollar text-indigo',
      }}},[(_vm.user.roles.production_admin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Work Planner Resources',
          path: '/resources',
        }}}):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
          name: 'Resource Billables',
          path: '/billings',
        }}}):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
          name: 'Work Attribution',
          path: '/ratecard-work',
        }}}):_vm._e(),(_vm.user.roles.production_shifts)?_c('sidebar-item',{attrs:{"link":{
          name: 'Budgets',
          path: '/budgets',
        }}}):_vm._e()],1):_vm._e(),(_vm.user.roles.user_admin)?_c('sidebar-item',{attrs:{"link":{
        name: 'Admin',
        icon: 'ni ni-atom text-pink',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          path: '/users',
        }}})],1):_vm._e(),_c('sidebar-item',{staticClass:"last-item",attrs:{"link":{
        name: _vm.user.name,
        icon: 'ni ni-badge text-warning',
        path: '/user',
      }}})],1)],2),_c('div',{staticClass:"main-content"},[(!_vm.$route.meta.hideNav)?_c('dashboard-navbar'):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }