import Firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

let firebaseConfig = {
  storageBucket: "ecb-echo.appspot.com",
apiKey: "AIzaSyD0DJR6PLRE8aWp7v6OkG6gOUVmTm_Gca8",
authDomain: "ecb-echo.firebaseapp.com",
projectId: "ecb-echo",
databaseURL: "https://ecb-echo.firebaseio.com",
messagingSenderId: "946278815737",
appId: "1:946278815737:web:477847bdcfde78f70d8f28",
measurementId: "G-GPZVKVXHE1",
}

const dev = false

if (dev) {
  firebaseConfig = {
    storageBucket: "ecb-creative.appspot.com",
  apiKey: "AIzaSyCLRwckEHzD7Qn4MuP6iks6JO5Ivg_yjLE",
  authDomain: "ecb-creative.firebaseapp.com",
  databaseURL: "https://ecb-creative.firebaseio.com",
  projectId: "ecb-creative",
  messagingSenderId: "63562601793",
  appId: "1:63562601793:web:ac0cfb97c2f8c27205bdc0",
  measurementId: "G-EG3W6MPDLB",
  }
}

function initFirebase() {
  Firebase.initializeApp(firebaseConfig)
  return new Promise((resolve, reject) => {
    // Firebase.firestore().enablePersistence()
    //   .then(resolve)
    //   .catch(err => {
    //     if (err.code === 'failed-precondition') {
    //       reject(err)
    //       // Multiple tabs open, persistence can only be
    //       // enabled in one tab at a a time.
    //     } else if (err.code === 'unimplemented') {
    //       reject(err)
    //       // The current browser does not support all of
    //       // the features required to enable persistence
    //     }
    //   })
  })
}

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


let defaultBucket = firebaseConfig.storageBucket


export { Firebase, initFirebase, defaultBucket, db, auth }
