<template>
  <div>
    <div class="row">
      <div class="col">
        <new-inspiration :brief="this.$route.params.id"></new-inspiration>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <masonry
          :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
          :gutter="30"
        >
          <inspiration
            v-for="insp in inspirations"
            :key="insp.id"
            :inspiration="insp"
          >
          </inspiration>
        </masonry>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";
import inspiration from "../Inspirations/Components/InspirationCard.vue";
import NewInspirationComponent from "../Inspirations/Components/NewInspirationComponent";
import moment from "moment";
export default {
  name: "Inspirations",
  components: {
    inspiration,
    "new-inspiration": NewInspirationComponent,
  },
  data() {
    return {};
  },
  beforeMount() {},
  computed: {
    config() {
      return this.$store.getters.config;
    },
    inspirations() {
      return this.$store.getters["inspirations/byBrief"](this.$route.params.id);
    },
  },
  methods: {},
};
</script>
<style scoped>
@media (min-width: 34em) {
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

@media (min-width: 48em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 62em) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 75em) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 100em) {
  .card-columns {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
}
</style>
