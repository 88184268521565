<template>
  <div>
    <div class="row">
      <div class="col">
        <base-input label="Type">
          <el-select v-model="type" placeholder="Type">
            <el-option v-for="option in config.delTypes" :key="option.display" :label="option.display"
              :value="option.display">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col">
        <base-input label="Brand">
          <el-select v-model="brand" placeholder="Brand">
            <el-option v-for="option in config.brand" :key="option" :label="option" :value="option">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col">
        <base-input label="Folder">
          <el-select v-model="folder" placeholder="Folder">
            <el-option v-for="option in folders" :key="option" :label="option" :value="option">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col" v-if="dimensionsNeeded">
        <base-input label="Dimensions">
          <el-select v-model="dimensions" placeholder="Dimensions">
            <el-option v-for="option in config.formats" :key="option" :label="option" :value="option">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col" v-if="user.roles.production_feedback">
        <base-input label="Status">
          <el-select v-model="state" placeholder="Status">
            <el-option v-for="option in states" :key="option.value" :label="option.text" :value="option.value">
            </el-option>
          </el-select>
        </base-input>
      </div>


    </div>
    <div class="row">
      <div class="col" v-if="validForBrief">

        <brief-uploader :prodID="prodID" :brand="brand" :dimensions="dimensions" :type="type" :folderName="folder"
          boxHeight="300px" :state="state"></brief-uploader>


      </div>
    </div>
  </div>
</template>
<script>
import { urlToHttpOptions } from "url";
import BriefUploader from "./SignedOffUploadWrapper";

export default {
  name: "uploader",
  components: {
    "brief-uploader": BriefUploader,
  },
  props: ["folders"],
  data() {
    return {
      uploadType: "",
      brand: "",
      folder: "",
      dimensions: "",
      type: "",
      state: 'feedback',
      states: [{
        value: 'signed',
        text: 'Signed Off'
      }, {
        value: 'feedback',
        text: 'Awaiting Feedback'
      }]
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    dimensionsNeeded() {
      // show dimension if video/image etc
      if (this.type === 'Email Design' || this.type === 'PDF') {
        return false
      } else {
        return true
      }
    },
    prodID() {
      return this.$route.params.id;
    },
    validForBrief() {
      if (this.type && this.brand) {
        if (this.dimensionsNeeded) {
          if (this.dimensions) {
            return true
          } else {
            return false
          }
        } else {
          return true;
        }
      } else {
        return false
      }
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
  },

  methods: {},
};
</script>