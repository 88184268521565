<template>
  <div class="row">
    <div class="col">
      <a class="mb-0" :href="`/#/livestreams/stream/${channel.id}`">
        {{ channel.name }}
      </a>
    </div>
    <div class="col">
      {{ stream.sep_state }}
    </div>
  </div>
</template>

<script>
import { Firebase } from "@/firebase.js";
export default {
  data() {
    return {
      stream: "",
    };
  },
  mounted() {
    var publish = Firebase.app().functions('europe-west2').httpsCallable('getStream')

    publish({ id: this.channel.id }).then((result) => {
      console.log(result)
      this.stream = result.data.job;
    });
  },
  props: ["channel"],
  computed: {},
  components: {},
  methods: {},
};
</script>

<style>
</style>
