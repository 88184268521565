<template>
  <div>
    <!--       Edit User-->
    <!-- <modal :show.sync="showEditModal" size="lg" modal-classes="modal">
      <form class="edit-user--form" @submit.prevent="editUser">
  
        <div class="row">
      <div class="col">
        <base-input
          label="Display Name"
          v-model="user.name"
          @change="changeField($event.target.value, 'name')"
        />
      </div>
            <div class="col">
                   <base-input label="Email" v-model="user.email" disabled />
   
            </div>
        </div>
         
           <div class="row">
      <div class="col">
         <base-input label="Company">
          <el-select
            input-classes="form-control"
            v-model="user.company"
            filterable
            allow-create
            placeholder="Select here"
            @change="changeField($event, 'company')"
          >
                <el-option
              v-for="prod in this.producers"
              :key="prod.value"
              :label="prod.text"
              :value="prod.value"
            >
               </el-option>
          </el-select>
        </base-input>
      </div>
        <div class="col">
               <base-input
          label="Slack ID"
          v-model="user.slack"
          @change="changeField($event.target.value, 'slack')"
        />
      </div>
           </div>
       
      
       
        <base-input label="Department Default">
          <el-select
            input-classes="form-control"
            v-model="user.department"
            clearable
            placeholder="Default department on filters"
            @change="changeField($event, 'department')"
          >
            <el-option
              v-for="prod in this.config.departments"
              :key="prod"
              :label="prod"
              :value="prod"
            >
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Department Restrictions">
          <el-select
            input-classes="form-control"
            v-model="user.departmentAccess"
            clearable
            multiple
            filterable
            placeholder="Limit access to these departments"
            @change="changeField($event, 'departmentAccess')"
          >
            <el-option
              v-for="prod in this.config.departments"
              :key="prod"
              :label="prod"
              :value="prod"
            >
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Brand Restrictions">
          <el-select
            input-classes="form-control"
            v-model="user.brandAccess"
            clearable
            filterable
            multiple
            placeholder="Limit access to these brands"
            @change="changeField($event, 'brandAccess')"
          >
            <el-option
              v-for="prod in this.config.brand"
              :key="prod"
              :label="prod"
              :value="prod"
            >
            </el-option>
          </el-select>
        </base-input>
        <base-input label="Module Access">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <role-selector
                  role="creative_catalogue_access"
                  label="Creative Catalogue: Access"
                  :userId="this.user.id"
                  colour="info"
                ></role-selector>
                <role-selector
                  role="production_access"
                  label="Production: Access"
                  colour="success"
                  :userId="this.user.id"
                ></role-selector>
                <role-selector
                  role="jimstagram_access"
                  label="Jimstagram: Access"
                  colour="default"
                  :userId="this.user.id"
                ></role-selector>
              </div>
              <div class="col-6">
                <role-selector
                  role="archive_access"
                  label="Match Archive: Access"
                  :userId="this.user.id"
                  colour="danger"
                ></role-selector>
                <role-selector
                  role="fcc_access"
                  label="FCC Video: Access"
                  :userId="this.user.id"
                  colour="yellow"
                ></role-selector>
                <role-selector
                  role="livestream_access"
                  label="Livestreaming: Access"
                  colour="primary"
                  :userId="this.user.id"
                ></role-selector>
              </div>
            </div>
          </div>
        </base-input>
        <base-input label="Priviledges">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <el-tooltip
                  placement="left"
                  content="See drafts of deliverables"
                >
                  <role-selector
                    role="production_drafts"
                    label="Production: Draft Deliverables"
                    colour="success"

                    :userId="this.user.id"
                  ></role-selector>
                </el-tooltip>
                <el-tooltip
                  placement="left"
                  content="Add feedback to drafts, create empty deliverables and draft briefs"
                >
                  <role-selector
                    role="production_feedback"
                    label="Production: Feedbacker"
                    colour="success"
                    :userId="this.user.id"

                  ></role-selector>
                </el-tooltip>
                <el-tooltip
                  placement="left"
                  content="See and edit the work planner"
                >
                  <role-selector
                    role="production_shifts"
                    label="Production: Scheduler"
                    colour="success"
                    :userId="this.user.id"

                  ></role-selector>
                </el-tooltip>
                <el-tooltip placement="left" content="Submit briefs">
                  <role-selector
                    role="production_admin"
                    label="Production: Admin"
                    colour="success"
                    :userId="this.user.id"

                  ></role-selector>
                </el-tooltip>
              </div>
              <div class="col-6">
                <el-tooltip
                  placement="left"
                  content="Create auto-signed-off deliverables by uploading files to briefs"
                >
                  <role-selector
                    role="production_new_deliberables"
                    label="Production: Upload Signed Off Deliverables"
                    :userId="this.user.id"

                    colour="success"
                  ></role-selector>
                </el-tooltip>
                <role-selector
                  role="archive_uploader"
                  label="Match Archive: Admin"
                  colour="danger"
                  :userId="this.user.id"
                ></role-selector>
                <role-selector
                  role="jimstagram_template"
                  label="Jimstagram: Templates"
                  colour="default"
                  :userId="this.user.id"
                ></role-selector>

                <role-selector
                  role="user_admin"
                  label="Users: Admin"
                  colour="warning"
                  :userId="this.user.id"
                ></role-selector>
              </div>
            </div>
          </div>
        </base-input>
      </form>
      <div class="row">
        <div class="col-3">
          <base-button block type="secondary" @click="showEditModal = false"
            >Close</base-button
          >
        </div>
        <div class="col-3">
    
        </div>
        <div class="col-3"></div>
        <div class="col-3">
          <base-button block type="warning" @click="deleteUser"
            >Delete User</base-button
          >
        </div>
      </div>
    </modal> -->
    <div class="row smaller-text">
      <div class="col-3">
        <div class="row">
          <div class="col">{{ user.name }}</div>
        </div>
        <div class="row">
          <div class="col small">{{ user.email }}</div>
        </div>
      </div>
      <div class="col-2">{{ user.company }}</div>
      <div class="col-2">{{ user.slack }}</div>
      <div class="col-2"><span v-if="user.last_log_in">{{ user.last_log_in | dateTime }}</span></div>
      <div class="col-2"><roles-length :userId="user.id"> </roles-length></div>
      <div class="col-auto">
        <!-- <base-button
          outline
          block
          size="sm"
          type="primary"
          @click.prevent="onEdit(user)"
        >
          Edit
        </base-button> -->
        <a :href="link">Edit</a>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/routes/router";
import RoleSelector from "./RoleSelector.vue";
import RolesLength from "./RolesLength.vue";
import date from "../../../../filters/date";
export default {
  data() {
    return {
      showEditModal: false,
    };
  },
  props: ["user", "preApproved"],
  computed: {

    link() {
      return "#/user/" + this.user.id
    },
    config() {
      return this.$store.getters["config/config"];
    },
    producers() {
      let pA = this.config.producer;
      let arr = [];
      if (pA) {
        pA.forEach(function (entry) {
          arr.push({
            text: entry,
            value: entry,
          });
        });
        return arr;
      } else {
        return [];
      }
    },
  },
  components: {
    "roles-length": RolesLength,
    "role-selector": RoleSelector,
  },
  methods: {
    onEdit(row) {
      // this.model.id = row.id;
      // this.model.name = row.name;
      // this.model.company = row.company;
      // this.model.email = row.email;
      // this.model.active = row.active;
      // this.model.slack = row.slack;
      // console.log(row)

      // if (row.roles) {
      //   let roles = Object.keys(row.roles);
      //   console.log(roles);
      //   roles.forEach((role) => {
      //     if (row.roles[role]) {
      //       this.model.roles[role] = true;
      //     } else {
      //       this.model.roles[role] = false;
      //     }
      //   });
      // }
      this.showEditModal = true;
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;

        this.$store.dispatch("users/patch", payload);
    },
    deleteUser() {
      this.$store.dispatch("users/delete", this.user.id);
      this.showEditModal = false;
    },
  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>