<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <!-- <div class="card shadow">
            <div class="card-body" v-if="stream"> -->
          <div class="h1 ml-3 mb-4 text-white">
            {{ stream.videocloud.video.name }}
          </div>
          <!-- </div>
          </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header" v-if="stream">
              <span class="h3">Encoder</span>
            </div>
            <div class="card-body" v-if="stream">
              <form>
                <div class="row mb-5">
                  <div class="col">
                    <base-button
                      v-if="
                        stream.sep_state === 'waiting' ||
                        stream.sep_state === 'pending_deactivation'
                      "
                      type="success"
                      block
                      @click="activateSEP"
                      >Activate</base-button
                    >
                    <base-button
                      v-if="
                        stream.sep_state === 'activation_in_progress' ||
                        stream.sep_state === 'ready'
                      "
                      type="warning"
                      block
                      @click="deactivateSEP"
                      >Deactivate</base-button
                    >
                  </div>
                  <div class="col m-xy-auto">State: {{ stream.sep_state }}</div>
                  <div
                    @click="refresh"
                    class="col-auto m-xy-auto psuedobutton text-primary"
                  >
                    <i class="fas fa-sync"></i>
                  </div>
                </div>
                <div v-if="iframe"
                  style="position: relative; display: block; max-width: 100%"
                >
                  <div style="padding-top: 56.25%">
                    <iframe
                      :src="iframe"
                      allowfullscreen=""
                      allow="encrypted-media"
                      style="
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                      "
                    ></iframe>
                  </div>
                </div>

                <base-input
                  label="Stream Protocol"
                  v-model="stream.protocol"
                  disabled
                />

                <base-input
                  label="Stream Address"
                  v-model="stream.stream.url"
                  id="address"
                  @focus="copyText('address')"
                />
                <base-input
                  label="Stream Key"
                  v-model="key"
                  id="key"
                  @focus="copyText('key')"
                />
              </form>
            </div>
            <div class="card-body" v-else>
              <loading-spinner></loading-spinner>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow">
            <div class="card-header" v-if="stream">
              <span class="h3">Sends</span>
            </div>
            <div class="card-body" v-if="stream">
              <form>
                <!-- <base-input label="Watermark">
                  <el-select
                    input-classes="form-control"
                    v-model="temp.logo"
                    filterable
                    clearable
                    placeholder="Type to filter"
                  >
                    <el-option
                      v-for="file in watermarks"
                      :key="file.id"
                      :label="file.label"
                      :value="file.sourceDownloadURL"
                    >
                    </el-option>
                  </el-select>
                </base-input>

                <div class="row" v-if="temp.logo">
                  <div class="col">
                    <div class="col-form-label form-control-label">
                      NV Match Centre Code
                    </div>
                    <base-input>
                      <textarea
                        class="form-control"
                        rows="4"
                        v-model="nvCode"
                        id="nv"
                        @focus="copyEmbed('nv')"
                      ></textarea>
                    </base-input>
                  </div>
                  <div class="col">
                    <div class="col-form-label form-control-label">
                      Direct Embed Code
                    </div>
                    <base-input>
                      <textarea
                        class="form-control"
                        rows="4"
                        v-model="embedCode"
                        id="embed"
                        @focus="copyEmbed('embed')"
                      ></textarea>
                    </base-input>
                  </div>
                </div> -->

                <!-- <label class="form-control-label">Social Feeds</label> -->
                <ul class="list-group list-group-flush list mb-3">
                  <li
                    v-for="(rtmp, index) in rtmps"
                    class="list-group-item px-0"
                    :key="index"
                  >
                    <rtmp :rtmp="rtmp" @stop="stopRTMP($event)"></rtmp>
                  </li>
                </ul>
                <!-- <base-input label="Add New Destination">
                  <el-select
                    input-classes="form-control"
                    v-model="temp.newFeed"
                    filterable
                    clearable
                    placeholder="Type to filter"
                  >
                    <el-option
                      v-for="prod in rtmpOutputs"
                      :key="prod.id"
                      :label="prod.name"
                      :value="prod.id"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <base-button v-if="temp.newFeed" @click="addRTMP"
                  >Add</base-button
                > -->
              </form>
            </div>
            <div class="card-body" v-else>
              <loading-spinner></loading-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3>Reporting</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="reporting.from"
                      placeholder="Report From"
                    >
                    </flat-picker>
                  </base-input>
                </div>
                <div class="col">
                  <base-input>
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{ allowInput: true }"
                      class="form-control datepicker"
                      v-model="reporting.to"
                      placeholder="Report To"
                    >
                    </flat-picker>
                  </base-input>
                </div>
                <div class="col" v-if="reporting.to && reporting.from">
                  <base-button type="primary" block @click="consumptionReport"
                    >Consumption across Sites</base-button
                  >
                </div>
                <div class="col" v-if="reporting.to && reporting.from">
                  <base-button type="primary" block @click="uniqueReport"
                    >Daily Unique Viewers</base-button
                  >
                </div>
              </div>
              <div class="row">
                <div class="col" v-if="reporting.report === 'unique'">
                  <table style="width: 100%">
                    <tr>
                      <th>Date</th>
                      <th>Unique Viewers</th>
                    </tr>
                    <tr v-for="row in reporting.data.items">
                      <td>{{ row.date }}</td>
                      <td>{{ row.daily_unique_viewers }}</td>
                    </tr>
                  </table>
                </div>
                <div class="col" v-if="reporting.report === 'consumption'">
                  <table style="width: 100%">
                    <tr>
                      <th>Date</th>
                      <th>Domain</th>
                      <th>Stream Starts</th>
                      <th>Minutes Viewed</th>
                    </tr>
                    <tr v-for="row in reporting.data.items">
                      <td>{{ row.date }}</td>
                      <td>{{ row.destination_domain }}</td>
                      <td>{{ row.video_view }}</td>
                      <td>{{ Math.round(row.video_seconds_viewed / 60) }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RTMP from "./Components/RTMP";
import { Firebase } from "@/firebase.js";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: { rtmp: RTMP, flatPicker },
  name: "streams",
  data() {
    return {
      reporting: { from: "", to: "", report: "", data: { items: [] } },
      key: "alive",
      showEditModal: false,
      brightcovePlayer: "axQX0FjyfN",
      temp: { player: "", newFeed: "" },
      loadedStreams: false,
      players: ["NV Play Widget", "Direct Embed Code"],
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    stream() {
      return this.$store.getters["livestreams/stream"];
    },
    token() {
      return this.$store.getters["livestreams/token"];
    },
    rtmpOutputs() {
      return this.$store.getters["rtmpOutputs/array"];
    },
    rtmps() {
      var rtmps = this.stream.rtmp_outputs;
      var arr = [];
      rtmps.forEach((r) => {
        if (r.state != "finished") {
          arr.push(r);
        }
      });
      return arr;
    },
    watermarks() {
      let arr = this.$store.getters["files/byWhat"]("livestream-county-logo");
      return arr.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        if (!a.name) {
          return -1;
        }
        return 0;
      });
    },
    // players() {
    //   return this.$store.getters["brightcovePlayers/array"];
    // },
    // player() {
    //   return this.$store.getters["brightcovePlayers/byId"](this.temp.player);
    // },
    loading() {
      return this.$store.getters["user/loading"];
    },
    newFeed() {
      return this.$store.getters["rtmpOutputs/byId"](this.temp.newFeed);
    },
    bbc() {
      if (this.stream.videocloud.tags) {
        const currentTags = this.stream.videocloud.tags;
        if (currentTags.includes("bbc-commentary")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    nvCode() {
      if (this.stream.videocloud && this.token) {
        const playerID = this.brightcovePlayer;
        const videoID = this.stream.job_videocloud_asset_id;
        let base =
          '<div style="max-width: 960px;"><video-js id="overlayVideoPlayer" data-account="4854911276001" data-player="' +
          playerID +
          '" data-embed="default" data-bbc="' +
          this.bbc +
          '" controls="" data-video-id="' +
          videoID +
          '" data-logo="' +
          this.temp.logo +
          '" data-ad-config-id="' +
          this.token +
          '" data-application-id="" class="vjs-fluid"></video-js></div><scr' +
          'ipt src="https://players.brightcove.net/4854911276001/' +
          playerID +
          '_default/index.min.js"></scr' +
          "ipt>";
        return base;
      }
    },
    embedCode() {
      if (this.stream.videocloud && this.token) {
        const playerID = this.brightcovePlayer;
        const videoID = this.stream.job_videocloud_asset_id;
        let base =
          '<div style="max-width: 960px;"><video-js id="overlayVideoPlayer" data-account="4854911276001" data-player="' +
          playerID +
          '" data-embed="default" data-bbc="' +
          this.bbc +
          '" controls="" data-video-id="' +
          videoID +
          '" data-logo="' +
          this.temp.logo +
          '" data-ad-config-id="' +
          this.token +
          '" data-application-id="" class="vjs-fluid"></video-js>   <div style="display:none" id="optInBlock"><div id="bbcInfoFlex"><div id="bbcInfoPanel"><div id="bbcInfoIcon"></div><div id="bbcInfoText"><strong>BBC Radio Commentary is available separately</strong><div>Would you like to add this?</div></div></div><div id="bbcInfoButtons"> <button onclick="allow()">YES</button><button onclick="allow()">HIDE</button></div></div></div></div><scr' +
          'ipt src="https://players.brightcove.net/4854911276001/' +
          playerID +
          '_default/index.min.js"></scr' +
          'ipt><link href="https://storage.googleapis.com/ecb-echo.appspot.com/livestreaming/widget/logo-overlay-muting.css" rel="stylesheet"><scr' +
          'ipt src="https://storage.googleapis.com/ecb-echo.appspot.com/livestreaming/widget/logo-overlay-muting.js"></scr' +
          "ipt>";
        return base;
      }
    },
    iframe() {
      if (this.stream.videocloud && this.token) {
        const videoID = this.stream.job_videocloud_asset_id;
        return (
          "https://players.brightcove.net/4854911276001/default_default/index.html?videoId=" +
          videoID +
          "&amp;adConfigId=" +
          this.token
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("livestreams/getStream", {
      id: this.$route.params.id,
    });
    // this.$store.dispatch("brightcovePlayers/fetchAndAdd");
    this.$store.dispatch("rtmpOutputs/fetchAndAdd");
    this.$store.dispatch("files/fetchAndAdd", {
      where: [["what", "==", "livestream-county-logo"]],
    });
  },
  methods: {
    consumptionReport() {
      const from = this.reporting.from;
      const to = this.reporting.to;
      let payload = {
        from: from,
        to: to,
        sort: "date",
        fields: "video_view,video_seconds_viewed",
        dimensions: "date,destination_domain",
        video_cloud_id: this.stream.job_videocloud_asset_id,
      };

      console.log(payload);
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("streamReporting");
      publish(payload).then((result) => {
        //console.log(result)
        this.reporting.data = result.data;
      });
      this.reporting.report = "consumption";
    },
    uniqueReport() {
      const from = this.reporting.from;
      const to = this.reporting.to;
      let payload = {
        from: from,
        to: to,
        sort: "date",
        fields: "daily_unique_viewers",
        dimensions: "date",
        video_cloud_id: this.stream.job_videocloud_asset_id,
      };

      console.log(payload);
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("streamReporting");
      publish(payload).then((result) => {
        //console.log(result)
        this.reporting.data = result.data;
      });
      this.reporting.report = "unique";
    },
    refresh() {
      this.$store.dispatch("livestreams/getStream", {
        id: this.$route.params.id,
      });
    },
    copyText(id) {
      var copier = document.getElementById(id);
      var copyBit = copier.querySelector("input");

      /* Select the text field */
      copyBit.select();
      copyBit.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$notify({
        message: "Address Copied",
        timeout: 2000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
    copyEmbed(id) {
      var copyBit = document.getElementById(id);
      /* Select the text field */
      copyBit.select();
      copyBit.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$notify({
        message: "Code Copied",
        timeout: 2000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    activateSEP() {
      let payload = { streamID: this.stream.id };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("activateSEP");
      this.$notify({
        message: "Please wait for activation",
        timeout: 5000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload).then((result) => {
        this.$store.dispatch("livestreams/getStream", {
          id: this.$route.params.id,
        });
        this.$notify({
          message: "Activated",
          timeout: 3000,
          type: "success",
          overlap: true,
          verticalAlign: "bottom",
          horizontalAlign: "right",
        });
      });
    },
    deactivateSEP() {
      let payload = { streamID: this.stream.id };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("deactivateSEP");
      this.$notify({
        message: "Please wait for deactivation",
        timeout: 5000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload).then((result) => {
        this.$store.dispatch("livestreams/getStream", {
          id: this.$route.params.id,
        });
        this.$notify({
          message: "Deactivated",
          timeout: 3000,
          type: "success",
          overlap: true,
          verticalAlign: "bottom",
          horizontalAlign: "right",
        });
      });
    },
    addRTMP() {
      let payload = { url: this.newFeed.url, streamID: this.stream.id };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("addRTMP");
      this.$notify({
        message: "Please wait for this to be set up",
        timeout: 3000,
        type: "info",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload)
        .then((result) => {
          this.$store.dispatch("livestreams/getStream", {
            id: this.$route.params.id,
          });
          this.$notify({
            message: "Destination Added",
            timeout: 3000,
            type: "success",
            overlap: true,
            verticalAlign: "bottom",
            horizontalAlign: "right",
          });
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
    stopRTMP(id) {
      console.log(id);
      let payload = {
        rtmpID: id.rtmp_out_id,
        streamID: this.stream.id,
      };
      var publish = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("stopRTMP");
      this.$notify({
        message: "Cancelling...",
        timeout: 3000,
        type: "info",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
      publish(payload)
        .then((result) => {
          this.$store.dispatch("livestreams/getStream", {
            id: this.$route.params.id,
          });
          this.$notify({
            message: "Cancelled",
            timeout: 3000,
            type: "success",
            overlap: true,
            verticalAlign: "bottom",
            horizontalAlign: "right",
          });
        })
        .catch((error) => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(error);
        });
    },
  },
};
</script>

<style>
.psuedobutton :hover {
  cursor: pointer;
}
</style>