<template>
  <div class="card">
    <div class="card-header border-0" v-if="!embed">
      <div class="row">
        <!-- <div class="col">
          <h3 class="mb-0"></h3>
        </div> -->

        <div class="col text-left">
          <button-radio-group :options="radioOptions" button-classes="btn-group-colors btn-secondary"
            v-model="buttonRadioGroup" @change="filterPersist">
          </button-radio-group>
        </div>


        <div class="col-auto text-right" v-if="zipOfSigned">
          <a v-if="zipOfSigned.status === 'requested'" class="btn btn-sm"><i
              class="fas fa-file-download mr-2"></i>Requesting Zip
          </a>
          <zip-link v-else-if="zipOfSigned.status === 'ready'" :zip="zipOfSigned"></zip-link>
        </div>
        <div class="col-auto text-right" v-else>
          <a class="btn btn-sm" @click="zipSigned()"><i class="fas fa-file-download mr-2"></i>Request Zip
          </a>
        </div>
        <div class="col-auto text-right" v-if="user.roles.production_drafts">
          <!-- <base-button
            type="secondary"
            icon
            size="sm"
            @click="newFolder"

          >
            <span class="btn-inner--icon"
              ><i class="fas fa-folder-plus"></i
            ></span>
            <span class="btn-inner--text">Add Folder</span>
          </base-button>

        -->
          <!--
     
     <base-button
            v-if="editFolderMode"
            type="warning"
            icon
            size="sm"
            @click="toggleEditMode"
          >
            <span class="btn-inner--icon"><i class="fas fa-folder"></i></span>
            <span class="btn-inner--text">Folders</span>
          </base-button>
          <base-button
            v-else
            type="secondary"
            icon
            size="sm"
            @click="toggleEditMode"
          >
            <span class="btn-inner--icon"><i class="fas fa-folder"></i></span>
            <span class="btn-inner--text">Folders</span>
          </base-button> -->

          <base-button type="secondary" icon size="sm" @click="newDelForm">
            <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
            <span class="btn-inner--text">Add Empty Deliverables</span>
          </base-button>
          <!-- toggle upload mode -->

          <base-button type="secondary" icon size="sm" @click="uploadMode = true">
            <span class="btn-inner--icon"><i class="fas fa-upload"></i></span>
            <span class="btn-inner--text">Create Deliverables from Files</span>
          </base-button>
        </div>
      </div>

      <modal :show.sync="uploadMode" size="lg" modal-classes="modal">
        <signed-off-uploader :folders="folders"></signed-off-uploader>
      </modal>

      <div class="row filter-row">
        <div class="col-auto form-group" v-if="this.buttonRadioGroup === 'spreadsheet'">

          <span>
            <span>
              <label class="small preview-label">Previews</label></span>

            <base-switch class="preview-switch" v-model="tablePreviews" offText="Off" onText="On"
              @input="filterPersist()"></base-switch></span>
        </div>
        <!-- <div class="col-auto form-group">

          <span>
            <span>
              <label class="small preview-label">Uploader</label></span>

            <base-switch class="preview-switch" v-model="tableUploads" offText="Off" onText="On"
              @input="filterPersist()"></base-switch></span>
        </div> -->
        <!-- <div class="col-auto form-group">
          <span> <label class="small preview-label">Batch Edit</label></span>
          <span>
            <base-switch class="preview-switch" v-model="batch"        offText="Off"
            onText="On"></base-switch
          ></span>
        </div> -->
        <!--    <div class="col">
          <base-input
            input-classes="form-control-sm"
            class="mb-0"
            placeholder="Search"
            v-model="search"
          >
          </base-input>
        </div>

        <div class="col">
          <base-input class="mb-0">
            <el-select
              clearable
              v-model="statusFilter"
              placeholder="Filter"
              size="mini"
            >
              <el-option
                v-for="option in statuses"
                :key="option.key"
                :label="option.display"
                :value="option.key"
              >
              </el-option>
            </el-select>
          </base-input>
        </div> -->
        <div class="col">
          <base-input class="mb-0" label="Sort By">
            <el-select
              clearable
              v-model="sortBy"
              placeholder="Sort By"
              size="mini"
            >
              <el-option
                v-for="option in sortOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col">
          <base-input class="mb-0" label="Group By">
            <el-select
              clearable
              v-model="groupBy"
              placeholder="Group By"
              size="mini"
            >
              <el-option
                v-for="option in groupOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div> 
      </div>
      <!-- CHANGE DELIVERABLES IN BATCH MODE -->
      <div class="row mt-4" v-if="buttonRadioGroup === 'spreadsheet' && selection.length > 0">
        <div class="col h4">Change Selected Deliverables</div>
      </div>
      <div class="row mt-4" v-if="buttonRadioGroup === 'spreadsheet' && selection.length > 0">
        <div class="col-auto">
          <base-button @click="clearSelection()">Clear Selection</base-button>
        </div>
        <div class="col">
          <base-input>
            <el-select v-model="batchEdits.brand" placeholder="Brand" filterable clearable
              @change="batchChange($event, 'brand')">
              <el-option v-for="option in config.brand" :key="option" :label="option" :value="option">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col">
          <base-input class="mb-0">
            <el-select v-model="batchEdits.type" placeholder="Type" filterable clearable
              @change="batchChange($event, 'type')">
              <el-option v-for="option in config.delTypes" :key="option.display" :label="option.display"
                :value="option.display">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col">
          <base-input>
            <el-select v-model="batchEdits.folder" placeholder="Folder" filterable clearable allow-create
              @change="batchChange($event, 'folderName')">
              <el-option v-for="option in folders" :key="option" :label="option" :value="option">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col">
          <base-input>
            <flat-picker slot-scope="{ focus, blur }" @on-open="focus" @on-close="blur" :config="{ allowInput: true }"
              class="form-control datepicker" v-model="batchEdits.eta" placeholder="ETA"
              @input="batchChange($event, 'eta')">
            </flat-picker>
          </base-input>
        </div>
        <div class="col">
          <base-input>
            <flat-picker slot-scope="{ focus, blur }" @on-open="focus" @on-close="blur" :config="{ allowInput: true }"
              class="form-control datepicker" v-model="batchEdits.deadline" placeholder="Posting Date"
              @input="batchChange($event, 'deadline')">
            </flat-picker>
          </base-input>
        </div>
        <div class="col" v-if="user.roles.production_feedback">
          <base-button class="mb-2" type="success" size="sm" @click="batchApprove">Batch Sign-Off</base-button>
          <base-button class="mb-2" type="warning" size="sm" @click="batchReject">Batch Reject</base-button>
          <base-button class="mb-2" type="danger" size="sm" @click="batchDelete">Batch Delete</base-button>
        </div>
      </div>
    </div>
    <!-- new form -->
    <modal :show.sync="showAddDelModal" size="lg" modal-classes="modal-scrollable">
      <new-deliverable-form :prod="prod" :config="config" :folders="folders" @onAction="showAddDelModal = false"
        @submitted="showAddDelModal = false">
      </new-deliverable-form>
    </modal>
    <!-- edit form -->
    <modal :show.sync="showEditDelModal" size="lg" modal-classes="modal-scrollable">
      <deliverable-edit-form :prod="prod" :folders="folders" :deliverable="editedDel" @onAction="showEditDelModal = false"
        @submitted="showEditDelModal = false">
      </deliverable-edit-form>
    </modal>
    <!-- Card body -->
    <div>
      <ul class="list-group folders">
        <li class="list-group-item" v-for="group in groups" :key="group">
          <display-group :groupName="group" :dels="groupedDels[group]" :sortProp="sortBy" :displayMode="buttonRadioGroup" @editDel="editDel" :previews="tablePreviews">
          </display-group>
        </li>
      </ul>
    </div>

    <div class="card-body" v-if="deliverables.length === 0">
      <div class="row">
        <div class="col">No Deliverables Yet</div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DeliverableRow from "./DeliverableRow";
import DeliverableCard from "./DeliverableCard";
import DeliverableEditForm from "./DeliverableEditForm";
import DeliverableUploadWrapper from "./DeliverableUploadWrapper";
import GroupDisplay from "./DeliverablesWidgetGroup";
import NewDeliverableForm from "./NewDeliverableForm";
import ZipLink from "@/echoComponents/ZipLink";
import SignedOffUploader from "./SignedOffUploader";

import { arrayUnion } from "vuex-easy-firestore";
// js import
import moment from "moment";
import swal from "sweetalert2";

// style import
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "deliverablesWidget",
  data: function () {
    return {
      showAddDelModal: false,
      showEditDelModal: false,
      editedDel: "",
      uploadMode: false,
      search: "",
      tablePreviews: false,
      tableUploads: false,
      statusFilter: "",
      sortBy: "title",
      sortOptions: [
        { value: "title", label: "Title" },
        { value: "updated_at", label: "Last Updated" },
        { value: "deadline", label: "Deadline" },
        
      ],
      groupBy: "folderName",
      groupOptions: [
        { value: "folderName", label: "Folder" },
        { value: "state", label: "Status" },
      ],
      ready: false,
      batch: false,
      // spreadsheet: false,
      // selection: [],
      radioOptions: [
        { value: "folders", label: "Cards" },
        { value: "spreadsheet", label: "Rows" },
      ],
      buttonRadioGroup: "folders",
      batchEdits: {
        brand: "",
        type: "",
        folder: "",
        brief: "",
        deadline: "",
        eta: "",
      },
    };
  },
  props: ["prod", "embed"],
  components: {
    "new-deliverable-form": NewDeliverableForm,
    "deliverable-edit-form": DeliverableEditForm,
    "deliverable-card": DeliverableCard,
    "deliverable-row": DeliverableRow,
    "zip-link": ZipLink,
    "signed-off-uploader": SignedOffUploader,
    "display-group": GroupDisplay,
    "deliverable-upload-wrapper": DeliverableUploadWrapper,
    flatPicker,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    prodID() {
      return this.prod.id;
    },
    users() {
      return this.$store.getters["users/users"];
    },
    admins() {
      const result = this.users.filter((usr) => usr.roles.production_feedback);
      return result;
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    zipOfSigned() {
      const zips = this.$store.getters["zips/signedDels"](this.prodID);
      if (zips) {
        return zips;
      } else {
        return null;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    signed() {
      const dels = this.$store.getters["deliverables/delsByProdID"](
        this.prodID
      );

      return dels.filter((p) => p.state === "signed");
    },
    folders() {
      if (this.deliverables) {
        let pA = this.deliverables;
        let arr = {};
        pA.forEach(function (entry) {
          if (entry.folderName) {
            arr[entry.folderName] = true;
          }
        });
        return Object.keys(arr);
      }
    },
    groupedDels() {
      function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) {
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          } else {
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }
        }, {});
      }
      return groupByKey(this.deliverables, this.groupBy);
    },
    groups() {
      return Object.keys(this.groupedDels);
    },
    // folderFilters() {
    //   let arr = []
    //   this.folders.forEach(function (value) {
    //     arr.push({ text: value, value: value })
    //   })
    //   return arr
    // },
    // filters() {
    //   let typesObj = {}
    //   let formatsObj = {}
    //   let brandsObj = {}
    //   let statesObj = {}
    //   this.deliverables.forEach(function (del) {
    //     if (del.type) {
    //       if (typesObj[del.type]) {
    //         typesObj[del.type] += 1
    //       } else {
    //         typesObj[del.type] = 1
    //       }
    //     }
    //     if (del.format) {
    //       if (formatsObj[del.format]) {
    //         formatsObj[del.format] += 1
    //       } else {
    //         formatsObj[del.format] = 1
    //       }
    //     }
    //     if (del.brand) {
    //       if (brandsObj[del.brand]) {
    //         brandsObj[del.brand] += 1
    //       } else {
    //         brandsObj[del.brand] = 1
    //       }
    //     }
    //     if (del.state) {
    //       if (statesObj[del.state]) {
    //         statesObj[del.state] += 1
    //       } else {
    //         statesObj[del.state] = 1
    //       }
    //     }

    //   })
    //   let types = []
    //   let formats = []
    //   let brands = []
    //   let states = []
    //   Object.keys(typesObj).forEach(key => {
    //     types.push({ text: key + ': ' + typesObj[key], value: key })
    //   })

    //   Object.keys(formatsObj).forEach(key => {
    //     formats.push({ text: key + ': ' + formatsObj[key], value: key })
    //   })
    //   Object.keys(brandsObj).forEach(key => {
    //     brands.push({ text: key + ': ' + brandsObj[key], value: key })
    //   })
    //   Object.keys(statesObj).forEach(key => {
    //     states.push({ text: key + ': ' + statesObj[key], value: key })
    //   })
    //   return { types, formats, brands, states }
    // },
    deliverables() {
      const dels = this.$store.getters["deliverables/delsByProdID"](
        this.prodID
      );
      let status;
      if (this.statusFilter === "") {
        status = dels;
      } else {
        status = dels.filter((p) => p.state === this.statusFilter);
      }
      if (!this.user.roles.production_drafts) {
        status = status.filter((p) => p.state === "signed");
      }

      let search = status.filter((o) =>
        o.title.toLowerCase().includes(this.search.toLowerCase())
      );

      let sortBy;
      if (this.sortBy) {
        sortBy = this.sortBy;
      } else {
        sortBy = "title";
      }

      search = search.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (!a[sortBy]) {
          return -1;
        }
        return 0;
      });
      return search;
    },
    selection() {
      return this.$store.getters["archiveTemp/delSelection"];
    },
  },
  beforeMount() {
    this.$store.dispatch("deliverables/openDBChannel", {
      where: [["production", "==", this.prod.id]],
    });
    this.$store.dispatch("files/openDBChannel", {
      where: [["prodID", "==", this.prod.id]],
    });
    if (localStorage.displayPref) {
      this.buttonRadioGroup = localStorage.displayPref;
      console.log(localStorage.displayPref)
    }
  },
  methods: {
    toggleEditMode() {
      this.editFolderMode = !this.editFolderMode;
    },
    filterPersist() {
      localStorage.displayPref = this.buttonRadioGroup;
      localStorage.tablePreviews = this.tablePreviews;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    // clearSelection() {
    //   this.$store.commit("archiveTemp/clearDelSelection");
    // },
    editDel(event) {
      this.showEditDelModal = true;
      this.editedDel = event;
    },
    newDelForm() {
      this.showAddDelModal = true;
    },
    handleSelectionChange(val) {
      this.selection = val
    },
    stateDisplay(val, version) {
      if (val === "reworking") {
        return "Working on v" + (version + 1);
      } else if (val === "signed") {
        return "Signed Off"
      } else if (val === "feedback") {
        return "Awaiting feedback for v" + version;
      } else if (val === "production") {
        return "Production";
      } else {
        return val;
      }
    },
    batchChange(event, field) {
      // apply them to each of the selected dels
      for (const del of this.selection) {
        if (event) {
          // console.log(del);
          var payload = {};
          payload.id = del;
          payload[field] = event;
          this.$store.dispatch("deliverables/patch", payload);
        }
      }
      this.batchEdits[field] = "";
    },
    zipSigned() {
      // if current file, get the current file

      var files = [];

      var i;
      for (i = 0; i < this.signed.length; i++) {
        let del = this.signed[i];
        if (del.currentFileID) {
          const file = { id: del.currentFileID };
          file.folderName = del.folderName ? del.folderName : "";
          files.push(file);
        }
      }
      swal
        .fire({
          title: `Request a Zip of ${files.length} files?`,
          text: "This will zip the signed off deliverables and be available for 1 year.",
          type: "question",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            // add 365 days to the deletion time for the zip
            var endDate = new Date();
            endDate.setDate(endDate.getDate() + 365);
            var date = new Date();

            var payload = {};
            payload.prodID = this.prodID;
            payload.deleteDate = endDate;
            payload.files = files;
            payload.zipName =
              this.prod.title + " " + moment(date).format("YYYY-MM-DD");
            payload.all = true;
            payload.status = "requested";
            // this.$store.dispatch("jimstaControl/patch", {
            //   lastSubmission: new Date(),
            // });
            this.$store.dispatch("zips/insert", payload);

            this.$store.dispatch("zips/openDBChannel", {
              where: [["prodID", "==", this.$route.params.id]],
            });
          }
        });
    },
    batchApprove() {

      this.batchChange("signed", "state");

      // for each deliverable in batch
      this.selection.forEach((delId) => {
        // if current file, get the current file

        const del = this.deliverables.filter((d) => d.id === delId)[0];

        if (del.currentFileID) {
          //set file to signed and add note to file log
          const fileId = del.currentFileID;
          var payload = {};
          payload.action = "Signed off";
          payload.user = this.user.name;
          payload.date = new Date();

          this.$store.dispatch("files/patch", {
            id: fileId,
            status: "signed",
            events: arrayUnion(payload),
          });
          // slack message
          var slack = {};
          slack.deliverable = this.del;
          slack.production = this.production;
          slack.version = this.file.version;
          this.$store.dispatch("files/signOffToSlack", slack);
          // this.$router.push(`/brief/${this.del.production}`)
        }
      });
      this.clearSelection();
    },
    batchReject() {
      this.batchChange("reworking", "state");

      // for each deliverable in batch
      this.selection.forEach((delId) => {
        // if current file, get the current file

        const del = this.deliverables.filter((d) => d.id === delId)[0];

        if (del.currentFileID) {
          //set file to edit mode and add note to file log
          const fileId = del.currentFileID;
          var payload = {};
          payload.action = "Submitted notes";
          payload.user = this.user.name;
          payload.date = new Date();

          this.$store.dispatch("files/patch", {
            id: fileId,
            status: "edit",
            events: arrayUnion(payload),
          });
        }
      });
      this.clearSelection();
    },
    batchDelete() {
      // for each deliverable in batch
      this.selection.forEach((delId) => {
        // if current file, get the current file
        // console.log(delId);

        this.$store.dispatch("deliverables/delete", delId);
      });
      this.clearSelection();
    },
  },
};
</script>

<style>
/* 
ul.deliverable > li:nth-child(odd) {
  background: #ffffff;
}
ul.deliverable > li:nth-child(even) {
  background: #fafafa;
} */

ul.folders>li {
  background: #f8f8f8;
}

.filter-row .form-group {
  margin-bottom: 0px;
  margin-top: 1.5em;
}

.preview-switch {
  margin-left: 15px;
  top: 8px;
}

/* .noFolders{
  margin-top: 15px;
} */
</style>
