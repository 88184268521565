const notes = {
  firestorePath: 'notes',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'notes',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    notes: state => Object.values(state.data),
    notesByFileID: (state) => (id) => {
      let notes = Object.values(state.data)
      notes = notes.filter(f => !f.response) // filter out responses
      notes = notes.filter(f => f.file === id)
      return notes.slice().sort((a, b) => {
        if (a.seconds > b.seconds) {
          return 1
        }
        if (a.seconds < b.seconds) {
          return -1
        }
        if (!a.seconds) {
          return -1
        }
        return 0
      })
    },
    responsesByNoteId: (state) => (id) => {
      let notes = Object.values(state.data)
      notes = notes.filter(f => f.response) // filter out responses
      notes = notes.filter(f => f.note === id) // just responses for this note
      return notes.slice().sort((a, b) => {
        if (a.created_at > b.created_at) {
          return 1
        }
        if (a.created_at < b.created_at) {
          return -1
        }
        if (!a.created_at) {
          return -1
        }
        return 0
      })
    },
  },
  mutations: {},
  actions: {},
}

export default notes