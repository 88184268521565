<template>
  <div class="container">
    <div class="row">
      <div class="col-auto">
        <base-button
          v-if="isActive"
          class="float-left mr-2"
          size="sm"
          type="default"
          @click="timeButton"
          >{{ time }}</base-button
        >
        <base-button
          v-else
          class="float-left mr-2"
          size="sm"
          outline
          type="default"
          @click="timeButton"
          >{{ time }}</base-button
        >
      </div>
      <div class="col">
        <div class="row">
          <div class="col-auto" v-if="event.source === 'user'">
            <small class="">{{ duration }}s</small>
          </div>
          <div class="col" v-if="event.source === 'user'">
            <div class="small" v-if="event.bookmark">{{ event.bookmark }}</div>
            <div class="small" v-if="bookmarkPlayer">{{ bookmarkPlayer.fullName }}</div>
            <div class="small" v-if="bookmarkTeam">{{bookmarkTeam.teamName}}</div>
                      <div class="small" v-if="event.username">{{event.username}}</div>
                                  <div class="small" v-if="event.note">{{ event.note }}</div>
          </div>
        </div>
        <div class="row" v-if="bowler && batter">
          <small class="col"
            >{{ event.innings }}.{{ event.over }}.{{ event.ballNum }} -
            {{ bowler.scorecardName }} to {{ batter.scorecardName }}</small
          >
        </div>
        <div class="row">
          <div class="col" v-if="event.runsBat">
            <!-- <i class="fas fa-running"></i> {{ event.runs }} -->
            Runs: {{ event.runsBat }}
          </div>
          <div class="col" v-if="event.wicket">Wicket</div>
        </div>
        <div class="row" v-for="(field, index) in displayFields" :key="index">
          <small class="col">{{ event[field] }}</small>
        </div>
      </div>
      <div class="col-auto">
        <base-checkbox
          type="default"
          @input="selection($event)"
          v-model="event.selected"
        ></base-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["event", "currentEditEvent"],

  computed: {
    isActive() {
      if (this.currentEditEvent === this.event.id) {
        return true;
      }
    },
    time() {
      var date = new Date(null);
      date.setSeconds(this.event.tcIn); // specify value for SECONDS here
      var result = date.toISOString().substr(11, 8);
      return result;
    },
    duration() {
      return Math.floor(this.event.tcOut - this.event.tcIn);
    },
    bowler() {
      if (this.event.bowler) {
        return this.$store.getters["players/byId"](this.event.bowler);
      }
    },
    batter() {
      if (this.event.batter) {
        return this.$store.getters["players/byId"](this.event.batter);
      }
    },
    bookmarkPlayer() {
      if (this.event.bookmarkPlayer) {
        return this.$store.getters["players/byId"](this.event.bookmarkPlayer);
      } else {
        return false;
      }
    },
    bookmarkTeam() {
       if (this.event.bookmarkTeam) {
          return this.$store.getters["teams/byId"](this.event.bookmarkTeam)
       }
    },
    displayFields() {
      return this.$store.getters["archiveTemp/displayFields"];
    },
    // selected: {
    //   get: function () {
    //         return this.event.selected
    //   },
    //   set: function (value) {
    //     this.$store.commit("archiveEvents/eventSelect", {id: this.event.id, value: value})
    //   }
    // }
  },
  methods: {
    timeButton() {
      this.$emit("jumpTo", this.event.tcIn);
      this.$emit("select", this.event.id);
    },
    selection(value) {
      this.$store.commit("archiveTemp/eventSelect", {
        id: this.event.id,
        value: value,
      });
    },
  },
};
</script>

<style>
</style>
