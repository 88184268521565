const players = {
    firestorePath: 'players',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'players',
    statePropName: 'data',
    namespaced: true, // automatically added
    // serverChange: {
    //   convertTimestamps: {
    //     startDate: '%convertTimestamp%',
    //   },
    // },
    state: {
    },
    getters: {
      array: state => Object.values(state.data),
      obj: state => state.data,
      byId: (state) => (id) => {
        return state.data[id]
      },
      byProp: (state) => (field, value) => {
        return Object.values(state.data).filter(player => player[field] === value)[0]
      },
      byPropMultiple: (state) => (field, values) => {
        return Object.values(state.data).filter(player => values.includes(player[field]))
      }
      
    },
    mutations: {
    },
    actions: {
    },
  }
  
  export default players