<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="departmentFilter"
                      placeholder="Department"
                      clearable
                      @change="setUserField($event, 'department')"
                      @focus="departmentFilter = ''"
                    >
                      <el-option
                        v-for="option in departments"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="typeFilter"
                      placeholder="Type"
                      clearable
                      @focus="typeFilter = ''"
                    >
                      <el-option
                        v-for="option in types"
                        :key="option.display"
                        :label="option.display"
                        :value="option.display"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="brandFilter"
                      placeholder="Brand"
                      clearable
                      @focus="brandFilter = ''"
                    >
                      <el-option
                        v-for="option in brands"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="formatFilter"
                      placeholder="Dimensions"
                      clearable
                      @focus="formatFilter = ''"
                    >
                      <el-option
                        v-for="option in dimensions"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col text-right">
                  <base-input>
                    <el-select
                      v-model="partnerFilter"
                      placeholder="Partner"
                      clearable
                      @focus="partnerFilter = ''"
                    >
                      <el-option
                        v-for="option in partners"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <form @submit.prevent="search">
                    <base-input
                      v-model="searchText"
                      placeholder="Search"
                      prepend-icon="fa fa-search"
                    />
                  </form>
                </div>
                <div class="col-auto" v-if="freshSearch">
                  <base-button type="primary" @click="search(true)"
                    >Search</base-button
                  >
                </div>
                <div class="col-auto" v-else>
                  <base-button type="secondary" @click="search"
                    >Search</base-button
                  >
                </div>
                <!-- <div class="col-auto">
                  <base-pagination
                    type="default"
                    :page-count="delPaginator.length"
                    v-model="pagination.default"
                  ></base-pagination>
                </div> -->
              </div>
              <div class="row mb-3" v-if="selected.length > 0">
                <div class="col">
                  <span class="mr-3">
                    Selected Items: {{ selected.length }}</span
                  >
                  <base-button @click="clearSelection" type="secondary"
                    >Clear All</base-button
                  >
                </div>
                <div class="col">
                  <base-button>Add to Collection</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9" v-if="isLoaded">
      <div class="row">
        <div class="col">
          <masonry
            :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
            :gutter="30"
          >
            <catalogue-card
              v-for="del in searchResults"
              :del="del"
              :key="del.id"
            ></catalogue-card>
          </masonry>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Showing {{ pagination.from + 1 }} to {{ pagination.to }} of
          {{ searchHits }} files
        </div>
        <div class="col">
          <base-pagination
            class="pagination-no-border"
            v-model="currentPage"
            :per-page="pagination.chunkSize"
            :total="searchHits"
          >
          </base-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>
<script>
import CatalogueCard from "./Components/CatalogueCard";
import { Firebase } from "@/firebase";

export default {
  name: "catalogue",
  components: {
    "catalogue-card": CatalogueCard,
  },
  data() {
    return {
      departmentFilter: "",
      searchText: "",
      freshSearch: false,
      searchResults: [],
      searchHits: 0,
      typeFilter: "",
      brandFilter: "",
      formatFilter: "",
      partnerFilter: "",
      previews: false,
      currentPage: 1,
      searchPages: 0,
      pagination: {
        default: 1,
        chunkSize: 20,
        from: 0,
        to: 0,
      },
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    allProds() {
      return this.$store.getters["briefs/productions"];
    },
    allDels() {
      return this.$store.getters["deliverables/dels"];
    },
    selected() {
      return this.$store.getters["archiveTemp/delSelection"];
    },

    // deliverables() {
    //   if (this.searchResults) {
    //     //let dels = this.$store.getters["deliverables/delsMerged"];
    //     return this.searchResults;
    //     //filter for feedback

    //   //   dels.sort((a, b) => {
    //   //     if (a.updated_at > b.updated_at) {
    //   //       return -1;
    //   //     }
    //   //     if (a.updated_at < b.updated_at) {
    //   //       return 1;
    //   //     }
    //   //     if (!a.updated_at) {
    //   //       return -1;
    //   //     }
    //   //     return 0;
    //   //   });
    //   //   return dels;
    //   // } else {
    //   //   return [];
    //   // }
    //   }
    // },
    // delPaginator() {
    //   let s = this.deliverables;
    //   let pages = [];
    //   var i,
    //     j,
    //     temparray,
    //     chunk = this.pagination.chunkSize;
    //   for (i = 0, j = s.length; i < j; i += chunk) {
    //     temparray = s.slice(i, i + chunk);
    //     pages.push(temparray);
    //   }
    //   return pages;
    // },

    departments() {
      if (this.departmentAccess) {
        return this.departmentAccess;
      } else {
        return this.config.departments;
      }
    },
    departmentAccess() {
      if (this.user.departmentAccess) {
        if (this.user.departmentAccess.length > 0) {
          return this.user.departmentAccess;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    types() {
      return this.config.delTypes;
    },
    brands() {
      if (this.brandAccess) {
        return this.brandAccess;
      } else {
        return this.config.brand;
      }
    },
    brandAccess() {
      if (this.user.brandAccess) {
        if (this.user.brandAccess.length > 0) {
          return this.user.brandAccess;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    dimensions() {
      return this.config.formats;
    },
    partners() {
      return this.config.partners;
    },
  },
  watch: {
    currentPage: function (value) {
      console.log(value - 1);
      this.pagination.from = (value - 1) * this.pagination.chunkSize;
      this.search();
    },
    searchText: function () {
      this.freshSearch = true;
    },
    departmentFilter: function () {
      this.freshSearch = true;
    },
    brandFilter: function () {
      this.freshSearch = true;
    },
    typeFilter: function () {
      this.freshSearch = true;
    },
    formatFilter: function () {
      this.freshSearch = true;
    },
    partnerFilter: function () {
      this.freshSearch = true;
    },
  },
  mounted() {
    // this.$store.commit("user/setLoading", true);
    //    if(this.restrictions) {
    //     this.$store.dispatch("deliverables/fetchAndAdd", { limit: 0, where: this.restrictions }).then(() => this.$store.commit("user/setLoading", false));;
    // } else {
    //   this.$store.dispatch("deliverables/fetchAndAdd", { limit: 0 }).then(() => this.$store.commit("user/setLoading", false))
    // }

    // } else {
    //    this.$store
    //   .dispatch("deliverables/fetchAndAdd", {
    //     where: [["state", "==", "signed"]],
    //     clauses: { limit: 0 },
    //   })
    //   .then(() => {
    //     this.$store.commit("user/setLoading", false);
    //   });
    // }

    this.$store.commit("user/setLoading", false);
    this.departmentFilter = this.user.department ? this.user.department : "";
    this.search();
  },
  methods: {
    clearSelection(){
      this.$store.commit("archiveTemp/clearDelSelection")
    },
    // filterPersist() {
    //   localStorage.worklistDepartmentFilter = this.departmentFilter;
    // },
    async search(reset) {
      let starter = reset ? 0 : this.pagination.from;
      this.pagination.from = starter;
      console.log(starter);
      let payload = {
        response: null,
        request: {
          index: "deliverables",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: starter,
            size: this.pagination.chunkSize,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [
                  {
                    match: { state: "signed" },
                  },
                ],
              },
            },
          },
        },
      };
      if (this.departmentFilter) {
        payload.request.body.query.bool.filter.push({
          match: { department: this.departmentFilter },
        });
      } else if (this.departmentAccess) {
        this.departmentAccess.forEach((dep) => {
          payload.request.body.query.bool.filter.push({
            match: { department: dep },
          });
        });
      }
      if (this.brandFilter) {
        payload.request.body.query.bool.filter.push({
          match: { brand: this.brandFilter },
        });
      } else if (this.brandAccess) {
        this.brandAccess.forEach((brand) => {
          payload.request.body.query.bool.filter.push({
            match: { brand: brand },
          });
        });
      }
      if (this.typeFilter) {
        payload.request.body.query.bool.filter.push({
          match: { type: this.typeFilter },
        });
      }
      if (this.formatFilter) {
        payload.request.body.query.bool.filter.push({
          match: { format: this.formatFilter },
        });
      }
      if (this.partnerFilter) {
        payload.request.body.query.bool.filter.push({
          match: { partner: this.partnerFilter },
        });
      }
      if (this.searchText) {
        payload.request.body.query.bool.should.push({
          match: { title: { query: this.searchText, fuzziness: "AUTO" } },
        });
        payload.request.body.query.bool.should.push({
          match: {
            productionTitle: { query: this.searchText, fuzziness: "AUTO" },
          },
        });
        payload.request.body.query.bool.minimum_should_match = 1;
      }
      this.$store.commit("user/setLoading", true);
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
      console.log(result.id);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            this.searchHits = d.response.hits.total.value;
            this.searchReturn = d.response.hits.hits.length;
            this.pagination.to = this.pagination.from + this.searchReturn;
            this.searchPages = Math.ceil(
              this.searchHits / this.pagination.chunkSize
            );
            this.$store.commit("user/setLoading", false);
            this.freshSearch = false;
          }
        });
    },
    setUserField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;
      this.$store.dispatch("user/patch", payload);
    },
  },
};
</script>