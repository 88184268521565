<template>
  <div>
    <base-header
      class="pb-6 content__title content__title--calendar"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row align-items-center py-4"></div>
    </base-header>

    <div v-if="loading === false">
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col-2">
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <!-- Title -->
                <h5 class="h3 mb-0">Competitions</h5>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.englandMen"
                >
                  England Men
                </base-checkbox>
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.englandWomen"
                >
                  England Women
                </base-checkbox>

                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.hundredMen"
                >
                  Hundred Men
                </base-checkbox>
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.hundredWomen"
                >
                  Hundred Women
                </base-checkbox>
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.blast"
                >
                  Blast
                </base-checkbox>
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.champo"
                >
                  Championship
                </base-checkbox>
                <base-checkbox
                  type="warning"
                  @input="addSources"
                  class="mb-3"
                  v-model="selector.onedaycup"
                >
                  One Day Cup
                </base-checkbox>
                <base-button size="sm" type="warning" @click="allCompetitions"
                  >All</base-button
                ><base-button type="warning" size="sm" @click="noCompetitions"
                  >None</base-button
                >
              </div>
            </div>

            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <!-- Title -->
                <h5 class="h3 mb-0">Department</h5>
              </div>
              <!-- Card body -->
              <div class="card-body">
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.Digital"
                >
                  Digital
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.TheHundred"
                >
                  The Hundred
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.PGMarketing"
                >
                  P+G Marketing
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.CommercialMarketing"
                >
                  Commercial Marketing
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.Communications"
                >
                  Communications
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.NationalProgrammes"
                >
                  National Programmes
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.Partnerships"
                >
                  Partnerships
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.Events"
                >
                  Events
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.OtherECB"
                >
                  Other ECB
                </base-checkbox>
                <base-checkbox
                  @input="addSources"
                  class="mb-3"
                  v-model="departments.External"
                >
                  External
                </base-checkbox>
                <base-button size="sm" type="primary" @click="allDepartments"
                  >All</base-button
                ><base-button type="primary" size="sm" @click="noDepartments"
                  >None</base-button
                >
              </div>
            </div>
          </div>

          <div class="col">
            <!-- Fullcalendar -->
            <div class="card card-calendar">
              <!-- Card header -->
              <div class="card-header">
                <!-- Title -->
                <div class="row">
                  <div class="col-3">
                    <h5 class="h3">{{ title }}</h5>
                  </div>
                  <div class="col-9 text-right">
                    <a
                      href="#"
                      @click.prevent="prev"
                      class="fullcalendar-btn-prev btn btn-sm btn-default"
                    >
                      <i class="fas fa-angle-left"></i>
                    </a>
                    <a
                      href="#"
                      @click.prevent="next"
                      class="fullcalendar-btn-next btn btn-sm btn-default"
                    >
                      <i class="fas fa-angle-right"></i>
                    </a>

                  </div>
                </div>
              </div>
              <!-- Card body -->
              <div class="card-body p-0 card-calendar-body">
                <full-calendar
                  :plugins="calendarPlugins"
                  :editable="true"
                  contentHeight="auto"
                  :theme="false"
                  :selectable="true"
                  :selectHelper="true"
                  ref="fullCalendar"
                  class="calendar"
                  @datesRender="datesRender"
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  :eventRender="filterer"
                  @eventMouseEnter="mouseEnter"
                  @eventDrop="onEventDrop"
                  defaultView="dayGridMonth"
                >
                </full-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Tooltip,
} from "element-ui";

export default {
  name: "calendar",
  components: {
    FullCalendar,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      zoom: 5,
      calendarPlugins: [interactionPlugin, dayGridPlugin],
      showAddModal: false,
      showEditModal: false,
      departments: {
        Digital: true,
        TheHundred: true,
        PGMarketing: true,
        CommercialMarketing: true,
        Communications: true,
        NationalProgrammes: true,
        Partnerships: true,
        Events: true,
        OtherECB: true,
        External: true,
      },
      selector: {
        englandMen: true,
        englandWomen: true,
        hundredMen: true,
        hundredWomen: true,
        blast: true,
        champo: true,
        onedaycup: true,
      },
      sources: [],
      title: "Calendar",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["user/loading"];
    },
    fixtures() {
      // let d = hundredMen2020.content;
      // d.forEach((fixture) => (fixture.comp = "hundredMen"));
      // let x = hundredWomen2020.content;
      // x.forEach((fixture) => (fixture.comp = "hundredWomen"));
      // d = d.concat(x);
      // x = blast2020.content;
      // x.forEach((fixture) => (fixture.comp = "blast"));
      // d = d.concat(x);
      // x = championshipDiv12020.content;
      // x.forEach((fixture) => (fixture.comp = "champo"));
      // d = d.concat(x);
      // x = championshipDiv22020.content;
      // x.forEach((fixture) => (fixture.comp = "champo"));
      // d = d.concat(x);
      // x = onedaycup2020.content;
      // x.forEach((fixture) => (fixture.comp = "onedaycup"));
      // d = d.concat(x);
      // x = englandMen2020.content;
      // x.forEach((fixture) => (fixture.comp = "englandMen"));
      // d = d.concat(x);
      // x = englandWomen2020.content;
      // x.forEach((fixture) => (fixture.comp = "englandWomen"));
      // d = d.concat(x);
      // let data = [];
      // d.forEach((fixture) => {
      //   let f = {};
      //   f.tournament = fixture.comp;
      //   f.start = fixture.timestamp;
      //   f.end = fixture.endTimestamp;
      //   f.className = fixture.comp;
      //   f.title = `🏏${fixture.scheduleEntry.team1.team.shortName} vs ${fixture.scheduleEntry.team2.team.shortName} - ${fixture.scheduleEntry.venue.shortName}`;
      //   data.push(f);
      // });

      // return data;
    },
    productions() {
      var newArray = this.$store.getters["briefs/productions"];
      var filteredOnce = newArray.filter(function (el) {
        return el.active;
      });
      return filteredOnce;
    },
    config() {
      return this.$store.getters["config/config"];
    },
    dels() {
      const stateList = this.$store.getters["deliverables/delsMerged"];
      let dels = [];

      stateList.forEach(function (del) {
        //var i = JSON.parse(JSON.stringify(del));
        let event = {};
        var emoji;
        switch (del.state) {
          case "signed":
            emoji = "✔️";
            break;
          case "feedback":
            emoji = "💬";
            break;
          default:
            emoji = "⏳";
        }
        //i.id = del.id
        event.className = "bg-primary"; //entry.status.replace(/[^0-9a-z]/gi, '') //
        event.start = del.deadline;
        event.allDay = true;

        event.id = del.id

        event.department = del.department;
        event.title =
          emoji +
          " " +
          del.productionTitle +
          " - " +
          del.title +
          " - " +
          del.format;
        event.url = `#/brief/${del.production}/deliverable/${del.id}`;

        dels.push(event);
      });

      return dels;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      var allUsers = this.$store.getters["users/users"];
      var filtered = allUsers.filter(function (el) {
        return el.resource === true;
      });
      return filtered;
    },
  },
  watch: {
    dels() {
      this.addSources();
    },
  },
  beforeMount() {
    this.$store.dispatch("deliverables/openDBChannel", {
      where: [["state", "not-in", ["signed", "held"]]],
    });
  },
  mounted() {
    if (localStorage.schedulerDepartments) {
      this.departments = JSON.parse(localStorage.schedulerDepartments);
    }
    if (localStorage.schedulerCompetitions) {
      this.selector = JSON.parse(localStorage.schedulerCompetitions);
    }
    this.addSources();
  },
  methods: {
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    datesRender(info) {
      this.title = info.view.title;
    },
    addSources() {
      const s = this.calendarApi().getEventSources();
      //console.log(s)
      s.forEach(function (entry) {
        entry.remove();
      });
      this.calendarApi().addEventSource(this.dels);
      this.calendarApi().addEventSource(this.fixtures);
      localStorage.schedulerDepartments = JSON.stringify(this.departments);
      localStorage.schedulerCompetitions = JSON.stringify(this.selector);
    },
    filterer(event) {
      //               console.log(event.el)
      //
      //               var element = event.el
      //                 // not working
      //                  var wrapper = document.createElement('el-tooltip');
      //                  wrapper.setAttribute('content', 'test')
      //                  wrapper.setAttribute('placement', 'top')
      //                  wrapper.appendChild(element)

      if (event.event.extendedProps.tournament) {
        let t = event.event.extendedProps.tournament;
        if (this.selector[t]) {
          return true;
        } else {
          return false;
        }
      }
      if (event.event.extendedProps.department) {
        let d = event.event.extendedProps.department.replace(/[^0-9a-z]/gi, "");

        //console.log(d)
        if (this.departments[d]) {
          return true;
        } else {
          return false;
        }
      }
    },
    next() {
      this.calendarApi().next();
    },
    prev() {
      this.calendarApi().prev();
    },
    notifyVue() {
      // console.log(event)
      this.$notify({
        message: event.target.innerText,
        timeout: 2000,
        type: "danger",
        overlap: true,
        verticalAlign: "top",
        horizontalAlign: "right",
      });
    },
    mouseEnter(event) {
      console.log(event);
      this.notifyVue(event);
    },
    day() {
      this.calendarApi().changeView("dayGrid");
    },
    week() {
      this.calendarApi().changeView("dayGridWeek");
    },
    month() {
      this.calendarApi().changeView("dayGridMonth");
    },
    onEventDrop(event) {
      //console.log(event)
      var payload = {};
      var d = moment(event.event.start).format("YYYY-MM-DD");
      payload.id = event.event.id;

      payload.deadline = d;
      this.$store.dispatch("deliverables/patch", payload);
    },
    allDepartments() {
      for (var key in this.departments) {
        //console.log(this.departments[key])
        this.departments[key] = true;
      }
      this.addSources();
    },
    noDepartments() {
      for (var key in this.departments) {
        this.departments[key] = false;
      }
      this.addSources();
    },
    allCompetitions() {
      for (var key in this.selector) {
        //console.log(this.departments[key])
        this.selector[key] = true;
      }
      this.addSources();
    },
    noCompetitions() {
      for (var key in this.selector) {
        this.selector[key] = false;
      }
      this.addSources();
    },
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/timeline/main.css";
@import "~@/assets/sass/core/vendors/fullcalendar";
@import "~@fullcalendar/resource-timeline/main.css";

.champo {
  background-color: darkseagreen;
}

.onedaycup {
  background-color: #521dc9;
}

.hundredMen {
  background-color: #000000;
}

.hundredWomen {
  background-color: #f76cd1;
}

.englandMen {
  background-color: #11157b;
}

.englandWomen {
  background-color: #6f50af;
}

.blast {
  background-color: coral;
}

.SignedOff {
  background-color: #171717;
}

.AwaitingFeedback {
  background-color: #505050;
}

.Production {
  background-color: #8e8e8e;
}

.fc-time {
  background: none;
  color: white;
}
</style>
