const collections = {
  firestorePath: 'collections',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'collections',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    // debounceTimerMs: 50
    // defaults to 1000
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {
  },
  getters: {
    obj: state => state.data,
    byId: (state) => (id) => state.data[id],
    array: state => Object.values(state.data).sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      if (!a.name) {
        return -1
      }
      return 0
    }),
  },
  mutations: {},
  actions: {



  }
}
export default collections
