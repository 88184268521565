<template>
  <div>
    <base-header class="pb-9 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <tabs tabNavClasses="nav-pills">
            <tab>
              <span slot="title">Retainer</span>
              <div class="card mt-3">
                <div class="card-header border-0">
                  <div class="row">
                    <div class="col-6">
                      <span>
                        <label class="small preview-label">Include Inactive</label></span>
                      <span>
                        <base-switch class="preview-switch" v-model="includeInactive" offText="No"
                          onText="Yes"></base-switch></span>
                    </div>
                    <div class="col-6 text-right">
                      <el-tooltip content="New" placement="top">
                        <base-button type="warning" icon size="sm" @click="addBilling">
                          <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
                          <span class="btn-inner--text">Add New Billing</span>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <!-- Card body -->
                <div>
                  <el-table class="
                  table-responsive
                  align-items-center
                  table-flush table-striped
                  jimTable
                " header-row-class-name="thead-light" :data="retainers">
                    <el-table-column label="Label" min-width="200" prop="label" sortable>
                      <div slot-scope="{ row }" class="table-actions">
                        <a :href="`/#/billing/${row.id}`">{{ row.label }}</a>
                      </div>
                    </el-table-column>
                    <el-table-column label="Group" prop="group" :filters="groups" :filter-method="filterHandler">
                    </el-table-column>
            

                    <el-table-column label="Days Logged">
                      <div slot-scope="{ row }" class="table-actions" v-if="billingDayTotals[row.id]">
                        {{ billingDayTotals[row.id].days }}
                      </div>
                    </el-table-column>
                    <el-table-column label="Quota" prop="quota" sortable>
                    </el-table-column>
                    <el-table-column label="Quota Used">
                      <div slot-scope="{ row }" class="table-actions" v-if="row.quota && billingDayTotals[row.id]">
                        {{
                          Math.floor(
                            (billingDayTotals[row.id].days / row.quota) * 100
                          )
                        }}%
                      </div>
                    </el-table-column>

                    <el-table-column label="Day Rate £" prop="dayRate" sortable>
                    </el-table-column>

                    <el-table-column label="Active" prop="status" width="80">
                      <div slot-scope="{ row }" class="table-actions">
                        <base-checkbox disabled v-model="row.active">
                        </base-checkbox>
                      </div>
                    </el-table-column>
                    <el-table-column label="Start Date" prop="start" sortable>
                    </el-table-column>

                    <el-table-column label="End Date" prop="end" sortable>
                    </el-table-column>
                  </el-table>
                </div>
              </div>

            </tab>
            <tab>
              <span slot="title">Rate Card</span>
              <div class="card mt-3">
                <div class="card-header border-0">
                  <div class="row">
                    <div class="col-6">
                      <span>
                        <label class="small preview-label">Include Inactive</label></span>
                      <span>
                        <base-switch class="preview-switch" v-model="includeInactive" offText="No"
                          onText="Yes"></base-switch></span>
                    </div>
                    <div class="col-6 text-right">
                      <el-tooltip content="New" placement="top">
                        <base-button type="warning" icon size="sm" @click="addBilling">
                          <span class="btn-inner--icon"><i class="fas fa-plus-square"></i></span>
                          <span class="btn-inner--text">Add New Billing</span>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <!-- Card body -->
                <div>
                  <el-table class="
                  table-responsive
                  align-items-center
                  table-flush table-striped
                  jimTable
                " header-row-class-name="thead-light" :data="ratecards">
                    <el-table-column label="Label" min-width="200" prop="label" sortable>
                      <div slot-scope="{ row }" class="table-actions">
                        <a :href="`/#/billing/${row.id}`">{{ row.label }}</a>
                      </div>
                    </el-table-column>
           

                    <el-table-column label="Days Logged">
                      <div slot-scope="{ row }" class="table-actions" v-if="billingDayTotals[row.id]">
                        {{ billingDayTotals[row.id].days }}
                      </div>
                    </el-table-column>
                   

                    <el-table-column label="Day Rate £" prop="dayRate" sortable>
                    </el-table-column>

                    <el-table-column label="Active" prop="status" width="80">
                      <div slot-scope="{ row }" class="table-actions">
                        <base-checkbox disabled v-model="row.active">
                        </base-checkbox>
                      </div>
                    </el-table-column>
           
                  </el-table>
                </div>
              </div>
            </tab>
          </tabs>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DropdownMenu, DropdownItem, Dropdown } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import tabs from "@/components/Tabs/Tabs";
import tab from "@/components/Tabs/Tab";

export default {
  name: "brief",
  data: function () {
    return {
      includeInactive: false,
      colours: [
        { text: "Rate Card", value: "#fb6340" },
        { text: "Retainer", value: "#5e72e4" },
      ],
    };
  },
  // props: ['id'],
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    flatPicker,
    tabs,
    tab,
  },
  beforeMount() {
    this.$store.dispatch("billings/fetchAndAdd");
    this.$store.dispatch("resources/fetchAndAdd");

    let start = new Date("2022-02-01");
    console.log(start);
    this.$store
      .dispatch("shifts/openDBChannel", { where: [["date", ">=", start]] })
      .catch(console.error);
  },
  computed: {
    loading() {
      return this.$store.getters["user/loading"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    billings() {
      let res = this.$store.getters["billings/billings"];
      if (this.includeInactive) {
        return res;
      } else {
        return res.filter((a) => a.active === true);
      }
    },
    shifts() {
      return this.$store.getters["shifts/shifts"];
    },
    groups() {
      let res = {};
      this.billings.forEach((a) => {
        if (a.group) {
          res[a.group] = true;
        }
      });
      let filters = [];
      let resArr = Object.keys(res);
      resArr.forEach((b) => {
        filters.push({ text: b, value: b });
      });
      return filters;
    },
    retainers(){
      return this.billings.filter((b) => b.colour === '#5e72e4');
    },
    ratecards(){
      return this.billings.filter((b) => b.colour === '#fb6340');
    },
    billingDayTotals() {
      let shifts = this.shifts;
      var result = {};
      shifts.reduce(function (res, value) {
        if (!res[value.billing]) {
          res[value.billing] = { billing: value.billing, days: 0 };
          result[value.billing] = res[value.billing];
        }
        if (value.billing) {
          res[value.billing].days += value.days;
        }
        return res;
      }, {});
      return result;
    },
  },
  methods: {
    changeField(value, key, field) {
      var payload = {};
      payload.id = key;
      payload[field] = value;
      this.$store.dispatch("billings/patch", payload);
    },
    async addBilling() {
      let id = await this.$store.dispatch("billings/insert", {
        label: "New Billing",
        active: false,
      });
      this.$router.push(`/billing/${id}`);
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>
<style>
.del-table .form-group {
  margin-bottom: 0px;
}

.card .jimTable td,
.card .jimTable th {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.card .jimTable td:first-child,
.card .jimTable th:first-child {
  padding-left: 1.5em;
}

.select-warning .el-select .el-tag {
  background: #fb6340;
}
</style>