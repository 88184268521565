import moment from 'moment'

export default (value) => {


  let v
  if (value.seconds) {
    v = new Date(value.seconds * 1000)
  } else if (value._seconds) {
    v = new Date(value._seconds * 1000)
  } 
  else {
    v = value
  } 


  return moment(v).calendar(null, {
    sameDay: '[Today] hh:mm a',
    nextDay: '[Tomorrow] hh:mm a',
    nextWeek: 'dddd hh:mm a',
    lastDay: '[Yesterday] hh:mm a',
    lastWeek: '[Last] dddd hh:mm a',
    sameElse: 'DD/MM/YYYY hh:mm a'
});
}
