const zips = {
    firestorePath: 'zips',
    firestoreRefType: 'collection', // or 'doc'
    moduleName: 'zips',
    statePropName: 'data',
    namespaced: true, // automatically added
  
    // this object is your store module (will be added as '/myModule')
    // you can also add state/getters/mutations/actions
    state: {
    },
    getters: {
      array: state => Object.values(state.data),
      byId: (state) => (id) => {
        return state.data[id]
      },
      signedDels: (state) => (prodId) => {
        let array = Object.values(state.data)
        array = array.filter(r => r.prodID === prodId)
        return array.filter(r => r.all)[0]
      },
      byCol: (state) => (id) => {
        let array = Object.values(state.data)
        return array.filter(r => r.colID === id)[0]
      },
    },
    mutations: {},
    actions: {},
  }
  
  export default zips