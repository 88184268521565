<template>
  <div>
    <NewFile
      :boxHeight="boxHeight"
      :multiple="false"
      :message="message"
      accepted="text/vtt"
      :fileName="fileName"
      :basePath="basePath"
      @success="success"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-subtitle",
  props: ["deliverable", "production", "videoFile", "file", "message", "boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    basePath() {
      var str = this.videoFile.sourcePath;
var n = str.lastIndexOf('/');
var result = str.substring(0, n);
      return result
    },
    fileName() {
        return `${this.videoFile.sourceFileName}-subtitles`
    },
  },
  methods: {
    success(payload) {
      payload.what = "subtitle";
      payload.videoFileID = this.videoFile.id
      payload.delID = this.deliverable.id;
      payload.delTitle = this.deliverable.title;
      payload.prodTitle = this.production.title;
      payload.prodID = this.deliverable.production;
      payload.department = this.production.department;

      // commit file to DB
      this.$store.dispatch("files/insert", payload).then((result) => {
        //update file details
        let delPayload = {};
        delPayload.id = this.videoFile.id;
        delPayload.subsFileID = result;
        this.$store.dispatch("files/patch", delPayload);
      });
    }
  }
};
</script>

<style scoped>
</style>