const resources = {
  firestorePath: 'resources',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'resources',
  statePropName: 'data',
  namespaced: true, // automatically added

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    resources: state => Object.values(state.data),
    active: state => Object.values(state.data).filter(a => a.active),
    withBilling: state => (id) => {
      let res = Object.values(state.data).filter(a => a.availableBillings)
      return res.filter(a => a.availableBillings.includes(id))
    },
    byId: (state) => (id) => {
      return state.data[id]
    },
  },
  mutations: {},
  actions: {},
}

export default resources