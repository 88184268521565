<template>
  <div>
    <div class="form-group row">
      <label class="col-3 col-form-label form-control-label">Title</label>
      <div class="col-9">
        <base-input
          v-model="deliverable.title"
          placeholder="e.g. Main Feature"
          @change="
            changeDeliverableDetail(
              $event.target.value,
              deliverable.id,
              'title'
            )
          "
        />
      </div>
      <label class="col-3 col-form-label form-control-label">Folder</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="deliverable.folderName"
            placeholder="Folder"
            clearable
            allow-create
            filterable
            @change="
              changeDeliverableDetail($event, deliverable.id, 'folderName')
            "
          >
            <el-option
              v-for="option in folders"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Type</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="deliverable.type"
            placeholder="Select"
            @change="changeDeliverableDetail($event, deliverable.id, 'type')"
          >
            <el-option
              v-for="option in config.delTypes"
              :key="option.display"
              :label="option.display"
              :value="option.display"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Dimensions</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="deliverable.format"
            placeholder="Select"
            clearable
            @change="changeDeliverableDetail($event, deliverable.id, 'format')"
          >
            <el-option
              v-for="option in config.formats"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label
        class="col-3 col-form-label form-control-label"
        v-if="deliverable.type != 'Image'"
        >Expected Duration</label
      >
      <div class="col-9" v-if="deliverable.type != 'Image'">
        <base-input
          v-model="deliverable.desiredDuration"
          placeholder="e.g. 2 minutes"
          @change="
            changeDeliverableDetail(
              $event.target.value,
              deliverable.id,
              'desiredDuration'
            )
          "
        />
      </div>
      <label class="col-3 col-form-label form-control-label">Brand</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="deliverable.brand"
            placeholder="Select"
            filterable
            clearable
            allow-create
            @change="changeDeliverableDetail($event, deliverable.id, 'brand')"
          >
            <el-option
              v-for="option in config.brand"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label pt-0"
        >Burnt-in Subtitles</label
      >
      <div class="col-9 pb-3">
        <base-switch
          v-model="deliverable.subs"
          type="warning"
          on-text="Yes"
          off-text="No"
          @input="changeDeliverableDetail($event, deliverable.id, 'subs')"
        ></base-switch>
      </div>
      <label class="col-3 col-form-label form-control-label pt-0"
        >WebVTT Subtitle Track</label
      >
      <div class="col-9 pb-3">
        <base-switch
          v-model="deliverable.subsTrack"
          type="warning"
          on-text="Yes"
          off-text="No"
          @input="changeDeliverableDetail($event, deliverable.id, 'subsTrack')"
        ></base-switch>
      </div>

      <!-- <label class="col-3 col-form-label form-control-label pt-0">SRT</label>
      <div class="col-9 pb-3">

                      <base-switch
                        v-model="deliverable.srt"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                    @change="changeDeliverableDetail($event, deliverable.id, 'srt')"
                      ></base-switch>

        </div> -->

      <label class="col-3 col-form-label form-control-label">Feedbackers</label>
      <div class="col-9">
        <base-input>
          <el-select
            v-model="owners"
            multiple
            filterable
            placeholder="Who should be notified for feedback on this"
            class="wider-select"
            @change="changeDeliverableDetail($event, deliverable.id, 'owners')"
          >
            <el-option
              class="select-warning"
              v-for="option in admins"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label"
        >ETA for next version</label
      >
      <div class="col-9">
        <base-input>
          <flat-picker
            slot-scope="{ focus, blur }"
            @on-open="focus"
            @on-close="blur"
            :config="{ allowInput: true }"
            class="form-control datepicker"
            v-model="deliverable.eta"
            placeholder="When will this land for feedback?"
            @input="changeDeliverableDetail($event, deliverable.id, 'eta')"
          >
          </flat-picker>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label"
        >Deadline</label
      >
      <div class="col-9">
        <base-input>
          <flat-picker
            slot-scope="{ focus, blur }"
            @on-open="focus"
            @on-close="blur"
            :config="{ allowInput: true }"
            class="form-control datepicker"
            v-model="deliverable.deadline"
            placeholder="When do we need this by"
            @input="changeDeliverableDetail($event, deliverable.id, 'deadline')"
          >
          </flat-picker>
        </base-input>
      </div>
      <label class="col-3 col-form-label form-control-label">Description</label>
      <div class="col-9">
        <base-input>
          <textarea
            placeholder="Description with CTA"
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="2"
            v-model="deliverable.description"
            @input="
              changeDeliverableDetail(
                $event.target.value,
                deliverable.id,
                'description'
              )
            "
          ></textarea>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <base-dropdown direction="up" block>
          <base-button
            slot="title-container"
            type="secondary"
            class="dropdown-toggle"
            block
          >
            {{ state.display }}
          </base-button>
          <a
            class="dropdown-item"
            v-if="deliverable.state != 'held' && deliverable.state != 'signed'"
            @click="changeDeliverableDetail('held', deliverable.id, 'state')"
            >Place on Hold</a
          >
          <a
            class="dropdown-item"
            v-if="deliverable.state == 'held'"
            @click="
              changeDeliverableDetail('production', deliverable.id, 'state')
            "
            >Place back into production</a
          >
          <a
            class="dropdown-item"
            v-if="
              (deliverable.state == 'feedback' ||
                deliverable.state == 'reworking') &&
              this.files.length == 0
            "
            @click="
              changeDeliverableDetail('production', deliverable.id, 'state')
            "
            >Reset</a
          >
          <a class="dropdown-item" @click="deleteDel(deliverable.id)">Delete</a>
        </base-dropdown>
      </div>
      <div class="col-auto">
        <base-button @click="duplicate">Duplicate</base-button>
      </div>
      <!-- <div class="col-auto">
<base-button>Delete</base-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// js import
import swal from "sweetalert2";
// style import
import "sweetalert2/dist/sweetalert2.css";

export default {
  props: ["prod", "deliverable", "folders"],
  name: "DeliverableEditForm",
  data: function () {
    return {
      title: "",
      type: "",
      format: "",
      desiredDuration: "",
      brand: "",
      description: "",
      status: "feedback",
      owners: "",
      deadline: "",
    };
  },
  computed: {
    users() {
      return this.$store.getters["users/users"];
    },
    config() {
      return this.$store.getters["config/config"];
    },
    admins() {
      return this.users.filter((usr) => usr.roles.production_feedback);
    },
    statuses() {
      return this.$store.getters["config/delStatuses"];
    },
    state() {
      const statuses = this.statuses;
      let s = statuses.filter((r) => r.key === this.status)[0];
      return s;
    },
    files() {
      return this.$store.getters["files/filesByDelId"](this.deliverable.id);
    },
  },
  components: {
    flatPicker,
  },
  watch: {
    deliverable: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.owners = this.deliverable.owners;
      this.status = this.deliverable.state;
    },
  },
  mounted() {
    this.owners = this.prod.owners;
  },
  methods: {
    duplicate() {
      let payload = { ...this.deliverable };
      delete payload.id;
      delete payload.created_at
      delete payload.created_by
      payload.currentFileID = ''
      payload.currentVersion = 0
      payload.reviewStatus = {}
      payload.title = payload.title + ' copy'
      payload.state = 'production';
      this.$store.dispatch("deliverables/insert", payload);
      this.$emit("submitted");
    },
    changeDeliverableDetail(value, key, field) {
      console.log(value)
      var payload = {};
      payload.id = key;
      payload[field] = value;
      this.$store.dispatch("deliverables/patch", payload);
      if (field === "state") { //legacy code 
        this.status = value;
      }
    },
    deleteDel(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "This will delete any assets uploaded. You won't be able to revert this.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes, delete it.",
        })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch("deliverables/delete", id);
            this.$emit("submitted");
            swal.fire(
              "Deleted!",
              "This deliverable has been deleted.",
              "success"
            );
          }
        });
    },
  },
};
</script>