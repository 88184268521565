const config = {
    firestorePath: 'config/default',
    firestoreRefType: 'doc', // or 'doc'
    moduleName: 'config',
    statePropName: 'data',
    namespaced: true, // automatically added
    sync: {
      preventInitialDocInsertion: true,
    },
  
    // this object is your store module (will be added as '/myModule')
    // you can also add state/getters/mutations/actions
    state: {
      delStatuses: [
        {key:'production', display:'Pre-Production'},
        {key:'feedback', display:'Awaiting Feedback'},
        {key:'reworking', display:'Reworking'},
        {key:'signed', display:'Signed Off'},
        {key:'held', display:'On Hold'}
      ],
    },
    getters: {
      delStatuses: state => state.delStatuses,
      config: state => state.data
    },
    mutations: {},
    actions: {},
  }
  
  export default config