<template>
  <div>
    <NewFile
      :boxHeight=boxHeight
      :multiple="false"
      message="Drop files or click to browse"
      :fileName="false"
      :basePath="basePath"
      @success="success"
    ></NewFile>
  </div>
</template>

<script>
import NewFile from "@/echoComponents/file/NewFile";
export default {
  name: "upload-watermark",
  props: ["boxHeight"],
  components: {
    NewFile,
  },
  computed: {
    allowedFileTypes() {
      return "image/png";
    },
    basePath() {
      let year = new Date().getFullYear();
      return `livestreaming/watermarks/${year}`;
    },
  },
  methods: {
    success(payload) {
      payload.what = "livestream-county-logo";
      this.$store.dispatch("files/insert", payload).then((result) => {});
    },
  },
};
</script>

<style scoped>
</style>