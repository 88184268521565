/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store';
import { Firebase } from './firebase'
import dateTimeFilter from './filters/dateTime'
import elasticsearchDate from './filters/elasticsearchDate'
import dateAndTimeFilter from './filters/dateAndTime'
import dateTimeArchiveAssociationFilter from './filters/dateTimeArchiveAssociation'
import dateFilter from './filters/date'
import fileSizeFilter from './filters/fileSize'
import durationFilter from './filters/duration'

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

Vue.filter('elasticsearchDate', elasticsearchDate)
Vue.filter('dateTime', dateTimeFilter)
Vue.filter('dateAndTime', dateAndTimeFilter)
Vue.filter('dateTimeArchiveAssociation', dateTimeArchiveAssociationFilter)
Vue.filter('fileSize', fileSizeFilter)
Vue.filter('duration', durationFilter)
Vue.filter('date', dateFilter)

// import { createPinia, PiniaVuePlugin } from 'pinia'
// // import {commonPlugin} from './stores/plugins/baseStorePlugin';


// Vue.use(PiniaVuePlugin)
// const pinia = createPinia()
// // pinia.use(commonPlugin)


import VueVideoPlayer from 'vue-video-player'

// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

//componenets
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);

import {
  Select,
  Option,
  OptionGroup,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Table,
  TableColumn
} from "element-ui";
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Tooltip)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Table)
Vue.use(TableColumn)

import Loader from '@/echoComponents/LoadingSpinner'
Vue.component('loading-spinner', Loader)
import FilePreview from '@/echoComponents/FilePreview'
Vue.component('file-preview', FilePreview)

/* eslint-disable no-new */

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  // pinia,
  created() {
    store.commit('user/setLoading', false)
    Firebase.auth().getRedirectResult().then(function (result) {
      // console.log(result);
    }, function (error) {
      console.log(error);
      if (error.code === "auth/user-disabled") {
      store.commit('user/setError', 'Your account is pending approval by an Admin.')
      }
      // The provider's account email, can be used in case of
      // auth/account-exists-with-different-credential to fetch the providers
      // linked to the email:
      var email = error.email;
      // The provider's credential:
      var credential = error.credential;
      // In case of auth/account-exists-with-different-credential error,
      // you can fetch the providers using this:
      if (error.code === 'auth/account-exists-with-different-credential') {
        Firebase.auth().fetchSignInMethodsForEmail(email).then(function (providers) {
          // The returned 'providers' is a list of the available providers
          // linked to the email address. Please refer to the guide for a more
          // complete explanation on how to recover from this error.
          console.log(providers)
          store.commit('user/setError', 'Your email is associated with a different login method - either ECB IT or Gmail, or email/password.')
        });
      }
    });
    Firebase.auth().onAuthStateChanged((user) => {

      if (user) {
        store.commit('user/setLoading', true)
        store.dispatch('user/patch', {id: user.uid, last_log_in: new Date()})

        const load1 = new Promise((resolve, reject) => {
          store.dispatch('user/openDBChannel').then(resolve).catch(reject)
        })
        const load2 = new Promise((resolve, reject) => {
          store.dispatch('config/fetchAndAdd').then(resolve).catch(reject)
        })
        // const load5 = new Promise((resolve, reject) => {
        //   store.dispatch('brands/fetchAndAdd').then(resolve).catch(reject)
        // })

        Promise.all([load1, load2]).then((values) => {
          store.commit('user/setLoading', false)
        })
          .catch(error => {
            console.log(error)
            store.commit('user/setError', error)
            store.commit('user/setLoading', false)

          })
      }
    })
  }
});


// import {
//   onAuthStateChanged,
// } from "firebase/auth";
// import { auth, db } from "./firebase";
// import { doc, getDoc} from "firebase/firestore"; 

// import { useUserStore } from './stores/user'

// let app
// onAuthStateChanged(auth, async (user) => {
//     if (!app) {
//     app = new Vue({
//       el: '#app',
//       render: h => h(App),
//       router,
//       store,
//       pinia,
//       async created() {

//     const userStore = useUserStore()

//     if (user) {
//         // User is signed in, see docs for a list of available properties
//         // https://firebase.google.com/docs/reference/js/auth.user

//         userStore.uid = user.uid
//         const docRef = doc(db, "users", user.uid);
//         const docSnap = await getDoc(docRef);
        
//         if (docSnap.exists()) {
//             const userData = docSnap.data()
//             userStore.userData = userData
//             console.log(userStore.userData)
//             // for (const [key, value] of Object.entries(userData.workspaces)) {
//             //     if(value) {
//             //         const workspaceRef = doc(db, "workspaces", key)
//             //         const docSnap = await getDoc(workspaceRef);
//             //         console.log(docSnap.data())
//             //     }
//             //   }
              
            
//         } else {
//           // docSnap.data() will be undefined in this case
//           console.log("No such document!");
//         }
        
        
//     } else {
//         userStore.userData = false;
//     }
//   }
// })
//     }
//     })
