<template>
  <div>
    <form class="new-event--form" @submit.prevent="saveEvent">
      <!-- <base-input label="Production" v-if="!productionId">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.production"
          filterable
          remote
          :remote-method="search"
          :loading="loading"
          placeholder="Select here"
        >
          <el-option
            v-for="prod in searchResults"
            :key="prod._source.id"
            :label="prod._source.department + ': ' + prod._source.title"
            :value="prod._source.id"
          >
          </el-option>
        </el-select>
      </base-input> -->
      <!-- <base-checkbox
        class="mt--3 mb-4"
        v-model="showCompletedProductions"
        v-if="!productionId"
      >
        Include Completed Productions
      </base-checkbox> -->

      <base-input label="Type">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.type"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="costType in costTypes"
            :value="costType"
          ></el-option>
        </el-select>
      </base-input>
      <base-input label="Shoot (if applicable)">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.shoot"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="shoot in shoots"
            :label="shoot.shootTitle + ' | ' + shoot.start"
            :value="shoot.id"
          ></el-option>
        </el-select>
      </base-input>
      <div class="row">
        <div class="col">
          <label class="col-form-label form-control-label">Purchase Date</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input>
            <flat-picker
              slot-scope="{ focus, blur }"
              @on-open="focus"
              @on-close="blur"
              :config="{ allowInput: true }"
              class="form-control datepicker"
              v-model="model.date"
            >
            </flat-picker>
          </base-input>
        </div>
      </div>

      <base-input label="Amount" type="number" v-model="model.amount" ><template slot="prepend">£</template></base-input>
      <base-input label="Description" placeholder="Basic summary" v-model="model.description"> </base-input>
      <base-input label="Budget">
        <el-select
          input-classes="form-control-alternative new-event--production"
          v-model="model.budget"
          filterable
  
        >
          <el-option
            v-for="prod in this.budgets"
            :key="prod.id"
            :label="prod.name"
            :value="prod.id"
          >
          </el-option>
        </el-select>
      </base-input>
    </form>

    <div class="modal-footer px-0 py-0 mt-4">
      <button
        v-if="
          (this.productionId || this.model.production) &&
          this.model.date &&
          this.model.amount &&
          this.model.type
        "
        type="submit"
        class="btn btn-primary new-event--add"
        @click="saveEvent()"
      >
        Add Cost
      </button>
      <!-- <button type="warning" class="btn btn-warning new-event--add" @click="saveEvent('unavailable')">Mark as Unavailable</button> -->

      <button type="button" class="btn btn-link ml-auto" @click="closeModals">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Firebase } from "@/firebase";

export default {
  name: "cost-add-form",
  data() {
    return {
      showCompletedProductions: false,
      searchResults: [],
      loading: false,
      model: {
        production: "",
        shoot: "",
        date: "",
        type: "",
        amount: "",
        description: "",
        budget: "",
      },
    };
  },
  props: ["productionId"],
  components: { flatPicker },
  computed: {
    production() {
      if (this.productionId) {
        return this.$store.getters["briefs/byId"](this.productionId);
      } else {
        return this.searchResults.filter(
          (a) => a._id === this.model.production
        )[0]["_source"];
      }
    },
    costTypes() {
      return this.$store.getters["config/config"]["costTypes"];
    },
    shoots() {
      return this.$store.getters["shoots/byBriefId"](this.productionId);
    },
    budgets() {
      return this.$store.getters["budgets/active"]
    },
  },

  methods: {
    closeModals() {
      this.model = {
        production: "",
        shoot: "",
        date: "",
        type: "",
        amount: "",
        description: "",
        budget: ""
      };
      this.$emit("closeModals");
    },

    saveEvent() {
      let payload = {
        shoot: this.model.shoot,
        date: this.model.date,
        type: this.model.type,
        amount: this.model.amount,
        description: this.model.description,
        budget: this.model.budget,
      };
      if (this.productionId) {
        //if on the brief form, then the productionId is inherited, otherwise it's added by the user
        payload.productionId = this.productionId;
      } else {
        payload.productionId = this.model.production;
      }
      console.log(payload);
      this.$store.dispatch("costs/insert", payload);

      this.closeModals();
    },
    async search(query) {
      let payload = {
        response: null,
        request: {
          index: "productions",
          body: {
            sort: [
              {
                "created_at._seconds": {
                  order: "desc",
                },
              },
            ],
            from: 0,
            size: 30,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
                must_not: [{ match: { status: "Draft" } }],
              },
            },
          },
        },
      };

      if (!this.showCompletedProductions) {
        payload.request.body.query.bool.filter.push({
          match: { completed: false },
        });
      }
      if (query) {
        payload.request.body.query.bool.filter.push({
          match: { title: { query: query, fuzziness: "AUTO" } },
        });
      }

      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            // this.freshSearch = false;
          }
        });
    },
  },
};
</script>

