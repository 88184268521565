const temp = {

  state: {
    jimstaDimensions: 'Square'
  },
  getters: {
    getJimstaDimensions: state => {
      return state.jimstaDimensions
    },
  },
  mutations: {
    setJimstaDimensions (state, value) {
      state.jimstaDimensions = value
    }
  },
  actions: {
  },
}

export default temp