<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row" v-if="selectedArchiveClipLength">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">Currently Selected Archive Clips</div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  Add {{ selectedArchiveClipLength }} clips for a duration of
                  {{ selectedArchiveClipDuration }} seconds?
                </div>
              </div>
              <div class="row">
                <div class="col mt-3">
                  <base-button type="default" @click="confirmArchiveSelection">Confirm</base-button>
                  <router-link class="btn btn-secondary" :to="'/archive/collections/'">Back</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col h3">
                  {{ col.name }}
                </div>
                <div class="col-auto text-right" v-if="zipOfSigned">
                  <div v-if="zipOfSigned.status === 'requested'">
                    <a class="btn btn-sm">Requesting Zip </a>
                  </div>
                  <div v-if="zipOfSigned.status === 'ready'">
                    <a class="btn btn-sm" @click="deleteZip">Delete Zip </a>
                    <zip-link :zip="zipOfSigned"></zip-link>
                  </div>
                </div>
                <div class="col-auto text-right" v-else>
                  <a class="btn btn-sm" @click="zipSigned()"
                    ><i class="fas fa-file-download mr-2"></i>Request Zip
                  </a>
                </div>
                <div
                  class="col-auto text-right"
                  v-if="user.roles.production_new_deliberables"
                >
                  <base-button
                    type="secondary"
                    icon
                    size="sm"
                    @click="uploadMode = true"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-upload"></i
                    ></span>
                    <span class="btn-inner--text"
                      >Upload files to this Collection</span
                    >
                  </base-button>
                </div>

                <modal :show.sync="uploadMode" size="lg" modal-classes="modal">
                  <collection-uploader></collection-uploader>
                </modal>

                <!-- <div class="col-auto">
                  <base-pagination
                  type="default"
                    :page-count="delPaginator.length"
                    v-model="pagination.default"
                  ></base-pagination>
                </div> -->
              </div>
              <div class="row mb-3" v-if="selected.length > 0">
                <div class="col">
                  <span class="mr-3">
                    Selected Items: {{ selected.length }}</span
                  >
                  <base-button @click="clearSelection" type="secondary"
                    >Clear All</base-button
                  >
                </div>
                <div class="col">
                  <base-input>
                    <el-select
                      v-model="batchEditFolder"
                      placeholder="Folder"
                      filterable
                      clearable
                      allow-create
                      @change="batchChange($event, 'folderName')"
                    >
                      <el-option
                        v-for="option in folders"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <!-- <div class="row mt-3">
                <div class="col h4">Restrictions</div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <base-input
                    label="Restrict to users who can access these departments"
                  >
                    <el-select
                      input-classes="form-control"
                      v-model="col.departmentAccess"
                      clearable
                      multiple
                      placeholder="Select Departments"
                      @change="changeField($event, 'departmentAccess')"
                    >
                      <el-option
                        v-for="prod in this.config.departments"
                        :key="prod"
                        :label="prod"
                        :value="prod"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input
                    label="Restrict to users who can access these brands"
                  >
                    <el-select
                      input-classes="form-control"
                      v-model="col.brandAccess"
                      clearable
                      multiple
                      placeholder="Select Brands"
                      @change="changeField($event, 'brandAccess')"
                    >
                      <el-option
                        v-for="prod in this.config.brand"
                        :key="prod"
                        :label="prod"
                        :value="prod"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Restrict to specific users">
                    <el-select
                      input-classes="form-control"
                      v-model="col.userAccess"
                      clearable
                      multiple
                      placeholder="Select Users"
                      @change="changeField($event, 'usersAccess')"
                    >
                      <el-option
                        v-for="usr in this.users"
                        :key="usr.id"
                        :label="usr.name"
                        :value="usr.id"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9" v-if="isLoaded">
      <ul class="list-group folders">
        <li class="list-group-item" v-for="group in groups" :key="group">
          <collection-group :groupName="group" :dels="groupedDels[group]">
          </collection-group>
        </li>
      </ul>
    </div>
    <div v-else>
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>
<script>
import CollectionGroup from "./Components/CollectionGroup";
import CollectionAssetUploader from "./Components/CollectionUploader";
import ZipLink from "@/echoComponents/ZipLink";
import moment from "moment";
import swal from "sweetalert2";

// style import
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "catalogue",
  components: {
    "collection-group": CollectionGroup,
    "collection-uploader": CollectionAssetUploader,
    "zip-link": ZipLink,
  },
  data() {
    return {
      uploadMode: false,
      batchEditFolder: "",
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    col() {
      return this.$store.getters["collections/byId"](this.$route.params.id);
    },
    allProds() {
      return this.$store.getters["briefs/productions"];
    },
    dels() {
      return this.$store.getters["deliverables/byCollection"](
        this.$route.params.id
      );
    },
    selected() {
      return this.$store.getters["archiveTemp/delSelection"];
    },

    folders() {
      if (this.deliverables) {
        let pA = this.deliverables;
        let arr = {};
        pA.forEach(function (entry) {
          if (entry.folderName) {
            arr[entry.folderName] = true;
          }
        });
        return Object.keys(arr);
      }
    },
    groupedDels() {
      function groupByKey(array, key) {
        return array.reduce((hash, obj) => {
          if (obj[key] === undefined) {
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          } else {
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }
        }, {});
      }
      return groupByKey(this.deliverables, "folderName");
    },
    groups() {
      return Object.keys(this.groupedDels);
    },
    deliverables() {
      if (this.dels) {
        //let dels = this.$store.getters["deliverables/delsMerged"];
        let dels = this.dels;
        //filter for feedback

        dels.sort((a, b) => {
          if (a.updated_at > b.updated_at) {
            return -1;
          }
          if (a.updated_at < b.updated_at) {
            return 1;
          }
          if (!a.updated_at) {
            return -1;
          }
          return 0;
        });
        return dels;
      } else {
        return [];
      }
    },
    delPaginator() {
      let s = this.deliverables;
      let pages = [];
      var i,
        j,
        temparray,
        chunk = this.pagination.chunkSize;
      for (i = 0, j = s.length; i < j; i += chunk) {
        temparray = s.slice(i, i + chunk);
        pages.push(temparray);
      }
      return pages;
    },
    paginatorLength() {
      return this.delPaginator.length;
    },
    departments() {
      if (this.departmentAccess) {
        return this.departmentAccess;
      } else {
        return this.config.departments;
      }
    },
    departmentAccess() {
      if (this.user.departmentAccess.length > 0) {
        return this.user.departmentAccess;
      } else {
        return null;
      }
    },
    types() {
      return this.config.delTypes;
    },
    brands() {
      if (this.brandAccess) {
        return this.brandAccess;
      } else {
        return this.config.brand;
      }
    },
    brandAccess() {
      if (this.user.brandAccess.length > 0) {
        return this.user.brandAccess;
      } else {
        return null;
      }
    },
    dimensions() {
      return this.config.formats;
    },
    partners() {
      return this.config.partners;
    },
    zipOfSigned() {
      return this.$store.getters["zips/byCol"](this.$route.params.id);
      if (zips) {
        return zips;
      } else {
        return null;
      }
    },
    eventsObj() {
      return this.$store.getters["archiveEvents/obj"];
    },
    selectedArchiveClips() {
      var selects = this.$store.getters["archiveTemp/eventSelection"];
      var clips = [];
      selects.forEach((select) => {
        clips.push(this.eventsObj[select]);
      });
      return clips;
    },
    selectedArchiveClipLength() {
      return this.selectedArchiveClips.length;
    },
    selectedArchiveClipDuration() {
      var clips = this.selectedArchiveClips;
      var dur = 0;
      clips.forEach((select) => {
        dur = dur + (select.tcOut - select.tcIn);
      });
      return Math.floor(dur);
    },
  },
  watch: {
    paginatorLength: function (newLength, oldLength) {
      if (this.pagination.default > newLength) {
        this.pagination.default = 1;
      }
    },
  },
  mounted() {
    this.$store.commit("user/setLoading", true);
    this.$store.dispatch("collections/fetchById", this.$route.params.id);
    this.$store.commit("deliverables/RESET_VUEX_EASY_FIRESTORE_STATE"); // reset vuex so we can run a fresh query
    this.$store.dispatch("deliverables/openDBChannel", {
      clauses: {
        where: [["collections", "array-contains", this.$route.params.id]],
        limit: 0,
      },
    });
    this.$store.dispatch("files/openDBChannel", {
      where: [["colID", "==", this.$route.params.id]],
    });
    this.$store.dispatch("zips/openDBChannel", {
      where: [["colID", "==", this.$route.params.id]],
    });
    this.$store.dispatch("users/fetchAndAdd", {
      limit: 0,
    });
    this.$store.commit("user/setLoading", false);
    this.$store.commit("archiveTemp/clearDelSelection");
  },
  methods: {
    // filterPersist() {
    //   localStorage.worklistDepartmentFilter = this.departmentFilter;
    // },
    deleteZip() {
      this.$store.dispatch("zips/delete", this.zipOfSigned.id);
    },
    clearSelection() {
      this.$store.commit("archiveTemp/clearDelSelection");
    },
    confirmArchiveSelection() {
// add files to this 

// clear selection
    },
    zipSigned() {
      // if current file, get the current file

      var files = [];

      var i;
      for (i = 0; i < this.dels.length; i++) {
        let del = this.dels[i];
        if (del.currentFileID) {
          const file = { id: del.currentFileID };
          file.folderName = del.folderName ? del.folderName : "";
          files.push(file);
        }
      }
      swal
        .fire({
          title: `Request a Zip of ${files.length} files?`,
          text: "This will create a zip containing all the files in the collection, and be available for 1 year.",
          type: "question",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            // add 365 days to the deletion time for the zip
            var endDate = new Date();
            endDate.setDate(endDate.getDate() + 365);
            var date = new Date();

            var payload = {};
            payload.colID = this.col.id;
            payload.deleteDate = endDate;
            payload.files = files;
            payload.zipName =
              this.col.name + " " + moment(date).format("YYYY-MM-DD");
            payload.all = true;
            payload.status = "requested";
            // this.$store.dispatch("jimstaControl/patch", {
            //   lastSubmission: new Date(),
            // });
            this.$store.dispatch("zips/insert", payload);

            this.$store.dispatch("zips/openDBChannel", {
              where: [["prodID", "==", this.$route.params.id]],
            });
          }
        });
    },
    batchChange(event, field) {
      console.log(event);
      // apply them to each of the selected dels
      for (const del of this.selected) {
        if (event) {
          console.log(del);
          var payload = {};
          payload.id = del;
          payload[field] = event;
          this.$store.dispatch("deliverables/patch", payload);
        }
      }
      this.batchEdits[field] = "";
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.col.id;
      payload[field] = value;
      this.$store.dispatch("collections/patch", payload);
    },
    confirmArchiveSelection(){

    },
  },
};
</script>