<template>
  <div class="row">
    <div class="col">
      <div class="card-wrapper">
        <!-- Form controls -->
        <div class="card">
          <div class="card-header">
            <div class="col-auto text-right">
              <base-button
                type="secondary"
                icon
                size="sm"
                @click="shootAddForm = true"
              >
                <span class="btn-inner--icon"
                  ><i class="fas fa-plus-square"></i
                ></span>
                <span class="btn-inner--text">Add Shoot</span>
              </base-button>
            </div>
            <modal
              :show.sync="shootAddForm"
              size="lg"
              modal-classes="modal-scrollable"
            >
              <shoot-add-form
                :productionId="$route.params.id"
                @closeModals="shootAddForm = false"
              ></shoot-add-form>
            </modal>
            <modal
              :show.sync="shootEditForm"
              size="lg"
              modal-classes="modal-scrollable"
            >
              <shoot-edit-form
                :shootId="shootEditId"
                :isBriefForm="true"
                @closeModals="shootEditForm = false"
              ></shoot-edit-form>
            </modal>
          </div>

          <!-- Card body -->
          <div class="card-body">
            <div class="row" v-if="shoots.length === 0">
              <div class="col">No Shoots Yet</div>
            </div>
            <li class="list-group list-group-flush">
              <ul
                class="list-group-item"
                v-for="(shoot, index) in shoots"
                :key="index"
              >
                <shoot-list :shoot="shoot" @edit="edit(shoot.id)"></shoot-list>
              </ul>
            </li>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShootList from "../Calendars/Components/ShootListItem";
import ShootAddForm from "../Calendars/Components/ShootAddForm.vue";
import ShootEditForm from "../Calendars/Components/ShootEditForm.vue";

export default {
  name: "shoots",
  components: {
    ShootList,
    ShootAddForm,
    ShootEditForm,
  },
  data() {
    return {
      shootAddForm: false,
      shootEditForm: false,
      shootEditId: '',
    };
  },
  computed: {
    shoots() {
      return this.$store.getters["shoots/byBriefId"](this.$route.params.id);
    },
  }, 
  methods: {
    edit(shootId) {
      this.shootEditId = shootId
      this.shootEditForm = true
    }
  }
};
</script>
