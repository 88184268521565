<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <span class="h3">Channels</span>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush list my--3">
                <li
                  v-for="(channel, index) in channels"
                  class="list-group-item px-0"
                  :key="channel"
                >
                  <channel :channel="channel" :key="index"></channel>
                </li>
              </ul>
            </div>
            <!-- <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              lazy
              :data="channels"
            >
              <el-table-column min-width="110px">
                <div slot-scope="{ row }" class="table-actions">
                   <a
                    class="mb-0"
                    :href="`/#/livestreams/stream/${row.id}`"
                  >


                  </a>
                </div>
              </el-table-column>
            </el-table> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RTMP from "./Components/RTMP";
import Channel from "./Components/Channel";
export default {
  components: { rtmp: RTMP, channel: Channel },
  name: "streams",
  data() {
    return {
      channels: [
        {
          name: "Derbyshire",
          id: "f5bbf177617e4a01a9f7d9b544cdd413",
        },
               {
          name: "Durham",
          id: "46a71af788bd48b7b87edffb496e0fb0",
        },
        {
          name: "Essex",
          id: "d155853a065e45a6a22c2185e74a79fc",
        },
        {
          name: "Glamorgan",
          id: "7d6c413c0fae4a3e9fffc9626e1dc116",
        },
        {
          name: "Gloucestershire",
          id: "2317e0deabc24d9c868706ea588722d5",
        },
        {
          name: "Hampshire",
          id: "7a570beb0ff14c04a9bc0134ad2b2451",
        },
        {
          name: "Kent",
          id: "c93c06ba5a444f3181faf7af7aeb7b2b",
        },
        {
          name: "Lancashire",
          id: "a437dca20e794453ad99c76714d399c1",
        },
        {
          name: "Leicestershire",
          id: "45fd78c4ab9b444e8ae3975b86b5b95b",
        },
        {
          name: "Middlesex",
          id: "974cda53e7d549a795308d9f4e601f1c",
        },
        {
          name: "Northamptonshire",
          id: "70676e1813ea4574b07fd810c0507898",
        },
        {
          name: "Nottinghamshire",
          id: "b24a772b59df4a3fa0283ce1eed26dc3",
        },
           {
          name: "Somerset",
          id: "8b6524ae471b4722bc9791468397457a",
        },
           {
          name: "Surrey",
          id: "b39a5b22a8914bc9a601aa4bbd6243d1",
        },
            {
          name: "Sussex",
          id: "54282f4430e8404789a8b9d0d38bcc5c",
        },
        {
          name: "Warwickshire",
          id: "22a8455e1c6d442687f03d82a263b311",
        },
            {
          name: "Worcestershire",
          id: "5d794e0d06f346708bae48d327e51cb6",
        },
        {
          name: "Yorkshire",
          id: "8220d1749ca94cdfbfc588d76030672e",
        },
      ],
    };
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    users() {
      return this.$store.getters["users/users"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    // streams() {
    //   return this.$store.getters["livestreams/array"];
    // },
    current() {
      return this.$store.getters["livestreams/current"];
    },
    rtmpOutputs() {
      return this.$store.getters["rtmpOutputs/array"];
    },
    watermarks() {
      return this.$store.getters["files/byWhat"]("livestream-county-logo");
    },
    // players() {
    //   return this.$store.getters["brightcovePlayers/array"];
    // },
    // player() {
    //   return this.$store.getters["brightcovePlayers/byId"](this.temp.player);
    // },
    loading() {
      return this.$store.getters["user/loading"];
    },
    newFeed() {
      return this.$store.getters["rtmpOutputs/byId"](this.temp.newFeed);
    },
  },
  mounted() {
    this.$store.dispatch("livestreams/channelStatus");
    // this.$store.dispatch("brightcovePlayers/fetchAndAdd");
    this.$store.dispatch("rtmpOutputs/fetchAndAdd");
    this.$store.dispatch("files/fetchAndAdd", {
      where: [["what", "==", "livestream-county-logo"]],
    });
  },
  methods: {
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
  },
};
</script>

<style>
</style>
