<template>
  <div>
    <base-header class="pb-6 pt-4" :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'">
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card-wrapper">
            <!-- Form controls -->
            <div class="card">
              <!-- Card header -->
              <div class="card-header">
                <div class="row">

                  <div class="col">
                    <el-steps :active="step" finish-status="success" align-center>
                      <el-step title="Outline"></el-step>
                      <el-step title="Detail"></el-step>
                      <el-step title="Inspirations"></el-step>
                      <el-step title="Budget / People"></el-step>
                      <el-step title="Channels" v-if="prod.channelsKnown === 'true'"></el-step>
                      <el-step title="Deliverables" v-if="prod.deliverablesKnown === 'true'"></el-step>
                    </el-steps>
                  </div>
                </div>
                <div class="row mt-3">

                  <div class="col"></div>
                  <div class="col-1">
                    <base-button type="primary" v-if="step > 0" @click="step--">
                      Back</base-button>
                  </div>
                  <div class="col-1">

                    <base-button type="primary" v-if="step < 6" @click="step++">Next</base-button>
                  </div>
                  <div class="col"></div>
                </div>



              </div>
              <!-- Card body -->
              <div class="card-body">
                <!-- outline -->
                <form v-if="step === 0">

                  <base-input label="Project Title" v-model="prod.title"
                    @change="changeSyncedField($event.target.value, 'title')" />
                  <base-input label="Department">
                    <el-select v-model="prod.department" filterable placeholder="Select"
                      @change="changeSyncedField($event, 'department')">
                      <el-option v-for="option in config.departments" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Template">
                    <el-select v-model="prod.template" filterable @change="changeField($event, 'template')">
                      <el-option value="Content">Content or advertising
                      </el-option>
                      <el-option value="Licencing">Licenced products
                      </el-option>
                      <el-option value="In-Stadia">In-Stadia media
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Brands on the Brief">
                    <el-select v-model="prod.brands" multiple filterable placeholder="Select"
                      @change="changeField($event, 'brands')">
                      <el-option v-for="option in config.brand" :key="option" :label="option" :value="option">
                      </el-option>
                    </el-select>
                  </base-input>
                  <!-- <base-input class="select-warning" label="Owners">
                    <el-select class="select-warning" v-model="owners" multiple filterable placeholder="Select">
                      <el-option class="select-warning" v-for="option in this.admins" :key="option.id"
                        :label="option.name" :value="option.id">
                      </el-option>
                    </el-select>
                  </base-input> -->
                  <div class="form-group row">
                    <label class="col-md-4 form-control-label">Partner Content</label>
                    <div class="col-md-8">
                      <base-switch v-model="prod.commercial" type="warning" on-text="Yes" off-text="No"
                        @input="commercialField($event)"></base-switch>
                    </div>
                  </div>
                  <div v-if="prod.commercial">
                    <base-input label="Partner">
                      <el-select v-model="prod.partner" filterable placeholder="Select"
                        @change="changeSyncedField($event, 'partner')">
                        <el-option v-for="option in config.partners" :key="option" :label="option" :value="option">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>

                </form>
                <form v-if="step === 1">
                  <div v-if="prod.template === 'Content'">
                    <base-input label="Intention">
                      <el-select @change="changeField($event, 'intention')" v-model="prod.intention" filterable
                        placeholder="Select" type="primary">
                        <el-option value="Awareness">
                          <span style="font-weight: bold;">Awareness</span>
                          <span style="color: #8492a6"> - make people aware of a brand, service or product</span>
                        </el-option>
                        <el-option value="Engagement"> <span style="font-weight: bold;">Engagement</span>
                          <span style="color: #8492a6"> - deepen relationships with a brand, service or
                            product</span>
                        </el-option>
                        <el-option value="Action"><span style="font-weight: bold;">Action</span>
                          <span style="color: #8492a6"> - push someone to do something, e.g. buy or sign-up</span>
                        </el-option>
                      </el-select>
                    </base-input>
                    <base-input
                    label="Raise awareness of what exactly?"
                      v-model="prod.intentionDetail"
                      @change="changeField($event, 'intentionDetail')"
                      v-if="prod.intention === 'Awareness'" />
                    <base-input
                    label="Engage with the brand generally, part of a campaign, or something specific?"
                      v-model="prod.intentionDetail"
                      v-if="prod.intention === 'Engagement'"
                      @change="changeField($event, 'intentionDetail')"/>
                    <base-input
                    label="What action are you looking to promote?"
                      v-model="prod.intentionDetail"
                      v-if="prod.intention === 'Action'"
                      @change="changeField($event, 'intentionDetail')"/>
                      <base-input label="Audience" v-model="prod.audience" v-if="prod.intentionDetail"
                      @change="changeField($event.target.value, 'audience')" />
                    <div class="form-group row" v-if="prod.audience">
                      <label class="col-md-6 form-control-label">Do you know the creative solution to achieve this?</label>
                      <div class="col-md-6">
                        <base-radio v-model="prod.messagingKnown" name=true class="mb-3"
                          @change="changeField($event, 'messagingKnown')">
                          Yes
                        </base-radio>
                        <base-radio v-model="prod.messagingKnown" name=false class="mb-3"
                          @change="changeField($event, 'messagingKnown')">
                          No
                        </base-radio>
                      </div>
                    </div>
          
                    <div class="form-group row" v-if="prod.messagingKnown">
                      <label class="col-md-6 form-control-label">Do you know the channels for execution?</label>
                      <div class="col-md-6">
                        <base-radio v-model="prod.channelsKnown" name=true class="mb-3" @change="changeField($event, 'channelsKnown')">
                          Yes
                        </base-radio>
                        <base-radio v-model="prod.channelsKnown" name=false class="mb-3" @change="changeField($event, 'channelsKnown')">
                          No
                        </base-radio>
                      </div>
                    </div>
                    <div class="form-group row" v-if="prod.channelsKnown === 'true'">
                      <label class="col-md-6 form-control-label">Do you know the deliverables?</label>
                      <div class="col-md-6">
                        <base-radio v-model="prod.deliverablesKnown" name=true class="mb-3" @change="changeField($event, 'deliverablesKnown')">
                          Yes
                        </base-radio>
                         <base-radio v-model="prod.deliverablesKnown" name=false class="mb-3" @change="changeField($event, 'deliverablesKnown')">
                          No
                        </base-radio>
                      </div>
                    </div>
                  </div>
                  <base-input label="Description">
                    <html-editor v-model="prod.description" @input="changeField($event, 'description')"></html-editor>
                  </base-input>
                  <brief-reference-uploader :production="prod" boxHeight="100px"
                    v-if="user.roles.production_feedback"></brief-reference-uploader>
                  <ul class="referenceArea">
                    <li v-for="file in references" :key="file.id" class="reference">
                      <div>
                        <a :href="file.sourceDownloadURL">{{ file.sourceFileNameAsUploaded }}</a>
                      </div>

                      <div v-if="user.roles.production_feedback">
                        <base-button type="secondary" class="btn-icon-only" @click="deleteReference(file)">
                          <span><i class="fas fa-trash"></i></span>
                        </base-button>
                      </div>
                    </li>
                  </ul>
                </form>


                <form v-if="step === 3">

                </form>
              </div>

            </div>
            <form v-if="step === 2">
              <inspirations></inspirations>
            </form>

          </div>

        </div>


      </div>


    </div>
  </div>
</template>

<script>
import {

  Steps,
  Step,

} from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BriefReferenceUploadWrapper from "./Components/BriefReferenceUploadWrapper";
import Inspirations from "./InspirationsPerBrief.vue";


export default {
  name: 'new-brief',
  data() {
    return {
      step: 1,

    }
  },
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    flatPicker,
    HtmlEditor,
    "brief-reference-uploader": BriefReferenceUploadWrapper,
    Inspirations,
  },
  beforeMount() {
    this.$store.dispatch("briefs/fetchById", this.$route.params.id);
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 }); // todo - fix
  },
  computed: {
    formIsValid() {
      return this.title !== '' && this.department !== '' && this.brands.length > 0
    },
    user() {
      return this.$store.getters['user/user']
    },
    users() {
      return this.$store.getters['users/users']
    },
    admins() {
      const result = this.users.filter(usr => usr.roles.production_feedback)
      return result
    },
    prodID() {
      return this.$route.params.id;
    },
    deliverables() {
      return this.$store.getters["deliverables/delsByProdID"](
        this.$route.params.id
      );
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
    prod() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id);
    },
    references() {
      const list = this.$store.getters["files/briefReferenceByProdId"](
        this.prodID
      );
      return list;
    },
  },
  methods: {
    next() {
      return this.step++
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.prodID;
      payload[field] = value;
      this.$store.dispatch("briefs/patch", payload);
    },
    commercialField(value) {
      this.changeField(value, "commercial");
      if (!value) {
        this.changeSyncedField("", "partner");
      }
    },
    changeSyncedField(value, field) {
      // these are fields that are replicated on the deliverable
      this.changeField(value, field);
      this.deliverables.forEach((element) => {
        let payload = {};
        payload.id = element.id;
        if (field === "title") {
          payload.productionTitle = value;
        } else {
          payload[field] = value;
        }
        this.$store.dispatch("deliverables/patch", payload);
      });
    },
    deleteReference(file) {
      this.$store.dispatch("files/delete", file.id);
    },
  },
  watch: {
    commercial(val) {
      // resets partner field is commercial is switched off
      if (val === false) {
        this.partner = ''
      }
    }
  }
}

</script>

<style>
.form-control {
  color: #606266;
}

.el-step .is-success {
  color: #5e72e4;
  border-color: #5e72e4
}
</style>
