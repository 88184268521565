<template>
    <div class="row">
        <div class="col-4">
            <file-preview :file="clip"></file-preview>
        </div>

        <div class="col">
            <div class="row">
                <div class="col h4">
                    {{ clip.created_at | dateAndTime }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Innings: {{ clip.innings }}
                </div>
            </div>


            <div class="row mt-3">
                <div class="col">
                    {{ capitalise(clip.clipType) }}
                </div>
            </div>
            <div class="row">
                <div class="col" v-if="player">
                    {{ player.fullName }}
                </div>
                <div class="col" v-else>
                  Player ID Unknown
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <a download class="btn btn-secondary" :href="clip.sourceDownloadURL">
                        <i class="fa fa-download mr-1"></i>Download
                    </a>
                </div>
                <!-- <button @click="kill(clip)">Kill</button> -->
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "Stream-Clip",
    props: ["clip"],
    data() {
        return {

        };
    },
    computed: {
        player() {
            let p = this.$store.getters["players/byProp"]("ellipsePlayerId", this.clip.ellipseBatterId);
            if (!p) {
          this.$store.dispatch("players/fetchAndAdd", {
            where: [["ellipsePlayerId", "==", this.clip.ellipseBatterId]],
          });
        } return p
        }
    },

    methods: {
        capitalise(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        kill(file) {
      this.$store.dispatch("files/delete", file.id);
    },
    }
}
</script>