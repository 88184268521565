<template>
  <div>
    <span class="mr-1">
      {{ rolesLength }}
    </span>
    <i v-if="roles.creative_catalogue_access" class="fa fa-images text-info mr-1"></i>
    <i v-if="roles.archive_access" class="ni ni-archive-2 text-danger mr-1"></i>
       <i v-if="roles.fcc_access" class="fas fa-video text-yellow mr-1"></i>
    <i v-if="roles.production_access" class="fa fa-pencil-ruler text-success mr-1"></i>
    <!-- <i v-if="roles.jimstagram_access" class="ni ni-spaceship text-default mr-1"></i> -->
    <i v-if="roles.livestream_access" class="ni ni-world-2 text-primary mr-1"></i>
    <i v-if="roles.user_admin" class="ni ni-atom text-pink"></i>
  </div>
</template>

<script>
export default {
  props: ["roles"],
  computed: {

    rolesLength() {
      let roles = {};
      let length = 0;
      roles = this.roles;
      let rolesArr = Object.keys(roles);
      rolesArr.forEach((role) => {
        if (roles[role]) {
          length++;
        }
      });
      return length;
    },
  },
  methods: {

  },
};
</script>

<style></style>
