import moment from 'moment'

export default (value) => {
  // var v = new Date(value)
  var dateMomentObject = moment(value, "DD/MM/YYYY");

  return dateMomentObject.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'D MMMM YYYY'
});
}
