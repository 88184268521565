<template>
  <div>
<div class="row">
                <div class="col">
                  <base-input label="Type">
                    <el-select v-model="type" placeholder="Type">
                      <el-option
                        v-for="option in config.delTypes"
                        :key="option.display"
                        :label="option.display"
                        :value="option.display"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Brand">
                    <el-select v-model="brand" placeholder="Brand">
                      <el-option
                        v-for="option in config.brand"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                         <div class="col">
                  <base-input label="Dimensions">
                    <el-select v-model="dimensions" placeholder="Dimensions">
                      <el-option
                        v-for="option in config.formats"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col">
                  <base-input label="Department">
                    <el-select v-model="department" placeholder="Department">
                      <el-option
                        v-for="option in config.departments"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
      
      
      </div>
      <div class="row">
        <div class="col" v-if="validForBrief">

              <collection-upload-box :colID="colID" :brand="brand" :dimensions="dimensions" :department="department" :type="type" boxHeight="300px"></collection-upload-box>
      

        </div>
      </div>
    </div>

</template>
<script>
import Uploader from "./CollectionAssetUploadWrapper";

export default {
  name: "uploader",
  components: {
    "collection-upload-box": Uploader,
  },
  data() {
    return {
      uploadType: "",
      brand: "",
      dimensions: "",
      department: '',
      type: "",
    };
  },
  computed: {
    colID() {
      return this.$route.params.id;
    },
    validForBrief() {
        if (this.type && this.brand && this.dimensions && this.department) {
          return true;
        }
    },
    config() {
      let config = this.$store.getters["config/config"];
      if (!config) {
        return {};
      } else {
        return config;
      }
    },
  },

  methods: {},
};
</script>