<template>
  <div>
    <base-header
      class="pb-9 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
      <div class="row">
        <div class="col">
          <div class="card mb-4 mt-3">
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col">
                  <base-input
                    v-model="search"
                    placeholder="Search"
                    prepend-icon="fa fa-search"
                  />
                </div>

                <div class="col-2 text-right">
                  <base-input>
                    <el-select
                      v-model="departmentFilter"
                      placeholder="Department Filter"
                      clearable
                      @change="setUserField($event, 'department')"
                    >
                      <el-option
                        v-for="option in departments"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-2 text-right">
                  <base-input>
                    <el-select
                      v-model="producerFilter"
                      placeholder="Producer Filter"
                      clearable
                      @change="setUserField($event, 'productionCompany')"
                    >
                      <el-option
                        v-for="option in config.producer"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row" v-if="isLoaded">
        <div class="col">
          <ul class="list-group">
            <li
              class="list-group-item smaller-text"
              v-for="prod in productions"
              :key="prod.id"
            >
              <div class="row">
                <span class="col m-xy-auto">
                  <!-- <base-button
                    size="lg"
                    type="secondary"
                    class="btn-icon-only"
                    @click="openClose(prod.id)"
                  >
                    <span v-if="prod.id === openBrief"
                      ><i class="fas fa-book-open"></i
                    ></span>
                    <span v-else><i class="fas fa-book"></i></span>
                  </base-button> -->
                  <a :href="`/#/brief/${prod.id}`">
                    {{ prod.title }}
                  </a>
                </span>

                <div class="col-2 m-xy-auto">
                  Deliverables: {{ prod.totalDels }}
                </div>

                <div class="col-2 m-xy-auto">{{ prod.department }}</div>

                <div class="col-2 m-xy-auto">
                  <div v-if="prod.nextDeadline">
                    Last Updated: {{ prod.updated_at | dateTime }}
                  </div>
                  <!-- <div v-else-if="prod.updated_at">
                     Updated: {{ prod.updated_at | dateTime }}
                    </div> -->
                </div>

                <div class="col-2 m-xy-auto">
                  <div v-if="prod.nextDeadline">
                    Soonest Deadline: {{ prod.nextDeadline | dateTime }}
                  </div>
                  <!-- <div v-else-if="prod.updated_at">
                     Updated: {{ prod.updated_at | dateTime }}
                    </div> -->
                </div>
                <div class="col-1 m-xy-auto">
                  <base-button
                    block
                    type="danger"
                    @click.prevent="onDelete(prod)"
                  >
                    <!--                        <i class="fas fa-arrow-right"></i>-->
                    Delete
                  </base-button>
                </div>
                <!-- <div v-else class="col-2 m-xy-auto">
                  {{ prod.up | dateTime }}
                </div> -->
                <!-- <div class="col-auto m-xy-auto">
                  <a
                    class="btn btn-block btn-warning mb-0"
                    :href="`/#/brief/${prod.id}`"
                  >
                    <i class="fas fa-file-alt worklistIcon"></i>
                  </a>
                </div> -->
              </div>
              <!-- <div class="row">
                <div class="col">
                  <DeliverablesWidget
                    v-if="prod.id === openBrief"
                    class="mt-3"
                    :prod="prod"
                    embed="true"
                  ></DeliverablesWidget>
                </div>
              </div> -->
            </li>
          </ul>
        </div>
      </div>
      <loading-spinner v-else></loading-spinner>
    </div>
  </div>
</template>
<script>
import router from "@/routes/router";

import DeliverablesWidget from "./Components/DeliverablesWidget";
import ResourceName from "./Components/ResourceName";

export default {
  components: {
    DeliverablesWidget,
    "resource-name": ResourceName,
  },
  data() {
    return {
      search: "",
      mine: false,
      completed: false,
      openBrief: "",
      departmentFilter: "",
      producerFilter: "",
    };
  },
  computed: {
    isLoaded() {
      if (this.$store.getters["user/loading"]) {
        return false;
      } else {
        return true;
      }
    },
    config() {
      return this.$store.getters["config/config"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    productions() {
      if (this.isLoaded) {
        let prods = this.$store.getters["briefs/drafts"];

        let deps;
        if (this.departmentFilter === "") {
          deps = prods;
        } else {
          deps = prods.filter((p) => p.department === this.departmentFilter);
        }
        if (this.producerFilter !== "") {
          deps = deps.filter((p) => p.producer === this.producerFilter);
        }

        if (this.completed === true) {
        } else {
          deps = deps.filter((p) => !p.completed);
        }
        if (this.mine === true) {
          deps = deps.filter((prod) => {
            if (prod.owners) {
              return prod.owners.includes(this.user.id);
            }
          });
        }

        let search = deps.filter((o) =>
          o.title.toLowerCase().includes(this.search.toLowerCase())
        );
        search.sort((a, b) => {
          if (a.queueOrder < b.queueOrder) {
            return -1;
          }
          if (a.queueOrder > b.queueOrder) {
            return 1;
          }
          if (!a.queueOrder) {
            return 1;
          }
          return 0;
        });
        return search;
      }
    },
    departments() {
      if (this.isLoaded) {
        let pA = this.config.departments;
        let arr = [];
        pA.forEach(function (entry) {
          arr.push({
            text: entry,
            value: entry,
          });
        });
        return this.config.departments;
      }
    },
  },
  mounted() {
    if (localStorage.briefsArchive) {
      this.completed = JSON.parse(localStorage.briefsArchive);
    }
    // if department, set it
    this.departmentFilter = this.user.department ? this.user.department : "";
    // if producer, set it
    this.producerFilter = this.user.productionCompany
      ? this.user.productionCompany
      : "";

    if (localStorage.briefsMine) {
      this.mine = JSON.parse(localStorage.briefsMine);
    }
    // if the user as restrictions
    // if(this.restrictions) {
    //     this.$store.dispatch("briefs/fetchAndAdd", { limit: 0, where: this.restrictions });
    // } else {
    this.$store.dispatch("briefs/fetchAndAdd", { limit: 0 });

    // }
  },
  methods: {
    brief(prod) {
      router.push(`/brief/${prod.id}`);
    },
    onDelete(row) {
      if (confirm("Are you sure you want to delete this brief: " + row.title)) {
        // Save it!
        this.$store.dispatch("briefs/delete", row.id);
      } else {
        // Do nothing!
      }
    },
    filterPersist() {
      localStorage.briefsArchive = this.completed;

      localStorage.briefsMine = this.mine;
    },
    setUserField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;
      this.$store.dispatch("user/patch", payload);
    },
    openClose(prod) {
      if (this.openBrief === prod) {
        this.openBrief = "";
      } else {
        this.openBrief = prod;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("users/fetchAndAdd", { limit: 0 });
    this.$store.dispatch("resources/fetchAndAdd", {
      where: [["active", "==", true]],
    });
  },
};
</script>
<style>
.smaller-text {
  font-size: 0.9em;
}

.overdue {
  color: tomato;
}

.worklistIcon {
  font-size: 1.2em;
}

.m-xy-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.progress-percentage {
  display: none;
}
.progress-label {
  margin-bottom: 5px;
  margin-top: -5px;
}
</style>
