<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="$route.meta.navbarClasses ? $route.meta.navbarClasses : 'bg-success navbar-dark'"
    type=""
  >
    <!-- Search form -->
<!--
    <form class="navbar-search form-inline mr-sm-3"
          :class="$route.meta.searchClasses ? $route.meta.searchClasses : 'navbar-search-light'"
          id="navbar-search-main">
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text">
        </div>
      </div>
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </form>
-->
   <div class="col-lg-6 col-7">
     <div v-if="routeName==='Brief'"><h6 class="h2 text-white d-inline-block mb-0">{{brief.title}}</h6></div>
         <div v-else-if="routeName==='Deliverable'">
           <a @click="openBrief" class="h2 text-white d-inline-block navbar-link mb-0">{{production.title}}</a><h6 class="h2 text-white d-inline-block mb-0">&nbsp;> {{deliverable.title}}</h6>
         </div>
         <div v-else><h6 class="h2 text-white d-inline-block mb-0">{{routeName}}</h6></div>
        </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler sidenav-toggler-dark"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

    </ul>
      
  </base-nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import { BaseNav, Modal } from '@/components';
    import router from '../../routes/router'

  export default {
    components: {
      CollapseTransition,
      BaseNav,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
    deliverable() {
      return this.$store.getters["deliverables/delById"](this.$route.params.id)
    },
    production() {
      return this.$store.getters["briefs/briefById"](this.$route.params.prodId)
    },
    brief() {
      return this.$store.getters["briefs/briefById"](this.$route.params.id)
    },
      user () {
        return this.$store.getters['user/user']
      },
      isRTL() {
        return this.$rtl.isRTL;
      }
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: ''
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      openBrief() {
        router.push(`/brief/${this.$route.params.prodId}`);
      },
    }
  };
</script>
<style scoped>
  .top-navbar {
    top: 0px;
  }
  a.navbar-link {
    text-decoration: underline;
  }
</style>
